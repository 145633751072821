import { isEmpty } from "lodash";

import { useEffect, useState } from "react";
import { LazyLoadComponent } from "react-lazy-load-image-component";

import { getImageUrl, getUserId } from "../../../utils/utils";

import CustomSpinner from "../../../components/common/spinner/CustomSpinner";
import ClientFoldersLayout from "../../../components/layout/client-folders-layout/ClientFoldersLayout";
import CustomNavbar from "../../../components/layout/navbar/Navbar";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrgCollections } from "../../../services/collections.service";
import { fetchProfile } from "../../../services/profile.service";
import { toast } from "react-toastify";
import { STD_ERROR_MESSAGE } from "../../../utils/constant";
import { clearFetchOrgCollectionsEmStates } from "../../../redux/org-collections/orgCollections.action";

const OrgCollections = () => {
  const collectionsState = useSelector((state) => state.orgCollectionsEm);
  const profileState = useSelector((state) => state.readProfileEm);

  const profileData = profileState?.response?.data;
  const dispatch = useDispatch();

  const [collectionLoading, setCollectionLoading] = useState(true);

  const collections = collectionsState?.response?.data;

  const history = useHistory();
  const params = useParams();

  useEffect(() => {
    if (isEmpty(collectionsState.response) || collectionsState.reload)
      dispatch(getAllOrgCollections(params.partnerId));
    if (isEmpty(profileState.response)) {
      dispatch(fetchProfile(getUserId()));
    }

    return () => {
      dispatch(clearFetchOrgCollectionsEmStates());
    };
  }, []);

  useEffect(() => {
    if (!collectionsState.loading && !isEmpty(collectionsState.response)) {
      setCollectionLoading(false);
    }
    if (!collectionsState.loading && collectionsState.error) {
      setCollectionLoading(false);
      toast.error(
        collectionsState?.error?.data?.error_data || STD_ERROR_MESSAGE
      );
    }
  }, [collectionsState]);

  const handleCollectionTrigger = (collectionId) => {
    history.push(`/collection/${collectionId}`);
    if (window.scrollY) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <CustomNavbar userProfile={profileData} />
      {!collectionLoading ? (
        <div className="container pt-4 pb-1">
          {!isEmpty(collections) ? (
            <div className="mb-4">
              <h1 className="pb20-lh32 mb-3">Events</h1>
              <div className="collection-grid">
                {collections.map((collection) => (
                  <LazyLoadComponent key={collection?.id}>
                    <ClientFoldersLayout
                      thumbnail={getImageUrl(collection?.image?.bucket_path)}
                      collectionName={collection?.name}
                      handleCardClick={() =>
                        handleCollectionTrigger(collection.id)
                      }
                    />
                  </LazyLoadComponent>
                ))}
              </div>
            </div>
          ) : (
            <div className="userCenter">
              <h1 className="mb-4 pr20-lh32 high-emphasis-color text-center">
                You have no events yet.
              </h1>
            </div>
          )}
        </div>
      ) : (
        <CustomSpinner />
      )}
    </>
  );
};

export default OrgCollections;
