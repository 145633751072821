import React from "react";
import "./admin-authentication.css";
import Footer from "../../components/layout/footer/Footer";
import SignIn from "../../components/layout/sign-in-form/SignInForm";
import CustomNavbar from "../../components/layout/navbar/Navbar";
import { ROLE_CATEGORIES } from "../../utils/constant";

function Login() {
  return (
    <>
      <CustomNavbar />
      <div className="container-fluid p-0 authentication-bg">
        <div className="card authentication-card">
          <h1 className="authentication-title pb18-lh32">
            Admin Sign in to Picsea
          </h1>
          <SignIn category={ROLE_CATEGORIES.admin} />
          <hr className="solid m-0" />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Login;
