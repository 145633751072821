import {
  CLEAR_FETCH_RSVP_DATE_EM_STATES,
  FETCH_RSVP_DATE_EM_FAILURE,
  FETCH_RSVP_DATE_EM_SUCCESS,
  REQUEST_RSVP_DATE_FETCH_EM,
} from "./rsvpDate.types";

export const requestFetchRSVPDateEm = () => {
  return {
    type: REQUEST_RSVP_DATE_FETCH_EM,
  };
};

export const fetchRSVPDateEmSuccess = (response) => {
  return {
    type: FETCH_RSVP_DATE_EM_SUCCESS,
    payload: response,
  };
};

export const fetchRSVPDateEmFailure = (error) => {
  return {
    type: FETCH_RSVP_DATE_EM_FAILURE,
    payload: error,
  };
};

export const clearFetchRSVPDateEmStates = () => {
  return {
    type: CLEAR_FETCH_RSVP_DATE_EM_STATES,
  };
};
