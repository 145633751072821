import React from "react";
import CautionImg from "../../../../assets/image/caution.png";
import CloseIcon from "../../../../assets/icon/cancel.svg";
import { SECTION_GAMES_USER_COMPONENTS } from "../../../../utils/constant";

export default function Caution(props) {
  const { changeCurrComponent } = props;

  return (
    <div
      style={{
        flex: 1,
        position: "relative",
        backgroundColor: "var(--secondary-color)",
        padding: "3rem 1rem",
      }}
    >
      <img
        style={{ position: "absolute", top: "1rem", right: "1rem" }}
        className="pt-cursor"
        src={CloseIcon}
        onClick={() =>
          changeCurrComponent(SECTION_GAMES_USER_COMPONENTS.question)
        }
        alt=""
      />
      <div className="d-flex flex-column justify-content-center align-items-center mb-3">
        <h1 className="pb36-lh56 primary-color mb-4">Don't Rush!!!</h1>
        <h4 className="psb24-lh40 high-emphasis-color text-center">
          Answer all the questions first to check scores
        </h4>
      </div>
      <div
        style={{
          maxWidth: "500px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <img
          style={{
            width: "100%",
            maxWidth: "100%",
          }}
          alt=""
          src={CautionImg}
        />
      </div>
    </div>
  );
}
