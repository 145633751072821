import {
  fetchLiveFeedsEmFailure,
  fetchLiveFeedsEmSuccess,
  requestFetchLiveFeedsEm,
} from "../redux/live-feed/liveFeed.action";
import ProtectedWebClient from "../utils/protected-web-client";

export const getAllFeeds = (collectionId) => {
  return async (dispatch) => {
    try {
      dispatch(requestFetchLiveFeedsEm());
      const response = await ProtectedWebClient.get(
        `/event/${collectionId}/feed`
      );
      dispatch(fetchLiveFeedsEmSuccess(response?.data));
    } catch (err) {
      dispatch(fetchLiveFeedsEmFailure(err?.response));
    }
  };
};

export const createLiveFeedPromise = (collectionId, payload) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.post(`/event/${collectionId}/feed`, payload)
      .then((res) => {
        resolve(res?.data);
      })
      .catch((err) => {
        reject(err?.response);
      });
  });
};

export const liveFeedLikeOrUnlikePromise = (
  collectionId,
  liveFeedId,
  payload
) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.post(
      `/event/${collectionId}/feed/${liveFeedId}`,
      payload
    )
      .then((res) => {
        resolve(res?.data);
      })
      .catch((err) => {
        reject(err?.response);
      });
  });
};

export const deleteLiveFeedByIdPromise = (collectionId, liveFeedId) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.delete(`/event/${collectionId}/feed/${liveFeedId}`)
      .then((res) => {
        resolve(res?.data);
      })
      .catch((err) => {
        reject(err?.response);
      });
  });
};

// entity
export const getAllEntityFeeds = (entityId) => {
  return async (dispatch) => {
    try {
      dispatch(requestFetchLiveFeedsEm());
      const response = await ProtectedWebClient.get(`/entity/${entityId}/feed`);
      dispatch(fetchLiveFeedsEmSuccess(response?.data));
    } catch (err) {
      dispatch(fetchLiveFeedsEmFailure(err?.response));
    }
  };
};

export const createEntityLiveFeedPromise = (entityId, payload) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.post(`/entity/${entityId}/feed`, payload)
      .then((res) => {
        resolve(res?.data);
      })
      .catch((err) => {
        reject(err?.response);
      });
  });
};

export const entityLiveFeedLikeOrUnlikePromise = (
  entityId,
  liveFeedId,
  payload
) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.post(`/entity/${entityId}/feed/${liveFeedId}`, payload)
      .then((res) => {
        resolve(res?.data);
      })
      .catch((err) => {
        reject(err?.response);
      });
  });
};

export const deleteEntityLiveFeedByIdPromise = (entityId, liveFeedId) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.delete(`/entity/${entityId}/feed/${liveFeedId}`)
      .then((res) => {
        resolve(res?.data);
      })
      .catch((err) => {
        reject(err?.response);
      });
  });
};
