import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, size } from "lodash";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import CollectionHeaderPartner from "../collection-header-partner/CollectionHeaderPartner";
import {
  SECTION_KNOW_THE_HOST_COMPONENTS,
  STD_ERROR_MESSAGE,
} from "../../../utils/constant";
import { getImageUrl } from "../../../utils/utils.js";

import editIcon from "../../../assets/icon/edit.svg";
import deleteIcon from "../../../assets/icon/delete.svg";
import { fetchKnowTheEntitySection } from "../../../services/knowTheHost.service";
import { deleteKnowTheEntitySectionBySequencePromise } from "../../../services/knowTheHost.service";
import KnowTheEntitySectionEdit from "../know_the_entity_edit/KnowTheEntityEdit";

const KnowTheEntity = (props) => {
  const knowTheHostState = useSelector((state) => state.knowTheHostEm);
  const dispatch = useDispatch();
  const params = useParams();
  const [editItem, setEditItem] = useState([]);

  const knowTheHostSections = knowTheHostState?.response?.data;

  function handleAddBtn() {
    props.setCurrentMainComponent((prevState) => {
      return {
        currComponent:
          SECTION_KNOW_THE_HOST_COMPONENTS.add_know_the_host_sections,
        prevComponent: prevState.component,
      };
    });
  }

  function handleSectionDelete(sequence) {
    deleteKnowTheEntitySectionBySequencePromise(params.entityId, sequence)
      .then((res) => {
        dispatch(fetchKnowTheEntitySection(params.entityId));
        toast.success("Deletion successful");
      })
      .catch((err) => {
        toast.error(
          !isEmpty(err?.data?.error_data)
            ? err?.data?.error_data
            : STD_ERROR_MESSAGE
        );
      });
  }

  function handleCancelBtnClick(id) {
    setEditItem((ps) => {
      return ps.filter((item) => item !== id);
    });
  }

  return (
    <main
      style={{ maxWidth: "850px", marginLeft: "auto", marginRight: "auto" }}
    >
      <CollectionHeaderPartner
        title={`About Entity(${size(knowTheHostSections)})`}
        addPhotosBtn={true}
        addPhotosLabel="Add About Entity"
        handleAddPhotosBtn={handleAddBtn}
      />
      <div className="mt-3">
        {!isEmpty(knowTheHostSections) ? (
          knowTheHostSections.map((kthSection, index) =>
            editItem.includes(kthSection.id) ? (
              <KnowTheEntitySectionEdit
                key={kthSection.id}
                onCancelClick={() => handleCancelBtnClick(kthSection.id)}
                kthSection={kthSection}
              />
            ) : (
              <div
                className="d-flex p-3 mb-3"
                style={{
                  border: "1px solid rgba(41, 41, 41, 0.38)",
                  borderRadius: "8px",
                }}
                key={kthSection.id}
              >
                <div
                  className="secondary-color-bg"
                  style={{
                    width: "180px",
                    height: "180px",
                    borderRadius: "50%",
                  }}
                >
                  <div className="h-100 position-relative">
                    <img
                      src={getImageUrl(kthSection?.image?.bucket_path)}
                      alt=""
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "50%",
                      }}
                    />
                  </div>
                </div>
                <div className="flex-1 ml-3">
                  <div>
                    <div className="mb-1 d-flex justify-content-between">
                      <h3 className="pb18-lh32 high-emphasis-color">
                        {kthSection.title}
                      </h3>
                      <div>
                        <img
                          style={{
                            width: "1.25rem",
                            height: "1.25rem",
                          }}
                          className="ml-2 pt-cursor"
                          src={editIcon}
                          alt=""
                          onClick={() =>
                            setEditItem((ps) => {
                              return [...ps, kthSection.id];
                            })
                          }
                        />
                        <img
                          style={{
                            width: "1.25rem",
                            height: "1.25rem",
                          }}
                          className="ml-2 pt-cursor"
                          src={deleteIcon}
                          alt=""
                          onClick={() =>
                            handleSectionDelete(kthSection.sequence)
                          }
                        />
                      </div>
                    </div>
                    <div>
                      <p className="pr16-lh24">{kthSection.custom_text}</p>
                    </div>
                  </div>
                </div>
              </div>
            )
          )
        ) : (
          <h2 className="text-center pr20-lh32 high-emphasis-color mt-5">
            You have no sections in this collection
          </h2>
        )}
      </div>
    </main>
  );
};

export default KnowTheEntity;
