import {
  fetchResidentsAdFailure,
  fetchResidentsAdSuccess,
  requestFetchResidentsAdReducer,
} from "../redux/residents/residents.action";
import protectedWebClient from "../utils/protected-web-client";

export const createResidentsPromise = (payload, collectionId, id) => {
  return new Promise((resolve, reject) => {
    protectedWebClient
      .post(`/event/${collectionId}/resident`, payload)
      .then((res) => {
        resolve({ id: id, res: res });
      })
      .catch((err) => {
        reject({ id: id, err: err?.response });
      });
  });
};

export const fetchResidents = (collectionId) => {
  return async (dispatch) => {
    try {
      dispatch(requestFetchResidentsAdReducer());
      const response = await protectedWebClient.get(
        `/event/${collectionId}/resident`
      );
      dispatch(fetchResidentsAdSuccess(response?.data));
    } catch (error) {
      dispatch(fetchResidentsAdFailure(error?.response));
    }
  };
};

export const deleteResidentsbySequencePromise = (collectionId, sequence) => {
  return new Promise((resolve, reject) => {
    protectedWebClient
      .delete(`/event/${collectionId}/resident/${sequence}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err?.response);
      });
  });
};

export const updateResidentsBySequencePromise = (
  payload,
  collectionId,
  sequence
) => {
  return new Promise((resolve, reject) => {
    protectedWebClient
      .put(`/event/${collectionId}/resident/${sequence}`, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err?.response);
      });
  });
};
