export const REQUEST_QUESTIONS_FETCH_EM = "REQUEST_QUESTIONS_FETCH_EM";
export const FETCH_QUESTIONS_EM_SUCCESS = "FETCH_QUESTIONS_EM_SUCCESS";
export const FETCH_QUESTIONS_EM_FAILURE = "FETCH_QUESTIONS_EM_FAILURE";

export const CHANGE_GAME_STARTED_STATE = "CHANGE_GAME_STARTED_STATE";
export const SAVE_USER_RESPONSE_STATE = "SAVE_USER_RESPONSE_STATE";
export const CHANGE_TO_NEXT_QUESTION_STATE = "CHANGE_TO_NEXT_QUESTION_STATE";

export const CLEAR_FETCH_QUESTIONS_EM_STATES =
  "CLEAR_FETCH_QUESTIONS_EM_STATES";
