import {
  requestProfile,
  readProfileSuccess,
  readProfileFailure,
} from "../redux/profile-read/readProfile.action";
import protectedWebClient from "../utils/protected-web-client";
import { getUserId } from "../utils/utils";

export const fetchProfile = () => {
  return async (dispatch) => {
    try {
      dispatch(requestProfile());
      const response = await protectedWebClient.get(`/user/${getUserId()}`);
      dispatch(readProfileSuccess(response.data));
    } catch (error) {
      dispatch(readProfileFailure(error?.response));
    }
  };
};

export const updateProfilePromise = (payload) => {
  return new Promise((resolve, reject) => {
    protectedWebClient
      .put(`/user/${getUserId()}`, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
