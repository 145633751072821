import {
  CLEAR_FETCH_RESIDENTS_AD_STATES,
  FETCH_RESIDENTS_AD_FAILURE,
  FETCH_RESIDENTS_AD_SUCCESS,
  REQUEST_RESIDENTS_FETCH_AD,
} from "./residents.types";

export const requestFetchResidentsAdReducer = () => {
  return {
    type: REQUEST_RESIDENTS_FETCH_AD,
  };
};

export const fetchResidentsAdSuccess = (response) => {
  return {
    type: FETCH_RESIDENTS_AD_SUCCESS,
    payload: response,
  };
};

export const fetchResidentsAdFailure = (error) => {
  return {
    type: FETCH_RESIDENTS_AD_FAILURE,
    payload: error,
  };
};

export const clearFetchresidentsAdStates = () => {
  return {
    type: CLEAR_FETCH_RESIDENTS_AD_STATES,
  };
};
