import React from "react";
import "react-phone-number-input/style.css";
import Input from "react-phone-number-input";

import "./phone-input.css";
import SimpleInputBox from "../../simple-input-box/SimpleInputBox";

const PhoneInput = (props) => {
  return (
    <Input
      international
      defaultCountry="IN"
      inputComponent={SimpleInputBox}
      {...props}
    />
  );
};

export default PhoneInput;
