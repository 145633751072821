import React, { useState, useEffect } from "react";
import { isEmpty, size } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import Carousel from "react-bootstrap/Carousel";
import { toast } from "react-toastify";
import BackArrow from "../../../assets/icon/back_arrow.svg";

import "./single-image-view-with-carousel.css";

import { getImageUrl } from "../../../utils/utils";
import { STD_ERROR_MESSAGE } from "../../../utils/constant";

import {
  getAllEntityGallery,
  getImagesOfEntityGalleryFolder,
} from "../../../services/gallery.service";
import CustomSpinner from "../../../components/common/spinner/CustomSpinner";
import { getAnEntity } from "../../../services/entities.service";

const EntitySingleImageViewWithCarousel = (props) => {
  // const isMobile = useMediaQuery({ query : '(max-width : 480px)'})

  const { currImageIndex, entityId, folderId, handleClose, handleSelect } =
    props;
  const dispatch = useDispatch();
  const entityState = useSelector((state) => state.entityAd);
  const galleryState = useSelector((state) => state.galleryEm);
  const galleryFoldersState = useSelector((state) => state.galleryFoldersEm);

  const [pageLoading, setPageLoading] = useState({
    entity: true,
    gallery: true,
    folder: true,
  });

  const [currGalleryFolderInfo, setCurrGalleryFolderInfo] = useState({});

  const entityInfo = entityState.response?.data;
  const galleryInfo = galleryState.response?.data;
  const currGalleryFolderData =
    galleryFoldersState.response?.[currGalleryFolderInfo?.id]?.data;

  useEffect(() => {
    if (isEmpty(entityState.response?.data)) {
      dispatch(getAnEntity(entityId));
    }
    if (isEmpty(galleryState.response?.data)) {
      dispatch(getAllEntityGallery(entityId));
    }
  }, []);

  useEffect(() => {
    if (!entityState.loading && !isEmpty(entityState.response)) {
      setPageLoading((ps) => {
        return {
          ...ps,
          entity: false,
        };
      });
    }
    if (!entityState.loading && !isEmpty(entityState.error)) {
      setPageLoading((ps) => {
        return {
          ...ps,
          entity: false,
        };
      });
      toast.error(entityState.error?.data?.error_data || STD_ERROR_MESSAGE);
    }
  }, [entityState]);

  useEffect(() => {
    if (!galleryState.loading && !isEmpty(galleryState.response)) {
      setPageLoading((ps) => {
        return {
          ...ps,
          gallery: false,
        };
      });
      const currGalleryInfo = galleryInfo.find(
        (folder) => folder.id === folderId
      );
      if (!isEmpty(currGalleryInfo)) {
        setCurrGalleryFolderInfo(currGalleryInfo);
      }
    }
    if (!galleryState.loading && !isEmpty(galleryState.error)) {
      setPageLoading((ps) => {
        return {
          ...ps,
          gallery: false,
        };
      });
      toast.error(galleryState.error?.data?.error_data || STD_ERROR_MESSAGE);
    }
  }, [galleryState]);

  useEffect(() => {
    if (!isEmpty(currGalleryFolderInfo)) {
      if (isEmpty(galleryFoldersState.response?.[currGalleryFolderInfo?.id])) {
        if (!pageLoading.folder)
          setPageLoading((ps) => ({ ...ps, folder: true }));
        dispatch(
          getImagesOfEntityGalleryFolder(entityId, currGalleryFolderInfo.id)
        );
      }
    }
  }, [currGalleryFolderInfo]);

  useEffect(() => {
    if (!isEmpty(currGalleryFolderInfo)) {
      if (
        !galleryFoldersState.loading &&
        !isEmpty(galleryFoldersState.response?.[currGalleryFolderInfo?.id])
      ) {
        setPageLoading((ps) => {
          return {
            ...ps,
            folder: false,
          };
        });
      }
      if (!galleryFoldersState.loading && !isEmpty(galleryFoldersState.error)) {
        setPageLoading((ps) => {
          return {
            ...ps,
            folder: false,
          };
        });
        toast.error(
          galleryFoldersState.error?.data?.error_data || STD_ERROR_MESSAGE
        );
      }
    }
  }, [galleryFoldersState, currGalleryFolderInfo]);

  return !pageLoading.entity ? (
    <div style={{ minHeight: "100vh" }} className="d-flex flex-column">
      <div className="header-single-image-view">
        <div className="pt-2">
          <img
            className="pt-cursor"
            src={BackArrow}
            alt=""
            onClick={() => handleClose()}
          />
        </div>
        <div className="d-flex flex-column ml-3">
          <h3 className="pb32-lh40 primary-color mb-0">
            {currGalleryFolderInfo?.title || ""}
          </h3>
          <div className="d-flex py-2">
            {size(currGalleryFolderData) ? (
              <>
                <span className="pb16-lh24 mb-0 high-emphasis-color">
                  {currImageIndex + 1}/{size(currGalleryFolderData)}
                </span>
                <span className="pb16-lh24 mb-0 high-emphasis-color px-2">
                  |
                </span>
                <span className="pr16-lh24 mb-0">{entityInfo?.name || ""}</span>
              </>
            ) : null}
          </div>
        </div>
      </div>
      <div className="d-flex flex-fill">
        <main className="flex-fill p-relative">
          {!pageLoading.folder ? (
            !isEmpty(currGalleryFolderData) ? (
              <div className="single-image-view-carousel-wrapper">
                <Carousel
                  indicators={false}
                  interval={null}
                  wrap={false}
                  activeIndex={currImageIndex}
                  onSelect={handleSelect}
                >
                  {currGalleryFolderData.map((image, index) => (
                    <Carousel.Item key={image.id}>
                      <img src={getImageUrl(image.image?.bucket_path)} alt="" />
                    </Carousel.Item>
                  ))}
                </Carousel>
              </div>
            ) : (
              <h4 className="text-center pr20-lh32 high-emphasis-color mt-5">
                No images found
              </h4>
            )
          ) : (
            <CustomSpinner />
          )}
        </main>
      </div>
    </div>
  ) : null;
};

export default EntitySingleImageViewWithCarousel;
