import ProtectedWebClient from "../utils/protected-web-client";
import {
  requestFetchInvitesEm,
  fetchInvitesEmSuccess,
  fetchInvitesEmFailure,
} from "../redux/invites/invites.action";

export const getAllInvitesInCollection = (collectionId) => {
  return async (dispatch) => {
    try {
      dispatch(requestFetchInvitesEm());
      const response = await ProtectedWebClient.get(
        `/event/${collectionId}/invitation`
      );
      dispatch(fetchInvitesEmSuccess(response?.data));
    } catch (error) {
      dispatch(fetchInvitesEmFailure(error?.response?.data));
    }
  };
};

export const createInvitesPromise = (collectionId, payload) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.post(`/event/${collectionId}/invitation`, payload)
      .then((res) => {
        resolve(res?.data);
      })
      .catch((error) => {
        reject(error?.data);
      });
  });
};

export const deleteInviteImageBySequencePromise = (collectionId, sequence) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.delete(`/event/${collectionId}/invitation/${sequence}`)
      .then((res) => {
        resolve(res?.data);
      })
      .catch((error) => {
        reject(error?.data);
      });
  });
};
