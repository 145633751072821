import React, { useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";

import "./reset-password.css";

import {
  resetPassword,
  updateNewPassword,
} from "../../services/firebase-auth.service";
import { STD_ERROR_MESSAGE } from "../../utils/constant";

import ButtonPrimary from "../../components/common/button-accent/ButtonAccent";
import InputBox from "../../components/common/input-box/InputBox";
import CustomNavbar from "../../components/layout/navbar/Navbar";

const ResetPassword = (props) => {
  const history = useHistory();
  const location = useLocation();

  const query = queryString.parse(location.search);

  const initialValues = {
    // currentPassword: '',
    newPassword: "",
  };

  const onSubmit = (values) => {
    const { oobCode, mode, continueUrl } = query;
    if (mode && mode === "resetPassword") {
      resetPassword(oobCode, values.newPassword)
        .then((res) => {
          toast.success("Password reset successful.");

          if (!continueUrl) history.push("/");
          else {
            const redirectUrl = new URL(continueUrl);
            history.push(redirectUrl.pathname + redirectUrl.search);
          }
        })
        .catch((err) => {
          const errorCode = err.code;
          toast.error(errorCode || STD_ERROR_MESSAGE);
        });
    } else {
      updateNewPassword(values.newPassword)
        .then((res) => {
          toast.success("Password reset successful.");
          history.goBack();
        })
        .catch((err) => {
          const errorCode = err.code;
          toast.error(errorCode || STD_ERROR_MESSAGE);
        });
    }
  };

  const validationSchema = Yup.object({
    // currentPassword: Yup.string()
    //                     .when([], {
    //                         is : () => query.mode && query.mode === "resetPassword",
    //                         then : Yup.string().notRequired(),
    //                         otherwise : Yup.string().required("Please enter your current password")
    //                     }),
    newPassword: Yup.string()
      .required("Please enter your new password")
      .test(
        "len",
        "Must contain atleast 1 character and must not contain exceeds more than 15 Characters",
        (val) => {
          return val && val.trim().length < 16 && val.trim().length > 0;
        }
      ),
  });

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  return (
    <>
      <CustomNavbar />
      <div className="content">
        <div className="card reset-password-card">
          <p className="pb14-lh21 stext-center">
            {" "}
            Password Reset.
            <span className="pr14-lh24 ml-1">Enter the password</span>
          </p>
          <form onSubmit={formik.handleSubmit}>
            {/* <InputBox type='password' 
                            name='currentPassword'
                            placeholder='Current Password' 
                            value={ formik.values.currentPassword } 
                            onChange={formik.handleChange} 
                            errorMsg={formik.touched.currentPassword && formik.errors.currentPassword} 
                            onBlur={formik.handleBlur} /> */}
            <InputBox
              type="password"
              name="newPassword"
              placeholder="New Password"
              value={formik.values.newPassword}
              onChange={formik.handleChange}
              errorMsg={formik.touched.newPassword && formik.errors.newPassword}
              onBlur={formik.handleBlur}
            />
            <ButtonPrimary
              type="submit"
              label="SAVE"
              className="w-100 pb14-lh21"
            />
          </form>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
