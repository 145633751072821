import { 
    REQUEST_INVITES_FETCH_EM,
    FETCH_INVITES_EM_SUCCESS,
    FETCH_INVITES_EM_FAILURE,
    CLEAR_FETCH_INVITES_EM_STATES
} from './invites.types';


export const requestFetchInvitesEm = () => {
    return {
        type : REQUEST_INVITES_FETCH_EM,
    }
}

export const fetchInvitesEmSuccess = (response) => {
    return {
        type : FETCH_INVITES_EM_SUCCESS,
        payload : response
    }
}

export const fetchInvitesEmFailure = (error) => {
    return {
        type : FETCH_INVITES_EM_FAILURE,
        payload : error
    }
}

export const clearFetchInvitesEmStates = () => {
    return {
        type : CLEAR_FETCH_INVITES_EM_STATES
    }
}