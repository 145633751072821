import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import { useSelector, useDispatch } from "react-redux";
import { size } from "lodash";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import CollectionHeaderPartner from "../collection-header-partner/CollectionHeaderPartner";
import Image from "../../common/v2/image/Image";
import { getImageUrl } from "../../../utils/utils";
import {
  deleteItineraryPromise,
  getAllItineraryInCollection,
} from "../../../services/itinerary.service";
import DeletePopup from "../delete-popup/DeletePopup";
import {
  SECTION_ITINERARY_COMPONENTS,
  STD_ERROR_MESSAGE,
} from "../../../utils/constant";

const ItineraryList = (props) => {
  const itineraryState = useSelector((state) => state.itineraryEm);
  const itineraryData = itineraryState.response.data;
  const params = useParams();
  const dispatch = useDispatch();
  const [deletePopup, setDeletePopup] = useState({ open: false, id: null });

  function handleDeleteItinerary() {
    deleteItineraryPromise(params.collectionId, deletePopup.id)
      .then((res) => {
        dispatch(getAllItineraryInCollection(params.collectionId));
        closeDeletePopup();
      })
      .catch((err) => {
        toast.error(err?.error_data || STD_ERROR_MESSAGE);
      });
  }

  function closeDeletePopup() {
    setDeletePopup({ open: false, id: null });
  }

  function openDeletePopup(id) {
    setDeletePopup({ open: true, id: id });
  }

  function itineraryGuestList(itineraryObj) {
    props.setFileData(itineraryObj);
    props.setCurrentMainComponent((ps) => {
      return {
        ...ps,
        currComponent: SECTION_ITINERARY_COMPONENTS.itinerary_guest_list,
        prevComponent: ps.currComponent,
      };
    });
  }

  return (
    <main
      style={{ maxWidth: "850px", marginLeft: "auto", marginRight: "auto" }}
    >
      <CollectionHeaderPartner
        title={`Itinerary(${size(itineraryData)})`}
        backToCollectionsBtn={true}
        addPhotosBtn={true}
        addPhotosLabel="Add Itinerary"
        handleAddPhotosBtn={props.onItineraryButtonClick}
        generalBtn={true}
        generalBtnLabel={"Master Invite List"}
        onGeneralBtnClick={props.onMasterInviteBtnClick}
      />
      <div style={{ marginTop: "27px" }}>
        {size(itineraryData) ? (
          <Grid container spacing={2}>
            {itineraryData.map((itinerary, index) => (
              <Grid item sm={12} md={6} lg={6} xl={6} key={index}>
                <LazyLoadComponent>
                  <Image
                    variant="withHover"
                    enableDeleteIcon={true}
                    enableShareIcon={true}
                    src={getImageUrl(itinerary.image.bucket_path)}
                    alt=""
                    onDeleteIconClick={() =>
                      openDeletePopup(itinerary.sequence)
                    }
                    onShareIconClick={() => itineraryGuestList(itinerary)}
                  />
                </LazyLoadComponent>
              </Grid>
            ))}
          </Grid>
        ) : (
          <h2 className="text-center pr20-lh32 high-emphasis-color mt-5">
            You have no itinerary in this collection
          </h2>
        )}
      </div>
      {deletePopup.open && (
        <DeletePopup
          heading="DELETE IMAGE"
          text="The image will be deleted permanently. This cannot be undone."
          handleDelete={handleDeleteItinerary}
          handleClose={closeDeletePopup}
        />
      )}
    </main>
  );
};

export default ItineraryList;
