import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty, size } from "lodash";
import moment from "moment";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { LazyLoadComponent } from "react-lazy-load-image-component";

import "./collections.css";

import CreateCollectionPopup from "../../../components/layout/create-collection-popup/CreateCollectionPopup";
import ButtonAccent from "../../../components/common/button-accent/ButtonAccent";
import CustomNavbar from "../../../components/layout/navbar/Navbar";
import rightSideBackArrow from "../../../assets/icon/right_side_back_arrow.svg";
import { getImageUrl, getUserId } from "../../../utils/utils";
import CustomSpinner from "../../../components/common/spinner/CustomSpinner";
// import placeholder from "../../../assets/placeholder/collection_thumbnail_fill_200x112.svg";
import DeletePopup from "../../../components/layout/delete-popup/DeletePopup";
import deleteIcon from "../../../assets/icon/delete.svg";

import {
  getAllCollections,
  deleteCollection,
} from "../../../services/collections.service";
import { EVENT_TYPES, STD_ERROR_MESSAGE } from "../../../utils/constant";
import Image from "../../../components/common/v2/image/Image";

const Collections = (props) => {
  const collectionsState = useSelector((state) => state.collectionsEm);
  const dispatch = useDispatch();

  const [createPopup, setCreatePopup] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [deletePopup, setDeletePopup] = useState({ open: false, id: null });

  const collections = collectionsState?.response?.data;

  const history = useHistory();

  const toggleCreateColPopup = () => {
    setCreatePopup(!createPopup);
  };

  useEffect(() => {
    if (isEmpty(collectionsState.response) || collectionsState.reload) {
      dispatch(getAllCollections());
    }
  }, []);

  useEffect(() => {
    if (!collectionsState.loading && !isEmpty(collectionsState.response)) {
      setPageLoading(false);
    }
    if (!collectionsState.loading && collectionsState.error) {
      setPageLoading(false);
      toast.error(
        collectionsState?.error?.data?.error_data || STD_ERROR_MESSAGE
      );
    }
  }, [collectionsState]);

  const handleCollectionTrigger = (collectionId) => {
    history.push(`/partner/${getUserId()}/collection/${collectionId}`);
  };

  function handleDeletePopupOpen(id) {
    setDeletePopup((ps) => {
      return {
        ...ps,
        open: true,
        id: id,
      };
    });
  }

  function handleDeletePopupClose() {
    setDeletePopup((ps) => {
      return {
        ...ps,
        open: false,
        id: null,
      };
    });
  }

  const handleDeleteCollection = () => {
    deleteCollection(deletePopup.id)
      .then((res) => {
        dispatch(getAllCollections());
        handleDeletePopupClose();
      })
      .catch((error) => {
        handleDeletePopupClose();
        if (!isEmpty(error?.data)) {
          toast.error(error.data?.error_data || STD_ERROR_MESSAGE);
        }
      });
  };

  const getEventType = (id) => {
    switch (id) {
      case EVENT_TYPES.birthday:
        return "Birthday";
      case EVENT_TYPES.wedding:
        return "Wedding";
      case EVENT_TYPES.apartment:
        return "Apartment";
      case EVENT_TYPES.bnichapter:
        return "BNI Chapter";
      default:
        return "Event";
    }
  };

  return (
    <>
      <CustomNavbar />
      {!pageLoading ? (
        <div className="container">
          <div className="d-flex justify-content-between align-items-center mt-4 mb-3">
            <h1 className="pb20-lh32 mb-0">Collections</h1>
            {size(collections) ? (
              <ButtonAccent
                label="Create New"
                startIcon="fas fa-plus btn-icon-14 mr-1"
                onClick={toggleCreateColPopup}
              />
            ) : null}
          </div>
          {!isEmpty(collections) ? (
            <Grid container spacing={2}>
              {collections &&
                collections.map((collection, index) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={4}
                    xl={4}
                    key={collection.event.id}
                  >
                    <div className="collection-card">
                      <LazyLoadComponent>
                        <Image
                          src={getImageUrl(
                            collection?.event?.image?.bucket_path
                          )}
                          alt=""
                          containerCustomStyle={{
                            width: "100%",
                            height: "auto",
                            aspectRatio: "16/9",
                            cursor: "pointer",
                          }}
                          otherContainerProps={{
                            onClick: () =>
                              handleCollectionTrigger(collection.event.id),
                          }}
                          imageCustomStyle={{
                            objectFit: "cover",
                            objectPosition: "center",
                            width: "100%",
                            height: "100%",
                          }}
                        />
                      </LazyLoadComponent>
                      <div className="card-heading">
                        <h5 className="pb16-lh24">{collection.event.name}</h5>
                        <div className="collection-details pr12-lh24">
                          {collection.event.start_date ? (
                            <>
                              <span>
                                {moment(collection.event.start_date).format(
                                  "MMMM Do, YYYY"
                                )}
                              </span>
                              {collection.event.category_id ? (
                                <>
                                  <span>|</span>
                                  <span>
                                    {getEventType(collection.event.category_id)}
                                  </span>
                                </>
                              ) : null}
                            </>
                          ) : null}

                          {!collection.event.published && (
                            <img
                              className="mb-5 pt-cursor"
                              src={deleteIcon}
                              alt="delete icon"
                              onClick={() =>
                                handleDeletePopupOpen(collection.event.id)
                              }
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </Grid>
                ))}
            </Grid>
          ) : (
            <div className="center">
              <h1 className="mb-4 pr20-lh32 high-emphasis-color text-center">
                Looks like you are new here
              </h1>
              <ButtonAccent
                onClick={toggleCreateColPopup}
                className="pb14-lh21 px-3 py-2"
                label="Create your first collection"
                endIcon={rightSideBackArrow}
                endIconClassName="pl-2"
                variant="icon-button"
                labelClassName="align-middle"
              />
            </div>
          )}
        </div>
      ) : (
        <CustomSpinner />
      )}
      {deletePopup.open && (
        <DeletePopup
          heading="DELETE COLLECTION"
          text="The collection will be deleted permanently. This cannot be undone."
          handleDelete={handleDeleteCollection}
          handleClose={handleDeletePopupClose}
        />
      )}
      {createPopup && (
        <CreateCollectionPopup
          setLoader={setPageLoading}
          handleClose={toggleCreateColPopup}
        />
      )}
    </>
  );
};

export default Collections;
