import {
  REQUEST_FETCH_ENTITIES_AD,
  FETCH_ENTITIES_AD_SUCCESS,
  FETCH_ENTITIES_AD_FAILURE,
  CLEAR_FETCH_ENTITIES_AD_STATES,
} from "./entities.types";

let initialState = {
  reload: false,
  response: {},
  error: null,
  loading: false,
};

const entitiesAdReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_FETCH_ENTITIES_AD:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_ENTITIES_AD_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
        reload: false,
      };

    case FETCH_ENTITIES_AD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        reload: false,
      };

    case CLEAR_FETCH_ENTITIES_AD_STATES:
      return {
        ...state,
        loading: false,
        response: {},
        error: null,
        reload: false,
      };

    default:
      return state;
  }
};

export default entitiesAdReducer;
