import React, { useState } from "react";
import { toast } from "react-toastify";
import { STD_ERROR_MESSAGE } from "../../../utils/constant";

import * as Yup from "yup";
import errorInfoIcon from "../../../assets/icon/error_icon_red.svg";
import { Formik, Form, Field, FieldArray } from "formik";
import cancelIcon from "../../../assets/icon/cancel.svg";
import "./AddInfo.css";
import "./InfoQuery.css";
import { addInformationToEnquiryPromise } from "../../../services/addInfo.sevice";
import { isEmpty } from "lodash";

const AddInfoPopup = (props) => {
  const [disabled, setDisabled] = useState(false);
  const { entityId } = props;

  const { handleClose } = props;

  const initialValues = {
    client: {
      name: "",
      mobile: "",
    },
  };

  const validationSchema = Yup.object().shape({
    client: Yup.object().shape({
      name: Yup.string().required("Please enter name"),
      mobile: Yup.string().required("Please enter your mobile number."),
    }),
  });

  const onSubmit = (values) => {
    setDisabled(true);
    const payload = {
      name: values.client.name,
      mobile: values.client.mobile.toString(),
    };
    addInformationToEnquiryPromise(entityId, payload)
      .then((response) => {
        toast.info("We'll get in touch with you shortly");
        handleClose();
      })
      .catch((err) => {
        handleClose();
        toast.error(err?.data?.error_data || STD_ERROR_MESSAGE);
      });
  };

  const checkForErrors = (errors, touched) => {
    const errorsObj = errors?.client;
    const touchedObj = touched?.client;

    if (isEmpty(errorsObj) || isEmpty(touchedObj)) return null;

    if (errorsObj.name && touchedObj.name) {
      return errorElement(errorsObj.name);
    } else if (errorsObj.mobile && touchedObj.mobile) {
      return errorElement(errorsObj.mobile);
    } else {
      return null;
    }

    function errorElement(msg) {
      return (
        <div className="d-flex align-items-center mt-3 pr12-lh18 error-color">
          <img
            className="mr-2"
            style={{ width: "16px", height: "16px" }}
            src={errorInfoIcon}
            alt=""
          />
          <span className="mb-0">{msg}</span>
        </div>
      );
    }
  };

  return (
    <div className="addInfoPopup">
      <div>
        <div className="addInfoBox">
          <img
            className="close-icon"
            onClick={handleClose}
            src={cancelIcon}
            alt=""
          />
          <div className="infoBox">
            <h1 className="infoText">
              {" "}
              Enter your name & Mobile no. to get in touch{" "}
            </h1>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {(formik) => (
                <Form>
                  <FieldArray name="client">
                    {(fieldArrayProps) => {
                      const { form } = fieldArrayProps;
                      const { values, errors, touched } = form;

                      return (
                        <div>
                          <div className="details">
                            <Field name="client.name">
                              {(props) => {
                                const { field, meta } = props;
                                return (
                                  <>
                                    <input
                                      className="text"
                                      type="text"
                                      placeholder=" Enter your Name"
                                      {...field}
                                    />
                                  </>
                                );
                              }}
                            </Field>
                            <div className="mb-3">
                              <Field name="client.mobile">
                                {(props) => {
                                  const { field, meta } = props;
                                  return (
                                    <>
                                      <input
                                        className="text"
                                        type="number"
                                        placeholder="Enter mobile no"
                                        {...field}
                                      />
                                    </>
                                  );
                                }}
                              </Field>
                            </div>
                          </div>
                          {checkForErrors(errors, touched)}

                          <div className="mt-4 d-flex justify-content-center">
                            <button
                              className="infoButton"
                              disabled={disabled}
                              type="submit"
                              label="Get A call"
                            >
                              <h5>Get a call</h5>
                            </button>
                          </div>
                        </div>
                      );
                    }}
                  </FieldArray>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddInfoPopup;
