import {
  requestFetchEntitiesAd,
  fetchEntitiesAdSuccess,
  fetchEntitiesAdFailure,
} from "../redux/entities/entities.action";

import {
  requestFetchEntityAd,
  fetchEntityAdSuccess,
  fetchEntityAdFailure,
} from "../redux/entity/entity.action";

import {
  requestFetchPermalinkAd,
  fetchPermalinkAdSuccess,
  fetchPermalinkAdFailure,
} from "../redux/permalink/permalink.action";

import ProtectedWebClient from "../utils/protected-web-client";

export const getAllEntities = () => {
  return async (dispatch) => {
    try {
      dispatch(requestFetchEntitiesAd());
      const response = await ProtectedWebClient.get(`/entity`);
      dispatch(fetchEntitiesAdSuccess(response?.data));
    } catch (error) {
      dispatch(fetchEntitiesAdFailure(error?.response));
    }
  };
};

export const getAnEntity = (entityId) => {
  return async (dispatch) => {
    try {
      dispatch(requestFetchEntityAd());
      const response = await ProtectedWebClient.get(`/entity/${entityId}`);
      dispatch(fetchEntityAdSuccess(response?.data));
    } catch (error) {
      dispatch(fetchEntityAdFailure(error?.response));
    }
  };
};

export const createEntity = (entityDetail) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.post(`/entity`, entityDetail)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err?.response);
      });
  });
};

export const deleteEntity = (entityId) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.delete(`/entity/${entityId}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err?.response);
      });
  });
};

export const updateEntity = (payload, entityId) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.put(`/entity/${entityId}`, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err?.response);
      });
  });
};

export const fetchEntityPermalink = (permalink) => {
  return async (dispatch) => {
    try {
      dispatch(requestFetchPermalinkAd());
      const response = await ProtectedWebClient.get(
        `/entity_permalink/${permalink}`
      );
      dispatch(fetchPermalinkAdSuccess(response?.data));
    } catch (error) {
      dispatch(fetchPermalinkAdFailure(error?.response));
    }
  };
};
