import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import queryString from "query-string";
import React from "react";
import { useHistory, useLocation } from "react-router";
import { toast } from "react-toastify";

import "./client-authentication.css";

import Footer from "../../components/layout/footer/Footer";
import CustomNavbar from "../../components/layout/navbar/Navbar";
import PhoneAuthenticationForm from "../../components/layout/phone-authentication-form/PhoneAuthenticationForm";

import GoogleLogo from "../../assets/icon/google_logo.svg";

import { signUpUser } from "../../services/auth.service";
import { signInWithGoogle } from "../../services/firebase-auth.service";
import {
  ROLE_CATEGORIES,
  SIGN_IN_OPTIONS,
  STD_ERROR_MESSAGE,
} from "../../utils/constant";
import { storeUserCredentials } from "../../utils/utils";

const ClientAuthentication = (props) => {
  const history = useHistory();
  const location = useLocation();

  const query = queryString.parse(location.search);

  function onSignInWithGoogleClick() {
    signInWithGoogle()
      .then((res) => {
        const payload = {
          firebase_id: res?.user?.uid,
          category: ROLE_CATEGORIES.other,
          email: res?.user?.email,
        };
        signUpUser(payload)
          .then((response) => {
            storeUserCredentials({
              authToken: response.data.access_token,
              userId: response.data.user.id,
              userRole: response.data.user.category || ROLE_CATEGORIES.other,
            });
            history.push("/collections");
          })
          .catch((err) => {
            toast.error(err?.data?.error_data || STD_ERROR_MESSAGE);
          });
      })
      .catch((err) => {
        toast.error(err?.message || STD_ERROR_MESSAGE);
      });
  }

  function getComponent() {
    switch (query.opt) {
      case SIGN_IN_OPTIONS.phoneSignIn:
        return (
          <div className="card client-authetication-card">
            <PhoneAuthenticationForm
              onSignUpClick={() =>
                history.push(`/sign-in?opt=${SIGN_IN_OPTIONS.emailSignUp}`)
              }
            />
          </div>
        );
      case SIGN_IN_OPTIONS.googleSignIn:
        return (
          <div className="card client-authetication-card">
            <h1 className="pb18-lh32 text-center mb-3">Sign in to Picsea</h1>
            <div
              className="d-flex justify-content-center sign-in-option-btn pt-cursor mb-4"
              onClick={onSignInWithGoogleClick}
            >
              <img src={GoogleLogo} alt="" />
              <h1 className="google-text">Sign In with Google</h1>
            </div>
            <hr className="solid m-0" />
            <p className="authentication-p pr12-lh24">
              Not a Picsea member?
              <span
                className="pb12-lh18 p4-horizontal pt-cursor"
                onClick={() =>
                  history.push(`/sign-in?opt=${SIGN_IN_OPTIONS.emailSignUp}`)
                }
              >
                Sign up here
              </span>
            </p>
          </div>
        );

      default:
        return (
          <div className="card client-authetication-card">
            <h1 className="pb18-lh32 text-center mb-3">Sign in to Picsea</h1>
            <div
              className="d-flex justify-content-center sign-in-option-btn pt-cursor mb-4"
              onClick={() =>
                history.push(`/sign-in?opt=${SIGN_IN_OPTIONS.phoneSignIn}`)
              }
            >
              <LocalPhoneIcon />
              <h1 className="google-text">Sign In with Mobile</h1>
            </div>
          </div>
        );
    }
  }

  return (
    <div>
      <CustomNavbar />
      <div className="container-fluid p-0 client-authentication-bg">
        {getComponent()}
      </div>
      <Footer />
    </div>
  );
};

export default ClientAuthentication;
