import React from 'react'
import ButtonDanger from '../../common/button-danger/ButtonDanger'
import './delete-popup.css'
import cancelIcon from '../../../assets/icon/cancel.svg';
import ButtonTransparent from '../../common/button-transparent/ButtonTransparent';
import PopUp from '../../common/pop-up/PopUp';

const DeletePopup = (props) => {
    let heading= props.heading ? props.heading : "DELETE FOLDER"
    let text = props.text ? props.text : "All photos in this folder will be deleted. This cannot be undone."
    return (
        <PopUp overlayClassName={props.overlayClassName}>
            <img className="close-icon" onClick={props.handleClose} src={cancelIcon} alt=""></img>
            <h1 className="pb18-lh32">{heading}</h1>
            <h5 className="pr12-lh24">{text}</h5>
            <div className="d-flex mt-3 f-gap-1">
                <ButtonTransparent className="pb14-lh21 f-grow-1" label="CANCEL" onClick={props.handleClose}/>
                <ButtonDanger label="DELETE" className="pb14-lh21 f-grow-1" onClick={props.handleDelete}/>
            </div>
        </PopUp>           
    )
}

export default DeletePopup
