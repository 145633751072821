import React, { useRef, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { isEmpty, size } from "lodash";
import * as Yup from "yup";
import Grid from "@mui/material/Grid";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

import ButtonAccent from "../../common/button-accent/ButtonAccent";
import errorInfoIcon from "../../../assets/icon/error_icon_red.svg";
import SimpleInputBox from "../simple-input-box/SimpleInputBox";
import ButtonTransparent from "../../common/button-transparent/ButtonTransparent";
import ButtonPrimary from "../../common/button-primary/ButtonPrimary";
import CancelLightIcon from "../../../assets/icon/cancel_light.svg";
import {
  getPresignedUrlPromise,
  uploadImageUsingPresignedUrlPromise,
} from "../../../services/uploadImage.service";
import {
  updateQuestionById,
  fetchQuestionsForGamesEm,
} from "../../../services/games.service";
import { BUCKET_PATH, STD_ERROR_MESSAGE } from "../../../utils/constant";
import { getImageUrl } from "../../../utils/utils";

export default function EditQuestionGamesEm(props) {
  const { onCancelBtnClick, gameData } = props;

  const imageRef = useRef(null);
  const params = useParams();
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);
  const [imageFile, setImageFile] = useState({
    new: false,
    file: null,
    preview: null,
  });

  const onSubmit = async (values) => {
    if (!submitting) {
      setSubmitting(true);

      let response = {};
      if (imageFile.file) {
        try {
          response = await uploadImageToS3(imageFile.file);
        } catch (error) {
          setSubmitting(false);
          toast.error(STD_ERROR_MESSAGE);
        }
      }

      const valuesCopy = JSON.parse(JSON.stringify(values));

      const { correct_answer_index, options, ...rest } = valuesCopy;
      options[parseInt(correct_answer_index)].is_correct = true;
      const payload = {
        options,
        ...rest,
      };

      if (!isEmpty(response) && response.imageId) {
        payload.image_id = response.imageId;
      }

      if (!isEmpty(payload)) {
        try {
          await updateQuestionById(params.collectionId, gameData.id, payload);
          dispatch(fetchQuestionsForGamesEm(params.collectionId));
          setSubmitting(false);
          onCancelBtnClick();
        } catch (error) {
          setSubmitting(false);
          toast.error(STD_ERROR_MESSAGE);
        }
      }
    }
  };

  const uploadImageToS3 = (file, id) => {
    return new Promise((resolve, reject) => {
      const presignedUrlPayload = [
        {
          bucket_path: `${BUCKET_PATH.collection}/${params.collectionId}/${BUCKET_PATH.games}/${file.name}`,
          image_name: file.name,
        },
      ];
      let imageId = "";

      getPresignedUrlPromise(presignedUrlPayload, id)
        .then((response) => {
          imageId = response?.res?.data?.data?.[0]?.image_id;
          return uploadImageUsingPresignedUrlPromise(
            response?.res?.data?.data?.[0]?.upload_url,
            file,
            id
          );
        })
        .then((response) => {
          resolve({ id, imageId, response });
        })
        .catch((error) => {
          reject({ id, error: error });
        });
    });
  };

  const getCorrectOptionIndex = (options) => {
    let currentOptionIndex = "";
    for (let i = 0; i < size(options); i++) {
      if (options[i].is_correct) {
        currentOptionIndex = `${i}`;
        break;
      }
    }
    return currentOptionIndex;
  };

  const getQuestionOptions = (options) => {
    return options.map(({ title, id }) => {
      return { title, id };
    });
  };

  const initialValues = {
    question: gameData.question,
    correct_answer_index: getCorrectOptionIndex(gameData.options),
    options: getQuestionOptions(gameData.options),
  };

  const validationSchema = Yup.object().shape({
    question: Yup.string().required("Please enter the question."),
    correct_answer_index: Yup.string().required(
      "Please choose the correct answer."
    ),
    options: Yup.array().of(
      Yup.object().shape({
        title: Yup.string().required("Please fill in all options"),
      })
    ),
  });

  const removeImage = () => {
    if (imageFile.file) {
      setImageFile((ps) => {
        return {
          file: null,
          preview: null,
          new: false,
        };
      });
    }
  };

  useEffect(() => {
    if (imageFile.file && imageFile.new) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageFile((ps) => {
          return {
            ...ps,
            new: false,
            preview: reader.result,
          };
        });
      };
      reader.readAsDataURL(imageFile.file);
    }
  }, [imageFile]);

  function handleSectionImageChange(event) {
    const file = event.target.files[0];
    if (file && file.type.substr(0, 5) === "image") {
      setImageFile((ps) => {
        return {
          ...ps,
          new: true,
          file: file,
        };
      });
    }
  }

  const checkForErrors = (errors, touched) => {
    if (isEmpty(errors) || isEmpty(touched)) return null;

    if (errors.question && touched.question) {
      return errorElement(errors.question);
    } else if (!isEmpty(errors.options) && !isEmpty(touched.options)) {
      let msg = "";
      for (let i = 0; i < size(errors.options); i++) {
        const titleError = errors.options?.[i]?.title;
        const titleTouched = touched.options?.[i]?.title;
        if (titleError && titleTouched) {
          msg = titleError;
        }
      }
      return msg ? errorElement(msg) : null;
    } else if (errors.correct_answer_index && touched.correct_answer_index) {
      return errorElement(errors.correct_answer_index);
    } else {
      return null;
    }

    function errorElement(msg) {
      return (
        <div className="d-flex align-items-center mt-3 pr12-lh18 error-color">
          <img
            className="mr-2"
            style={{ width: "16px", height: "16px" }}
            src={errorInfoIcon}
            alt=""
          />
          <span className="mb-0">{msg}</span>
        </div>
      );
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ values, errors, touched }) => (
        <Form>
          <div
            className="p-3 mb-3"
            style={{
              border: "2px solid rgba(41, 41, 41, 0.38)",
              borderRadius: "8px",
            }}
          >
            <Field name="question">
              {(props) => {
                const { field, form, meta } = props;
                return (
                  <div className="mb-2">
                    <textarea
                      placeholder="Type Questions here..."
                      className="img-description-textarea pr16-lh24"
                      style={{
                        border: "none",
                        resize: "none",
                        height: "100px",
                      }}
                      {...field}
                    />
                  </div>
                );
              }}
            </Field>
            <div>
              {(imageFile?.file && imageFile?.preview) ||
              gameData.image?.bucket_path ? (
                <div
                  className="mb-2"
                  style={{
                    width: "fit-content",
                    height: "150px",
                    position: "relative",
                  }}
                >
                  <img
                    src={
                      imageFile?.preview ||
                      getImageUrl(gameData.image?.bucket_path)
                    }
                    alt=""
                    style={{
                      maxHeight: "150px",
                      width: "auto",
                      maxWidth: "100%",
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                  />
                  <div
                    style={{
                      backgroundColor: "rgba(0, 0, 0, 0.7)",
                      borderRadius: "50%",
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                      width: "30px",
                      height: "30px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    className={`close-btn pt-cursor ${
                      !imageFile?.preview && gameData.image?.bucket_path
                        ? "disabled"
                        : ""
                    }`}
                    onClick={() => removeImage()}
                  >
                    <img src={CancelLightIcon} alt="" />
                  </div>
                </div>
              ) : null}
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <ButtonTransparent
                    variant="span-button"
                    startSpan="+"
                    customStyling={{
                      width: "100%",
                      border: "1px solid var(--high-emphasis)",
                    }}
                    startSpanClassName="icon-medium margin-right-8 vertical-align-bottom"
                    onClick={(event) => {
                      event.preventDefault();
                      imageRef.current.click();
                    }}
                    label={`${
                      Boolean(imageFile?.file || gameData.image?.bucket_path)
                        ? "Change Image"
                        : "Add Image"
                    }`}
                  />
                  <input
                    type="file"
                    style={{ display: "none" }}
                    ref={imageRef}
                    accept="image/png, image/jpeg, image/jpg"
                    name="image"
                    onChange={(event) => handleSectionImageChange(event)}
                  />
                </Grid>
              </Grid>
            </div>
            <div role="group" className="mt-3">
              <Grid container spacing={3}>
                {values.options.map((option, optIndex) => (
                  <Grid
                    key={option.id}
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    sx={{ position: "relative" }}
                  >
                    <Field name={`correct_answer_index`}>
                      {(props) => {
                        const {
                          field,
                          form: { values },
                          meta,
                        } = props;
                        const disabled = isEmpty(
                          values.options?.[optIndex]?.title
                        );

                        return (
                          <input
                            type="radio"
                            {...field}
                            checked={field.value === `${optIndex}`}
                            value={`${optIndex}`}
                            disabled={disabled}
                            style={{
                              position: "absolute",
                              top: "60%",
                              left: "10%",
                            }}
                          />
                        );
                      }}
                    </Field>
                    <Field name={`options[${optIndex}].title`}>
                      {(props) => {
                        const {
                          field,
                          form: { values, setFieldValue },
                          meta,
                        } = props;
                        const { onChange, ...rest } = field;
                        let correctAnswerIndex = values?.correct_answer_index;

                        return (
                          <SimpleInputBox
                            placeholder={`Option ${optIndex + 1}`}
                            onChange={(event) => {
                              onChange(event);
                              if (
                                isEmpty(event.target.value) &&
                                !isEmpty(correctAnswerIndex) &&
                                correctAnswerIndex === `${optIndex}`
                              ) {
                                setFieldValue(`correct_answer_index`, "");
                              }
                            }}
                            {...rest}
                          />
                        );
                      }}
                    </Field>
                  </Grid>
                ))}
              </Grid>
            </div>
            {checkForErrors(errors, touched)}
            <Grid container spacing={3} sx={{ marginTop: "0rem" }}>
              <Grid item xs={12} sm={12} md={6}>
                <ButtonPrimary
                  customStyling={{ width: "100%" }}
                  label="Cancel"
                  onClick={onCancelBtnClick}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <ButtonAccent
                  customStyling={{ width: "100%" }}
                  type="submit"
                  label="Edit"
                />
              </Grid>
            </Grid>
          </div>
        </Form>
      )}
    </Formik>
  );
}
