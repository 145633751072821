import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import appLogo from "../../../assets/icon/app_logo.svg";
import { isEmpty } from "lodash";

import * as Yup from "yup";
import { Formik, Field, Form } from "formik";

import "../../../pages/client-authentication/client-authentication.css";

import "../sign-in-form/sign-in-form.css";
import InputBox from "../../common/input-box/InputBox";
import ButtonPrimary from "../../common/button-accent/ButtonAccent";
import "./register-form.css";

import PhoneInput from "../../common/v2/phone-input/PhoneInput";
import Error from "../../common/v2/error/Error";
import { isValidPhoneNumber } from "react-phone-number-input";
import { registerUser } from "../../../services/register.service";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { STD_ERROR_MESSAGE } from "../../../utils/constant";
import { getImageUrl, setOriginalUrl, setUserName } from "../../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchEventPermalink,
  getACollectionLogo,
} from "../../../services/collections.service";
import AccentLoadingButton from "../../common/v2/loading-button/AccentLoadingButton";

function Register(props) {
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  const collectionState = useSelector((state) => state.collectionEm);
  const collectionData = collectionState?.response?.data;

  const settings = collectionData?.settings
    ? JSON.parse(collectionData.settings)
    : {};

  useEffect(() => {
    if (isEmpty(collectionState.response)) {
      dispatch(getACollectionLogo(props.eventId));
    }
  }, []);

  useEffect(() => {
    if (!collectionState.loading && !isEmpty(collectionState.response)) {
      setLoading(false);
    }
    if (!collectionState.loading && !isEmpty(collectionState.error)) {
      setLoading(false);
      toast.error(collectionState.error?.error_data || STD_ERROR_MESSAGE);
    }
  }, [collectionState]);

  const initialValues = {
    name: "",
    mobile: "",
    category: "o",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Please enter your name"),
    mobile: Yup.string()
      .required("Please enter your mobile number.")
      .test("validatePhoneNumber", "Invalid mobile number", (val) => {
        return val && isValidPhoneNumber(val);
      }),
  });

  const onSubmit = (values) => {
    setOriginalUrl(props.permalink);
    setSubmitting(true);
    const payload = values;
    registerUser(props.eventId, payload)
      .then((response) => {
        setSubmitting(false);
        toast.success("Registered successfully");
        history.push(`/sign-in`);
      })
      .catch((err) => {
        setSubmitting(false);
        toast.error(err.data?.error_data || STD_ERROR_MESSAGE);
      });
  };

  return (
    <>
      {!loading && (
        <div className="container-fluid p-0 client-authentication-bg">
          <div className="registerForm">
            <div className="logopart">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img style={{ marginRight: "5px" }} src={appLogo} />
                <div
                  style={{
                    display: "grid",
                    justifyItems: "normal",
                  }}
                >
                  <span
                    style={{
                      fontSize: "25px",
                      color: "#F2F2F2",
                      marginTop: "5px",
                    }}
                  >
                    PICSEA
                  </span>
                  <span
                    style={{
                      fontSize: "9.7px",
                      color: "#16A5FF",
                      marginBottom: "12px",
                    }}
                  >
                    EVENT PLATFORM
                  </span>
                </div>
                {collectionData?.logo?.bucket_path ? (
                  <img
                    style={{
                      marginLeft: "140px",
                      width: "55px",
                      height: "55px",
                    }}
                    src={getImageUrl(collectionData.logo?.bucket_path)}
                  />
                ) : null}
              </div>
            </div>
            <div
              style={{
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "white",
                borderBottomLeftRadius: "8px",
                borderBottomRightRadius: "8px",
                height: "320px",
              }}
            >
              {settings.register ? (
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  {(formik) => {
                    return (
                      <Form>
                        <div style={{ width: "320px" }}>
                          <h1
                            style={{
                              fontFamily: "Rubik",
                              fontSize: "20px",
                              marginBottom: "15px",
                              fontWeight: 100,
                            }}
                          >
                            Register
                          </h1>
                          <Field name="name">
                            {(props) => {
                              const { field, meta } = props;
                              return (
                                <>
                                  <InputBox
                                    type="text"
                                    placeholder="Full Name"
                                    {...field}
                                    errorMsg={
                                      meta.touched && meta.error
                                        ? meta.error
                                        : ""
                                    }
                                  />
                                </>
                              );
                            }}
                          </Field>
                          <Field name="mobile">
                            {(props) => {
                              const { field, meta, form } = props;
                              return (
                                <>
                                  <PhoneInput
                                    placeholder="Mobile Number"
                                    value={field.value}
                                    onChange={(value) =>
                                      form.setFieldValue(field.name, value)
                                    }
                                    name={field.name}
                                    onBlur={form.handleBlur}
                                    error={meta.touched && meta.error}
                                  />
                                  {meta.touched && meta.error && (
                                    <Error
                                      errorMsg={meta.error}
                                      className="mt-2"
                                    />
                                  )}
                                </>
                              );
                            }}
                          </Field>
                          <div style={{ marginTop: "20px" }}>
                            <AccentLoadingButton
                              type="submit"
                              label="Submit"
                              loadingState={submitting}
                              fullWidth={true}
                            />
                          </div>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              ) : (
                <h1
                  style={{
                    fontFamily: "Rubik",
                    fontSize: "20px",
                    alignItems: "center",
                    fontWeight: 100,
                  }}
                >
                  This event is not open for registration
                </h1>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Register;
