import {
  CLEAR_FETCH_NEWS_AD_STATES,
  FETCH_NEWS_AD_FAILURE,
  FETCH_NEWS_AD_SUCCESS,
  REQUEST_NEWS_FETCH_AD,
} from "./news.types.js";

export const requestFetchNewsAd = () => {
  return {
    type: REQUEST_NEWS_FETCH_AD,
  };
};

export const fetchNewsAdSuccess = (response) => {
  return {
    type: FETCH_NEWS_AD_SUCCESS,
    payload: response,
  };
};

export const fetchNewsAdFailure = (error) => {
  return {
    type: FETCH_NEWS_AD_FAILURE,
    payload: error,
  };
};

export const clearFetchNewsAdStates = () => {
  return {
    type: CLEAR_FETCH_NEWS_AD_STATES,
  };
};
