import React, { useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { isEmpty } from "lodash";
import { toast } from "react-toastify";

import SingleInputPopUp from "../../common/single-input-pop-up/SingleInputPopUp";
import {
  updateAEntityGalleryFolderPromise,
  getAllEntityGallery,
} from "../../../services/gallery.service";
import { STD_ERROR_MESSAGE } from "../../../utils/constant";

const EditEntityFolder = (props) => {
  const folderInfo = props.folderInfo;
  const dispatch = useDispatch();

  const params = useParams();

  const initialValues = {
    title: folderInfo?.title,
  };

  const validationSchema = Yup.object({
    title: Yup.string().required("Please enter the folder name"),
  });

  const onSubmit = (values) => {
    updateAEntityGalleryFolderPromise(params.entityId, folderInfo.id, values)
      .then((res) => {
        dispatch(getAllEntityGallery(params.entityId));
        props.handleClose();
      })
      .catch((err) => {
        toast.error(err?.data?.error_data || STD_ERROR_MESSAGE);
      });
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  return (
    <SingleInputPopUp
      handleClose={props.handleClose}
      heading="Edit Folder"
      inputPlaceholder="Name your Folder"
      inputName="title"
      inputOnChange={formik.handleChange}
      inputValue={formik.values.title}
      inputErrorMsg={formik.touched.title && formik.errors.title}
      inputOnBlur={formik.handleBlur}
      btnLabel="Save"
      onSubmit={formik.handleSubmit}
    />
  );
};

export default EditEntityFolder;
