import React from "react";
import { isEmpty } from "lodash";

import ImagePlaceholder from "../../image-placeholder/ImagePlaceholder";
import "./image.css";

// import addFolderIcon from '../../../../assets/icon/add_icon_white.svg'
import favouriteIcon from "../../../../assets/icon/favourite_icon_outline_white.svg";
import favouriteFilledIcon from "../../../../assets/icon/favourites_filled_white.svg";
import deleteWhiteIcon from "../../../../assets/icon/delete_white.svg";
import shareWhiteIcon from "../../../../assets/icon/share_white.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Image = (props) => {
  const [loaded, setLoaded] = React.useState(false);

  const containerClassName = props.containerClassName
    ? `masonry-image-container ${props.containerClassName}`
    : "masonry-image-container";

  const dimensions = {};
  if (props.width) dimensions.width = props.width;
  if (props.height) dimensions.height = props.height;

  const containerCustomStyle = !isEmpty(props.containerCustomStyle)
    ? props.containerCustomStyle
    : {};
  const imageCustomStyle = !isEmpty(props.imageCustomStyle)
    ? props.imageCustomStyle
    : {};

  switch (props.variant) {
    case "withHover":
      return (
        <div
          className={containerClassName}
          style={{ ...dimensions, ...containerCustomStyle }}
          {...props.otherContainerProps}
        >
          {!loaded ? (
            <ImagePlaceholder className={props.placeholderClassName} />
          ) : null}
          <LazyLoadImage
            className={loaded ? "image" : "display-none"}
            style={{ ...imageCustomStyle }}
            src={props.src}
            height="100px"
            width="100px"
            onLoad={() => setLoaded(true)}
            onClick={props.onImageClick}
            alt=""
          />
          {(props.enableDeleteIcon ||
            props.enableShareIcon ||
            props.enableLikeIcon) && (
            <div className="img-overlay">
              <div className="icon-container">
                {props.enableDeleteIcon ? (
                  <img
                    className="mr-3 pt-cursor"
                    style={{
                      width: "20px",
                      height: "20px",
                    }}
                    src={deleteWhiteIcon}
                    alt="delete icon"
                    onClick={props.onDeleteIconClick}
                  />
                ) : null}
                {props.enableShareIcon ? (
                  <img
                    className="mr-3 pt-cursor"
                    style={{
                      width: "20px",
                      height: "20px",
                    }}
                    src={shareWhiteIcon}
                    alt="delete icon"
                    onClick={props.onShareIconClick}
                  />
                ) : null}
                {props.enableLikeIcon ? (
                  <img
                    className="mr-3 pt-cursor"
                    style={{
                      width: "20px",
                      height: "20px",
                    }}
                    src={props.isLiked ? favouriteFilledIcon : favouriteIcon}
                    alt="delete icon"
                    onClick={props.onLikeIconClick}
                  />
                ) : null}
              </div>
            </div>
          )}
        </div>
      );
    default:
      return (
        <div
          className={containerClassName}
          style={{ ...dimensions, ...containerCustomStyle }}
          {...props.otherContainerProps}
        >
          {!loaded ? (
            <ImagePlaceholder className={props.placeholderClassName} />
          ) : null}
          <img
            style={
              loaded
                ? { display: "block", width: "100%", ...imageCustomStyle }
                : { display: "none" }
            }
            src={props.src}
            height="100px"
            width="100px"
            onLoad={() => setLoaded(true)}
            onClick={props.onClick}
            alt=""
          />
        </div>
      );
  }
};

export default Image;
