import ProtectedWebClient from "../utils/protected-web-client";
import {
  requestFetchMasterGuestInvitesEm,
  fetchMasterGuestInvitesEmSuccess,
  fetchGuestInvitesEmFailure,
  requestFetchInviteGuestInvitesEm,
  fetchInviteGuestInvitesEmSuccess,
  requestFetchItineraryGuestInvitesEm,
  fetchInviteGuestItineraryEmSuccess,
} from "../redux/guest-invites/guestInvites.action";

// master guest services
export const getMasterGuestInvitesOfCollection = (collectionId) => {
  return async (dispatch) => {
    try {
      dispatch(requestFetchMasterGuestInvitesEm());
      const response = await ProtectedWebClient.get(
        `/event/${collectionId}/guest`
      );
      dispatch(fetchMasterGuestInvitesEmSuccess(response?.data));
    } catch (error) {
      dispatch(fetchGuestInvitesEmFailure(error?.data));
    }
  };
};

export const inviteGuestsToMasterPromise = (collectionId, payload) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.post(`/event/${collectionId}/guest`, payload)
      .then((res) => {
        resolve(res?.data);
      })
      .catch((error) => {
        reject(error?.data);
      });
  });
};

export const removeInvitedGuestFromMasterPromise = (collectionId, payload) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.delete(`/event/${collectionId}/guest`, { data: payload })
      .then((res) => {
        resolve(res?.data);
      })
      .catch((error) => {
        reject(error?.data);
      });
  });
};

// invitation guest services
export const getGuestOfInvitesCollection = (collectionId, sequenceId) => {
  return async (dispatch) => {
    try {
      dispatch(requestFetchInviteGuestInvitesEm());
      const response = await ProtectedWebClient.get(
        `/event/${collectionId}/invitation/${sequenceId}/guest`
      );
      dispatch(fetchInviteGuestInvitesEmSuccess(sequenceId, response?.data));
    } catch (error) {
      dispatch(fetchGuestInvitesEmFailure(error?.data));
    }
  };
};

export const inviteGuestsToInvitationPromise = (
  collectionId,
  sequenceId,
  payload
) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.post(
      `/event/${collectionId}/invitation/${sequenceId}/guest`,
      payload
    )
      .then((res) => {
        resolve(res?.data);
      })
      .catch((error) => {
        reject(error?.data);
      });
  });
};

export const removeGuestFromInvitationPromise = (
  collectionId,
  sequenceId,
  payload
) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.delete(
      `/event/${collectionId}/invitation/${sequenceId}/guest`,
      { data: payload }
    )
      .then((res) => {
        resolve(res?.data);
      })
      .catch((error) => {
        reject(error?.data);
      });
  });
};

// itinerary guest services
export const getGuestOfItineraryCollection = (collectionId, sequenceId) => {
  return async (dispatch) => {
    try {
      dispatch(requestFetchItineraryGuestInvitesEm());
      const response = await ProtectedWebClient.get(
        `/event/${collectionId}/itinerary/${sequenceId}/guest`
      );
      dispatch(fetchInviteGuestItineraryEmSuccess(sequenceId, response?.data));
    } catch (error) {
      dispatch(fetchGuestInvitesEmFailure(error?.data));
    }
  };
};

export const inviteGuestsToItineraryPromise = (
  collectionId,
  sequenceId,
  payload
) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.post(
      `/event/${collectionId}/itinerary/${sequenceId}/guest`,
      payload
    )
      .then((res) => {
        resolve(res?.data);
      })
      .catch((error) => {
        reject(error?.data);
      });
  });
};

export const removeGuestFromItineraryPromise = (
  collectionId,
  sequenceId,
  payload
) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.delete(
      `/event/${collectionId}/itinerary/${sequenceId}/guest`,
      { data: payload }
    )
      .then((res) => {
        resolve(res?.data);
      })
      .catch((error) => {
        reject(error?.data);
      });
  });
};
