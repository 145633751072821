import React from "react";
import successIcon from "../../../assets/icon/success.svg";
import warningIcon from "../../../assets/icon/warning.svg";
import ForwardArrowIcon from "../../../assets/icon/forward_arrow.svg";
import IconText from "../../common/icon-text/IconText";
import ButtonPrimary from "../../common/button-primary/ButtonPrimary";
import ButtonAccent from "../../common/button-accent/ButtonAccent";
import "./collection-header-partner.css";
import LoadingButton from "@mui/lab/LoadingButton";

const CollectionHeaderPartner = (props) => {
  return (
    <div className="d-flex justify-content-between align-items-center">
      <div className="margin-vertical-5">
        {props.title ? (
          <h4 className="pr16-lh24 margin-vertical-5 margin-right-26 d-inline">
            {props.title}
          </h4>
        ) : (
          ""
        )}
        {props.uploadSuccessContent ? (
          <IconText
            layoutContainerClassName="margin-vertical-5 margin-right-26 text-nowrap"
            textClassName="pr16-lh24 align-middle margin-left-8"
            startIcon={successIcon}
            content={props.uploadSuccessContent}
          />
        ) : (
          ""
        )}
        {props.uploadErrorContent ? (
          <IconText
            layoutContainerClassName="margin-vertical-5 margin-right-26 text-nowrap"
            textClassName="pr16-lh24 align-middle margin-left-8"
            startIcon={warningIcon}
            content={props.uploadErrorContent}
          />
        ) : (
          ""
        )}
      </div>
      <div className="margin-vertical-5">
        {props.cancelBtn ? (
          <ButtonPrimary
            label="Cancel"
            className="py5-px20 pb14-lh21 high-emphasis-bg"
            onClick={props.handleCancelBtnClick}
          />
        ) : (
          ""
        )}
        {props.retryBtn ? (
          <ButtonPrimary
            label="Retry Errors"
            className="py5-px20 pb14-lh21 margin-right-16 high-emphasis-bg"
            onClick={props.handleRetryBtnClick}
          />
        ) : (
          ""
        )}
        {props.backToCollectionsBtn ? (
          <ButtonAccent
            className="pb14-lh21 margin-right-16 py6-px16"
            label="Back to collections"
            onClick={props.handleBackToCollectionsBtn}
          />
        ) : (
          ""
        )}
        {props.generalBtn ? (
          <ButtonAccent
            className="pb14-lh21 py6-px16 mr-3"
            label={props.generalBtnLabel}
            onClick={props.onGeneralBtnClick}
            {...props.generalBtnProps}
          />
        ) : (
          ""
        )}
        {props.generalBtn2 ? (
          <ButtonAccent
            className="pb14-lh21 py6-px16 mr-3"
            label={props.generalBtn2Label}
            onClick={props.onGeneralBtn2Click}
            {...props.generalBtn2Props}
          />
        ) : (
          ""
        )}
        {props.addPhotosBtn ? (
          <ButtonAccent
            variant="span-button"
            startSpan="+"
            startSpanClassName="icon-medium margin-right-8 vertical-align-bottom"
            className="pb14-lh21 py6-px16"
            label={props.addPhotosLabel}
            onClick={props.handleAddPhotosBtn}
          />
        ) : (
          ""
        )}
        {props.proceedBtn ? (
          <ButtonAccent
            variant="icon-button"
            endIcon={ForwardArrowIcon}
            labelClassName="align-middle margin-right-8"
            className="pb14-lh21 py4-px18 margin-left-16"
            label="Proceed"
            onClick={props.handleProceedBtn}
          />
        ) : (
          ""
        )}
        {props.loadingBtn ? (
          <LoadingButton
            size="small"
            color="secondary"
            sx={{
              textTransform: "none",
              fontFamily: "'Poppins', sans-serif",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "14px",
              lineHeight: "21px",
              padding: "4px 18px",
              backgroundColor: "var(--accent-color)",
              color: "var(--primary-color)",
              "&:hover": {
                backgroundColor: "var(--accent-color)",
                color: "var(--primary-color)",
              },
              marginLeft: "1rem",
            }}
            variant="contained"
            {...props.loadingBtnProps}
          >
            {props.loadingBtnLabel}
          </LoadingButton>
        ) : null}
      </div>
    </div>
  );
};

export default CollectionHeaderPartner;
