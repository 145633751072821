import { getAlphaNumericNanoId } from "../../../utils/utils";
import CollectionHeaderPartner from "../collection-header-partner/CollectionHeaderPartner";
import { Formik, Form, FieldArray, Field } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { isEmpty, size } from "lodash";
import SquareCloseIcon from "../../../assets/icon/cancel.svg";
import React, { useRef, useState, useEffect, useCallback } from "react";
import Grid from "@mui/material/Grid";
import SimpleInputBox from "../simple-input-box/SimpleInputBox";
import ButtonAccent from "../../common/button-accent/ButtonAccent";
import errorInfoIcon from "../../../assets/icon/error_icon_red.svg";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  SECTION_PEFERENCES_COMPONENTS,
  STD_ERROR_MESSAGE,
} from "../../../utils/constant";
import {
  addQuestionsForPreferencesEm,
  fetchQuestionsForPreferencesEm,
} from "../../../services/preferences.service";
import {
  getRSVPDate,
  addRSVPDatePromise,
} from "../../../services/rsvpDate.service";
const AddPreferences = (props) => {
  const { onCancelClick, setCurrentMainComponent } = props;
  const imagesRef = useRef({});
  const [submitting, setSubmitting] = useState(false);
  const [dateSubmitting, setDateSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);
  const rsvpState = useSelector((state) => state.rsvpDateEm);
  const params = useParams();
  const [date, setDate] = useState("");
  const dispatch = useDispatch();
  const [imageFiles, setImageFiles] = useState({
    currId: null,
    files: null, //{1: {file: null, preview: null}}
  });

  useEffect(() => {
    if (isEmpty(rsvpState.response)) {
      dispatch(getRSVPDate(params.collectionId));
    }
  }, []);

  useEffect(() => {
    if (!rsvpState.loading && !isEmpty(rsvpState.response)) {
      setLoading(false);
      setDate(rsvpState.response?.data?.respond_date);
    }
    if (!rsvpState.loading && !isEmpty(rsvpState.error)) {
      setLoading(false);
      toast.error(rsvpState.error?.error_data || STD_ERROR_MESSAGE);
    }
  }, [rsvpState]);

  const onSubmit = async (values) => {
    if (!submitting) {
      setSubmitting(true);
      const valuesCopy = JSON.parse(JSON.stringify(values));
      const payload = [];
      for (const question of valuesCopy.preferences) {
        const { id, options, ...rest } = question;
        const filteredOptions = options.filter((opt) => !isEmpty(opt.title));
        const questionProperties = {
          options: filteredOptions,
          ...rest,
        };
        payload.push(questionProperties);
      }
      if (!isEmpty(payload)) {
        try {
          await addQuestionsForPreferencesEm(params.collectionId, payload);
          dispatch(fetchQuestionsForPreferencesEm(params.collectionId));
          setSubmitting(false);
          setCurrentMainComponent((prevState) => {
            return {
              currComponent: SECTION_PEFERENCES_COMPONENTS.saved_questions,
              prevComponent: prevState.currComponent,
            };
          });
        } catch (error) {
          setSubmitting(false);
          toast.error(STD_ERROR_MESSAGE);
        }
      }
    }
  };

  const onSaveDate = (event) => {
    event.preventDefault();
    if (!dateSubmitting) {
      setDateSubmitting(true);
      const payload = { ["respond_date"]: date };

      addRSVPDatePromise(params.collectionId, payload)
        .then((res) => {
          dispatch(getRSVPDate(params.collectionId));
          setDateSubmitting(false);
          toast.info("RSVP respond date updated successfully.");
        })
        .catch((err) => {
          setDateSubmitting(false);
          toast.error(
            !isEmpty(err?.data?.error_data)
              ? err?.data?.error_data
              : STD_ERROR_MESSAGE
          );
        });
    }
  };
  const getEmptySection = () => {
    return {
      id: getAlphaNumericNanoId(),
      question: "",
      published: false,
      options: [
        {
          title: "",
        },
        {
          title: "",
        },
        {
          title: "",
        },
        {
          title: "",
        },
      ],
    };
  };
  const initialValues = {
    preferences: [
      {
        id: getAlphaNumericNanoId(),
        question: "",
        published: false,
        options: [
          {
            title: "",
          },
          {
            title: "",
          },
          {
            title: "",
          },
          {
            title: "",
          },
        ],
      },
    ],
  };
  const validationSchema = Yup.object().shape({
    preferences: Yup.array().of(
      Yup.object().shape({
        question: Yup.string().required("Please enter the question."),
        options: Yup.array()
          .of(
            Yup.object().shape({
              title: Yup.string(),
            })
          )
          .test({
            name: "min_options_allowed",
            message: "Please fill at least two options",
            test: (val) => {
              let optionsCount = 0;
              for (const opt of val) {
                if (opt.title) {
                  optionsCount++;
                }
              }
              return optionsCount >= 2;
            },
          }),
      })
    ),
  });
  function removeASection(index, remove, id) {
    remove(index);
    const { [index]: removedObj, ...rest } = imagesRef.current;
    imagesRef.current = rest;
  }
  const checkForErrors = (errors, index, touched) => {
    const errorsObj = errors?.preferences?.[index];
    const touchedObj = touched?.preferences?.[index];

    if (isEmpty(errorsObj) || isEmpty(touchedObj)) return null;

    if (errorsObj.question && touchedObj.question) {
      return errorElement(errorsObj.question);
    } else if (errorsObj.options && size(touchedObj.options) >= 2) {
      return errorElement(errorsObj.options);
    }
    function errorElement(msg) {
      return (
        <div className="d-flex align-items-center mt-3 pr12-lh18 error-color">
          <img
            className="mr-2"
            style={{ width: "16px", height: "16px" }}
            src={errorInfoIcon}
            alt=""
          />
          <span className="mb-0">{msg}</span>
        </div>
      );
    }
  };
  return (
    <main
      style={{ maxWidth: "850px", marginLeft: "auto", marginRight: "auto" }}
    >
      {!loading && (
        <form onSubmit={onSaveDate}>
          <CollectionHeaderPartner
            title="Respond Date"
            loadingBtn={true}
            loadingBtnProps={{
              type: "save",
              loading: dateSubmitting,
            }}
            loadingBtnLabel="Save"
          />
          <div
            style={{
              display: "flex",
              gap: "5px",
            }}
          ></div>
          <div className="mt-3">
            <div className="d-flex  mb-5">
              <input
                onChange={(e) => setDate(e.target.value)}
                value={date}
                type="date"
                placeholder="Enter Response Date"
                style={{
                  width: "180px",
                  height: "40px",
                  border: "2px solid grey",
                  borderRadius: "8px",
                  paddingLeft: "23px",
                  paddingRight: "23px",
                }}
              />
            </div>
          </div>
        </form>
      )}
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {(formik) => (
          <Form>
            <CollectionHeaderPartner
              title="Add Preferences"
              cancelBtn={true}
              handleCancelBtnClick={onCancelClick}
              loadingBtn={true}
              loadingBtnLabel="Save"
              loadingBtnProps={{ type: "submit" }}
            />

            <FieldArray name="preferences">
              {(fieldArrayProps) => {
                const { push, form, remove } = fieldArrayProps;
                const { values, errors, touched } = form;
                const { preferences: preferenceSections } = values;
                return (
                  <div className="mt-3">
                    {preferenceSections.map((preferenceSection, index) => (
                      <div
                        style={{
                          border: "2px solid rgba(41, 41, 41, 0.38)",
                          borderRadius: "8px",
                        }}
                        key={preferenceSection.id}
                      >
                        <div>
                          <div className="mb-1 d-flex justify-content-end">
                            {size(preferenceSection) > 1 ? (
                              <img
                                className="pt-cursor"
                                src={SquareCloseIcon}
                                alt=""
                                onClick={() =>
                                  removeASection(
                                    index,
                                    remove,
                                    preferenceSection.id
                                  )
                                }
                              />
                            ) : null}
                          </div>
                          <Field name={`preferences[${index}].question`}>
                            {(props) => {
                              const { field, form, meta } = props;
                              return (
                                <div className="mb-2">
                                  <textarea
                                    placeholder="Type Questions here..."
                                    className="img-description-textarea pr16-lh24"
                                    style={{
                                      border: "none",
                                      resize: "none",
                                      height: "100px",
                                    }}
                                    {...field}
                                  />
                                </div>
                              );
                            }}
                          </Field>

                          <div role="group" className="mt-3">
                            <Grid container spacing={3}>
                              {preferenceSection.options.map(
                                (options, optIndex) => (
                                  <Grid
                                    key={optIndex}
                                    item
                                    xs={12}
                                    sm={12}
                                    md={6}
                                    sx={{ position: "relative" }}
                                  >
                                    <Field
                                      name={`preferences[${index}].options[${optIndex}].title`}
                                    >
                                      {(props) => {
                                        const {
                                          field,
                                          form: { values, setFieldValue },
                                          meta,
                                        } = props;
                                        const { onChange, ...rest } = field;

                                        return (
                                          <SimpleInputBox
                                            placeholder={`Option ${
                                              optIndex + 1
                                            }`}
                                            onChange={(event) => {
                                              onChange(event);
                                              if (
                                                !isEmpty(event.target.value)
                                              ) {
                                                setFieldValue(
                                                  `preferences[${index}].options[${optIndex}].title`,
                                                  event.target.value
                                                );
                                              }
                                            }}
                                            {...rest}
                                          />
                                        );
                                      }}
                                    </Field>
                                  </Grid>
                                )
                              )}
                            </Grid>
                          </div>
                          {checkForErrors(errors, index, touched)}
                        </div>
                      </div>
                    ))}

                    <div className="mt-4 d-flex justify-content-center">
                      <ButtonAccent
                        variant="span-button"
                        startSpan="+"
                        startSpanClassName="icon-medium margin-right-8 vertical-align-bottom"
                        label="Add Preference"
                        onClick={() => push(getEmptySection())}
                      />
                    </div>
                  </div>
                );
              }}
            </FieldArray>
          </Form>
        )}
      </Formik>
    </main>
  );
};
export default AddPreferences;
