import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import "../../../pages/client-authentication/client-authentication.css";

import "../sign-in-form/sign-in-form.css";
// import "../register-form.css";
import "../register-form/register-form.css";

import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { registerUser } from "../../../services/register.service";
import { STD_ERROR_MESSAGE } from "../../../utils/constant";
import { getUserId, removeOriginalUrl } from "../../../utils/utils";
import CustomSpinner from "../../common/spinner/CustomSpinner";

function RegisterAuthenticatedUser(props) {
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);

  const collectionState = useSelector((state) => state.collectionEm);
  const collectionData = collectionState?.response?.data;
  const { permalink } = props;
  const userId = getUserId();

  useEffect(() => {
    if (!submitting) {
      setSubmitting(true);
      const payload = { id: userId };
      registerUser(props.eventId, payload)
        .then((response) => {
          setSubmitting(false);
          removeOriginalUrl();
          toast.success("Registered successfully");
          history.push(`/${permalink}`);
        })
        .catch((err) => {
          setSubmitting(false);
          removeOriginalUrl();
          toast.error(err.data?.error_data || STD_ERROR_MESSAGE);
        });
    }
  }, []);

  return <>{submitting && <CustomSpinner />}</>;
}

export default RegisterAuthenticatedUser;
