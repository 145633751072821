import React from 'react'
import './text-field.css';


const TextField = (props) => {
    const className = props.className ? `text-field-static ${props.className}` : 'text-field-static'

    return (
        <div className={className}>
            <label className="pr10-lh15 m-0 d-block text-left">{props.label}</label>
            <h1 className="pr16-lh24 m-0 text-left">{props.value}</h1>
        </div>
    )
}

export default TextField
