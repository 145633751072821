import React from 'react'
import errorInfoIcon from '../../../../assets/icon/info-red.svg';

const Error = (props) => {

    return (
        <div className={`d-flex align-items-center justify-content-start ${props.className || ''}`}>
            <img src={errorInfoIcon} alt=""/>
            <span className="margin-left-5 pr10-lh15 error-color">
                {props.errorMsg}
            </span>
        </div>
    )
}

export default Error