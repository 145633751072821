import { 
    REQUEST_LIVE_FEED_FETCH_EM,
    FETCH_LIVE_FEED_EM_SUCCESS,
    FETCH_LIVE_FEED_EM_FAILURE,
    CLEAR_FETCH_LIVE_FEED_EM_STATES
} from './liveFeed.types';


export const requestFetchLiveFeedsEm = () => {
    return {
        type : REQUEST_LIVE_FEED_FETCH_EM,
    }
}

export const fetchLiveFeedsEmSuccess = (response) => {
    return {
        type : FETCH_LIVE_FEED_EM_SUCCESS,
        payload : response
    }
}

export const fetchLiveFeedsEmFailure = (error) => {
    return {
        type : FETCH_LIVE_FEED_EM_FAILURE,
        payload : error
    }
}

export const clearFetchLiveFeedsEmStates = () => {
    return {
        type : CLEAR_FETCH_LIVE_FEED_EM_STATES
    }
}