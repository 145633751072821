import {
  requestFetchQuestionsEm,
  fetchQuestionsEmSuccess,
  fetchQuestionsEmFailure,
} from "../redux/games/games.action";

import {
  requestFetchLeaderBoardUser,
  fetchLeaderBoardUserSuccess,
  fetchLeaderBoardUserFailure,
} from "../redux/leader-board-user/leaderBoardUser.action";

import ProtectedWebClient from "../utils/protected-web-client";

export const fetchQuestionsForGamesEm = (collectionId) => {
  return async (dispatch) => {
    try {
      dispatch(requestFetchQuestionsEm());
      const fetchedQuestionsResponse = await ProtectedWebClient.get(
        `/event/${collectionId}/game`
      );
      dispatch(fetchQuestionsEmSuccess(fetchedQuestionsResponse?.data));
    } catch (error) {
      dispatch(fetchQuestionsEmFailure(error?.response));
    }
  };
};

export const getQuestionByGameId = (collectionId, gameId) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.get(`/event/${collectionId}/game/${gameId}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error?.response));
  });
};

export const addQuestionsForGamesEm = (collectionId, payload) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.post(`/event/${collectionId}/game`, payload)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error?.response));
  });
};

export const updateQuestionById = (collectionId, gameId, payload) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.put(`/event/${collectionId}/game/${gameId}`, payload)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error?.response));
  });
};

export const deleteQuestionById = (collectionId, gameId) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.delete(`/event/${collectionId}/game/${gameId}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error?.response));
  });
};

export const resetGameById = (collectionId) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.put(`/event/${collectionId}/resetGames`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error?.response));
  });
};

export const fetchGameQuestionUser = (collectionId) => {
  return async (dispatch) => {
    try {
      dispatch(requestFetchQuestionsEm());
      const response = await ProtectedWebClient.get(
        `/event/${collectionId}/play`
      );
      dispatch(fetchQuestionsEmSuccess(response?.data));
    } catch (error) {
      dispatch(fetchQuestionsEmFailure(error?.response));
    }
  };
};

export const fetchGameQuestionByIdUser = (collectionId, gameId) => {
  return async (dispatch) => {
    try {
      dispatch(requestFetchQuestionsEm());
      const response = await ProtectedWebClient.get(
        `/event/${collectionId}/play/${gameId}`
      );
      dispatch(fetchQuestionsEmSuccess(response?.data));
    } catch (error) {
      dispatch(fetchQuestionsEmFailure(error?.response));
    }
  };
};

export const fetchLeaderBoardDataUser = (collectionId) => {
  return async (dispatch) => {
    try {
      dispatch(requestFetchLeaderBoardUser());
      const response = await ProtectedWebClient.get(
        `/event/${collectionId}/leaderboard`
      );
      dispatch(fetchLeaderBoardUserSuccess(response?.data));
    } catch (error) {
      dispatch(fetchLeaderBoardUserFailure(error?.response));
    }
  };
};

export const submitOption = (collectionId, playId, payload) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.post(`/event/${collectionId}/play/${playId}`, payload)
      .then((response) => {
        resolve(response?.data);
      })
      .catch((error) => {
        reject(error?.response);
      });
  });
};
