import React, { useState } from "react";
import "./partner-authenication.css";
import Footer from "../../components/layout/footer/Footer";
import SignIn from "../../components/layout/sign-in-form/SignInForm";
import SignUp from "../../components/layout/sign-up-form/SignUpForm";
import { useHistory, useLocation } from "react-router-dom";
import CustomNavbar from "../../components/layout/navbar/Navbar";
import { ROLE_CATEGORIES } from "../../utils/constant";

function Login() {
  const path = useLocation().pathname;
  const location = path.split("/")[2];

  const history = useHistory();

  return (
    <>
      <CustomNavbar />
      <div className="container-fluid p-0 authentication-bg">
        <div
          className={
            location === "sign-in"
              ? "card authentication-card"
              : "card authentication-card mr40-vertical"
          }
        >
          {location === "sign-in" ? (
            <>
              <h1 className="authentication-title pb18-lh32">
                Sign in to Picsea
              </h1>
              <SignIn category={ROLE_CATEGORIES.eventManager} />
              <hr className="solid m-0" />
              <p className="authentication-p pr12-lh24">
                Not a Picsea member?
                <span
                  className="pb12-lh18 p4-horizontal pt-cursor"
                  onClick={() => history.push("/partner/sign-up")}
                >
                  Sign up here
                </span>
              </p>
            </>
          ) : (
            <>
              <h1 className="authentication-title pb18-lh32">
                Sign up for Picsea
              </h1>
              <SignUp />
              <hr className="solid m-0" />
              <p className="authentication-p pr12-lh24">
                Already a Picsea member?
                <span
                  className="pb12-lh18 p4-horizontal pt-cursor"
                  onClick={() => history.push("/partner/sign-in")}
                >
                  Sign in here
                </span>
              </p>
            </>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Login;
