import React from "react";
import { WEDDING_FOLDERS } from "../../../../utils/constant";
import LiveUpdates from "../../live-updates/LiveUpdates";

const SectionLiveFeed = (props) => {
  return (
    <LiveUpdates
      onMasterInviteBtnClick={() =>
        props.setSelected({
          folder: WEDDING_FOLDERS.master_invite_list,
          subFolder: null,
        })
      }
    />
  );
};

export default SectionLiveFeed;
