import uploadFileTypes from './uploadFiles.types';

export const setUploadFiles = (payload) => ({
    type: uploadFileTypes.SET_UPLOAD_FILES,
    payload: payload
    // [{
    //     file: file,
    //     segment: 1,
    //     payload: {}
    // }]
})

export const setUploadProgress = (id, progress) => ({
    type: uploadFileTypes.SET_UPLOAD_PROGRESS,
    payload: {
        id,
        progress,
    },
})

export const successUploadFile = (id, url) => ({
    type: uploadFileTypes.SUCCESS_UPLOAD_FILE,
    payload: {
        id,
        url
    }
})

export const setImagePreviewFile = (id, data) => ({
    type: uploadFileTypes.SET_IMAGE_PREVIEW,
    payload: {
        id,
        data
    }
})

export const setImageId = (id, imageId) => ({
    type: uploadFileTypes.SET_IMAGE_ID,
    payload: {
        id,
        imageId
    }
})

export const failureUploadFile = id => ({
    type: uploadFileTypes.FAILURE_UPLOAD_FILE,
    payload: id,
})

export const retryUploadFailedFile = id => ({
    type: uploadFileTypes.RETRY_UPLOAD_FAILED_FILE,
    payload: id,
})

export const clearErrorStack = () => ({
    type : uploadFileTypes.CLEAR_UPLOAD_ERROR_STACK
})

export const clearUploadFilesState = () => ({
    type : uploadFileTypes.CLEAR_UPLOAD_FILES
})