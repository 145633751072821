import {
  CLEAR_FETCH_EVENT_PERMALINK_EM_STATES,
  FETCH_EVENT_PERMALINK_EM_FAILURE,
  FETCH_EVENT_PERMALINK_EM_SUCCESS,
  REQUEST_FETCH_EVENT_PERMALINK_EM,
} from "./eventPermalink.types";

export const requestFetchEventPermalink = () => {
  return {
    type: REQUEST_FETCH_EVENT_PERMALINK_EM,
  };
};

export const fetchEventPermalinkEmSuccess = (response) => {
  return {
    type: FETCH_EVENT_PERMALINK_EM_SUCCESS,
    payload: response,
  };
};

export const fetchEventPermalinkEmFailure = (error) => {
  return {
    type: FETCH_EVENT_PERMALINK_EM_FAILURE,
    payload: error,
  };
};

export const clearFetchEventPermalinkEmStates = () => {
  return {
    type: CLEAR_FETCH_EVENT_PERMALINK_EM_STATES,
  };
};
