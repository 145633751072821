import { 
    CLEAR_FETCH_COLLECTION_EM_STATES,
    REQUEST_FETCH_COLLECTION_EM,
    FETCH_COLLECTION_EM_FAILURE,
    FETCH_COLLECTION_EM_SUCCESS
} from './collection.types.js';

export const requestFetchCollectionEm = () => {
    return {
        type : REQUEST_FETCH_COLLECTION_EM,
    }
}

export const fetchCollectionEmSuccess = (response) => {
    return {
        type : FETCH_COLLECTION_EM_SUCCESS,
        payload : response
    }
}

export const fetchCollectionEmFailure = (error) => {
    return {
        type : FETCH_COLLECTION_EM_FAILURE,
        payload : error
    }
}

export const clearFetchCollectionEmStates = () => {
    return {
        type : CLEAR_FETCH_COLLECTION_EM_STATES
    }
}