import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { Link } from "react-router-dom";
import "./navbar.css";
import React, { useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import hamBurgerIcon from "../../../assets/icon/chat.svg";
import favouriteIconFilledWhite from "../../../assets/icon/favourites_filled_white.svg";
import profileLogo from "../../../assets/icon/user_logo.svg";
import backProfileLogo from "../../../assets/icon/user_logo_black.svg";
import { IMAGE_VARIANT, ROLE_CATEGORIES } from "../../../utils/constant";
import "./query.css";
import {
  getAuthToken,
  getImageUrl,
  getUserId,
  getUserName,
  getUserRole,
} from "../../../utils/utils";
import AppLogo from "../app-logo/AppLogo";
import LogoutPopup from "../logout-popup/LogoutPopup";
import { Button } from "@mui/material";
import { IOSSwitch } from "../../common/ios-switch/IOSSwitch";

function CustomNavbar(props) {
  const [userNamePopup, setUserNamePopup] = useState(false);

  const handleShow = () => setUserNamePopup(true);
  const handleClose = () => setUserNamePopup(false);
  const isSmallScreen = useMediaQuery("(max-width: 670px)");
  const isXSmallScreen = useMediaQuery("(max-width: 400px)");

  const {
    className,
    onItemClick,
    checked,
    handleToggleOn,
    removeSwitch,
    userProfile,
  } = props;

  const defaultClassName =
    "toggle-container d-flex justify-content-between pt-cursor";
  const mClassName = className
    ? defaultClassName + ` ${className}`
    : defaultClassName;

  function userName() {
    let username = getUserName();
    if (username) {
      const firstLetterOfUserName = username.substring(0, 1).toUpperCase();
      return firstLetterOfUserName;
    } else {
      return "";
    }
  }

  function ElevationScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 0,
      target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
      elevation: trigger ? 4 : 0,
    });
  }

  return (
    <>
      <ElevationScroll {...props}>
        <AppBar
          sx={{
            backgroundColor: "#000000",
            paddingLeft: { sm: "1px", md: "5%" },
            paddingRight: { sm: "1px", md: "5%" },
          }}
        >
          <Toolbar>
            <div className="d-flex w-100 align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <Link className="link-light pb24-lh40" to="/">
                  <AppLogo />
                </Link>
              </div>
              <div className="event-title">
                {props.eventTitle && (
                  <h3 className="parisienne-normal-fs24-lh40 secondary-color mb-0">
                    {props.eventTitle}
                  </h3>
                )}
              </div>
              <div
                className="d-flex align-tems-center"
                style={{
                  gap: "0.5rem",
                  alignItems: "center",
                }}
              >
                {props.myProfile && (
                  <div>
                    <Button
                      sx={{
                        textTransform: "none",
                        fontFamily: "'Poppins', sans-serif",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: {
                          xxs: "8px",
                          xs: "10px",
                          sm: "12px",
                          md: "14px",
                          lg: "16px",
                        },
                        lineHeight: "24px",
                        color: "white",
                        borderRadius: "32px",
                        maxHeight: "2.8rem",
                        ...(isXSmallScreen
                          ? {
                              border: "none",
                              fontSize: "14px",
                              maxHeight: "2rem",
                              lineHeight: "15px",
                              paddingRight: "0.1rem",
                            }
                          : { border: "2px solid white" }),
                      }}
                    >
                      {isSmallScreen ? "My Pics" : "My photos only"}
                      <div className={mClassName} onClick={onItemClick}>
                        {!removeSwitch && (
                          <IOSSwitch
                            checked={checked}
                            onChange={handleToggleOn}
                          />
                        )}
                      </div>
                    </Button>
                  </div>
                )}
                {getAuthToken() ? (
                  <>
                    {getUserRole() ? (
                      props.lightNavbarBg ? (
                        getUserRole() === ROLE_CATEGORIES.eventManager ||
                        getUserRole() === ROLE_CATEGORIES.orgManager ||
                        getUserRole() === ROLE_CATEGORIES.schoolManager ? (
                          <Link
                            className="margin-left-24 pt-cursor"
                            to={`/partner/${getUserId()}/profile`}
                          >
                            <img
                              src={backProfileLogo}
                              onClick={handleShow}
                              alt=""
                            />
                          </Link>
                        ) : getUserRole() === ROLE_CATEGORIES.admin ? (
                          <Link
                            className="margin-left-24 pt-cursor"
                            to={`/admin/${getUserId()}/profile`}
                          >
                            <img
                              src={backProfileLogo}
                              onClick={handleShow}
                              alt=""
                            />
                          </Link>
                        ) : (
                          <a className="margin-left-24 nav-icon pt-cursor">
                            <img
                              src={backProfileLogo}
                              onClick={handleShow}
                              alt=""
                            />
                          </a>
                        )
                      ) : getUserRole() === ROLE_CATEGORIES.eventManager ||
                        getUserRole() === ROLE_CATEGORIES.orgManager ||
                        getUserRole() === ROLE_CATEGORIES.schoolManager ? (
                        <Link
                          className="nav-icon pb16-lh24 high-emphasis-color"
                          to={`/partner/${getUserId()}/profile`}
                        >
                          {userName()}
                        </Link>
                      ) : getUserRole() === ROLE_CATEGORIES.admin ? (
                        <Link
                          className="nav-icon pb16-lh24 high-emphasis-color"
                          to={`/admin/${getUserId()}/profile`}
                        >
                          {userName()}
                        </Link>
                      ) : (
                        <a
                          className="margin-left-24 profile-icon nav-icon pb16-lh24 pt-cursor m-0"
                          onClick={handleShow}
                        >
                          <img
                            style={{ width: "150%" }}
                            src={
                              userProfile?.thumbnail
                                ? getImageUrl(
                                    userProfile.image?.bucket_path,
                                    IMAGE_VARIANT.original
                                  )
                                : profileLogo
                            }
                            alt=""
                            onClick={handleShow}
                          />
                        </a>
                      )
                    ) : null}
                    {props.favouriteIcon ? (
                      <div
                        className="margin-left-24 pt-cursor"
                        onClick={props.onFavouriteActionClick}
                      >
                        <img
                          className="mr-2"
                          src={favouriteIconFilledWhite}
                          alt=""
                        />
                      </div>
                    ) : null}
                  </>
                ) : null}
              </div>
            </div>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <Toolbar />
      {props.collectionMenu ? (
        <img
          className="pt-cursor ml-3"
          src={hamBurgerIcon}
          onClick={props.onFolderActionsClick}
          alt=""
        ></img>
      ) : (
        ""
      )}
      {userNamePopup && <LogoutPopup handleClose={handleClose} />}
    </>
  );
}

export default CustomNavbar;
