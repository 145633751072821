import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { isEmpty } from "lodash";

import { getAllLocationInEntity } from "../../../../services/entity.location.service";
import {
  SECTION_ENTITY_PROFILE_COMPONENTS,
  STD_ERROR_MESSAGE,
} from "../../../../utils/constant";

import LocationList from "../../locationList/LocationList";
import UploadLocation from "../../upload-location/UploadLocation";
import LocationFilesUploading from "../../location-file-uploading/LocationFileUploading";

const SectionEntityProfile = (props) => {
  const [loading, setLoading] = useState(true);
  const [currentMainComponent, setCurrentMainComponent] = useState({
    currComponent: null,
    prevComponent: null,
  });
  const [fileData, setFileData] = useState("");
  const entityProfileState = useSelector((state) => state.entityProfileAd);
  const dispatch = useDispatch();
  const params = useParams();

  const entityProfileData = entityProfileState.response?.data;

  useEffect(() => {
    if (isEmpty(entityProfileState.response)) {
      dispatch(getAllLocationInEntity(params.entityId));
    }
  }, []);

  useEffect(() => {
    if (!entityProfileState.loading && !isEmpty(entityProfileState.response)) {
      setLoading(false);
      if (!isEmpty(entityProfileData)) {
        setCurrentMainComponent((prevState) => {
          return {
            currComponent: SECTION_ENTITY_PROFILE_COMPONENTS.location_list,
            prevComponent: prevState.currComponent,
          };
        });
      } else {
        setCurrentMainComponent((prevState) => {
          return {
            currComponent: SECTION_ENTITY_PROFILE_COMPONENTS.upload_location,
            prevComponent: prevState.currComponent,
          };
        });
      }
    }

    if (!entityProfileState.loading && !isEmpty(entityProfileState.error)) {
      setLoading(false);
      toast.error(entityProfileState.error?.error_data || STD_ERROR_MESSAGE);
    }
  }, [entityProfileState]);

  function handleCancelClick() {
    setCurrentMainComponent((prevState) => {
      return {
        currComponent:
          prevState.prevComponent ||
          SECTION_ENTITY_PROFILE_COMPONENTS.location_list,
        prevComponent: prevState.currComponent,
      };
    });
  }

  function changeNextComponent(component) {
    setCurrentMainComponent((prevState) => {
      return {
        currComponent: component,
        prevComponent: prevState.currComponent,
      };
    });
  }

  function getMainComponent() {
    switch (currentMainComponent.currComponent) {
      case SECTION_ENTITY_PROFILE_COMPONENTS.location_list:
        return (
          <LocationList
            onLocationButtonClick={() =>
              changeNextComponent(
                SECTION_ENTITY_PROFILE_COMPONENTS.upload_location
              )
            }
            setCurrentMainComponent={setCurrentMainComponent}
            setFileData={setFileData}
          />
        );
      case SECTION_ENTITY_PROFILE_COMPONENTS.upload_location:
        return (
          <UploadLocation
            onCancelClick={handleCancelClick}
            setCurrentMainComponent={setCurrentMainComponent}
          />
        );
      case SECTION_ENTITY_PROFILE_COMPONENTS.uploading_location:
        return (
          <LocationFilesUploading
            setCurrentMainComponent={setCurrentMainComponent}
          />
        );
      default:
        return null;
    }
  }
  return !loading ? getMainComponent() : null;
};
export default SectionEntityProfile;
