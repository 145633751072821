import useMediaQuery from "@mui/material/useMediaQuery";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "../user-invite/user-invite.css";
import CustomSpinner from "../../common/spinner/CustomSpinner";

import { STD_ERROR_MESSAGE } from "../../../utils/constant";
import { getImageUrl } from "../../../utils/utils";

import PatternBackground from "../../../assets/image/pattern_bg.png";
import { getAllLocationInEntity } from "../../../services/entity.location.service";

const UserEntityLocation = (props) => {
  const { entityInfoLoading, entityId } = props;

  const isMobile = useMediaQuery("(max-width: 480px)");

  const [loading, setLoading] = useState(true);
  const [currLocationIndex, setcurrLocationIndex] = useState(0);

  const entityProfileState = useSelector((state) => state.entityProfileAd);
  const entityState = useSelector((state) => state.entityAd);

  const dispatch = useDispatch();

  const entityProfileData = entityProfileState.response?.data;
  const entityData = entityState.response?.data;

  const handleSelect = (selectedIndex, e) => {
    setcurrLocationIndex(selectedIndex);
  };

  useEffect(() => {
    if (isEmpty(entityProfileState.response)) {
      dispatch(getAllLocationInEntity(entityId));
    }
  }, []);

  useEffect(() => {
    if (!entityProfileState.loading && !isEmpty(entityProfileState.response)) {
      setLoading(false);
    }

    if (!entityProfileState.loading && !isEmpty(entityProfileState.error)) {
      setLoading(false);
      toast.error(entityProfileState.error?.error_data || STD_ERROR_MESSAGE);
    }
  }, [entityProfileState]);

  return (
    <div style={{ minHeight: "calc(100vh - 64px - 56px - 40px)" }}>
      {!loading && !entityInfoLoading ? (
        !isEmpty(entityData) ? (
          <>
            <div
              style={{
                paddingBottom: "2rem",
                backgroundImage: `url(${PatternBackground})`,
                backgroundRepeat: "repeat",
                backgroundColor: "var(--secondary-color)",
              }}
            >
              <div
                style={{
                  backgroundImage: `url(${getImageUrl(
                    entityData.image?.bucket_path
                  )})`, // header image.
                  height: isMobile ? "50vh" : "70vh",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: isMobile ? "100vw auto" : "auto 70vh",
                  position: "relative",
                }}
              >
                <h1
                  className={`${
                    isMobile ? "pb32-lh40" : "psb54-lh54"
                  } secondary-color`}
                  style={{
                    position: "absolute",
                    bottom: "1.5rem",
                    left: 0,
                    right: 0,
                    textAlign: "center",
                  }}
                ></h1>
              </div>
              <div>
                <div
                  style={{
                    padding: "52px 16px",
                  }}
                >
                  <div
                    style={{
                      maxWidth: "480px",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <h1
                      className={`${
                        isMobile
                          ? "parisienne-normal-fs80-lh96"
                          : "parisienne-normal-fs96-lh96"
                      } grey-2-color text-center`}
                    >
                      {entityData.name}
                    </h1>
                    {/* <h3 className="psb24-lh40 text-center grey-3-color">
                      TO OUR WEDDING
                    </h3> */}
                    {entityData.entity_title && (
                      <p className="pr18-lh32 text-center grey-2-color">
                        {entityData.entity_title}
                      </p>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    position: "static",
                  }}
                  className="invite-carousel-wrapper"
                >
                  <Carousel
                    indicators={true}
                    interval={null}
                    wrap={false}
                    activeIndex={currLocationIndex}
                    onSelect={handleSelect}
                  >
                    {entityProfileData?.map((location) => (
                      <Carousel.Item key={location.id}>
                        <img
                          src={getImageUrl(location.image?.bucket_path)}
                          alt=""
                        />
                      </Carousel.Item>
                    ))}
                  </Carousel>
                </div>
              </div>
            </div>
          </>
        ) : (
          <h4 className="text-center pr20-lh32 high-emphasis-color pt-5">
            You have no profile
          </h4>
        )
      ) : (
        <CustomSpinner />
      )}
    </div>
  );
};

export default UserEntityLocation;
