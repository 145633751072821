// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const env = process.env.REACT_APP_ENV;
const firebaseConfig =
  env === "production"
    ? {
        apiKey: "AIzaSyCTTY7hHee_EDLXvLF4Yh1LIlGwy-ZamGg",
        authDomain: "picsea-web.firebaseapp.com",
        projectId: "picsea-web",
        storageBucket: "picsea-web.appspot.com",
        messagingSenderId: "206904309741",
        appId: "1:206904309741:web:5f76b3e78a84a345a7d9e4",
        measurementId: "G-66KMTE073T",
      }
    : {
        apiKey: "AIzaSyDKvcSCZn8_ZZ9RRAO9G2U1H3WziC5B9P0",
        authDomain: "picsea-staging.firebaseapp.com",
        projectId: "picsea-staging",
        storageBucket: "picsea-staging.appspot.com",
        messagingSenderId: "898009956634",
        appId: "1:898009956634:web:77d745f0cd6bd011f0eca3",
        measurementId: "G-B56XCNBH58",
      };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const auth = getAuth(app);
