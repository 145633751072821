import React, { useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { toast } from "react-toastify";
import CollectionHeaderPartner from "../collection-header-partner/CollectionHeaderPartner";
import "./AddMessage.css";
import InputEmoji from "react-input-emoji";
import { STD_ERROR_MESSAGE } from "../../../utils/constant.js";
import { notificationPromise } from "../../../services/notification.service";

const AddMessage = (props) => {
  const [submitting, setSubmitting] = useState(false);
  const invitesState = useSelector((state) => state.invitesEm);
  const inputRef = useRef(null);
  const params = useParams();
  const invitesData = invitesState.response?.data;
  const initialCheckedData = new Array(invitesData.length).fill({
    checked: false,
    seq: 0,
  });
  const [checkedData, setCheckedData] = useState(initialCheckedData);
  const [text, setText] = useState("");

  const onClearBtnClick = () => {
    inputRef.current.value = "";
  };

  const handleChange = (position, sequence) => {
    const updatedCheckedData = checkedData.map((data, index) =>
      index === position ? { checked: !data.checked, seq: sequence } : data
    );
    setCheckedData((prev) => updatedCheckedData);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    if (!submitting) {
      setSubmitting(true);
      const sequence = checkedData
        .filter((data) => data.checked)
        .map((data) => data.seq);
      const payload = { message: text, sequence: sequence };

      notificationPromise(params.collectionId, payload)
        .then((res) => {
          setSubmitting(false);
          setText("");
          setCheckedData(initialCheckedData);
          toast.info("Notification sent successfully.");
        })
        .catch((err) => {
          setSubmitting(false);
          setText("");
          setCheckedData(initialCheckedData);
          toast.error(
            !isEmpty(err?.data?.error_data)
              ? err?.data?.error_data
              : STD_ERROR_MESSAGE
          );
        });
    }
  };

  return (
    <main
      style={{ maxWidth: "850px", marginLeft: "auto", marginRight: "auto" }}
    >
      <form onSubmit={onSubmit}>
        <CollectionHeaderPartner
          title="Send Notification"
          loadingBtn={true}
          loadingBtnProps={{
            type: "submit",
            loading: submitting,
            disabled:
              isEmpty(text) ||
              isEmpty(checkedData.filter((data) => data.checked)),
          }}
          loadingBtnLabel="Share"
          generalBtn={true}
          generalBtnLabel={"Clear"}
          onGeneralBtnClick={onClearBtnClick}
        />
        <div className="mt-3">
          <div
            className="d-flex  mb-3"
            style={{
              border: "1px solid rgba(41, 41, 41, 0.38)",
              borderRadius: "25px",
            }}
          >
            <InputEmoji
              onChange={setText}
              value={text}
              shouldReturn
              placeholder="Leave a message....!"
              type="text"
              style={{
                width: "100%",
                height: "150px",
                border: "2px solid rgba(41,41,41)",
                borderRadius: "25px",
              }}
            />
          </div>
        </div>
        <div>
          {invitesData.map((inviteData, position) => (
            <div key={inviteData.sequence}>
              <input
                type="Checkbox"
                disabled={submitting}
                className="checkbox"
                onChange={() => handleChange(position, inviteData.sequence)}
                checked={checkedData[position].checked}
              />
              {inviteData.sequence}
              <br />
            </div>
          ))}
        </div>
      </form>
    </main>
  );
};
export default AddMessage;
