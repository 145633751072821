import React from "react";
import starIcon from "../../../assets/icon/star.svg";
import Placeholder from "../../../assets/placeholder/collection_thumbnail_fill_200x112.svg";

import "./client-folders-layout.css";

const ClientFoldersLayout = (props) => {
  return (
    <figure
      key={props.index}
      className="effect-sub-title-slide"
      onClick={props.handleCardClick}
    >
      <img
        className="client-collection-image-container"
        src={props.thumbnail || Placeholder}
        alt=""
      />
      <figcaption>
        {props.collectionType === "friends" ? (
          <img className="star" src={starIcon} alt=""></img>
        ) : null}
        <h2 className="client-collection-title">{props.collectionName}</h2>
        <p className="client-collection-sub-title">
          {/* <span className="regular">By</span>
                    <span className="semi-bold m-1" >{props.enterpriseName}</span> */}
          <span className="regular">{props.createdAt}</span>
        </p>
      </figcaption>

      <figcaption>
        {props.collectionType === "friends" ? (
          <img className="star" src={starIcon} alt=""></img>
        ) : null}
        <h2 className="client-collection-title">{props.entityName}</h2>
      </figcaption>
    </figure>
  );
};

export default ClientFoldersLayout;
