import useMediaQuery from "@mui/material/useMediaQuery";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import CustomSpinner from "../../../components/common/spinner/CustomSpinner";
import CustomNavbar from "../../../components/layout/navbar/Navbar";
import { getACollection } from "../../../services/collections.service";
import { getAllVenuesInCollection } from "../../../services/venue.service";
import { STD_ERROR_MESSAGE } from "../../../utils/constant";
import { getImageUrl } from "../../../utils/utils";

export default function VenueBanner() {
  const isDesktop = useMediaQuery("(min-width: 992px)");
  const isMobile = useMediaQuery("(max-width: 480px)");

  const [loading, setLoading] = useState({ venue: true, collection: true });
  const collectionState = useSelector((state) => state.collectionEm);
  const venueState = useSelector((state) => state.venueEm);
  const dispatch = useDispatch();
  const params = useParams();

  const collectionInfo = collectionState.response?.data;
  const venueData = venueState.response?.data?.[0];

  const minHeight = isDesktop ? 64 : 56;

  useEffect(() => {
    if (isEmpty(collectionState.response)) {
      dispatch(getACollection(params.collectionId));
    }
    if (isEmpty(venueState.response)) {
      dispatch(getAllVenuesInCollection(params.collectionId));
    }
  }, []);

  useEffect(() => {
    if (!collectionState.loading && !isEmpty(collectionState.response)) {
      setLoading((ps) => ({ ...ps, collection: false }));
    }
    if (!collectionState.loading && !isEmpty(collectionState.error)) {
      setLoading((ps) => ({ ...ps, collection: false }));
      toast.error(collectionState.error?.data?.error_data || STD_ERROR_MESSAGE);
    }
  }, [collectionState]);

  useEffect(() => {
    if (!venueState.loading && !isEmpty(venueState.response)) {
      setLoading((ps) => ({ ...ps, venue: false }));
    }
    if (!venueState.loading && !isEmpty(venueState.error)) {
      setLoading((ps) => ({ ...ps, venue: false }));
      toast.error(venueState.error?.data?.error_data || STD_ERROR_MESSAGE);
    }
  }, [venueState]);

  function getBanner() {
    if (isDesktop) {
      return venueData?.image?.bucket_path ? (
        <img
          style={{ width: "100%", height: "auto" }}
          alt=""
          src={getImageUrl(venueData.image.bucket_path)}
        />
      ) : null;
    } else if (isMobile) {
      return venueData?.mobile_image?.bucket_path ? (
        <img
          style={{ width: "100%", height: "auto" }}
          alt=""
          src={getImageUrl(venueData.mobile_image.bucket_path)}
        />
      ) : null;
    } else {
      return venueData?.tablet_image?.bucket_path ? (
        <img
          style={{ width: "100%", height: "auto" }}
          alt=""
          src={getImageUrl(venueData.tablet_image.bucket_path)}
        />
      ) : null;
    }
  }

  return (
    <div>
      <CustomNavbar eventTitle={collectionInfo?.name} />
      <div style={{ minHeight: `calc(100vh - ${minHeight}px)` }}>
        {loading.venue ? <CustomSpinner /> : getBanner()}
      </div>
    </div>
  );
}
