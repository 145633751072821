import { 
    CLEAR_FETCH_COLLECTIONS_EM_STATES,
    REQUEST_FETCH_COLLECTIONS_EM,
    FETCH_COLLECTIONS_EM_FAILURE,
    FETCH_COLLECTIONS_EM_SUCCESS
} from './collections.types';

export const requestFetchCollectionsEm = () => {
    return {
        type : REQUEST_FETCH_COLLECTIONS_EM,
    }
}

export const fetchCollectionsEmSuccess = (response) => {
    return {
        type : FETCH_COLLECTIONS_EM_SUCCESS,
        payload : response
    }
}

export const fetchCollectionsEmFailure = (error) => {
    return {
        type : FETCH_COLLECTIONS_EM_FAILURE,
        payload : error
    }
}

export const clearFetchCollectionsEmStates = () => {
    return {
        type : CLEAR_FETCH_COLLECTIONS_EM_STATES
    }
}