import {
  CLEAR_FETCH_RESIDENTS_AD_STATES,
  FETCH_RESIDENTS_AD_FAILURE,
  FETCH_RESIDENTS_AD_SUCCESS,
  REQUEST_RESIDENTS_FETCH_AD,
} from "./residents.types";

let initialState = {
  reload: false,
  response: {},
  error: null,
  loading: false,
};

const fetchResidentsAdReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_RESIDENTS_FETCH_AD:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_RESIDENTS_AD_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
        reload: false,
      };
    case FETCH_RESIDENTS_AD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        reload: false,
      };
    case CLEAR_FETCH_RESIDENTS_AD_STATES:
      return {
        ...state,
        loading: false,
        response: {},
        error: null,
        reload: false,
      };
    default:
      return state;
  }
};
export default fetchResidentsAdReducer;
