import { isEmpty } from "lodash";
import "./select-box.css";

const SelectBox = (props) => {
  function createOptions(optionsList) {
    if (isEmpty(optionsList) || !Array.isArray(optionsList)) {
      return null;
    }

    const firstItem = optionsList[0];
    if (
      typeof firstItem === "object" &&
      !Array.isArray(firstItem) &&
      firstItem !== null &&
      "value" in firstItem &&
      "label" in firstItem
    ) {
      return optionsList.map((item, index) => (
        <option key={index} value={item.value}>
          {item.label}
        </option>
      ));
    } else {
      return optionsList.map((item, index) => (
        <option key={index} value={item}>
          {item}
        </option>
      ));
    }
  }

  return (
    <div className={`select-container ${props.className}`}>
      <select
        className="floating-select pb16-lh24"
        name={props.name}
        onChange={props.onChange}
        value={props.value}
        onBlur={props.onBlur}
      >
        <option value="" disabled hidden></option>
        {createOptions(props.options)}
      </select>
      <label className="floating-label-select">
        <span className="floating-label-select-content">
          {props.placeholder}
        </span>
      </label>
    </div>
  );
};

export default SelectBox;
