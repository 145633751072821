import ProtectedWebClient from "../utils/protected-web-client";
import axios from "axios";

export const getPresignedUrlPromise = (payload, id) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.post("/image", payload)
      .then((res) => {
        resolve({ id: id, res: res });
      })
      .catch((err) => {
        reject({ id: id, err: err });
      });
  });
};

export const uploadImageUsingPresignedUrlPromise = (presignedUrl, file, id) => {
  return new Promise((resolve, reject) => {
    axios
      .put(presignedUrl, file, { headers: { "Content-Type": "image/jpeg" } })
      .then((res) => {
        resolve({ id: id, res: res });
      })
      .catch((err) => {
        reject({ id: id, err: err });
      });
  });
};

export const detectFacePromise = (payload, id) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.post("/image/face", payload)
      .then((res) => {
        resolve({ id: id, res: res });
      })
      .catch((err) => {
        reject({ id: id, err: err });
      });
  });
};

export const deleteFaceRecData = () => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.delete("/face")
      .then((res) => {
        resolve({ res: res });
      })
      .catch((err) => {
        reject({ err: err });
      });
  });
};
