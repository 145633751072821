import React, { useState } from "react";
import { isEmpty, size } from "lodash";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import FileUploading from "../files-uploading/FilesUploading";
import CollectionHeaderPartner from "../collection-header-partner/CollectionHeaderPartner";
import {
  cancelUploadingFiles,
  retryUploadErrors,
} from "../../../services/uploadImages.service";
import {
  SECTION_ITINERARY_COMPONENTS,
  STD_ERROR_MESSAGE,
} from "../../../utils/constant";
import { clearUploadFilesState } from "../../../redux/upload-files/uploadFiles.action";
import {
  createItineraryPromise,
  getAllItineraryInCollection,
} from "../../../services/itinerary.service";

const ItineraryFilesUploading = (props) => {
  const uploadFilesState = useSelector((state) => state.uploadFiles);
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();

  const params = useParams();

  function handleUploadImageStatusCount(statusRole) {
    if (
      size(uploadFilesState.fileProgress) ===
      size(uploadFilesState.uploadSuccess.ids) +
        size(uploadFilesState.uploadError.ids)
    ) {
      if (statusRole === "success") {
        return `Successful Uploads: ${size(
          uploadFilesState.uploadSuccess.ids
        )}`;
      } else {
        if (size(uploadFilesState.uploadError.ids) > 0) {
          return `Errors: ${size(uploadFilesState.uploadError.ids)}`;
        } else {
          return "";
        }
      }
    } else {
      return "";
    }
  }

  function isUploadProgressing() {
    return (
      size(uploadFilesState.fileProgress) >
      size(uploadFilesState.uploadSuccess.ids) +
        size(uploadFilesState.uploadError.ids)
    );
  }

  function handleRetryBtnEvent() {
    dispatch(retryUploadErrors());
  }

  function uploadHasErrors() {
    return size(uploadFilesState.uploadError.ids) > 0;
  }

  function handleProceedBtnEvent() {
    if (!submitting) {
      setSubmitting(true);
      if (size(uploadFilesState.uploadSuccess.ids)) {
        const itineraryRequest = [];
        for (const id of uploadFilesState.uploadSuccess.ids) {
          const fileData = uploadFilesState.fileProgress[id];
          itineraryRequest.push({
            image_id: fileData.imageId,
            sequence: fileData.id,
          });
        }
        createItineraryPromise(params.collectionId, {
          itinerary_request: itineraryRequest,
        })
          .then((res) => {
            setSubmitting(false);
            dispatch(getAllItineraryInCollection(params.collectionId));
            props.setCurrentMainComponent((prevState) => {
              return {
                currComponent: SECTION_ITINERARY_COMPONENTS.invites_list,
                prevComponent: prevState.currComponent,
              };
            });
            dispatch(clearUploadFilesState());
          })
          .catch((err) => {
            setSubmitting(false);
            toast.error(
              !isEmpty(err?.error_data) ? err?.error_data : STD_ERROR_MESSAGE
            );
          });
      } else {
        setSubmitting(false);
        props.setCurrentMainComponent((prevState) => {
          return {
            currComponent: SECTION_ITINERARY_COMPONENTS.itinerary_list,
            prevComponent: prevState.currComponent,
          };
        });
        dispatch(clearUploadFilesState());
      }
    }
  }

  function handleAddPhotosBtnEvent() {
    props.setCurrentMainComponent((prevState) => {
      return {
        currComponent: SECTION_ITINERARY_COMPONENTS.upload_itinerary,
        prevComponent: prevState.currComponent,
      };
    });
  }

  function handleCancelBtnEvent() {
    dispatch(cancelUploadingFiles());
    props.setCurrentMainComponent((prevState) => {
      return {
        currComponent: SECTION_ITINERARY_COMPONENTS.itinerary_list,
        prevComponent: prevState.currComponent,
      };
    });
  }

  return (
    <>
      <CollectionHeaderPartner
        title={`Adding Photos to Itinerary`}
        uploadSuccessContent={handleUploadImageStatusCount("success")}
        uploadErrorContent={handleUploadImageStatusCount("failure")}
        cancelBtn={isUploadProgressing()}
        handleCancelBtnClick={handleCancelBtnEvent}
        retryBtn={!isUploadProgressing() && uploadHasErrors()}
        handleRetryBtnClick={handleRetryBtnEvent}
        addPhotosBtn={!isUploadProgressing()}
        addPhotosLabel="Add More"
        handleAddPhotosBtn={handleAddPhotosBtnEvent}
        loadingBtn={!isUploadProgressing()}
        loadingBtnProps={{
          loading: submitting,
          onClick: handleProceedBtnEvent,
        }}
        loadingBtnLabel="Proceed"
      />
      <FileUploading />
    </>
  );
};

export default ItineraryFilesUploading;
