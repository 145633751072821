import {
  fetchPermalinkAdFailure,
  fetchPermalinkAdSuccess,
  requestFetchPermalinkAd,
} from "../redux/permalink/permalink.action";
import ProtectedWebClient from "../utils/protected-web-client";

export const createPermaLink = (entityId, payload) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.post(`/entity/${entityId}/permalink`, payload)
      .then((res) => {
        resolve(res?.data);
      })
      .catch((error) => {
        reject(error?.response);
      });
  });
};

export const getPermaLinkById = (entityId) => {
  return async (dispatch) => {
    try {
      dispatch(requestFetchPermalinkAd());
      const fetchPermalinkResponse = await ProtectedWebClient.get(
        `/entity/${entityId}/permalink`
      );
      dispatch(fetchPermalinkAdSuccess(fetchPermalinkResponse?.data));
    } catch (error) {
      dispatch(fetchPermalinkAdFailure(error?.response));
    }
  };
};

export const updatePermalinkById = (entityId, payload) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.put(`/entity/${entityId}/permalink`, payload)
      .then((res) => {
        resolve(res?.data);
      })
      .catch((error) => {
        reject(error?.response);
      });
  });
};

/// to get permalink url

export const getPermaLinkByEventId = (collectionId) => {
  return async (dispatch) => {
    try {
      dispatch(requestFetchPermalinkAd());
      const fetchPermalinkEmResponse = await ProtectedWebClient.get(
        `/event/${collectionId}/permalink`
      );
      dispatch(fetchPermalinkAdSuccess(fetchPermalinkEmResponse?.data));
    } catch (error) {
      dispatch(fetchPermalinkAdFailure(error?.response));
    }
  };
};
