import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import * as yup from "yup";
import { isEmpty, size } from "lodash";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import Carousel from "react-bootstrap/Carousel";
import { toast } from "react-toastify";
import queryString from "query-string";

import BackArrow from "../../../assets/icon/back_arrow.svg";

import "./single-image-view-with-carousel.css";

import { getImageUrl } from "../../../utils/utils";
import { STD_ERROR_MESSAGE } from "../../../utils/constant";

import { getACollection } from "../../../services/collections.service";
import {
  getAllGallery,
  getImagesOfGalleryFolder,
  getImagesOfGalleryFolderForUser,
  updateImageDetailsInAGalleryFolderPromise,
} from "../../../services/gallery.service";
import CustomSpinner from "../../../components/common/spinner/CustomSpinner";

const SingleImageViewWithCarousel = (props) => {
  // const isMobile = useMediaQuery({ query : '(max-width : 480px)'})

  const dispatch = useDispatch();
  const collectionState = useSelector((state) => state.collectionEm);
  const galleryState = useSelector((state) => state.galleryEm);
  const galleryFoldersState = useSelector((state) => state.galleryFoldersEm);
  const myPhotosGalleryFoldersState = useSelector(
    (state) => state.myPhotosGalleryFoldersEm
  );

  const params = useParams();
  const history = useHistory();
  const location = useLocation();

  const query = queryString.parse(location.search);

  const [pageLoading, setPageLoading] = useState({
    collection: true,
    gallery: true,
    folder: true,
  });

  const [currGalleryFolderInfo, setCurrGalleryFolderInfo] = useState({});
  const [editForm, setEditForm] = useState(false);

  const collectionInfo = collectionState.response?.data;
  const galleryInfo = galleryState.response?.data;
  const myPhotosOnly = query?.myPhotosOnly === "true";
  const currGalleryFolderData = myPhotosOnly
    ? myPhotosGalleryFoldersState.response?.[currGalleryFolderInfo?.id]?.data
    : galleryFoldersState.response?.[currGalleryFolderInfo?.id]?.data;

  const currImageIndex = parseInt(query?.selected) || 0;

  useEffect(() => {
    if (isEmpty(collectionState.response?.data)) {
      dispatch(getACollection(params.collectionId));
    }
    if (isEmpty(galleryState.response?.data)) {
      dispatch(getAllGallery(params.collectionId));
    }
  }, []);

  useEffect(() => {
    if (!collectionState.loading && !isEmpty(collectionState.response)) {
      setPageLoading((ps) => {
        return {
          ...ps,
          collection: false,
        };
      });
    }
    if (!collectionState.loading && !isEmpty(collectionState.error)) {
      setPageLoading((ps) => {
        return {
          ...ps,
          collection: false,
        };
      });
      toast.error(collectionState.error?.data?.error_data || STD_ERROR_MESSAGE);
    }
  }, [collectionState]);

  useEffect(() => {
    if (!galleryState.loading && !isEmpty(galleryState.response)) {
      setPageLoading((ps) => {
        return {
          ...ps,
          gallery: false,
        };
      });
      const currGalleryInfo = galleryInfo.find(
        (folder) => folder.id === params.folderId
      );
      if (!isEmpty(currGalleryInfo)) {
        setCurrGalleryFolderInfo(currGalleryInfo);
      }
    }
    if (!galleryState.loading && !isEmpty(galleryState.error)) {
      setPageLoading((ps) => {
        return {
          ...ps,
          gallery: false,
        };
      });
      toast.error(galleryState.error?.data?.error_data || STD_ERROR_MESSAGE);
    }
  }, [galleryState]);

  useEffect(() => {
    if (!isEmpty(currGalleryFolderInfo)) {
      if (
        !myPhotosOnly &&
        isEmpty(galleryFoldersState.response?.[currGalleryFolderInfo?.id])
      ) {
        if (!pageLoading.folder)
          setPageLoading((ps) => ({ ...ps, folder: true }));
        dispatch(
          getImagesOfGalleryFolder(
            params.collectionId,
            currGalleryFolderInfo.id
          )
        );
      }
    }
  }, [currGalleryFolderInfo, myPhotosOnly]);

  useEffect(() => {
    if (!isEmpty(currGalleryFolderInfo)) {
      if (
        myPhotosOnly &&
        isEmpty(
          myPhotosGalleryFoldersState.response?.[currGalleryFolderInfo?.id]
        )
      ) {
        if (!pageLoading.folder)
          setPageLoading((ps) => ({ ...ps, folder: true }));
        dispatch(
          getImagesOfGalleryFolderForUser(
            params.collectionId,
            currGalleryFolderInfo.id
          )
        );
      }
    }
  }, [currGalleryFolderInfo, myPhotosOnly]);

  useEffect(() => {
    if (!isEmpty(currGalleryFolderInfo)) {
      if (
        !myPhotosOnly &&
        !galleryFoldersState.loading &&
        !isEmpty(galleryFoldersState.response?.[currGalleryFolderInfo?.id])
      ) {
        setPageLoading((ps) => {
          return {
            ...ps,
            folder: false,
          };
        });
      }
      if (
        !myPhotosOnly &&
        !galleryFoldersState.loading &&
        !isEmpty(galleryFoldersState.error)
      ) {
        setPageLoading((ps) => {
          return {
            ...ps,
            folder: false,
          };
        });
        toast.error(
          galleryFoldersState.error?.data?.error_data || STD_ERROR_MESSAGE
        );
      }
    }
  }, [galleryFoldersState, currGalleryFolderInfo, myPhotosOnly]);

  useEffect(() => {
    if (!isEmpty(currGalleryFolderInfo)) {
      if (
        myPhotosOnly &&
        !myPhotosGalleryFoldersState.loading &&
        !isEmpty(
          myPhotosGalleryFoldersState.response?.[currGalleryFolderInfo?.id]
        )
      ) {
        setPageLoading((ps) => {
          return {
            ...ps,
            folder: false,
          };
        });
      }
      if (
        myPhotosOnly &&
        !myPhotosGalleryFoldersState.loading &&
        !isEmpty(myPhotosGalleryFoldersState.error)
      ) {
        setPageLoading((ps) => {
          return {
            ...ps,
            folder: false,
          };
        });
        toast.error(
          myPhotosGalleryFoldersState.error?.data?.error_data ||
            STD_ERROR_MESSAGE
        );
      }
    }
  }, [myPhotosGalleryFoldersState, currGalleryFolderInfo, myPhotosOnly]);

  const initialValues = {
    description: !isEmpty(currGalleryFolderData?.[currImageIndex]?.description)
      ? currGalleryFolderData?.[currImageIndex]?.description
      : "",
  };

  const validationSchema = yup.object({
    description: yup
      .string()
      .required("Please enter the description")
      .min(2, "Too short")
      .max(150, "Too long"),
  });

  function onSubmit(values) {
    const imageId = currGalleryFolderData?.[currImageIndex]?.image_id;
    updateImageDetailsInAGalleryFolderPromise(
      params.collectionId,
      params.folderId,
      imageId,
      values
    )
      .then((res) => {
        // TODO update getImagesOfGalleryFolder
        closeEditForm();
      })
      .catch((err) => {
        toast.error(err?.data?.error_data || STD_ERROR_MESSAGE);
      });
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit,
  });

  function showEditForm() {
    setEditForm(true);
  }

  function closeEditForm() {
    setEditForm(false);
  }

  const handleSelect = (selectedIndex, e) => {
    history.push(
      `/collection/${params.collectionId}/folder/${params.folderId}/image-slide?myPhotosOnly=${myPhotosOnly}&selected=${selectedIndex}`
    );
  };

  function handleFavouriteImageTrigger(imageId) {
    // if(currentFolder.images[index].favourite){
    //     requestUnfavouriteImage(params.collectionId,currentFolder.folder_id,currentFolder.images[index].id)
    //     .then((res) => {
    //         if(res.data){
    //             if(res.data.data.success == true){
    //                 saveImageFavouriteStatus(currentFolder.folder_id,index)
    //             }
    //         }
    //     }).catch((error) => {
    //         if(!isEmpty(error.response)){
    //             if(!isEmpty(error.response.data)){
    //                 console.log(error.response.data.error_data)
    //             }
    //         }
    //     })
    // }else{
    //     requestFavouriteImage(params.collectionId,currentFolder.folder_id,currentFolder.images[index].id)
    //     .then((res) => {
    //         if(res.data){
    //             if(res.data.data.success == true){
    //                 saveImageFavouriteStatus(currentFolder.folder_id,index)
    //                 setToast(!toast)
    //             }
    //         }
    //     }).catch((error) => {
    //         if(!isEmpty(error.response)){
    //             if(!isEmpty(error.response.data)){
    //                 console.log(error.response.data.error_data)
    //             }
    //         }
    //     })
    // }
  }

  return !pageLoading.collection ? (
    <div style={{ minHeight: "100vh" }} className="d-flex flex-column">
      <div className="header-single-image-view">
        <div className="pt-2">
          <img
            className="pt-cursor"
            src={BackArrow}
            alt=""
            onClick={() =>
              history.push(
                `/collection/${params.collectionId}?selected=photo-gallery`
              )
            }
          />
        </div>
        <div className="d-flex flex-column ml-3">
          <h3 className="pb32-lh40 primary-color mb-0">
            {currGalleryFolderInfo?.title || ""}
          </h3>
          <div className="d-flex py-2">
            {size(currGalleryFolderData) ? (
              <>
                <span className="pb16-lh24 mb-0 high-emphasis-color">
                  {currImageIndex + 1}/{size(currGalleryFolderData)}
                </span>
                <span className="pb16-lh24 mb-0 high-emphasis-color px-2">
                  |
                </span>
                <span className="pr16-lh24 mb-0">
                  {collectionInfo?.name || ""}
                </span>
              </>
            ) : null}
          </div>
        </div>
      </div>
      <div className="d-flex flex-fill">
        <main className="flex-fill p-relative">
          {!pageLoading.folder ? (
            !isEmpty(currGalleryFolderData) ? (
              <div className="single-image-view-carousel-wrapper">
                <Carousel
                  indicators={false}
                  interval={null}
                  wrap={false}
                  activeIndex={currImageIndex}
                  onSelect={handleSelect}
                >
                  {currGalleryFolderData.map((image, index) => (
                    <Carousel.Item key={image.id}>
                      <img src={getImageUrl(image.image?.bucket_path)} alt="" />
                    </Carousel.Item>
                  ))}
                </Carousel>
              </div>
            ) : (
              <h4 className="text-center pr20-lh32 high-emphasis-color mt-5">
                No images found
              </h4>
            )
          ) : (
            <CustomSpinner />
          )}
        </main>
        {/* <aside className="sidebar-single-image-view p-4">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h2 className="pb18-lh32 m-0">About</h2>
            {!editForm ? (
              <div className="pt-cursor d-inline" onClick={showEditForm}>
                <img className="editIconImage" src={editIcon} alt="" />
                <span className="pr12-lh24 vertical-middle">Edit</span>
              </div>
            ) : null}
          </div>
          {editForm ? (
            <div>
              <form onSubmit={formik.handleSubmit}>
                <textarea
                  name="description"
                  className="img-description-textarea pr16-lh24"
                  placeholder="Add a description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.description && formik.errors.description ? (
                  <div className="d-flex align-items-center justify-content-start mb-2">
                    <img src={errorInfoIcon} alt="" />
                    <span className="margin-left-5 pr10-lh15 error-color">
                      {formik.errors.description}
                    </span>
                  </div>
                ) : null}
                <div className="description-btn-container mt-2">
                  <ButtonTransparent
                    label="Cancel"
                    className="description-cancel-btn pb14-lh21 flex-1 desc-btn-height p-0"
                    onClick={closeEditForm}
                  />
                  <ButtonAccent
                    label="Post"
                    type="submit"
                    className="flex-1 desc-btn-height pb14-lh21 p-0"
                  />
                </div>
              </form>
            </div>
          ) : (
            <div className="pr16-lh24">
              <p>{currGalleryFolderData?.description}</p>
              <div className="like">
                <img
                  src={
                    currGalleryFolderData?.liked_by_user
                      ? FavouriteFilledIcon
                      : FavouriteOutlinedIcon
                  }
                  alt=""
                />
                <span className="pr14-lh24">
                  {currGalleryFolderData?.likes_count || 0} like
                </span>
              </div>
            </div>
          )}
        </aside> */}
      </div>
    </div>
  ) : null;
};

export default SingleImageViewWithCarousel;
