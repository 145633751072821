import { REQUEST_PROFILE,
        PROFILE_SUCCESS,
        PROFILE_FAILURE,
        CLEAR_PROFILE } from './readProfile.types';

export const requestProfile = () => {
    return {
        type : REQUEST_PROFILE,
    }
}

export const readProfileSuccess = (response) => {
    return {
        type : PROFILE_SUCCESS,
        payload : response
    }
}

export const readProfileFailure = (error) => {
    return {
        type : PROFILE_FAILURE,
        payload : error
    }
}

export const clearProfile = () => {
    return {
        type : CLEAR_PROFILE,
    }
}