import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { isEmpty } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { getAllInvitesInCollection } from "../../../../services/invites.service.js";
import {
  GUEST_COMPONENTS,
  SECTION_INVITES_COMPONENTS,
  STD_ERROR_MESSAGE,
  WEDDING_FOLDERS,
} from "../../../../utils/constant";

import UploadInvites from "../../upload-invites/UploadInvites";
import InviteFilesUploading from "../../invite-files-uploading/InviteFilesUploading";
import InvitesList from "../../InvitesList/InvitesList";
import GuestInviteList from "../../guest-invite-list/GuestInviteList.jsx";

const SectionInvites = (props) => {
  const [loading, setLoading] = useState(true);
  const [currentMainComponent, setCurrentMainComponent] = useState({
    currComponent: null,
    prevComponent: null,
  });
  const [fileData, setFileData] = useState("");

  const invitesState = useSelector((state) => state.invitesEm);
  const dispatch = useDispatch();
  const params = useParams();

  const invitesData = invitesState.response?.data;

  useEffect(() => {
    if (isEmpty(invitesState.response)) {
      dispatch(getAllInvitesInCollection(params.collectionId));
    }
  }, []);

  useEffect(() => {
    if (!invitesState.loading && !isEmpty(invitesState.response)) {
      setLoading(false);
      if (!isEmpty(invitesData)) {
        setCurrentMainComponent((prevState) => {
          return {
            currComponent: SECTION_INVITES_COMPONENTS.invites_list,
            prevComponent: prevState.currComponent,
          };
        });
      } else {
        setCurrentMainComponent((prevState) => {
          return {
            currComponent: SECTION_INVITES_COMPONENTS.upload_invites,
            prevComponent: prevState.currComponent,
          };
        });
      }
    }

    if (!invitesState.loading && !isEmpty(invitesState.error)) {
      setLoading(false);
      toast.error(invitesState.error?.error_data || STD_ERROR_MESSAGE);
    }
  }, [invitesState]);

  function handleCancelClick() {
    setCurrentMainComponent((prevState) => {
      return {
        currComponent:
          prevState.prevComponent || SECTION_INVITES_COMPONENTS.invites_list,
        prevComponent: prevState.currComponent,
      };
    });
  }

  function changeNextComponent(component) {
    setCurrentMainComponent((prevState) => {
      return {
        currComponent: component,
        prevComponent: prevState.currComponent,
      };
    });
  }

  function getMainComponent() {
    switch (currentMainComponent.currComponent) {
      case SECTION_INVITES_COMPONENTS.invites_list:
        return (
          <InvitesList
            onInviteButtonClick={() =>
              changeNextComponent(SECTION_INVITES_COMPONENTS.upload_invites)
            }
            onMasterInviteBtnClick={() =>
              props.setSelected({
                folder: WEDDING_FOLDERS.master_invite_list,
                subFolder: null,
              })
            }
            setCurrentMainComponent={setCurrentMainComponent}
            setFileData={setFileData}
          />
        );
      case SECTION_INVITES_COMPONENTS.upload_invites:
        return (
          <UploadInvites
            onCancelClick={handleCancelClick}
            setCurrentMainComponent={setCurrentMainComponent}
          />
        );
      case SECTION_INVITES_COMPONENTS.uploading_invites:
        return (
          <InviteFilesUploading
            setCurrentMainComponent={setCurrentMainComponent}
          />
        );
      case SECTION_INVITES_COMPONENTS.invite_guest_list:
        return (
          <GuestInviteList
            onCancelBtnClick={handleCancelClick}
            component={GUEST_COMPONENTS.invite}
            fileData={fileData}
          />
        );
      default:
        return null;
    }
  }

  return !loading ? getMainComponent() : null;
};

export default SectionInvites;
