import React from 'react'
import './button-primary.css';

const ButtonPrimary = (props) => {

    let classes = props.className ? `button button-primary pb14-lh21 ${props.className}` : 'button button-primary pb14-lh21';

    if(props.variant){
        switch (props.variant) {
            case "icon-button" :
                return (
                    <button type={ props.type?props.type: "button" } className={classes} style={props.customStyling} onClick={props.onClick} disabled={props.disabled ? props.disabled : false } >
                    {props.startIcon && <img className={props.startIconClassName} src={props.startIcon} alt=""></img>} 
                    <span className={props.labelClassName}>{props.label}</span>
                    {props.endIcon && <img className={props.endIconClassName} src={props.endIcon} alt=""></img>} </button>
                );
            case "span-button":
                return (
                    <button type={ props.type?props.type: "button" } className={classes} style={props.customStyling} onClick={props.onClick} disabled={props.disabled ? props.disabled : false } >
                    {props.startSpan && <span className={props.startSpanClassName}>{props.startSpan}</span>}
                    <span className={props.labelClassName}>{props.label}</span>
                    {props.endSpan && <span className={props.endSpanClassName}>{props.endSpan}</span>} </button>
                );
    
            default:
                return (
                    <button type={ props.type?props.type: "button" } className={classes} style={props.customStyling} onClick={props.onClick} disabled={props.disabled ? props.disabled : false } >
                    {props.label}</button>
                );
        }
    } else {
        return (
            <button type={ props.type?props.type: "button" } className={classes} style={props.customStyling} onClick={props.onClick} disabled={props.disabled ? props.disabled : false } >
            {props.label}</button>
        );
    }
}

export default ButtonPrimary
