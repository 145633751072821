import {
  REQUEST_LEADER_BOARD_FETCH_USER,
  FETCH_LEADER_BOARD_USER_SUCCESS,
  FETCH_LEADER_BOARD_USER_FAILURE,
  CLEAR_FETCH_LEADER_BOARD_USER_STATES,
} from "./leaderBoardUser.types";

let initialState = {
  loading: false,
  response: {},
  error: null,
};

const fetchLeaderBoardUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_LEADER_BOARD_FETCH_USER:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_LEADER_BOARD_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };

    case FETCH_LEADER_BOARD_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_FETCH_LEADER_BOARD_USER_STATES:
      return {
        ...state,
        loading: false,
        response: {},
        error: null,
      };

    default:
      return state;
  }
};

export default fetchLeaderBoardUserReducer;
