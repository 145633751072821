import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { isEmpty } from "lodash";
import React, { useState } from "react";
import ButtonAccent from "../../common/button-accent/ButtonAccent";
import PublishIcon from "../../../assets/icon/publish_web.svg";
import UnpublishIcon from "../../../assets/icon/unpublish.svg";
import MoreIcon from "../../../assets/icon/horizontal_more.svg";
import DeleteIcon from "../../../assets/icon/delete.svg";
import EditIcon from "../../../assets/icon/edit.svg";
import "../games-question-item/games-question-item.css";

export default function PreferenceQuestionItem(props) {
  const {
    onPublishChangeClick,
    preferenceData,
    onEditQuestionClick,
    onDeleteQuestionClick,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div
      className="p-3 mb-3"
      style={{
        border: "2px solid rgba(41, 41, 41, 0.38)",
        borderRadius: "8px",
      }}
    >
      <div className="d-flex justify-content-end">
        <img
          className="py-1 pt-cursor mb-2"
          src={MoreIcon}
          alt=""
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        />
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            onClick={() => {
              onEditQuestionClick();
              handleClose();
            }}
          >
            <img
              style={{
                width: "14px",
                height: "14px",
              }}
              alt=""
              src={EditIcon}
            />
            <h6 className="pb14-lh21 mb-0 ml-2">Edit</h6>
          </MenuItem>
          <MenuItem
            onClick={() => {
              onDeleteQuestionClick();
              handleClose();
            }}
          >
            <img
              style={{
                width: "14px",
                height: "14px",
              }}
              alt=""
              src={DeleteIcon}
            />
            <h6 className="pb14-lh21 mb-0 ml-2">Delete</h6>
          </MenuItem>
        </Menu>
      </div>
      <div
        className="secondary-color-bg pr16-lh24 high-emphasis-color"
        style={{
          padding: "10px",
          borderRadius: "8px",
          minHeight: "100px",
        }}
      >
        <p style={{ width: "100%" }}>{preferenceData.question}</p>
      </div>
      {/* {!isEmpty(preferenceData.image) ? (
        <div className="mt-3">
          {/* <LazyLoadComponent>
            <Image
              src={getImageUrl(preferenceData.image.bucket_path)}
              alt=""
              containerCustomStyle={{
                maxHeight: "150px",
                width: "100%",
                maxWidth: "100%",
              }}
              imageCustomStyle={{
                maxWidth: "100%",
                maxHeight: "100%",
                width: "auto",
                height: "auto",
              }}
              placeholderClassName="live-feed-placeholder"
            />
          </LazyLoadComponent> */}
      {/* </div>
      ) : null} */}
      {/* <Grid container sx={{ marginTop: "0.25rem" }} spacing={2}>
        {preferenceData.options.map((option) => (
          <Grid key={option.id} item xs={12} sm={12} md={6}>
            <div
            //   className={`option-outline-box ${
            //     option.is_correct ? "correct" : ""
            //   }`}
            >
              <h4 className="pb14-lh21 mb-0">{option.title}</h4>
            </div>
          </Grid>
        ))}
      </Grid> */}
      <Grid sx={{ marginTop: "0.25rem" }} container spacing={2}>
        <Grid item xs={12} sm={12} md={6}>
          <ButtonAccent
            variant="icon-button"
            className="d-flex align-items-center justify-content-center"
            startIcon={preferenceData.published ? UnpublishIcon : PublishIcon}
            label={preferenceData.published ? "Unpublish" : "Publish"}
            labelClassName="ml-2"
            startIconClassName="btn-icon-small"
            customStyling={{
              width: "100%",
              padding: "6px 16px",
            }}
            onClick={onPublishChangeClick}
          />
        </Grid>
      </Grid>
    </div>
  );
}
