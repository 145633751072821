import SingleInputPopUp from "../../common/single-input-pop-up/SingleInputPopUp";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { updateCollection } from "../../../services/collections.service";
import { fetchCollectionEmSuccess } from "../../../redux/collection/collection.action";

const EditCollectionName = (props) => {
  const collectionState = useSelector((state) => state.collectionEm);
  const dispatch = useDispatch();

  const collection = collectionState.response?.data;

  const initialValues = {
    name: collection?.name,
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Please enter the collection name"),
  });

  const onSubmit = (values) => {
    updateCollection(values, collection.id)
      .then((res) => {
        dispatch(fetchCollectionEmSuccess(res.data));
        props.handleClose();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  return (
    <SingleInputPopUp
      handleClose={props.handleClose}
      heading="Edit Collection"
      inputPlaceholder="Name your Collection"
      inputName="name"
      inputOnChange={formik.handleChange}
      inputValue={formik.values.name}
      inputErrorMsg={formik.touched.name && formik.errors.name}
      inputOnBlur={formik.handleBlur}
      btnLabel="Save"
      onSubmit={formik.handleSubmit}
    />
  );
};

export default EditCollectionName;
