import {
  CLEAR_FETCH_ORG_COLLECTIONS_EM_STATES,
  FETCH_ORG_COLLECTIONS_EM_FAILURE,
  FETCH_ORG_COLLECTIONS_EM_SUCCESS,
  REQUEST_FETCH_ORG_COLLECTIONS_EM,
} from "./orgCollections.types";

export const requestFetchOrgCollectionsEm = () => {
  return {
    type: REQUEST_FETCH_ORG_COLLECTIONS_EM,
  };
};

export const fetchOrgCollectionsEmSuccess = (response) => {
  return {
    type: FETCH_ORG_COLLECTIONS_EM_SUCCESS,
    payload: response,
  };
};

export const fetchOrgCollectionsEmFailure = (error) => {
  return {
    type: FETCH_ORG_COLLECTIONS_EM_FAILURE,
    payload: error,
  };
};

export const clearFetchOrgCollectionsEmStates = () => {
  return {
    type: CLEAR_FETCH_ORG_COLLECTIONS_EM_STATES,
  };
};
