import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import useMediaQuery from "@mui/material/useMediaQuery";
import { isEmpty } from "lodash";
import qs from "query-string";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../../../components/layout/footer/Footer";

import Navbar from "../../../components/layout/navbar/Navbar";
import UserCoupleStory from "../../../components/layout/user-couple-story/UserCoupleStory";
import UserInvite from "../../../components/layout/user-invite/UserInvite";
import UserLiveUpdates from "../../../components/layout/user-live-updates/UserLiveUpdates";
import UserTimeline from "../../../components/layout/user-timeline/UserTimeline";
import PhotoGallery from "../photo-gallery/PhotoGallery";

import { STD_ERROR_MESSAGE } from "../../../utils/constant";
import { getKeyByValue, getUserId, setOriginalUrl } from "../../../utils/utils";

import UserGamesSection from "../../../components/layout/user-games-section/UserGamesSection";
import { getACollection } from "../../../services/collections.service.js";
import { clearFetchCollectionEmStates } from "../../../redux/collection/collection.action.js";
import { clearFetchInvitesEmStates } from "../../../redux/invites/invites.action.js";
import { clearFetchKnowTheHostSectionsEmStates } from "../../../redux/know-the-host/knowTheHost.action.js";
import { clearFetchLiveFeedsEmStates } from "../../../redux/live-feed/liveFeed.action.js";
import { clearFetchItineraryEmStates } from "../../../redux/itinerary/itinerary.action.js";
import { clearFetchGalleryEmStates } from "../../../redux/gallery/gallery.action.js";
import { clearFetchGalleryFoldersEmStates } from "../../../redux/gallery-folders/galleryFolders.action.js";
import { clearMyPhotosGalleryFoldersEmStates } from "../../../redux/gallery-folders/myPhotosGalleryFolders.action.js";
import { clearFetchQuestionsEmStates } from "../../../redux/games/games.action.js";
import { clearFetchVenueEmStates } from "../../../redux/venue/venue.action.js";
import { clearFetchPermalinkAdStates } from "../../../redux/permalink/permalink.action.js";
import { clearFetchEntityAdStates } from "../../../redux/entity/entity.action.js";
import { clearProfile } from "../../../redux/profile-read/readProfile.action.js";
import { fetchProfile } from "../../../services/profile.service.js";
import { getGroupByOwnerId } from "../../../services/group.service.js";
import { clearFetchGroupEmStates } from "../../../redux/group/group.action.js";
import UserRSVPPreferenceSection from "../../../components/layout/user-rsvp-pref-section/UserRSVPPreferenceSection.js";
import CustomSpinner from "../../../components/common/spinner/CustomSpinner.js";
import { clearFetchRSVPDateEmStates } from "../../../redux/rsvpDate/rsvpDate.action.js";
import { clearFetchEventPermalinkEmStates } from "../../../redux/event-permalink/eventPermalink.action.js";
import UserResidents from "../../../components/layout/user-residents/UserResidents.js";
import UserNews from "../../../components/layout/user-news/UserNews.js";
import UserPolicies from "../../../components/layout/user-policies/UserPolicies.js";
import { clearFetchNewsAdStates } from "../../../redux/news/news.action.js";
import { clearFetchPoliciesAdStates } from "../../../redux/policies/policies.action.js";
import { clearFetchresidentsAdStates } from "../../../redux/residents/residents.action.js";

const TAB_NAME_INDEX_MAPPING = {
  welcome: 0,
  "know-the-couple": 1,
  "live-updates": 3,
  itinerary: 2,
  games: 4,
  "photo-gallery": 5,
  rsvp: 6,
  home: 7,
  associates: 8,
  residents: 9,
  feed: 10,
  events: 11,
  policies: 12,
};

const StyledTabs = styled(Tabs)({
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.12)",
  backgroundColor: "var(--secondary-color)",
  "& .MuiTabs-indicator": {
    backgroundColor: "var(--high-emphasis)",
    height: "2.5px",
  },
});

const StyledTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
  textTransform: "none",
  minWidth: 0,
  [theme.breakpoints.up("sm")]: {
    minWidth: 0,
  },
  fontWeight: 400,
  fontFamily: '"Poppins" , sans-serif',
  lineHeight: "32px",
  fontSize: "18px",
  color: "var(--medium-emphasis)",
  marginRight: theme.spacing(1),
  "&.Mui-selected": {
    color: "var(--high-emphasis)",
    fontWeight: 600,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {children}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
    value: index,
  };
}

export default function Collection(props) {
  const isDesktop = useMediaQuery("(min-width: 992px)");
  const isSmallScreen = useMediaQuery("(max-width: 600px)");

  const [collectionLoading, setCollectionLoading] = useState(true);
  const [profileLoading, setProfileLoading] = useState(true);
  const [groupLoading, setGroupLoading] = useState(true);

  const collectionState = useSelector((state) => state.collectionEm);
  const profileState = useSelector((state) => state.readProfileEm);
  const profileData = profileState?.response?.data;

  const [checked, setChecked] = useState(false);
  const dispatch = useDispatch();

  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const queryParams = qs.parse(location.search);
  const selected = queryParams.selected;

  const collectionInfo = collectionState.response?.data;
  const settings = collectionState.response?.data?.settings
    ? JSON.parse(collectionState.response?.data?.settings)
    : {};

  const groupState = useSelector((state) => state.groupEm);
  const groupData = groupState.response?.data;

  const collectionId = props.collectionId
    ? props.collectionId
    : params?.collectionId;

  useEffect(() => {
    if (isEmpty(collectionState.response)) {
      dispatch(getACollection(collectionId));
    }
    if (isEmpty(profileState.response)) {
      dispatch(fetchProfile(getUserId()));
    }
    if (isEmpty(groupState.response)) {
      dispatch(getGroupByOwnerId(collectionId));
    }
    // clear up
    return () => {
      dispatch(clearFetchCollectionEmStates());
      dispatch(clearFetchInvitesEmStates());
      dispatch(clearFetchKnowTheHostSectionsEmStates());
      dispatch(clearFetchLiveFeedsEmStates());
      dispatch(clearFetchItineraryEmStates());
      dispatch(clearFetchGalleryEmStates());
      dispatch(clearFetchGalleryFoldersEmStates());
      dispatch(clearMyPhotosGalleryFoldersEmStates());
      dispatch(clearFetchQuestionsEmStates());
      dispatch(clearFetchVenueEmStates());
      dispatch(clearFetchPermalinkAdStates());
      dispatch(clearFetchEntityAdStates());
      dispatch(clearProfile());
      dispatch(clearFetchGroupEmStates());
      dispatch(clearFetchRSVPDateEmStates());
      dispatch(clearFetchEventPermalinkEmStates());
      dispatch(clearFetchNewsAdStates());
      dispatch(clearFetchresidentsAdStates());
      dispatch(clearFetchPoliciesAdStates());
    };
  }, []);

  useEffect(() => {
    if (!collectionState.loading && !isEmpty(collectionState.response)) {
      setCollectionLoading(false);
    }
    if (!collectionState.loading && !isEmpty(collectionState.error)) {
      setCollectionLoading(false);
      toast.error(collectionState.error?.data?.error_data || STD_ERROR_MESSAGE);
    }
  }, [collectionState]);

  useEffect(() => {
    if (!profileState.loading && !isEmpty(profileState.response)) {
      setProfileLoading(false);
    }
    if (!profileState.loading && !isEmpty(profileState.error)) {
      setProfileLoading(false);
      toast.error("Couldn't load user profile.");
    }
  }, [profileState]);

  useEffect(() => {
    if (!groupState.loading && !isEmpty(groupState.response)) {
      setGroupLoading(false);
    }
    if (!groupState.loading && !isEmpty(groupState.error)) {
      setGroupLoading(false);
      toast.error(groupState.error?.data?.error_data || STD_ERROR_MESSAGE);
    }
  }, [groupState]);

  const handleOnToggleChange = (event) => {
    if (event.target.checked) {
      if (isEmpty(profileData?.thumbnail)) {
        toast.info("Please upload a profile picture to enable My Photos");
      } else {
        setChecked(true);
        toast.info(
          "We are scanning the gallery for your photos. Please refresh this page after 5 minutes."
        );
      }
    } else {
      setChecked(false);
    }
  };

  const handleChange = (event, newValue) => {
    let path;
    if (props.collectionName) {
      path = `${props.collectionName}`;
    } else {
      path = `collection/${collectionId}`;
    }
    history.push(
      `/${path}?selected=${getKeyByValue(TAB_NAME_INDEX_MAPPING, newValue)}`
    );
    if (window.scrollY) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  function checkFolderSettings(folderId) {
    switch (folderId) {
      case 0:
        return settings.hasOwnProperty("invitation")
          ? Boolean(settings.invitation)
          : false;
      case 1:
        return settings.hasOwnProperty("know_the_host")
          ? Boolean(settings.know_the_host)
          : false;
      case 2:
        return settings.hasOwnProperty("itinerary")
          ? Boolean(settings.itinerary)
          : false;
      case 3:
        return settings.hasOwnProperty("live_updates")
          ? Boolean(settings.live_updates)
          : false;
      case 4:
        return settings.hasOwnProperty("games")
          ? Boolean(settings.games)
          : false;
      case 6:
        return !groupLoading && !!groupData;
      case 5:
        return settings.hasOwnProperty("gallery")
          ? Boolean(settings.gallery)
          : false;
      case 7:
        return settings.hasOwnProperty("home") ? Boolean(settings.home) : false;
      case 8:
        return settings.hasOwnProperty("associates")
          ? Boolean(settings.associates)
          : false;
      case 9:
        return settings.hasOwnProperty("residents")
          ? Boolean(settings.residents)
          : false;
      case 10:
        return settings.hasOwnProperty("feed") ? Boolean(settings.feed) : false;
      case 11:
        return settings.hasOwnProperty("events")
          ? Boolean(settings.events)
          : false;
      case 12:
        return settings.hasOwnProperty("policies")
          ? Boolean(settings.policies)
          : false;
      default:
        return true;
    }
  }

  const getAssociatesTabName = (category) => {
    switch (category) {
      case "t":
        return "Associates";
      case "r":
        return "LT/MC";
      default:
        return "Associates";
    }
  };

  const getResidentsTabName = (category) => {
    switch (category) {
      case "t":
        return "Residents";
      case "r":
        return "Members";
      default:
        return "Residents";
    }
  };

  return (
    <>
      <Navbar
        eventTitle={collectionInfo?.name}
        myProfile={
          collectionInfo?.face_rec &&
          collectionId != "170ff2f3-24cc-4367-960d-b77787a985f0" &&
          selected === "photo-gallery"
        }
        userProfile={profileData}
        handleToggleOn={handleOnToggleChange}
        checked={checked}
      />
      {!collectionLoading && !profileLoading && !groupLoading ? (
        <>
          {selected === "photo-gallery" || selected === "events" ? (
            <PhotoGallery
              myPhotosOnly={
                collectionId == "170ff2f3-24cc-4367-960d-b77787a985f0"
                  ? true
                  : checked
              }
              collectionId={collectionId}
              // onBackBtnClick={() =>
              //   history.push(
              //     `/collection/${collectionId}?selected=${
              //       selected === "photo-gallery" ? "welcome" : "home"
              //     }`
              //   )
              // }
              onBackBtnClick={() => history.goBack()}
            />
          ) : (
            <Box sx={{ width: "100%" }}>
              <Box
                sx={{
                  position: "sticky",
                  zIndex: 10,
                  ...(isSmallScreen ? { top: "56px" } : { top: "64px" }),
                }}
              >
                <StyledTabs
                  value={selected ? TAB_NAME_INDEX_MAPPING[selected] : 0}
                  onChange={handleChange}
                  aria-label="tabs"
                  {...(isDesktop
                    ? {
                        centered: true,
                      }
                    : {
                        variant: "scrollable",
                        scrollButtons: "auto",
                        allowScrollButtonsMobile: true,
                      })}
                >
                  {checkFolderSettings(0) ? (
                    <StyledTab label="Welcome" {...a11yProps(0)} />
                  ) : null}
                  {checkFolderSettings(1) ? (
                    <StyledTab label="About" {...a11yProps(1)} />
                  ) : null}
                  {checkFolderSettings(2) ? (
                    <StyledTab label="Itinerary" {...a11yProps(2)} />
                  ) : null}
                  {checkFolderSettings(3) ? (
                    <StyledTab label="Updates" {...a11yProps(3)} />
                  ) : null}
                  {checkFolderSettings(4) ? (
                    <StyledTab label="Games" {...a11yProps(4)} />
                  ) : null}
                  {checkFolderSettings(5) ? (
                    <StyledTab label="Gallery" {...a11yProps(5)} />
                  ) : null}
                  {checkFolderSettings(6) ? (
                    <StyledTab label="RSVP" {...a11yProps(6)} />
                  ) : null}
                  {checkFolderSettings(7) ? (
                    <StyledTab label="Home" {...a11yProps(7)} />
                  ) : null}
                  {checkFolderSettings(8) ? (
                    <StyledTab
                      label={getAssociatesTabName(collectionInfo.category_id)}
                      {...a11yProps(8)}
                    />
                  ) : null}
                  {checkFolderSettings(9) ? (
                    <StyledTab
                      label={getResidentsTabName(collectionInfo.category_id)}
                      {...a11yProps(9)}
                    />
                  ) : null}
                  {checkFolderSettings(10) ? (
                    <StyledTab label="Feed" {...a11yProps(10)} />
                  ) : null}
                  {checkFolderSettings(11) ? (
                    <StyledTab label="Events" {...a11yProps(11)} />
                  ) : null}
                  {checkFolderSettings(12) ? (
                    <StyledTab label="Policies" {...a11yProps(12)} />
                  ) : null}
                </StyledTabs>
              </Box>
              {checkFolderSettings(0) ? (
                <TabPanel
                  value={selected ? TAB_NAME_INDEX_MAPPING[selected] : 0}
                  index={0}
                >
                  <UserInvite
                    collectionInfoLoading={collectionLoading}
                    collectionId={collectionId}
                  />
                </TabPanel>
              ) : null}
              {checkFolderSettings(1) ? (
                <TabPanel
                  value={selected ? TAB_NAME_INDEX_MAPPING[selected] : 0}
                  index={1}
                >
                  <UserCoupleStory
                    eventType={collectionInfo?.category_id}
                    collectionId={collectionId}
                  />
                </TabPanel>
              ) : null}
              {checkFolderSettings(2) ? (
                <TabPanel
                  value={selected ? TAB_NAME_INDEX_MAPPING[selected] : 0}
                  index={2}
                >
                  {/* <PhotoGallery /> */}
                  <UserTimeline collectionId={collectionId} />
                </TabPanel>
              ) : null}
              {checkFolderSettings(3) ? (
                <TabPanel
                  value={selected ? TAB_NAME_INDEX_MAPPING[selected] : 0}
                  index={3}
                >
                  <UserLiveUpdates collectionId={collectionId} />
                </TabPanel>
              ) : null}
              {checkFolderSettings(4) ? (
                <TabPanel
                  value={selected ? TAB_NAME_INDEX_MAPPING[selected] : 0}
                  index={4}
                >
                  <UserGamesSection collectionId={collectionId} />
                </TabPanel>
              ) : null}
              {checkFolderSettings(5) ? (
                <TabPanel
                  value={selected ? TAB_NAME_INDEX_MAPPING[selected] : 0}
                  index={5}
                ></TabPanel>
              ) : null}

              {checkFolderSettings(6) ? (
                <TabPanel
                  value={selected ? TAB_NAME_INDEX_MAPPING[selected] : 0}
                  index={6}
                >
                  <UserRSVPPreferenceSection collectionId={collectionId} />
                </TabPanel>
              ) : null}
              {checkFolderSettings(7) ? (
                <TabPanel
                  value={selected ? TAB_NAME_INDEX_MAPPING[selected] : 7}
                  index={7}
                >
                  <UserNews />
                </TabPanel>
              ) : null}
              {checkFolderSettings(8) ? (
                <TabPanel
                  value={selected ? TAB_NAME_INDEX_MAPPING[selected] : 7}
                  index={8}
                >
                  <UserCoupleStory eventType={collectionInfo?.category_id} />
                </TabPanel>
              ) : null}
              {checkFolderSettings(9) ? (
                <TabPanel
                  value={selected ? TAB_NAME_INDEX_MAPPING[selected] : 7}
                  index={9}
                >
                  <UserResidents />
                </TabPanel>
              ) : null}
              {checkFolderSettings(10) ? (
                <TabPanel
                  value={selected ? TAB_NAME_INDEX_MAPPING[selected] : 7}
                  index={10}
                >
                  <UserLiveUpdates />
                </TabPanel>
              ) : null}
              {checkFolderSettings(11) ? (
                <TabPanel
                  value={selected ? TAB_NAME_INDEX_MAPPING[selected] : 7}
                  index={11}
                ></TabPanel>
              ) : null}
              {checkFolderSettings(12) ? (
                <TabPanel
                  value={selected ? TAB_NAME_INDEX_MAPPING[selected] : 7}
                  index={12}
                >
                  <UserPolicies />
                </TabPanel>
              ) : null}
            </Box>
          )}
        </>
      ) : (
        <CustomSpinner />
      )}
      <Footer />
    </>
  );
}
