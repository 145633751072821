import {
  fetchNewsAdFailure,
  fetchNewsAdSuccess,
  requestFetchNewsAd,
} from "../redux/news/news.action";
import protectedWebClient from "../utils/protected-web-client";

export const createNewsPromise = (payload, collectionId, id) => {
  return new Promise((resolve, reject) => {
    protectedWebClient
      .post(`/event/${collectionId}/news`, payload)
      .then((res) => {
        resolve({ id: id, res: res });
      })
      .catch((err) => {
        reject({ id: id, err: err?.response });
      });
  });
};

export const fetchNews = (collectionId) => {
  return async (dispatch) => {
    try {
      dispatch(requestFetchNewsAd());
      const response = await protectedWebClient.get(
        `/event/${collectionId}/news`
      );
      dispatch(fetchNewsAdSuccess(response?.data));
    } catch (error) {
      dispatch(fetchNewsAdFailure(error?.response));
    }
  };
};

export const deleteNewsbySequencePromise = (collectionId, sequence) => {
  return new Promise((resolve, reject) => {
    protectedWebClient
      .delete(`/event/${collectionId}/news/${sequence}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err?.response);
      });
  });
};

export const updateNewsBySequencePromise = (
  payload,
  collectionId,
  sequence
) => {
  return new Promise((resolve, reject) => {
    protectedWebClient
      .put(`/event/${collectionId}/news/${sequence}`, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err?.response);
      });
  });
};
