import {
  REQUEST_VENUE_FETCH_EM,
  FETCH_VENUE_EM_SUCCESS,
  FETCH_VENUE_EM_FAILURE,
  CLEAR_FETCH_VENUE_EM_STATES,
} from "./venue.types";

export const requestFetchVenueEm = () => {
  return {
    type: REQUEST_VENUE_FETCH_EM,
  };
};

export const fetchVenueEmSuccess = (response) => {
  return {
    type: FETCH_VENUE_EM_SUCCESS,
    payload: response,
  };
};

export const fetchVenueEmFailure = (error) => {
  return {
    type: FETCH_VENUE_EM_FAILURE,
    payload: error,
  };
};

export const clearFetchVenueEmStates = () => {
  return {
    type: CLEAR_FETCH_VENUE_EM_STATES,
  };
};
