import { REQUEST_PROFILE, 
        PROFILE_SUCCESS,
        PROFILE_FAILURE,
        CLEAR_PROFILE } from './readProfile.types';

let initialState = {
    response : {},
    error : null,
    loading : false
}

const readProfileReducer = (state = initialState, action) => {

    switch(action.type) {
        case REQUEST_PROFILE :
            return {
                ...state,
                loading: true,
                error: null
            }

        case PROFILE_FAILURE:
            return {
                ...state,
                loading : false,
                error : action.payload
            }

        case PROFILE_SUCCESS:
            return {
                ...state,
                loading : false,
                response : action.payload
            }
        
        case CLEAR_PROFILE:
            return {
                ...state,
                loading : false,
                response : {},
                error : null
            }

        default:
            return state;
    }
}

export default readProfileReducer