import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { isEmpty } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import EntityLiveFeedCreateOrEdit from "../entity-live-feed-create-or-edit/EntityLIveFeedCreateOrEdit";
import CollectionHeaderPartner from "../collection-header-partner/CollectionHeaderPartner";
import { STD_ERROR_MESSAGE } from "../../../utils/constant";
import {
  getAllEntityFeeds,
  entityLiveFeedLikeOrUnlikePromise,
  deleteEntityLiveFeedByIdPromise,
} from "../../../services/liveFeed.service";
import { getImageUrl } from "../../../utils/utils";
import LiveFeedItem from "../live-feed-item/LiveFeedItem";

const AdminLiveUpdates = (props) => {
  const [pageLoading, setPageLoading] = useState(true);

  const params = useParams();
  const history = useHistory();

  const liveFeedsState = useSelector((state) => state.liveFeedEm);
  const entityState = useSelector((state) => state.entityAd);
  const dispatch = useDispatch();

  const liveFeedsData = liveFeedsState?.response?.data;
  const entityData = entityState.response?.data;

  useEffect(() => {
    if (!liveFeedsState?.loading && !isEmpty(liveFeedsState?.response)) {
      setPageLoading(false);
    }

    if (!liveFeedsState?.loading && !isEmpty(liveFeedsState?.error)) {
      setPageLoading(false);
      toast.error(liveFeedsState.error?.error_data || STD_ERROR_MESSAGE);
    }
  }, [liveFeedsState]);

  useEffect(() => {
    if (isEmpty(liveFeedsState?.response)) {
      dispatch(getAllEntityFeeds(params.entityId));
    }
  }, []);

  function handleLiveFeedLike(id, currLikeState) {
    entityLiveFeedLikeOrUnlikePromise(params.entityId, id, {
      action: currLikeState ? "dislike" : "like",
    })
      .then((res) => {
        dispatch(getAllEntityFeeds(params.entityId));
      })
      .catch((err) => {
        toast.error(
          !isEmpty(err?.data?.error_data)
            ? err?.data?.error_data
            : STD_ERROR_MESSAGE
        );
      });
  }

  function handleDeleteFeed(id) {
    deleteEntityLiveFeedByIdPromise(params.entityId, id)
      .then((res) => {
        dispatch(getAllEntityFeeds(params.entityId));
      })
      .catch((err) => {
        toast.error(
          !isEmpty(err?.data?.error_data)
            ? err?.data?.error_data
            : STD_ERROR_MESSAGE
        );
      });
  }

  return (
    <main
      style={{ maxWidth: "850px", marginLeft: "auto", marginRight: "auto" }}
    >
      <CollectionHeaderPartner
        title="Add Live updates"
        backToCollectionsBtn={true}
        handleBackToCollectionsBtn={() => history.goBack()}
      />
      <EntityLiveFeedCreateOrEdit action="create" />
      {!pageLoading
        ? !isEmpty(liveFeedsData)
          ? liveFeedsData.map((obj) => (
              <LiveFeedItem
                key={obj?.live_feed?.id}
                thumbnail={getImageUrl(entityData?.image?.bucket_path)}
                enableMoreOption={true}
                title={entityData?.name}
                onDeletePostClick={() => handleDeleteFeed(obj?.live_feed?.id)}
                contentText={obj?.live_feed?.text}
                contentImageUrl={getImageUrl(
                  obj?.live_feed?.image?.bucket_path
                )}
                onLikeClick={() =>
                  handleLiveFeedLike(obj?.live_feed?.id, obj?.liked_by_user)
                }
                likesCount={obj?.live_feed?.likes_count}
                likeState={obj?.liked_by_user}
              />
            ))
          : null
        : null}
    </main>
  );
};

export default AdminLiveUpdates;
