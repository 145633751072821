import {
  REQUEST_LEADER_BOARD_FETCH_USER,
  FETCH_LEADER_BOARD_USER_SUCCESS,
  FETCH_LEADER_BOARD_USER_FAILURE,
  CLEAR_FETCH_LEADER_BOARD_USER_STATES,
} from "./leaderBoardUser.types";

export const requestFetchLeaderBoardUser = () => {
  return {
    type: REQUEST_LEADER_BOARD_FETCH_USER,
  };
};

export const fetchLeaderBoardUserSuccess = (response) => {
  return {
    type: FETCH_LEADER_BOARD_USER_SUCCESS,
    payload: response,
  };
};

export const fetchLeaderBoardUserFailure = (error) => {
  return {
    type: FETCH_LEADER_BOARD_USER_FAILURE,
    payload: error,
  };
};

export const clearFetchLeaderBoardUserStates = () => {
  return {
    type: CLEAR_FETCH_LEADER_BOARD_USER_STATES,
  };
};
