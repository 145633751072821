import { useSelector } from "react-redux";
import UserRSVP from "../user-rsvp/UserRSVP";
import UserPreference from "../user-preference/UserPreference";

export default function UserRSVPPreferenceSection() {
  const groupState = useSelector((state) => state.groupEm);
  const groupData = groupState.response?.data;

  let screen;
  if (!groupData.event_user_group.rsvpDone) {
    screen = <UserRSVP />;
  } else {
    screen = <UserPreference />;
  }

  return screen;
}
