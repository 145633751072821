import SingleInputPopUp from "../../common/single-input-pop-up/SingleInputPopUp";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { updateEntity } from "../../../services/entities.service";
import { fetchEntitiesAdSuccess } from "../../../redux/entities/entities.action";

const EditEntityName = (props) => {
  const entityState = useSelector((state) => state.entityAd);
  const dispatch = useDispatch();

  const entity = entityState.response?.data;

  const initialValues = {
    name: entity?.name,
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Please enter the entity name"),
  });

  const onSubmit = (values) => {
    updateEntity(values, entity.id)
      .then((res) => {
        dispatch(fetchEntitiesAdSuccess(res.data));
        props.handleClose();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  return (
    <SingleInputPopUp
      handleClose={props.handleClose}
      heading="Edit Entity"
      inputPlaceholder="Name your Entity"
      inputName="name"
      inputOnChange={formik.handleChange}
      inputValue={formik.values.name}
      inputErrorMsg={formik.touched.name && formik.errors.name}
      inputOnBlur={formik.handleBlur}
      btnLabel="Save"
      onSubmit={formik.handleSubmit}
    />
  );
};

export default EditEntityName;
