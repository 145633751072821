import { auth } from "../utils/init-firebase";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  signInWithPopup,
  GoogleAuthProvider,
  sendPasswordResetEmail,
  confirmPasswordReset,
  updatePassword,
  deleteUser,
} from "firebase/auth";

export const signUpByEmailAndPassword = (email, password) => {
  return createUserWithEmailAndPassword(auth, email, password);
};

export const signInByEmailAndPassword = (email, password) => {
  return signInWithEmailAndPassword(auth, email, password);
};

export const signInWithGoogle = () => {
  const provider = new GoogleAuthProvider();
  return signInWithPopup(auth, provider);
};

export const forgetPassword = (email, options) => {
  if (options) {
    return sendPasswordResetEmail(auth, email, options);
  } else {
    return sendPasswordResetEmail(auth, email);
  }
};

export const resetPassword = (oobCode, newPassword) => {
  return confirmPasswordReset(auth, oobCode, newPassword);
};

export const updateNewPassword = (password) => {
  return updatePassword(auth.currentUser, password);
};

export const deleteNewUser = (user) => {
  return deleteUser(user);
};

export const logout = () => {
  return signOut(auth);
};
