import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import AddQuestionsGamesEm from "../../add-questions-games-em/AddQuestionsGamesEm";
import ListQuestionsGamesEm from "../../list-questions-games-em/ListQuestionsGamesEm";

import { fetchQuestionsForGamesEm } from "../../../../services/games.service";
import {
  SECTION_GAMES_COMPONENTS,
  STD_ERROR_MESSAGE,
} from "../../../../utils/constant";

export default function SectionGames() {
  const [loading, setLoading] = useState(true);
  const [currentMainComponent, setCurrentMainComponent] = useState({
    currComponent: null,
    prevComponent: null,
  });

  const gamesState = useSelector((state) => state.gamesEm);
  const dispatch = useDispatch();
  const params = useParams();

  const gamesData = gamesState?.response.data;

  useEffect(() => {
    if (isEmpty(gamesState.response)) {
      dispatch(fetchQuestionsForGamesEm(params.collectionId));
    }
  }, []);

  useEffect(() => {
    if (!gamesState.loading && !isEmpty(gamesState.response)) {
      setLoading(false);
      if (!isEmpty(gamesData)) {
        setCurrentMainComponent((ps) => {
          return {
            currComponent: SECTION_GAMES_COMPONENTS.saved_questions,
            prevComponent: ps.currComponent,
          };
        });
      } else {
        setCurrentMainComponent((ps) => {
          return {
            currComponent: SECTION_GAMES_COMPONENTS.add_questions,
            prevComponent: ps.currComponent,
          };
        });
      }
    }

    if (!gamesState.loading && !isEmpty(gamesState.error)) {
      toast.error(gamesState.error?.error_data || STD_ERROR_MESSAGE);
    }
  }, [gamesState]);

  function handleCancelClick() {
    setCurrentMainComponent((ps) => {
      return {
        currComponent:
          ps.prevComponent || SECTION_GAMES_COMPONENTS.saved_questions,
        prevComponent: ps.currComponent,
      };
    });
  }

  function changeNextComponent(component) {
    setCurrentMainComponent((ps) => {
      return {
        currComponent: component,
        prevComponent: ps.currComponent,
      };
    });
  }

  function getMainComponent() {
    switch (currentMainComponent.currComponent) {
      case SECTION_GAMES_COMPONENTS.saved_questions:
        return (
          <ListQuestionsGamesEm
            onAddQuestionButtonClick={() =>
              changeNextComponent(SECTION_GAMES_COMPONENTS.add_questions)
            }
            setCurrentMainComponent={setCurrentMainComponent}
          />
        );

      case SECTION_GAMES_COMPONENTS.add_questions:
        return (
          <AddQuestionsGamesEm
            onCancelClick={handleCancelClick}
            setCurrentMainComponent={setCurrentMainComponent}
          />
        );

      default:
        return null;
    }
  }

  return <>{!loading ? getMainComponent() : null}</>;
}
