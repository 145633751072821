import ProtectedWebClient from "../utils/protected-web-client";

import {
  requestFetchKnowTheHostSectionsEm,
  fetchKnowTheHostSectionsEmSuccess,
  fetchKnowTheHostSectionsEmFailure,
} from "../redux/know-the-host/knowTheHost.action.js";

export const createKnowTheHostSectionPromise = (payload, collectionId, id) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.post(`/event/${collectionId}/about`, payload)
      .then((res) => {
        resolve({ id: id, res: res });
      })
      .catch((err) => {
        reject({ id: id, err: err?.response });
      });
  });
};

export const fetchKnowTheHostSection = (collectionId) => {
  return async (dispatch) => {
    try {
      dispatch(requestFetchKnowTheHostSectionsEm());
      const response = await ProtectedWebClient.get(
        `/event/${collectionId}/about`
      );
      dispatch(fetchKnowTheHostSectionsEmSuccess(response?.data));
    } catch (error) {
      dispatch(fetchKnowTheHostSectionsEmFailure(error?.response));
    }
  };
};

export const deleteKnowTheHostSectionBySequencePromise = (
  collectionId,
  sequence
) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.delete(`/event/${collectionId}/about/${sequence}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err?.response);
      });
  });
};

export const updateKnowTheHostSectionBySequencePromise = (
  payload,
  collectionId,
  sequence
) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.put(`/event/${collectionId}/about/${sequence}`, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err?.response);
      });
  });
};

// Entity

export const fetchKnowTheEntitySection = (entityId) => {
  return async (dispatch) => {
    try {
      dispatch(requestFetchKnowTheHostSectionsEm());
      const response = await ProtectedWebClient.get(
        `/entity/${entityId}/about`
      );
      dispatch(fetchKnowTheHostSectionsEmSuccess(response?.data));
    } catch (error) {
      dispatch(fetchKnowTheHostSectionsEmFailure(error?.response));
    }
  };
};

export const createKnowTheEntitySectionPromise = (payload, entityId, id) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.post(`/entity/${entityId}/about`, payload)
      .then((res) => {
        resolve({ id: id, res: res });
      })
      .catch((err) => {
        reject({ id: id, err: err?.response });
      });
  });
};

export const deleteKnowTheEntitySectionBySequencePromise = (
  entityId,
  sequence
) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.delete(`/entity/${entityId}/about/${sequence}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err?.response);
      });
  });
};

export const updateKnowTheEntitySectionBySequencePromise = (
  payload,
  entityId,
  sequence
) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.put(`/entity/${entityId}/about/${sequence}`, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err?.response);
      });
  });
};
