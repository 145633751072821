import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import useMediaQuery from "@mui/material/useMediaQuery";
import DeletePopup from "../delete-popup/DeletePopup";

import {
  getAllEntityFeeds,
  getAllFeeds,
  liveFeedLikeOrUnlikePromise,
  deleteEntityLiveFeedByIdPromise,
  deleteLiveFeedByIdPromise,
} from "../../../services/liveFeed.service";

import { STD_ERROR_MESSAGE } from "../../../utils/constant";
import { getImageUrl, getUserId } from "../../../utils/utils";

import CustomSpinner from "../../common/spinner/CustomSpinner";
import LiveFeedItem from "../live-feed-item/LiveFeedItem";
import LiveFeedCreateOrEdit from "../live-feed-create-or-edit/LiveFeedCreateOrEdit";
import ButtonAccent from "../../common/button-accent/ButtonAccent";
import { Box } from "@mui/material";

const UserLiveUpdates = (props) => {
  const params = useParams();
  const dispatch = useDispatch();

  const userId = getUserId();

  const [loading, setLoading] = useState(true);

  const isXSmallScreen = useMediaQuery("(max-width: 600px)");
  const isSmallScreen = useMediaQuery("(max-width:1280px, min-width:800px)");

  const [liveFeed, setLiveFeed] = useState(false);

  const liveFeedsState = useSelector((state) => state.liveFeedEm);
  const liveFeedsData = liveFeedsState?.response?.data;

  const collectionState = useSelector((state) => state.collectionEm);
  const collectionData = collectionState.response?.data;

  const settings = collectionState.response?.data?.settings
    ? JSON.parse(collectionState.response?.data?.settings)
    : {};

  const [deletePopup, setDeletePopup] = useState({
    open: false,
    id: null,
  });

  useEffect(() => {
    if (isEmpty(liveFeedsState?.response)) {
      dispatch(getAllFeeds(props.collectionId));
    }
  }, []);

  useEffect(() => {
    if (!liveFeedsState?.loading && !isEmpty(liveFeedsState?.response)) {
      setLoading(false);
    }

    if (!liveFeedsState?.loading && !isEmpty(liveFeedsState?.error)) {
      setLoading(false);
      toast.error(liveFeedsState.error?.data?.error_data || STD_ERROR_MESSAGE);
    }
  }, [liveFeedsState]);

  const onClick = () => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 1);
    setLiveFeed(true);
  };

  function handleDeletePopupClick(id) {
    setDeletePopup({
      open: true,
      id,
    });
  }

  function closeDeletePopup() {
    setDeletePopup({
      open: false,
      id: null,
    });
  }

  function handleLiveFeedLike(id, currLikeState) {
    liveFeedLikeOrUnlikePromise(props.collectionId, id, {
      action: currLikeState ? "dislike" : "like",
    })
      .then((res) => {
        dispatch(getAllFeeds(props.collectionId));
      })
      .catch((err) => {
        toast.error(
          !isEmpty(err?.data?.error_data)
            ? err?.data?.error_data
            : STD_ERROR_MESSAGE
        );
      });
  }

  function handleDeleteFeed() {
    deleteLiveFeedByIdPromise(props.collectionId, deletePopup.id)
      .then((res) => {
        setDeletePopup({
          open: false,
          id: null,
        });
        dispatch(getAllFeeds(props.collectionId));
      })
      .catch((err) => {
        toast.error(
          !isEmpty(err?.data?.error_data)
            ? err?.data?.error_data
            : STD_ERROR_MESSAGE
        );
      });
  }

  return (
    <>
      <div
        style={{
          maxWidth: "480px",
          marginLeft: "auto",
          marginRight: "auto",
          paddingTop: "1rem",
          paddingBottom: "2rem",
          minHeight: "calc(100vh - 64px - 56px - 40px)",
        }}
      >
        {loading && <CustomSpinner />}
        {liveFeed && (
          <LiveFeedCreateOrEdit
            userFeed
            action="create"
            handleClose={() => setLiveFeed(false)}
            collectionId={props.collectionId}
          />
        )}
        {!loading &&
          (!isEmpty(liveFeedsData) ? (
            liveFeedsData.map((obj) => (
              <>
                <LiveFeedItem
                  key={obj?.live_feed?.id}
                  thumbnail={
                    obj?.live_feed?.creator_id == collectionData?.em_id
                      ? getImageUrl(collectionData?.image?.bucket_path)
                      : getImageUrl(obj?.creator?.image?.bucket_path)
                  }
                  enableMoreOption={obj?.live_feed?.creator_id == userId}
                  title={
                    obj?.live_feed?.creator_id == collectionData?.em_id
                      ? collectionData?.name
                      : obj?.creator?.name
                  }
                  onDeletePostClick={() =>
                    handleDeletePopupClick(obj?.live_feed?.id)
                  }
                  contentText={obj?.live_feed?.text}
                  contentImageUrl={getImageUrl(
                    obj?.live_feed?.image?.bucket_path
                  )}
                  onLikeClick={() =>
                    handleLiveFeedLike(obj?.live_feed?.id, obj?.liked_by_user)
                  }
                  likesCount={obj?.live_feed?.likes_count}
                  likeState={obj?.liked_by_user}
                />
              </>
            ))
          ) : (
            <h4 className="text-center pr20-lh32 high-emphasis-color mt-5">
              You have no feeds
            </h4>
          ))}
      </div>

      {!loading && settings.user_post && (
        <Box
          sx={{
            maxWidth: "100%",
          }}
        >
          <Box>
            <div
              style={{
                position: "fixed",
                marginBottom: "0",
                width: "100%",
                display: "flex",
                alignItems: "center",
                height: "4rem",
                backgroundColor: "white",
                padding: "0.5rem 0.5rem",
                bottom: "0rem",
              }}
            >
              <ButtonAccent
                variant="span-button"
                disabled={liveFeed}
                customStyling={{ margin: "0 auto" }}
                startSpan="+"
                startSpanClassName="icon-medium margin-right-8 vertical-align-bottom"
                onClick={onClick}
                type="submit"
                label="Add Post"
              />
            </div>
          </Box>
        </Box>
      )}
      {deletePopup.open && (
        <DeletePopup
          heading="DELETE POST"
          text="This post will be deleted permanently. This cannot be undone."
          handleDelete={handleDeleteFeed}
          handleClose={closeDeletePopup}
        />
      )}
    </>
  );
};

export default UserLiveUpdates;
