import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import * as Yup from "yup";
import {
  createEventPermaLink,
  getEventPermalinkById,
  updateEventPermalinkById,
} from "../../../../services/eventPermalink.service";
import { toast } from "react-toastify";
import { STD_ERROR_MESSAGE } from "../../../../utils/constant";
import CustomSpinner from "../../../common/spinner/CustomSpinner";
import { Field, Form, Formik } from "formik";
import CollectionHeaderPartner from "../../collection-header-partner/CollectionHeaderPartner";
import ButtonAccent from "../../../common/button-accent/ButtonAccent";
import PublishIcon from "../../../../assets/icon/publish_web.svg";
import errorInfoIcon from "../../../../assets/icon/error_icon_red.svg";

const SectionEventPermalink = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const params = useParams();
  const eventPermalinkState = useSelector((state) => state.eventPermalinkEm);
  const eventPermalinkData = eventPermalinkState.response?.data;

  const initialValues = {
    permalink: !isEmpty(eventPermalinkData) ? eventPermalinkData.permalink : "",
    publish: false,
  };

  const validationSchema = Yup.object({
    permalink: Yup.string()
      .required("Permalink cannot be blank")
      .matches(
        /^[a-zA-Z][a-zA-Z0-9-_]*$/,
        "Please start with alphabets and use alphabets, numerics, hyphen and underscores only"
      ),
  });

  useEffect(() => {
    if (isEmpty(eventPermalinkState.response)) {
      dispatch(getEventPermalinkById(params.collectionId));
    }
  }, []);

  useEffect(() => {
    if (
      !eventPermalinkState.loading &&
      !isEmpty(eventPermalinkState.response)
    ) {
      setLoading(false);
    }

    if (!eventPermalinkState.loading && !isEmpty(eventPermalinkState.error)) {
      setLoading(false);
      toast.error(
        eventPermalinkState.error?.data?.error_data || STD_ERROR_MESSAGE
      );
    }
  }, [eventPermalinkState]);

  const handleOnPublishChangeEvent = () => {
    const publishStatus = eventPermalinkData?.publish;
    const collectionId = eventPermalinkData?.event_id;
    if (typeof publishStatus === "boolean" && collectionId) {
      updateEventPermalinkById(collectionId, {
        publish: !publishStatus,
      })
        .then((res) => {
          dispatch(getEventPermalinkById(collectionId));
          toast.info(
            "Permalink " +
              `${!publishStatus ? "published" : "unpublished"}` +
              " successfully."
          );
        })
        .catch((error) =>
          toast.error(error?.data?.error_data || STD_ERROR_MESSAGE)
        );
    } else {
      toast.error(STD_ERROR_MESSAGE);
    }
  };

  const onSubmitPermalink = (values) => {
    if (!submitting) {
      setSubmitting(true);
      const valuesCopy = JSON.parse(JSON.stringify(values));

      if (eventPermalinkData) {
        eventPermalinkData.permalink = values.permalink;
        updateEventPermalinkById(params.collectionId, eventPermalinkData)
          .then((response) => {
            setSubmitting(false);
            dispatch(getEventPermalinkById(params.collectionId));
            toast.info("Permalink updated successfully.");
          })
          .catch((err) => {
            setSubmitting(false);
            toast.error(err?.data?.error_data || STD_ERROR_MESSAGE);
          });
      } else {
        createEventPermaLink(params.collectionId, valuesCopy)
          .then((response) => {
            setSubmitting(false);
            dispatch(getEventPermalinkById(params.collectionId));
            toast.info("Permalink created successfully.");
          })
          .catch((err) => {
            setSubmitting(false);
            toast.error(err?.data?.error_data || STD_ERROR_MESSAGE);
          });
      }
    }
  };

  function errorElement(msg) {
    return (
      <div className="d-flex align-items-center mt-3 pr12-lh18 error-color">
        <img
          className="mr-2"
          style={{ width: "16px", height: "16px" }}
          src={errorInfoIcon}
          alt=""
        />
        <span className="mb-0">{msg}</span>
      </div>
    );
  }
  return (
    <main
      style={{ maxWidth: "850px", marginLeft: "auto", marginRight: "auto" }}
    >
      {!loading ? (
        <>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmitPermalink}
          >
            {(formik) => (
              <Form>
                <CollectionHeaderPartner
                  title="Add Permalink"
                  loadingBtn={!eventPermalinkData?.publish}
                  loadingBtnLabel="Save Permalink"
                  loadingBtnProps={{ type: "submit", loading: submitting }}
                />
                <div className="mt-3">
                  <div
                    style={{
                      border: "2px solid rgba(41, 41, 41, 0.38)",
                      borderRadius: "8px",
                    }}
                  >
                    <Field name="permalink">
                      {(props) => {
                        const { field, meta } = props;
                        return (
                          <div
                            className="mb-2"
                            style={{
                              padding: "2rem 2rem",
                            }}
                          >
                            <textarea
                              disabled={eventPermalinkData?.publish}
                              placeholder="Enter a permalink..."
                              className="img-description-textarea pr16-lh24"
                              style={{
                                padding: "1rem 2rem",
                                border: "none",
                                resize: "none",
                                height: "100px",
                                width: "100%",
                              }}
                              {...field}
                            />
                            {meta.touched && meta.error
                              ? errorElement(meta.error)
                              : ""}
                          </div>
                        );
                      }}
                    </Field>
                  </div>
                  <ButtonAccent
                    variant="icon-button"
                    className="d-flex align-items-center justify-content-center"
                    startIcon={
                      eventPermalinkData?.publish ? PublishIcon : PublishIcon
                    }
                    label={
                      eventPermalinkData?.publish ? "Published" : "Publish"
                    }
                    labelClassName="ml-2"
                    disabled={
                      eventPermalinkData?.publish ||
                      eventPermalinkData?.permalink == undefined
                    }
                    startIconClassName="btn-icon-small"
                    customStyling={{
                      width: "20%",
                      padding: "6px 16px",
                      marginTop: "20px",
                    }}
                    onClick={handleOnPublishChangeEvent}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </>
      ) : (
        <CustomSpinner />
      )}
    </main>
  );
};

export default SectionEventPermalink;
