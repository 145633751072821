import {
  fetchRSVPDateEmFailure,
  fetchRSVPDateEmSuccess,
  requestFetchRSVPDateEm,
} from "../redux/rsvpDate/rsvpDate.action";
import protectedWebClient from "../utils/protected-web-client";

//respond date

export const addRSVPDatePromise = (collectionId, payload) => {
  return new Promise((resolve, reject) => {
    protectedWebClient
      .post(`/event/${collectionId}/rsvpDate`, payload)
      .then((res) => {
        resolve(res?.data);
      })
      .catch((error) => {
        reject(error?.data);
      });
  });
};

export const getRSVPDate = (collectionId) => {
  return async (dispatch) => {
    try {
      dispatch(requestFetchRSVPDateEm());
      const response = await protectedWebClient.get(
        `/event/${collectionId}/rsvpDate`
      );
      dispatch(fetchRSVPDateEmSuccess(response?.data));
    } catch (error) {
      dispatch(fetchRSVPDateEmFailure(error?.data));
    }
  };
};
