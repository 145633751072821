import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useParams } from "react-router-dom";
import {
  SECTION_PEFERENCES_COMPONENTS,
  STD_ERROR_MESSAGE,
} from "../../../../utils/constant";
import { toast } from "react-toastify";
import AddPreferences from "../../add-preferences/AddPreferences";
import ListQuestionsPreferencesEm from "../../list-questions-preferences-em/ListQuestionsPreferencesEm";
import { useEffect } from "react";
import { isEmpty } from "lodash";
import { fetchQuestionsForPreferencesEm } from "../../../../services/preferences.service";
// const SectionPreferences = () => {
//   return <ListQuestionsPreferencesEm />;
// };
// export default SectionPreferences;

export default function SectionPreferences() {
  const [loading, setLoading] = useState(true);
  const [currentMainComponent, setCurrentMainComponent] = useState({
    currComponent: null,
    prevComponent: null,
  });

  const preferenceState = useSelector((state) => state.preferenceEm);
  const dispatch = useDispatch();
  const params = useParams();

  const preferencesData = preferenceState?.response.data;

  useEffect(() => {
    if (isEmpty(preferenceState.response))
      dispatch(fetchQuestionsForPreferencesEm(params.collectionId));
  }, []);

  useEffect(() => {
    if (!preferenceState.loading && !isEmpty(preferenceState.response)) {
      if (!isEmpty(preferencesData)) {
        setCurrentMainComponent((ps) => {
          return {
            currComponent: SECTION_PEFERENCES_COMPONENTS.saved_questions,
            prevComponent: ps.currComponent,
          };
        });
      } else {
        setCurrentMainComponent((ps) => {
          return {
            currComponent: SECTION_PEFERENCES_COMPONENTS.add_questions,
            prevComponent: ps.currComponent,
          };
        });
      }
      setLoading(false);
    }
    if (!preferenceState.loading && !isEmpty(preferenceState.error)) {
      toast.error(preferenceState.error?.error_data || STD_ERROR_MESSAGE);
    }
  }, [preferenceState]);

  function handleCancelClick() {
    setCurrentMainComponent((ps) => {
      return {
        currComponent:
          ps.prevComponent || SECTION_PEFERENCES_COMPONENTS.saved_questions,
        prevComponent: ps.currComponent,
      };
    });
  }
  function changeNextComponent(component) {
    setCurrentMainComponent((ps) => {
      return {
        currComponent: component,
        prevComponent: ps.currComponent,
      };
    });
  }

  function getMainComponent() {
    switch (currentMainComponent.currComponent) {
      case SECTION_PEFERENCES_COMPONENTS.saved_questions:
        return (
          <ListQuestionsPreferencesEm
            onAddQuestionButtonClick={() =>
              changeNextComponent(SECTION_PEFERENCES_COMPONENTS.add_questions)
            }
            setCurrentMainComponent={setCurrentMainComponent}
          />
        );

      case SECTION_PEFERENCES_COMPONENTS.add_questions:
        return (
          <AddPreferences
            onCancelClick={handleCancelClick}
            setCurrentMainComponent={setCurrentMainComponent}
          />
        );

      default:
        return null;
    }
  }

  return <>{!loading ? getMainComponent() : null}</>;
  // return <h4> hlo</h4>;
}
