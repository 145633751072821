import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { isEmpty } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { STD_ERROR_MESSAGE } from "../../../utils/constant";

import {
  deletePoliciesbySequencePromise,
  fetchPolicies,
  updatePoliciesBySequencePromise,
} from "../../../services/policies.service";
import PoliciesEdit from "../policies-edit/PoliciesEdit";
import AddPolicies from "../add-policies/AddPolicies";
import PoliciesView from "../policies-view/PoliciesView";

const Policies = (props) => {
  const [pageLoading, setPageLoading] = useState(true);
  const [editItem, setEditItem] = useState([]);

  const params = useParams();

  const policiesState = useSelector((state) => state.policiesAd);
  const dispatch = useDispatch();

  const policiesData = policiesState?.response?.data;

  useEffect(() => {
    if (isEmpty(policiesState?.response)) {
      dispatch(fetchPolicies(params.collectionId));
    }
  }, []);

  useEffect(() => {
    if (!policiesState?.loading && !isEmpty(policiesState?.response)) {
      setPageLoading(false);
    }

    if (!policiesState?.loading && !isEmpty(policiesState?.error)) {
      setPageLoading(false);
      toast.error(policiesState.error?.error_data || STD_ERROR_MESSAGE);
    }
  }, [policiesState]);

  const handleEdit = (id) => {
    setEditItem((ps) => {
      return [...ps, id];
    });
  };

  const handleCancel = (id) => {
    setEditItem((ps) => {
      return ps.filter((item) => item !== id);
    });
  };

  function handleDeletePolicies(sequence) {
    deletePoliciesbySequencePromise(params.collectionId, sequence)
      .then((res) => {
        dispatch(fetchPolicies(params.collectionId));
        toast.info("Policy deleted successfully.");
      })
      .catch((err) => {
        toast.error(
          !isEmpty(err?.data?.error_data)
            ? err?.data?.error_data
            : STD_ERROR_MESSAGE
        );
      });
  }

  return (
    <main
      style={{ maxWidth: "850px", marginLeft: "auto", marginRight: "auto" }}
    >
      <AddPolicies />
      {!pageLoading && !isEmpty(policiesData)
        ? policiesData.map((obj) =>
            editItem.includes(obj.id) ? (
              <PoliciesEdit
                key={obj?.id}
                id={obj?.id}
                sequence={obj?.sequence}
                policy={obj?.policy_name}
                description={obj?.description}
                link={obj?.link}
                handleCancel={handleCancel}
              />
            ) : (
              <PoliciesView
                key={obj?.id}
                id={obj?.id}
                policy={obj?.policy_name}
                description={obj?.description}
                link={obj?.link}
                handleEdit={handleEdit}
                onDeleteClick={() => handleDeletePolicies(obj?.sequence)}
              />
            )
          )
        : null}
    </main>
  );
};

export default Policies;
