import React from 'react'

const IconText = (props) => {
    return (
        <span className={props.layoutContainerClassName} onClick={props.onClick}>
            {props.startIcon ? <img className={props.startIconClassName} src={props.startIcon} alt={props.startAlt} /> : ""}
            <span className={props.textClassName} >{props.content}</span>
            {props.endIcon ? <img className={props.endIconClassName} src={props.endIcon} alt={props.endAlt} /> : ""}
        </span>
    )
}

export default IconText
