import React, {forwardRef} from 'react'

const SimpleInputBox = (props, ref) => {

    const {disabled, error, className, containerClassName, ...rest} = props 

    const inputClassName = className ? `input-box pb16-lh24 ${className}` : "input-box pb16-lh24" 
    const floatingLabelClassName = error ? "error-floating-label-content pr18-lh32" : "floating-label-content pr18-lh32";
    const mContainerClassName = containerClassName ? `input-box-container w-100 ${containerClassName}` : 'input-box-container w-100'

    return (
        <div className={Boolean(disabled) ? `${mContainerClassName} disabled`: mContainerClassName}>
            <input
                className={Boolean(error) ? `${inputClassName} error` : inputClassName}
                ref={ref}
                {...rest}
            />
            <label className='floating-label'>
                <span className={floatingLabelClassName}>
                    {props.placeholder}
                </span>
            </label>
        </div>
    )
}

export default forwardRef(SimpleInputBox)