import { reject } from "lodash";
import PublicWebClient from "../utils/public-web-client";

export const registerUser = (collectionId, payload) => {
  return new Promise((resolve, reject) => {
    PublicWebClient.post(`/event/${collectionId}/register`, payload)
      .then((res) => {
        resolve(res?.data);
      })
      .catch((err) => {
        reject(err?.response);
      });
  });
};
