import axios from "axios";
import Cookies from "js-cookie";
import { isEmpty } from "lodash";
import { customAlphabet } from "nanoid";
import { IMAGE_VARIANT, STORAGE_KEYS } from "./constant";
import config from "../config";
const { BASE_IMAGE_URL } = config();

export const storeUserCredentials = (userCredentials) => {
  if (userCredentials.authToken) setAuthToken(userCredentials.authToken);
  if (userCredentials.userId) setUserId(userCredentials.userId);
  if (userCredentials.userRole) setUserRole(userCredentials.userRole);
  if (userCredentials.userName) setUserName(userCredentials.userName);
  if (userCredentials.studioName) setUserStudioName(userCredentials.studioName);
};

export const removeUserCredentials = () => {
  Cookies.remove(STORAGE_KEYS.authToken);
  localStorage.removeItem(STORAGE_KEYS.userId);
  localStorage.removeItem(STORAGE_KEYS.userRole);
  localStorage.removeItem(STORAGE_KEYS.userName);
  localStorage.removeItem(STORAGE_KEYS.userStudioName);
  localStorage.removeItem(STORAGE_KEYS.originalUrl);
};

export const setUserStudioName = (studioName) => {
  localStorage.setItem(STORAGE_KEYS.userStudioName, studioName);
};

export const setUserName = (name) => {
  localStorage.setItem(STORAGE_KEYS.userName, name);
};

export const setUserId = (userId) => {
  localStorage.setItem(STORAGE_KEYS.userId, userId);
};

export const setUserRole = (userRole) => {
  localStorage.setItem(STORAGE_KEYS.userRole, userRole);
};

export const setAuthToken = (token) => {
  Cookies.set(STORAGE_KEYS.authToken, token, { expires: 60, path: "/" });
};

export const setOriginalUrl = (url) => {
  localStorage.setItem(STORAGE_KEYS.originalUrl, url);
};

export const isAuthenticated = () => {
  if (!isEmpty(Cookies.get(STORAGE_KEYS.authToken))) {
    return true;
  } else {
    return false;
  }
};

export const getAuthToken = () => {
  return Cookies.get(STORAGE_KEYS.authToken);
};

export const getUserId = () => {
  return localStorage.getItem(STORAGE_KEYS.userId);
};

export const getUserRole = () => {
  return localStorage.getItem(STORAGE_KEYS.userRole);
};

export const getUserName = () => {
  return localStorage.getItem(STORAGE_KEYS.userName);
};

export const getUserStudioName = () => {
  return localStorage.getItem(STORAGE_KEYS.userStudioName);
};

export const checkConnectivity = () => {
  const condition = navigator.onLine ? "online" : "offline";
  if (condition === "online") {
    axios
      .get("https://www.google.com/", {
        mode: "no-cors",
      })
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      });
  } else {
    return false;
  }
};

export const getImageUrl = (
  imagePath,
  variant = IMAGE_VARIANT.processed_2000
) => {
  if (!imagePath) return "";

  switch (variant) {
    case IMAGE_VARIANT.processed_2000:
    case IMAGE_VARIANT.processed_4000:
      return `${BASE_IMAGE_URL}/${variant}/original/${imagePath}`;

    case IMAGE_VARIANT.original:
      return `${BASE_IMAGE_URL}/${variant}/${imagePath}`;

    default:
      return "";
  }
};

export function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

export function truncate(input, limit) {
  if (input.length > limit) {
    return input.substring(0, limit) + "...";
  }
  return input;
}

export function getAlphaNumericNanoId(length = 10) {
  const nanoid = customAlphabet("1234567890abcdef", length);
  return nanoid();
}

export const getOriginalUrl = () => {
  return localStorage.getItem(STORAGE_KEYS.originalUrl);
};

export const removeOriginalUrl = () => {
  localStorage.removeItem(STORAGE_KEYS.originalUrl);
};
