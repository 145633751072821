import {
  REQUEST_QUESTIONS_FETCH_EM,
  FETCH_QUESTIONS_EM_SUCCESS,
  FETCH_QUESTIONS_EM_FAILURE,
  CLEAR_FETCH_QUESTIONS_EM_STATES,
  CHANGE_GAME_STARTED_STATE,
  SAVE_USER_RESPONSE_STATE,
  CHANGE_TO_NEXT_QUESTION_STATE,
} from "./games.types";

let initialState = {
  reload: false,
  response: {},
  error: null,
  isStarted: false,
  loading: false,
};

const fetchQuestionsEmReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_QUESTIONS_FETCH_EM:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_QUESTIONS_EM_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
        reload: false,
      };

    case FETCH_QUESTIONS_EM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        reload: false,
      };

    case CHANGE_GAME_STARTED_STATE:
      return {
        ...state,
        isStarted: action.payload,
      };

    case SAVE_USER_RESPONSE_STATE:
      return {
        ...state,
        response: {
          ...this.response,
          data: {
            ...this.response.data,
            user_response: action.payload,
          },
        },
      };

    case CHANGE_TO_NEXT_QUESTION_STATE:
      return {
        ...state,
        response: action.payload,
      };

    case CLEAR_FETCH_QUESTIONS_EM_STATES:
      return {
        ...state,
        loading: false,
        response: {},
        error: null,
        reload: false,
        isStarted: false,
      };

    default:
      return state;
  }
};

export default fetchQuestionsEmReducer;
