import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { fetchPolicies } from "../../../services/policies.service";
import { STD_ERROR_MESSAGE } from "../../../utils/constant";
import { toast } from "react-toastify";
import "./user-policies.css";

const UserPolicies = () => {
  const [pageLoading, setPageLoading] = useState(true);

  const params = useParams();
  const policiesState = useSelector((state) => state.policiesAd);
  const dispatch = useDispatch();

  const policiesData = policiesState?.response?.data;

  useEffect(() => {
    if (isEmpty(policiesState?.response)) {
      dispatch(fetchPolicies(params.collectionId));
    }
  }, []);

  useEffect(() => {
    if (!policiesState?.loading && !isEmpty(policiesState?.response)) {
      setPageLoading(false);
    }
    if (!policiesState?.loading && !isEmpty(policiesState?.error)) {
      setPageLoading(false);
      toast.error(policiesState.error?.error_data || STD_ERROR_MESSAGE);
    }
  }, [policiesState]);

  return (
    <>
      <main
        style={{
          maxWidth: "90%",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <div className="top-box policy-header">
          <div className="inner-text flex-1-1">
            <span>Policy</span>
            <div className="policy-divider" />
          </div>
          <div className="inner-text flex-2-1">
            <span>Description</span>
            <div className="policy-divider" />
          </div>
          <div className="inner-text flex-2-1">
            <span>Link</span>
          </div>
        </div>
        {!pageLoading && !isEmpty(policiesData) ? (
          policiesData?.map((p) => (
            <div key={p.id} className="detail-box policy-content">
              <div className="inner-text flex-1-1">
                <span className="textmessages">{p.policy_name}</span>
                <div className="policy-divider ht-8" />
              </div>

              <div className="inner-text flex-2-1">
                <span className="textmessages">{p.description}</span>
                <div className="policy-divider ht-8" />
              </div>

              <div className="inner-text flex-2-1">
                <span className=" textmessages link">{p.link}</span>
              </div>
            </div>
          ))
        ) : (
          <h4 className="text-center pr20-lh32 high-emphasis-color mt-5">
            You have no policies to view.
          </h4>
        )}
      </main>
    </>
  );
};

export default UserPolicies;
