import React, { useRef, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Formik, Form, FieldArray, Field } from "formik";
import { isEmpty, size } from "lodash";
import * as Yup from "yup";
import Grid from "@mui/material/Grid";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

import CollectionHeaderPartner from "../collection-header-partner/CollectionHeaderPartner";
import ButtonAccent from "../../common/button-accent/ButtonAccent";
import errorInfoIcon from "../../../assets/icon/error_icon_red.svg";
import SquareCloseIcon from "../../../assets/icon/cancel.svg";
import SimpleInputBox from "../simple-input-box/SimpleInputBox";
import ButtonTransparent from "../../common/button-transparent/ButtonTransparent";
import CancelLightIcon from "../../../assets/icon/cancel_light.svg";
import {
  getPresignedUrlPromise,
  uploadImageUsingPresignedUrlPromise,
} from "../../../services/uploadImage.service";
import {
  addQuestionsForGamesEm,
  fetchQuestionsForGamesEm,
} from "../../../services/games.service";
import {
  BUCKET_PATH,
  SECTION_GAMES_COMPONENTS,
  STD_ERROR_MESSAGE,
} from "../../../utils/constant";
import { getAlphaNumericNanoId } from "../../../utils/utils";

export default function AddQuestionsGamesEm(props) {
  const { onCancelClick, setCurrentMainComponent } = props;

  const imagesRef = useRef({});
  const params = useParams();
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);
  const [imageFiles, setImageFiles] = useState({
    currId: null,
    files: null, //{1: {file: null, preview: null}}
  });

  const onSubmit = async (values) => {
    if (!submitting) {
      setSubmitting(true);
      const uploadImagesPromise = [];
      if (!isEmpty(imageFiles.files)) {
        for (const fileId in imageFiles.files) {
          const currentFileObj = imageFiles.files[fileId];
          if (!isEmpty(currentFileObj) && currentFileObj?.file) {
            uploadImagesPromise.push(
              uploadImageToS3(currentFileObj.file, fileId)
            );
          }
        }
      }

      let response = [];
      if (!isEmpty(uploadImagesPromise)) {
        try {
          response = await Promise.all(uploadImagesPromise);
        } catch (error) {
          setSubmitting(false);
          toast.error(STD_ERROR_MESSAGE);
        }
      }

      const valuesCopy = JSON.parse(JSON.stringify(values));
      const payload = [];
      for (const question of valuesCopy.games) {
        const { correct_answer_index, id, options, ...rest } = question;
        options[parseInt(correct_answer_index)].is_correct = true;
        const filteredOptions = options.filter((opt) => !isEmpty(opt.title));
        const questionProperties = {
          options: filteredOptions,
          ...rest,
        };
        if (!isEmpty(response)) {
          const uploadRes = response.find((item) => item.id === id);
          if (!isEmpty(uploadRes) && uploadRes.imageId) {
            questionProperties.image_id = uploadRes.imageId;
          }
        }
        payload.push(questionProperties);
      }

      if (!isEmpty(payload)) {
        try {
          await addQuestionsForGamesEm(params.collectionId, payload);
          dispatch(fetchQuestionsForGamesEm(params.collectionId));
          setSubmitting(false);
          setCurrentMainComponent((prevState) => {
            return {
              currComponent: SECTION_GAMES_COMPONENTS.saved_questions,
              prevComponent: prevState.currComponent,
            };
          });
        } catch (error) {
          setSubmitting(false);
          toast.error(STD_ERROR_MESSAGE);
        }
      }
    }
  };

  const uploadImageToS3 = (file, id) => {
    return new Promise((resolve, reject) => {
      const presignedUrlPayload = [
        {
          bucket_path: `${BUCKET_PATH.collection}/${params.collectionId}/${BUCKET_PATH.games}/${file.name}`,
          image_name: file.name,
        },
      ];
      let imageId = "";

      getPresignedUrlPromise(presignedUrlPayload, id)
        .then((response) => {
          imageId = response?.res?.data?.data?.[0]?.image_id;
          return uploadImageUsingPresignedUrlPromise(
            response?.res?.data?.data?.[0]?.upload_url,
            file,
            id
          );
        })
        .then((response) => {
          resolve({ id, imageId, response });
        })
        .catch((error) => {
          reject({ id, error: error });
        });
    });
  };

  const getEmptySection = () => {
    return {
      id: getAlphaNumericNanoId(),
      question: "",
      published: false,
      correct_answer_index: "",
      options: [
        {
          title: "",
        },
        {
          title: "",
        },
        {
          title: "",
        },
        {
          title: "",
        },
      ],
    };
  };

  const initialValues = {
    games: [
      {
        id: getAlphaNumericNanoId(),
        question: "",
        published: false,
        correct_answer_index: "",
        options: [
          {
            title: "",
          },
          {
            title: "",
          },
          {
            title: "",
          },
          {
            title: "",
          },
        ],
      },
    ],
  };

  const validationSchema = Yup.object().shape({
    games: Yup.array().of(
      Yup.object().shape({
        question: Yup.string().required("Please enter the question."),
        correct_answer_index: Yup.string().required(
          "Please choose the correct answer."
        ),
        options: Yup.array()
          .of(
            Yup.object().shape({
              title: Yup.string(),
            })
          )
          .test({
            name: "min_options_allowed",
            message: "Please fill at least two options",
            test: (val) => {
              let optionsCount = 0;
              for (const opt of val) {
                if (opt.title) {
                  optionsCount++;
                }
              }
              return optionsCount >= 2;
            },
          }),
      })
    ),
  });

  function removeASection(index, remove, id) {
    remove(index);
    removeImage(id);
    const { [index]: removedObj, ...rest } = imagesRef.current;
    imagesRef.current = rest;
  }

  const removeImage = (id) => {
    if (!isEmpty(imageFiles?.files?.[id])) {
      setImageFiles((ps) => {
        const files = ps.files;
        delete files[id];
        return {
          ...ps,
          files: {
            ...ps.files,
          },
        };
      });
    }
  };

  useEffect(() => {
    if (!isEmpty(imageFiles?.files?.[imageFiles?.currId])) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageFiles((ps) => {
          return {
            ...ps,
            currId: null,
            files: {
              ...ps.files,
              [ps.currId]: {
                ...ps.files?.[ps.currId],
                preview: reader.result,
              },
            },
          };
        });
      };
      reader.readAsDataURL(imageFiles.files[imageFiles.currId].file);
    }
  }, [imageFiles]);

  function handleSectionImageChange(event, id) {
    const file = event.target.files[0];
    if (file && file.type.substr(0, 5) === "image") {
      setImageFiles((ps) => {
        return {
          ...ps,
          currId: id,
          files: {
            ...ps.files,
            [id]: { file: file },
          },
        };
      });
    }
  }

  const checkForErrors = (errors, index, touched) => {
    const errorsObj = errors?.games?.[index];
    const touchedObj = touched?.games?.[index];

    if (isEmpty(errorsObj) || isEmpty(touchedObj)) return null;

    if (errorsObj.question && touchedObj.question) {
      return errorElement(errorsObj.question);
    } else if (errorsObj.options && size(touchedObj.options) >= 2) {
      return errorElement(errorsObj.options);
    } else if (
      errorsObj.correct_answer_index &&
      touchedObj.correct_answer_index
    ) {
      return errorElement(errorsObj.correct_answer_index);
    } else {
      return null;
    }

    function errorElement(msg) {
      return (
        <div className="d-flex align-items-center mt-3 pr12-lh18 error-color">
          <img
            className="mr-2"
            style={{ width: "16px", height: "16px" }}
            src={errorInfoIcon}
            alt=""
          />
          <span className="mb-0">{msg}</span>
        </div>
      );
    }
  };

  return (
    <main
      style={{ maxWidth: "850px", marginLeft: "auto", marginRight: "auto" }}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {(formik) => (
          <Form>
            <CollectionHeaderPartner
              title="Add Questions"
              cancelBtn={true}
              handleCancelBtnClick={onCancelClick}
              loadingBtn={true}
              loadingBtnLabel="Save"
              loadingBtnProps={{ type: "submit" }}
            />
            <FieldArray name="games">
              {(fieldArrayProps) => {
                const { push, form, remove } = fieldArrayProps;
                const { values, errors, touched } = form;
                const { games: gameSections } = values;

                return (
                  <div className="mt-3">
                    {gameSections.map((gameSection, index) => (
                      <div
                        className="d-flex p-3 mb-3"
                        style={{
                          border: "2px solid rgba(41, 41, 41, 0.38)",
                          borderRadius: "8px",
                        }}
                        key={gameSection.id}
                      >
                        <div>
                          <div className="mb-1 d-flex justify-content-end">
                            {size(gameSections) > 1 ? (
                              <img
                                className="pt-cursor"
                                src={SquareCloseIcon}
                                alt=""
                                onClick={() =>
                                  removeASection(index, remove, gameSection.id)
                                }
                              />
                            ) : null}
                          </div>
                          <Field name={`games[${index}].question`}>
                            {(props) => {
                              const { field, form, meta } = props;
                              return (
                                <div className="mb-2">
                                  <textarea
                                    placeholder="Type Questions here..."
                                    className="img-description-textarea pr16-lh24"
                                    style={{
                                      border: "none",
                                      resize: "none",
                                      height: "100px",
                                    }}
                                    {...field}
                                  />
                                </div>
                              );
                            }}
                          </Field>
                          <div>
                            {!isEmpty(imageFiles?.files?.[gameSection.id]) &&
                            !isEmpty(
                              imageFiles?.files?.[gameSection.id]?.preview
                            ) ? (
                              <div
                                className="mb-2"
                                style={{
                                  width: "fit-content",
                                  height: "150px",
                                  position: "relative",
                                }}
                              >
                                <img
                                  src={
                                    imageFiles?.files?.[gameSection.id]?.preview
                                  }
                                  alt=""
                                  style={{
                                    maxHeight: "150px",
                                    width: "auto",
                                    maxWidth: "100%",
                                    objectFit: "cover",
                                    objectPosition: "center",
                                  }}
                                />
                                <div
                                  style={{
                                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                                    borderRadius: "50%",
                                    position: "absolute",
                                    top: "10px",
                                    right: "10px",
                                    width: "30px",
                                    height: "30px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                  className="pt-cursor"
                                  onClick={() => removeImage(gameSection.id)}
                                >
                                  <img src={CancelLightIcon} alt="" />
                                </div>
                              </div>
                            ) : null}
                            <Grid container spacing={2}>
                              <Grid item xs={6}>
                                <ButtonTransparent
                                  variant="span-button"
                                  startSpan="+"
                                  customStyling={{
                                    width: "100%",
                                    border: "1px solid var(--high-emphasis)",
                                  }}
                                  startSpanClassName="icon-medium margin-right-8 vertical-align-bottom"
                                  onClick={(event) => {
                                    event.preventDefault();
                                    imagesRef.current[index].click();
                                  }}
                                  label={`${
                                    Boolean(
                                      imageFiles?.files?.[gameSection.id]?.file
                                    )
                                      ? "Change Image"
                                      : "Add Image"
                                  }`}
                                />
                                <input
                                  type="file"
                                  style={{ display: "none" }}
                                  ref={(element) =>
                                    (imagesRef.current[index] = element)
                                  }
                                  accept="image/png, image/jpeg, image/jpg"
                                  name="image"
                                  onChange={(event) =>
                                    handleSectionImageChange(
                                      event,
                                      gameSection.id
                                    )
                                  }
                                />
                              </Grid>
                            </Grid>
                          </div>
                          <div role="group" className="mt-3">
                            <Grid container spacing={3}>
                              {gameSection.options.map((option, optIndex) => (
                                <Grid
                                  key={optIndex}
                                  item
                                  xs={12}
                                  sm={12}
                                  md={6}
                                  sx={{ position: "relative" }}
                                >
                                  <Field
                                    name={`games[${index}].correct_answer_index`}
                                  >
                                    {(props) => {
                                      const {
                                        field,
                                        form: { values },
                                        meta,
                                      } = props;
                                      const disabled = isEmpty(
                                        values.games?.[index]?.options?.[
                                          optIndex
                                        ]?.title
                                      );
                                      return (
                                        <input
                                          type="radio"
                                          {...field}
                                          checked={
                                            field.value === `${optIndex}`
                                          }
                                          value={`${optIndex}`}
                                          disabled={disabled}
                                          style={{
                                            position: "absolute",
                                            top: "60%",
                                            left: "10%",
                                          }}
                                        />
                                      );
                                    }}
                                  </Field>
                                  <Field
                                    name={`games[${index}].options[${optIndex}].title`}
                                  >
                                    {(props) => {
                                      const {
                                        field,
                                        form: { values, setFieldValue },
                                        meta,
                                      } = props;
                                      const { onChange, ...rest } = field;
                                      let correctAns =
                                        values.games?.[index]
                                          ?.correct_answer_index;

                                      return (
                                        <SimpleInputBox
                                          placeholder={`Option ${optIndex + 1}`}
                                          onChange={(event) => {
                                            onChange(event);
                                            if (
                                              isEmpty(event.target.value) &&
                                              !isEmpty(correctAns) &&
                                              correctAns === `${optIndex}`
                                            ) {
                                              setFieldValue(
                                                `games[${index}].correct_answer_index`,
                                                ""
                                              );
                                            }
                                          }}
                                          {...rest}
                                        />
                                      );
                                    }}
                                  </Field>
                                </Grid>
                              ))}
                            </Grid>
                          </div>
                          {checkForErrors(errors, index, touched)}
                        </div>
                      </div>
                    ))}
                    <div className="mt-4 d-flex justify-content-center">
                      <ButtonAccent
                        variant="span-button"
                        startSpan="+"
                        startSpanClassName="icon-medium margin-right-8 vertical-align-bottom"
                        label="Add Question"
                        onClick={() => push(getEmptySection())}
                      />
                    </div>
                  </div>
                );
              }}
            </FieldArray>
          </Form>
        )}
      </Formik>
    </main>
  );
}
