import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";

import {
  SECTION_PHOTO_GALLERY_COMPONENTS,
  WEDDING_FOLDERS,
} from "../../../../utils/constant";
import { setAuthToken } from "../../../../utils/utils";
import PhotoGalleryList from "../../photo-gallery-list/PhotoGalleryList";
import UploadPhotoGallery from "../../upload-photo-gallery/UploadPhotoGallery";
import PhotoGalleryFilesUploading from "../../photo-gallery-files-uploading/PhotoGalleryFilesUploading";
import { getAllGallery } from "../../../../services/gallery.service.js";

const SectionPhotoGallery = (props) => {
  const [loading, setLoading] = useState(true);
  const [currGalleryFolderInfo, setCurrGalleryFolderInfo] = useState({});
  const [currentMainComponent, setCurrentMainComponent] = useState({
    currComponent: SECTION_PHOTO_GALLERY_COMPONENTS.photo_gallery_list,
    prevComponent: null,
  });

  const dispatch = useDispatch();
  const galleryState = useSelector((state) => state.galleryEm);

  const params = useParams();

  const galleryData = galleryState.response?.data;

  useEffect(() => {
    if (!galleryState.loading && isEmpty(galleryState.response)) {
      dispatch(getAllGallery(params.collectionId));
    }
  }, []);

  useEffect(() => {
    if (!galleryState.loading && !isEmpty(galleryState.response)) {
      changeNextComponent(SECTION_PHOTO_GALLERY_COMPONENTS.photo_gallery_list);
      if (
        props.selected === WEDDING_FOLDERS.photos_gallery &&
        !isEmpty(galleryData) &&
        loading
      ) {
        props.setSelected((ps) => {
          return {
            ...ps,
            subFolder: galleryData[0].id,
          };
        });
      }
      setLoading(false);
    }
    if (!galleryState.loading && !isEmpty(galleryState.error)) {
      dispatch(getAllGallery(params.collectionId));
    }
  }, [galleryState]);

  function changeNextComponent(component) {
    setCurrentMainComponent((ps) => {
      return {
        ...ps,
        currComponent: component,
        prevComponent: ps.currComponent,
      };
    });
  }

  function handleCancelClick() {
    setCurrentMainComponent((ps) => {
      return {
        currComponent:
          ps.prevComponent ||
          SECTION_PHOTO_GALLERY_COMPONENTS.photo_gallery_list,
        prevComponent: ps.currComponent,
      };
    });
  }

  function getMainComponent() {
    switch (currentMainComponent.currComponent) {
      case SECTION_PHOTO_GALLERY_COMPONENTS.photo_gallery_list:
        return (
          <PhotoGalleryList
            onAddPhotosButtonClick={() =>
              changeNextComponent(
                SECTION_PHOTO_GALLERY_COMPONENTS.upload_photo_gallery
              )
            }
            selected={props.selected}
            setSelected={props.setSelected}
            currGalleryFolderInfo={currGalleryFolderInfo}
            setCurrGalleryFolderInfo={setCurrGalleryFolderInfo}
            onMasterInviteBtnClick={() =>
              props.setSelected({
                folder: WEDDING_FOLDERS.master_invite_list,
                subFolder: null,
              })
            }
          />
        );
      case SECTION_PHOTO_GALLERY_COMPONENTS.upload_photo_gallery:
        return (
          <UploadPhotoGallery
            onCancelClick={handleCancelClick}
            changeNextComponent={changeNextComponent}
            currGalleryFolderInfo={currGalleryFolderInfo}
          />
        );
      case SECTION_PHOTO_GALLERY_COMPONENTS.uploading_photo_gallery:
        return (
          <PhotoGalleryFilesUploading
            setCurrentMainComponent={setCurrentMainComponent}
            currGalleryFolderInfo={currGalleryFolderInfo}
          />
        );
      default:
        return null;
    }
  }

  return <>{!loading ? getMainComponent() : null}</>;
};

export default SectionPhotoGallery;
