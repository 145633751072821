import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { getOriginalUrl, getUserId, getUserRole } from "./utils/utils";
import { ROLE_CATEGORIES } from "./utils/constant";

import PhotographerProfile from "./pages/profile/Profile";
import InfoScreen from "./pages/info-screen/InfoScreens";
import PhotographerAuthentication from "./pages/partner-authentication/PartnerAuthentication";
import LandingPage from "./pages/landing_page/LandingPage";
import ResetPassword from "./pages/reset-password/ResetPassword";
import ProtectedRoute from "./components/routes/ProtectedRoute";
import PublicRoute from "./components/routes/PublicRoute";
import SingleImageViewWithCarousel from "./pages/user/single-Image-view-with-carousel/SingleImageViewWithCarousel";
import UserCollection from "./pages/user/collection/Collection";
import UserCollections from "./pages/user/collections/Collections";
import ClientAuthentication from "./pages/client-authentication/ClientAuthentication";
import AdminAuthentication from "./pages/admin-authentication/AdminAuthentication";
import InjectAxiosInterceptor from "./utils/injectAxiosInterceptor";
import Collection from "./pages/event-manager/collection/Collection";
import Collections from "./pages/event-manager/collections/Collections";
import Advertisement from "./pages/advertisement/Advertisement";
import VenueBanner from "./pages/user/venue-banner/VenueBanner";
import Entities from "./pages/admin/entities/Entities";
import Entity from "./pages/admin/entity/Entity";
import UserEntity from "./pages/user/entity/Entity";
import PermalinkResolver from "./pages/user/entity/PermalinkResolver";
import OrgCollections from "./pages/user/collection/OrgCollections";
import Register from "./components/layout/register-form/RegisterForm";

function App() {
  return (
    <Router>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <InjectAxiosInterceptor />
      <Switch>
        {/* landing page */}
        <PublicRoute
          exact
          path="/"
          redirectPathname={
            getUserRole() === ROLE_CATEGORIES.eventManager ||
            getUserRole() === ROLE_CATEGORIES.orgManager ||
            getUserRole() === ROLE_CATEGORIES.schoolManager
              ? `/partner/${getUserId()}`
              : getUserRole() === ROLE_CATEGORIES.admin
              ? `/admin/${getUserId()}`
              : `/collections`
          }
          component={LandingPage}
        />
        {/* advertisement page */}
        <PublicRoute
          exact
          path="/wedding"
          redirectPathname={
            getUserRole() === ROLE_CATEGORIES.eventManager ||
            getUserRole() === ROLE_CATEGORIES.orgManager ||
            getUserRole() === ROLE_CATEGORIES.schoolManager
              ? `/partner/${getUserId()}`
              : `/collections`
          }
          component={Advertisement}
        />
        {/* partner authentication */}
        <PublicRoute
          exact
          path={["/partner/sign-in", "/partner/sign-up"]}
          redirectPathname={
            getUserRole() === ROLE_CATEGORIES.eventManager ||
            getUserRole() === ROLE_CATEGORIES.orgManager ||
            getUserRole() === ROLE_CATEGORIES.schoolManager
              ? `/partner/${getUserId()}`
              : `/collections`
          }
          component={PhotographerAuthentication}
        />
        {/* client authentication */}
        <PublicRoute
          exact
          path="/sign-in"
          redirectPathname={
            getUserRole() === ROLE_CATEGORIES.eventManager ||
            getUserRole() === ROLE_CATEGORIES.orgManager ||
            getUserRole() === ROLE_CATEGORIES.schoolManager
              ? `/partner/${getUserId()}`
              : `/collections`
          }
          component={ClientAuthentication}
        />
        {/* admin authentication */}
        <PublicRoute
          exact
          path="/admin/sign-in"
          redirectPathname={`/admin/${getUserId()}`}
          component={AdminAuthentication}
        />
        <PublicRoute
          exact
          accessLevel={ROLE_CATEGORIES.other}
          redirectPathname={`/${getOriginalUrl()}/registerAuthenticatedUser`}
          path="/:permalink/register"
          component={PermalinkResolver}
        />

        {/* Info screens */}
        <Route
          exact
          path={[
            "/about-us",
            "/help-and-support",
            "/privacy-policy",
            "/terms-of-use",
            "/contact-us",
          ]}
          component={InfoScreen}
        />
        {/* reset password */}
        <Route exact path="/reset-password" component={ResetPassword} />
        {/* admin page */}
        <ProtectedRoute
          exact
          accessLevel={[ROLE_CATEGORIES.admin]}
          redirectPathname={"/partner/sign-in"}
          path="/admin/:adminId"
          component={Entities}
        />
        <ProtectedRoute
          exact
          accessLevel={[ROLE_CATEGORIES.admin]}
          redirectPathname={"/admin/sign-in"}
          path="/admin/:adminId/entity/:entityId"
          component={Entity}
        />
        <ProtectedRoute
          exact
          accessLevel={[ROLE_CATEGORIES.admin]}
          redirectPathname={"/admin/sign-in"}
          path="/admin/:adminId/profile"
          component={PhotographerProfile}
        />
        {/* partner pages */}
        <ProtectedRoute
          exact
          accessLevel={[
            ROLE_CATEGORIES.eventManager,
            ROLE_CATEGORIES.orgManager,
            ROLE_CATEGORIES.schoolManager,
          ]}
          redirectPathname={"/partner/sign-in"}
          path="/partner/:partnerId"
          component={Collections}
        />
        <ProtectedRoute
          exact
          accessLevel={[
            ROLE_CATEGORIES.eventManager,
            ROLE_CATEGORIES.orgManager,
            ROLE_CATEGORIES.schoolManager,
          ]}
          redirectPathname={"/partner/sign-in"}
          path="/partner/:partnerId/collection/:collectionId"
          component={Collection}
        />
        <ProtectedRoute
          exact
          accessLevel={[
            ROLE_CATEGORIES.eventManager,
            ROLE_CATEGORIES.orgManager,
            ROLE_CATEGORIES.schoolManager,
          ]}
          redirectPathname={"/partner/sign-in"}
          path="/partner/:partnerId/profile"
          component={PhotographerProfile}
        />

        {/* user screens */}
        <ProtectedRoute
          exact
          accessLevel={[ROLE_CATEGORIES.other]}
          path="/entity/:entityId"
          redirectPathname={"/sign-in"}
          component={UserEntity}
        />
        <ProtectedRoute
          exact
          accessLevel={[ROLE_CATEGORIES.other]}
          path="/collections"
          redirectPathname={"/sign-in"}
          component={UserCollections}
        />
        <ProtectedRoute
          exact
          accessLevel={[ROLE_CATEGORIES.other]}
          key="client-view"
          path="/collection/:collectionId"
          component={UserCollection}
        />
        <ProtectedRoute
          exact
          accessLevel={[ROLE_CATEGORIES.other]}
          key="client-view"
          path="/orgEvents/:partnerId"
          component={OrgCollections}
        />
        <ProtectedRoute
          exact
          accessLevel={[ROLE_CATEGORIES.other]}
          key="client-image-slider"
          redirectPathname={"/sign-in"}
          path="/collection/:collectionId/folder/:folderId/image-slide"
          component={SingleImageViewWithCarousel}
        />
        <ProtectedRoute
          exact
          accessLevel={[ROLE_CATEGORIES.other]}
          redirectPathname={"/sign-in"}
          path="/collection/:collectionId/venue"
          component={VenueBanner}
        />
        <ProtectedRoute
          exact
          accessLevel={[ROLE_CATEGORIES.other]}
          redirectPathname={"sign-in"}
          path="/:permalink"
          component={PermalinkResolver}
        />
        <ProtectedRoute
          exact
          accessLevel={[ROLE_CATEGORIES.other]}
          redirectPathname={"sign-in"}
          path="/:permalink/registerAuthenticatedUser"
          component={PermalinkResolver}
        />
      </Switch>
    </Router>
  );
}

export default App;
