import ProtectedWebClient from "../utils/protected-web-client";
import {
  requestGalleryFetchEm,
  fetchGalleryEmSuccess,
  fetchGalleryEmFailure,
} from "../redux/gallery/gallery.action";
import {
  requestGalleryFoldersFetchEm,
  fetchGalleryFoldersEmSuccess,
  fetchGalleryFoldersEmFailure,
} from "../redux/gallery-folders/galleryFolders.action";

import {
  requestMyPhotosGalleryFoldersFetchEm,
  fetchMyPhotosGalleryFoldersEmSuccess,
  fetchMyPhotosGalleryFoldersEmFailure,
} from "../redux/gallery-folders/myPhotosGalleryFolders.action";

export const getAllGallery = (collectionId) => {
  return async (dispatch) => {
    try {
      dispatch(requestGalleryFetchEm());
      const fetchedGalleryResponse = await ProtectedWebClient.get(
        `/event/${collectionId}/gallery`
      );
      dispatch(fetchGalleryEmSuccess(fetchedGalleryResponse?.data));
    } catch (error) {
      dispatch(fetchGalleryEmFailure(error?.response));
    }
  };
};

export const createGalleryPromise = (collectionId, payload) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.post(`/event/${collectionId}/gallery`, payload)
      .then((res) => {
        resolve(res?.data);
      })
      .catch((err) => {
        reject(err?.response);
      });
  });
};

export const getImagesOfGalleryFolder = (collectionId, folderId) => {
  return async (dispatch) => {
    try {
      dispatch(requestGalleryFoldersFetchEm());
      const response = await ProtectedWebClient.get(
        `/event/${collectionId}/gallery/${folderId}`
      );
      dispatch(fetchGalleryFoldersEmSuccess(folderId, response?.data));
    } catch (error) {
      dispatch(fetchGalleryFoldersEmFailure(error?.response));
    }
  };
};

export const getImagesOfGalleryFolderForUser = (collectionId, folderId) => {
  return async (dispatch) => {
    try {
      dispatch(requestMyPhotosGalleryFoldersFetchEm());
      const response = await ProtectedWebClient.get(
        `/event/${collectionId}/gallery/${folderId}/user`
      );
      dispatch(fetchMyPhotosGalleryFoldersEmSuccess(folderId, response?.data));
    } catch (error) {
      dispatch(fetchMyPhotosGalleryFoldersEmFailure(error?.response));
    }
  };
};

export const deleteAGalleryFolderPromise = (collectionId, folderId) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.delete(`/event/${collectionId}/gallery/${folderId}`)
      .then((res) => {
        resolve(res?.data);
      })
      .catch((err) => {
        reject(err?.response);
      });
  });
};

export const updateAGalleryFolderPromise = (
  collectionId,
  folderId,
  payload
) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.put(
      `/event/${collectionId}/gallery/${folderId}`,
      payload
    )
      .then((res) => {
        resolve(res?.data);
      })
      .catch((err) => {
        reject(err?.response);
      });
  });
};

export const addImagesInAGalleryFolderPromise = (
  collectionId,
  folderId,
  payload
) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.post(
      `event/${collectionId}/gallery/${folderId}`,
      payload
    )
      .then((res) => {
        resolve(res?.data);
      })
      .catch((err) => {
        reject(err?.response);
      });
  });
};

export const updateImageDetailsInAGalleryFolderPromise = (
  collectionId,
  folderId,
  imageId,
  payload
) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.put(
      `event/${collectionId}/gallery/${folderId}/${imageId}`,
      payload
    )
      .then((res) => {
        resolve(res?.data);
      })
      .catch((err) => {
        reject(err?.response);
      });
  });
};

export const deleteAImageInGalleryFolderPromise = (
  collectionId,
  folderId,
  imageId
) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.delete(
      `event/${collectionId}/gallery/${folderId}/${imageId}`
    )
      .then((res) => {
        resolve(res?.data);
      })
      .catch((err) => {
        reject(err?.response);
      });
  });
};

// entities

export const getAllEntityGallery = (entityId) => {
  return async (dispatch) => {
    try {
      dispatch(requestGalleryFetchEm());
      const fetchedGalleryResponse = await ProtectedWebClient.get(
        `/entity/${entityId}/gallery`
      );
      dispatch(fetchGalleryEmSuccess(fetchedGalleryResponse?.data));
    } catch (error) {
      dispatch(fetchGalleryEmFailure(error?.response));
    }
  };
};

export const getImagesOfEntityGalleryFolder = (entityId, folderId) => {
  return async (dispatch) => {
    try {
      dispatch(requestGalleryFoldersFetchEm());
      const response = await ProtectedWebClient.get(
        `/entity/${entityId}/gallery/${folderId}`
      );
      dispatch(fetchGalleryFoldersEmSuccess(folderId, response?.data));
    } catch (error) {
      dispatch(fetchGalleryFoldersEmFailure(error?.response));
    }
  };
};

export const deleteAImageInEntityGalleryFolderPromise = (
  entityId,
  folderId,
  imageId
) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.delete(
      `entity/${entityId}/gallery/${folderId}/${imageId}`
    )
      .then((res) => {
        resolve(res?.data);
      })
      .catch((err) => {
        reject(err?.response);
      });
  });
};

export const createEntityGalleryPromise = (entityId, payload) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.post(`/entity/${entityId}/gallery`, payload)
      .then((res) => {
        resolve(res?.data);
      })
      .catch((err) => {
        reject(err?.response);
      });
  });
};

export const addImagesInAEntityGalleryFolderPromise = (
  entityId,
  folderId,
  payload
) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.post(`entity/${entityId}/gallery/${folderId}`, payload)
      .then((res) => {
        resolve(res?.data);
      })
      .catch((err) => {
        reject(err?.response);
      });
  });
};

export const deleteAEntityGalleryFolderPromise = (entityId, folderId) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.delete(`/entity/${entityId}/gallery/${folderId}`)
      .then((res) => {
        resolve(res?.data);
      })
      .catch((err) => {
        reject(err?.response);
      });
  });
};

export const updateAEntityGalleryFolderPromise = (
  entityId,
  folderId,
  payload
) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.put(`/entity/${entityId}/gallery/${folderId}`, payload)
      .then((res) => {
        resolve(res?.data);
      })
      .catch((err) => {
        reject(err?.response);
      });
  });
};
