import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import {
  GUEST_COMPONENTS,
  SECTION_ITINERARY_COMPONENTS,
  STD_ERROR_MESSAGE,
  WEDDING_FOLDERS,
} from "../../../../utils/constant.js";
import ItineraryFilesUploading from "../../itinerary-files-uploading/ItineraryFilesUploading.js";
import ItineraryList from "../../itinerary-list/ItineraryList.js";
import UploadItinerary from "../../upload-itinerary/UploadItinerary.js";
import { getAllItineraryInCollection } from "../../../../services/itinerary.service.js";
import GuestInviteList from "../../guest-invite-list/GuestInviteList.jsx";

const SectionItinerary = (props) => {
  const [loading, setLoading] = useState(true);
  const [currentMainComponent, setCurrentMainComponent] = useState({
    currComponent: null,
    prevComponent: null,
  });
  const [fileData, setFileData] = useState("");

  const itineraryState = useSelector((state) => state.itineraryEm);
  const dispatch = useDispatch();
  const params = useParams();

  const itineraryData = itineraryState?.response.data;

  useEffect(() => {
    if (isEmpty(itineraryState.response)) {
      dispatch(getAllItineraryInCollection(params.collectionId));
    }
  }, []);

  useEffect(() => {
    if (!itineraryState.loading && !isEmpty(itineraryState.response)) {
      setLoading(false);
      if (!isEmpty(itineraryData)) {
        setCurrentMainComponent((ps) => {
          return {
            currComponent: SECTION_ITINERARY_COMPONENTS.itinerary_list,
            prevComponent: ps.currComponent,
          };
        });
      } else {
        setCurrentMainComponent((ps) => {
          return {
            currComponent: SECTION_ITINERARY_COMPONENTS.upload_itinerary,
            prevComponent: ps.currComponent,
          };
        });
      }
    }

    if (!itineraryState.loading && !isEmpty(itineraryState.error)) {
      toast.error(itineraryState.error?.error_data || STD_ERROR_MESSAGE);
    }
  }, [itineraryState]);

  function handleCancelClick() {
    setCurrentMainComponent((ps) => {
      return {
        currComponent:
          ps.prevComponent || SECTION_ITINERARY_COMPONENTS.itinerary_list,
        prevComponent: ps.currComponent,
      };
    });
  }

  function changeNextComponent(component) {
    setCurrentMainComponent((ps) => {
      return {
        currComponent: component,
        prevComponent: ps.currComponent,
      };
    });
  }

  function getMainComponent() {
    switch (currentMainComponent.currComponent) {
      case SECTION_ITINERARY_COMPONENTS.itinerary_list:
        return (
          <ItineraryList
            onItineraryButtonClick={() =>
              changeNextComponent(SECTION_ITINERARY_COMPONENTS.upload_itinerary)
            }
            setCurrentMainComponent={setCurrentMainComponent}
            setFileData={setFileData}
            onMasterInviteBtnClick={() =>
              props.setSelected({
                folder: WEDDING_FOLDERS.master_invite_list,
                subFolder: null,
              })
            }
          />
        );

      case SECTION_ITINERARY_COMPONENTS.upload_itinerary:
        return (
          <UploadItinerary
            onCancelClick={handleCancelClick}
            setCurrentMainComponent={setCurrentMainComponent}
          />
        );

      case SECTION_ITINERARY_COMPONENTS.uploading_itinerary:
        return (
          <ItineraryFilesUploading
            setCurrentMainComponent={setCurrentMainComponent}
          />
        );

      case SECTION_ITINERARY_COMPONENTS.itinerary_guest_list:
        return (
          <GuestInviteList
            fileData={fileData}
            onCancelBtnClick={handleCancelClick}
            component={GUEST_COMPONENTS.itinerary}
          />
        );

      default:
        return null;
    }
  }

  return <>{!loading ? getMainComponent() : null}</>;
};

export default SectionItinerary;
