import React from 'react'
import { Spinner } from 'react-bootstrap'
import './custom-spinner.css';

const CustomSpinner = () => {
    return (
        <div className="loader">
            <Spinner animation="border" role="status"/>
        </div>
    )
}

export default CustomSpinner
