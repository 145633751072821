import {
  CLEAR_FETCH_ENTITY_AD_STATES,
  REQUEST_FETCH_ENTITY_AD,
  FETCH_ENTITY_AD_FAILURE,
  FETCH_ENTITY_AD_SUCCESS,
} from "./entity.types";

export const requestFetchEntityAd = () => {
  return {
    type: REQUEST_FETCH_ENTITY_AD,
  };
};

export const fetchEntityAdSuccess = (response) => {
  return {
    type: FETCH_ENTITY_AD_SUCCESS,
    payload: response,
  };
};

export const fetchEntityAdFailure = (error) => {
  return {
    type: FETCH_ENTITY_AD_FAILURE,
    payload: error,
  };
};

export const clearFetchEntityAdStates = () => {
  return {
    type: CLEAR_FETCH_ENTITY_AD_STATES,
  };
};
