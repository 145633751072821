import React from 'react'
import LoadingButton from '@mui/lab/LoadingButton'

const AccentLoadingButton = (props) => {

    return (
        <LoadingButton 
            size={props.size}
            color="secondary"
            className={props.className}
            fullWidth={Boolean(props.fullWidth)}
            sx={{
                textTransform: 'none',
                fontFamily: "'Poppins', sans-serif",
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: "14px",
                lineHeight: "21px",
                padding: '10px 18px',
                backgroundColor: 'var(--accent-color)',
                color: 'var(--primary-color)',
                '&:hover':{
                    backgroundColor: 'var(--accent-color)',
                    color: 'var(--primary-color)',
                },
            }}
            loading={props.loadingState}
            type={props.type}
            variant="container"
        >
            {props.label}
        </LoadingButton>
    )
}

export default AccentLoadingButton