import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { fetchLeaderBoardDataUser } from "../../../../services/games.service";

import HighScoreBg from "../../../../assets/image/high-score-bg.png";
import CloseIcon from "../../../../assets/icon/cancel.svg";
import { isEmpty } from "lodash";
import {
  STD_ERROR_MESSAGE,
  SECTION_GAMES_USER_COMPONENTS,
} from "../../../../utils/constant";

function ScoreCard(props) {
  const { name, score, inputProps: { className, ...rest } = {} } = props;

  return (
    <div
      className={`d-flex justify-content-between align-items-center p-2 ${
        className || ""
      }`}
      {...rest}
    >
      <span className="pb16-lh24">{name}</span>
      <span className="pb16-lh24">{score}</span>
    </div>
  );
}

export default function HighScore(props) {
  const { changeCurrComponent } = props;

  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const params = useParams();
  const leaderBoardState = useSelector((state) => state.leaderBoardUser);

  const leaderBoardData = leaderBoardState.response?.data;

  const collectionId = props.collectionId
    ? props.collectionId
    : params?.collectionId;

  useEffect(() => {
    dispatch(fetchLeaderBoardDataUser(collectionId));
  }, []);

  useEffect(() => {
    if (!leaderBoardState.loading && !isEmpty(leaderBoardState.response)) {
      setLoading(false);
    }

    if (!leaderBoardState.loading && !isEmpty(leaderBoardState.error)) {
      toast.error(STD_ERROR_MESSAGE);
    }
  }, [leaderBoardState]);

  return (
    <div
      style={{
        flex: 1,
        position: "relative",
      }}
    >
      <img
        onClick={() =>
          changeCurrComponent(SECTION_GAMES_USER_COMPONENTS.question)
        }
        className="pt-cursor"
        style={{ position: "absolute", top: "1rem", right: "1rem" }}
        alt=""
        src={CloseIcon}
      />
      <img
        alt=""
        style={{ width: "100%", maxWidth: "100%" }}
        src={HighScoreBg}
      />
      {!loading ? (
        <div>
          <h2
            style={{ textAlign: "center" }}
            className="merriweather-black-fs20-lh36 mb-2"
          >
            High Scorers
          </h2>
          <div
            style={{
              maxWidth: "500px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
            className="px-2"
          >
            {!isEmpty(leaderBoardData.other_players) ? (
              <>
                {!isEmpty(leaderBoardData.other_players?.[0]) ? (
                  <ScoreCard
                    inputProps={{
                      className: "success-color-bg mb-2",
                    }}
                    name={leaderBoardData.other_players?.[0]?.name}
                    score={leaderBoardData.other_players?.[0]?.score}
                  />
                ) : null}
                {!isEmpty(leaderBoardData.other_players?.[1]) ? (
                  <ScoreCard
                    inputProps={{
                      className: "success-color-bg-o75 mb-2",
                    }}
                    name={leaderBoardData.other_players?.[1]?.name}
                    score={leaderBoardData.other_players?.[1]?.score}
                  />
                ) : null}
                {!isEmpty(leaderBoardData.other_players?.[2]) ? (
                  <ScoreCard
                    inputProps={{
                      className: "success-color-bg-o50 mb-2",
                    }}
                    name={leaderBoardData.other_players?.[2]?.name}
                    score={leaderBoardData.other_players?.[2]?.score}
                  />
                ) : null}
                {!isEmpty(leaderBoardData?.current_player) ? (
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <div
                      style={{
                        width: "8px",
                        height: "8px",
                        borderRadius: "50%",
                      }}
                      className="success-color-bg-o75 mb-2"
                    />
                    <div
                      style={{
                        width: "8px",
                        height: "8px",
                        borderRadius: "50%",
                        background:
                          "linear-gradient(180deg, rgba(159, 223, 176, 0.45) 0%, rgba(255, 226, 166, 0.45) 100%)",
                      }}
                      className="mb-2"
                    />
                    <div
                      style={{
                        width: "8px",
                        height: "8px",
                        borderRadius: "50%",
                        background: "rgba(255, 197, 77, 0.5)",
                      }}
                      className="mb-2"
                    />
                  </div>
                ) : null}
              </>
            ) : null}
            {!isEmpty(leaderBoardData?.current_player) ? (
              <ScoreCard
                inputProps={{
                  className: "mb-2",
                  style: {
                    background: "#FFC54D",
                  },
                }}
                name={leaderBoardData.current_player.name}
                score={leaderBoardData.current_player.score}
              />
            ) : null}
          </div>
        </div>
      ) : null}
    </div>
  );
}
