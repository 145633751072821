import { 
    REQUEST_KNOW_THE_HOST_SECTIONS_FETCH_EM,
    FETCH_KNOW_THE_HOST_SECTIONS_EM_SUCCESS,
    FETCH_KNOW_THE_HOST_SECTIONS_EM_FAILURE,
    CLEAR_FETCH_KNOW_THE_HOST_SECTIONS_EM_STATES
} from './knowTheHost.types';


export const requestFetchKnowTheHostSectionsEm = () => {
    return {
        type : REQUEST_KNOW_THE_HOST_SECTIONS_FETCH_EM,
    }
}

export const fetchKnowTheHostSectionsEmSuccess = (response) => {
    return {
        type : FETCH_KNOW_THE_HOST_SECTIONS_EM_SUCCESS,
        payload : response
    }
}

export const fetchKnowTheHostSectionsEmFailure = (error) => {
    return {
        type : FETCH_KNOW_THE_HOST_SECTIONS_EM_FAILURE,
        payload : error
    }
}

export const clearFetchKnowTheHostSectionsEmStates = () => {
    return {
        type : CLEAR_FETCH_KNOW_THE_HOST_SECTIONS_EM_STATES
    }
}