import {
  CLEAR_FETCH_TESTIMONIAL_AD_STATES,
  FETCH_TESTIMONIAL_AD_FAILURE,
  FETCH_TESTIMONIAL_AD_SUCCESS,
  REQUEST_TESTIMONIAL_FETCH_AD,
} from "./testimonial.types";

export const requestFetchtestimonialAdReducer = () => {
  return {
    type: REQUEST_TESTIMONIAL_FETCH_AD,
  };
};

export const fetchTestimonialAdSuccess = (response) => {
  return {
    type: FETCH_TESTIMONIAL_AD_SUCCESS,
    payload: response,
  };
};

export const fetchTestimonialAdFailure = (error) => {
  return {
    type: FETCH_TESTIMONIAL_AD_FAILURE,
    payload: error,
  };
};

export const clearFetchTestimonialAdStates = () => {
  return {
    type: CLEAR_FETCH_TESTIMONIAL_AD_STATES,
  };
};
