import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import { toast } from "react-toastify";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import IconButton from "@mui/material/IconButton";

import CollectionHeaderPartner from "../collection-header-partner/CollectionHeaderPartner";
import {
  DeviceIdentifyIconButton,
  PreviewLogo,
} from "../add-or-edit-venue/AddOrEditVenue";
import DeletePopup from "../delete-popup/DeletePopup";

import { getImageUrl } from "../../../utils/utils";
import {
  deleteVenueByIdPromise,
  getAllVenuesInCollection,
} from "../../../services/venue.service";
import {
  SECTION_VENUE_COMPONENTS,
  STD_ERROR_MESSAGE,
} from "../../../utils/constant";

const VenueImage = (props) => {
  const { src } = props;
  return (
    <img
      style={{
        maxWidth: "100%",
        maxHeight: "350px",
        width: "auto",
        height: "auto",
      }}
      src={src}
      alt=""
    />
  );
};

const VenueList = (props) => {
  const { onMasterInviteBtnClick, changeNextComponent } = props;
  const venueState = useSelector((state) => state.venueEm);
  const [deletePopup, setDeletePopup] = useState({ open: false, id: null });
  const dispatch = useDispatch();
  const history = useHistory();

  const params = useParams();

  const venueData = venueState.response?.data?.[0];

  function handleDeleteVenueEvent() {
    deleteVenueByIdPromise(params.collectionId, deletePopup.id)
      .then((res) => {
        dispatch(getAllVenuesInCollection(params.collectionId));
        closeDeletePopup();
      })
      .catch((err) => {
        toast.error(err?.error_data || STD_ERROR_MESSAGE);
      });
  }

  function closeDeletePopup() {
    setDeletePopup({ open: false, id: null });
  }

  function openDeletePopup(id) {
    setDeletePopup({ open: true, id: id });
  }

  function handleEditEvent() {
    changeNextComponent(SECTION_VENUE_COMPONENTS.edit_venue);
  }

  function handleAddVenueEvent() {
    changeNextComponent(SECTION_VENUE_COMPONENTS.add_venue);
  }

  return (
    <main
      style={{ maxWidth: "850px", marginLeft: "auto", marginRight: "auto" }}
    >
      <CollectionHeaderPartner
        title={`Venue`}
        backToCollectionsBtn={true}
        addPhotosBtn={isEmpty(venueData)}
        addPhotosLabel="Add Venue"
        handleAddPhotosBtn={handleAddVenueEvent}
        handleBackToCollectionsBtn={() => history.goBack()}
        generalBtn={true}
        generalBtnLabel={"Master Invite List"}
        onGeneralBtnClick={onMasterInviteBtnClick}
      />
      <div style={{ marginTop: "27px" }}>
        {!isEmpty(venueData) ? (
          <div>
            <div className="mb-4 d-flex justify-content-between align-items-center">
              <div>
                {!isEmpty(venueData.logo) ? (
                  <PreviewLogo src={getImageUrl(venueData.logo?.bucket_path)} />
                ) : null}
              </div>
              <div>
                <IconButton
                  sx={{ marginRight: "1.5rem" }}
                  onClick={handleEditEvent}
                >
                  <EditIcon sx={{ color: "var(--primary-color)" }} />
                </IconButton>
                <IconButton onClick={() => openDeletePopup(venueData.id)}>
                  <DeleteIcon sx={{ color: "var(--primary-color)" }} />
                </IconButton>
              </div>
            </div>
            <div className="d-flex flex-wrap justify-content-start align-items-start">
              {!isEmpty(venueData.mobile_image) ? (
                <LazyLoadComponent>
                  <div
                    style={{
                      position: "relative",
                      marginRight: "20px",
                      marginBottom: "20px",
                    }}
                  >
                    <VenueImage
                      src={getImageUrl(venueData.mobile_image?.bucket_path)}
                    />
                    <DeviceIdentifyIconButton device="mobile" />
                  </div>
                </LazyLoadComponent>
              ) : null}
              {!isEmpty(venueData.tablet_image) ? (
                <LazyLoadComponent>
                  <div
                    style={{
                      position: "relative",
                      marginRight: "20px",
                      marginBottom: "20px",
                    }}
                  >
                    <VenueImage
                      src={getImageUrl(venueData.tablet_image?.bucket_path)}
                    />
                    <DeviceIdentifyIconButton device="tablet" />
                  </div>
                </LazyLoadComponent>
              ) : null}
              {!isEmpty(venueData.image) ? (
                <LazyLoadComponent>
                  <div
                    style={{
                      position: "relative",
                      marginRight: "20px",
                      marginBottom: "20px",
                    }}
                  >
                    <VenueImage
                      src={getImageUrl(venueData.image?.bucket_path)}
                    />
                    <DeviceIdentifyIconButton device="desktop" />
                  </div>
                </LazyLoadComponent>
              ) : null}
            </div>
          </div>
        ) : (
          <h2 className="text-center pr20-lh32 high-emphasis-color mt-5">
            You have no venues in this collection
          </h2>
        )}
      </div>
      {deletePopup.open && (
        <DeletePopup
          heading="DELETE VENUE"
          text="This venue will be deleted permanently. This cannot be undone."
          handleDelete={handleDeleteVenueEvent}
          handleClose={closeDeletePopup}
        />
      )}
    </main>
  );
};

export default VenueList;
