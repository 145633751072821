import { 
    REQUEST_ITINERARY_FETCH_EM,
    FETCH_ITINERARY_EM_SUCCESS,
    FETCH_ITINERARY_EM_FAILURE,
    CLEAR_FETCH_ITINERARY_EM_STATES
} from './itinerary.types';


export const requestFetchItineraryEm = () => {
    return {
        type : REQUEST_ITINERARY_FETCH_EM,
    }
}

export const fetchItineraryEmSuccess = (response) => {
    return {
        type : FETCH_ITINERARY_EM_SUCCESS,
        payload : response
    }
}

export const fetchItineraryEmFailure = (error) => {
    return {
        type : FETCH_ITINERARY_EM_FAILURE,
        payload : error
    }
}

export const clearFetchItineraryEmStates = () => {
    return {
        type : CLEAR_FETCH_ITINERARY_EM_STATES
    }
}