import React, {forwardRef} from 'react'
import './button-transparent.css'
const ButtonTransparent = (props, ref) => {

    const classes = props.className ? `button button-transparent ${props.className}` : 'button button-transparent pb14-lh21';

    if(props.variant){
        switch (props.variant) {
            case "icon-button" :
                return (
                    <button
                        ref={ref}
                        type={ props.type?props.type: "button" } 
                        className={classes} style={props.customStyling} 
                        onClick={props.onClick} 
                        disabled={props.disabled ? props.disabled : false } 
                    >
                        {
                            props.startIcon && 
                                <img 
                                    className={props.startIconClassName} 
                                    src={props.startIcon} 
                                    alt=""
                                />
                        } 
                        <span className={props.labelClassName}>
                            {props.label}
                        </span>
                        {
                            props.endIcon && 
                                <img 
                                    className={props.endIconClassName} 
                                    src={props.endIcon}
                                    alt=""
                                />
                        }
                    </button>
                );
            case "span-button":
                return (
                    <button
                        ref={ref}
                        type={ props.type?props.type: "button" } 
                        className={classes} 
                        style={props.customStyling} 
                        onClick={props.onClick} 
                        disabled={props.disabled ? props.disabled : false } 
                    >
                        {
                            props.startSpan && 
                                <span className={props.startSpanClassName}>
                                    {props.startSpan}
                                </span>
                        }
                        <span className={props.labelClassName}>
                            {props.label}
                        </span>
                        {
                            props.endSpan && 
                                <span className={props.endSpanClassName}>
                                    {props.endSpan}
                                </span>
                        }
                    </button>
                );
    
            default:
                return (
                    <button
                        ref={ref}
                        type={ props.type?props.type: "button" } 
                        className={classes}
                        style={props.customStyling} 
                        onClick={props.onClick} 
                        disabled={props.disabled ? props.disabled : false } 
                    >
                        {props.label}
                    </button>
                );
        }
    } else {
        return (
            <button
                ref={ref}
                type={ props.type?props.type: "button" } 
                className={classes} 
                style={props.customStyling} 
                onClick={props.onClick} 
                disabled={props.disabled ? props.disabled : false } 
            >
                {props.label}
            </button>
        );
    }
}

export default forwardRef(ButtonTransparent)
