import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { useSelector, useDispatch } from "react-redux";
import { size } from "lodash";
import { toast } from "react-toastify";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import CollectionHeaderPartner from "../collection-header-partner/CollectionHeaderPartner";

import { LazyLoadComponent } from "react-lazy-load-image-component";
import Image from "../../common/v2/image/Image";
import { getImageUrl } from "../../../utils/utils";
import DeletePopup from "../delete-popup/DeletePopup";
import {
  deleteInviteImageBySequencePromise,
  getAllInvitesInCollection,
} from "../../../services/invites.service";
import {
  SECTION_INVITES_COMPONENTS,
  STD_ERROR_MESSAGE,
} from "../../../utils/constant";
import editIcon from "../../../assets/icon/edit.svg";
import ButtonAccent from "../../common/button-accent/ButtonAccent";
import ButtonPrimary from "../../common/button-primary/ButtonPrimary";
import {
  updateCollection,
  getACollection,
} from "../../../services/collections.service";
import errorInfoIcon from "../../../assets/icon/info-red.svg";

const InvitesList = (props) => {
  const invitesState = useSelector((state) => state.invitesEm);
  const collectionState = useSelector((state) => state.collectionEm);
  const [deletePopup, setDeletePopup] = useState({ open: false, id: null });
  const [descriptionEdit, setDescriptionEdit] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const params = useParams();

  const invitesData = invitesState.response?.data;
  const collectionData = collectionState.response?.data;

  const initialValues = {
    invitation_title: collectionData?.invitation_title
      ? collectionData?.invitation_title
      : "",
  };

  const validationSchema = Yup.object().shape({
    invitation_title: Yup.string().required("Please enter caption/body text"),
  });

  useEffect(() => {
    if (!collectionData?.invitation_title) {
      setDescriptionEdit(true);
    }
  }, []);

  function onSubmit(values) {
    updateCollection(values, collectionData.id)
      .then((res) => {
        dispatch(getACollection(collectionData.id));
        setDescriptionEdit(false);
      })
      .catch((err) => {
        toast.error(err?.error_data || STD_ERROR_MESSAGE);
      });
  }

  function handleDeleteImageEvent() {
    deleteInviteImageBySequencePromise(params.collectionId, deletePopup.id)
      .then((res) => {
        dispatch(getAllInvitesInCollection(params.collectionId));
        closeDeletePopup();
      })
      .catch((err) => {
        toast.error(err?.error_data || STD_ERROR_MESSAGE);
      });
  }

  function closeDeletePopup() {
    setDeletePopup({ open: false, id: null });
  }

  function openDeletePopup(id) {
    setDeletePopup({ open: true, id: id });
  }

  function inviteGuest(inviteObj) {
    props.setFileData(inviteObj);
    props.setCurrentMainComponent((ps) => {
      return {
        ...ps,
        currComponent: SECTION_INVITES_COMPONENTS.invite_guest_list,
        prevComponent: ps.currComponent,
      };
    });
  }

  return (
    <main
      style={{ maxWidth: "850px", marginLeft: "auto", marginRight: "auto" }}
    >
      <CollectionHeaderPartner
        title={`Invites(${size(invitesData)})`}
        backToCollectionsBtn={true}
        addPhotosBtn={true}
        addPhotosLabel="Add Invites"
        handleAddPhotosBtn={props.onInviteButtonClick}
        handleBackToCollectionsBtn={() => history.goBack()}
        generalBtn={true}
        generalBtnLabel={"Master Invite List"}
        onGeneralBtnClick={props.onMasterInviteBtnClick}
      />
      <div style={{ marginTop: "27px" }}>
        <div
          className="p-3 mb-3"
          style={{
            border: "1px solid rgba(41, 41, 41, 0.38)",
            borderRadius: "8px",
          }}
        >
          {descriptionEdit ? (
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
            >
              {(formik) => (
                <Form>
                  <div className="d-flex justify-content-between align-items-center">
                    <h3 className="pb18-lh32 high-emphasis-color mb-0">
                      Type Home Screen Content here...
                    </h3>
                    <div>
                      {collectionData?.invitation_title && (
                        <ButtonPrimary
                          label="Cancel"
                          onClick={() => setDescriptionEdit(false)}
                          className="mr-3"
                        />
                      )}
                      <ButtonAccent label="Save" type="submit" />
                    </div>
                  </div>
                  <Field name="invitation_title">
                    {(props) => {
                      const { field, form, meta } = props;
                      return (
                        <div className="mt-3">
                          <textarea
                            placeholder="Type caption / body text here..."
                            className="img-description-textarea pr16-lh24"
                            style={{
                              border: "none",
                              resize: "none",
                              height: "135px",
                            }}
                            {...field}
                            // errorMsg={meta.touched && meta.error ? meta.error : ""}
                          />
                          {meta.touched && meta.error ? (
                            <div className="d-flex align-items-center justify-content-start mt-2 mb-2">
                              <img src={errorInfoIcon} alt="" />
                              <span className="margin-left-5 pr10-lh15 error-color">
                                {meta.error}
                              </span>
                            </div>
                          ) : null}
                        </div>
                      );
                    }}
                  </Field>
                </Form>
              )}
            </Formik>
          ) : (
            <>
              <div className="d-flex justify-content-between align-items-center">
                <h3 className="pb18-lh32 high-emphasis-color mb-0">
                  Home Screen Content
                </h3>
                <img
                  style={{
                    width: "1.25rem",
                    height: "1.25rem",
                  }}
                  src={editIcon}
                  alt=""
                  className="ml-2 pt-cursor"
                  onClick={() => setDescriptionEdit(true)}
                />
              </div>
              <p className="pr16-lh24 mt-3 mb-0">
                {collectionData?.invitation_title}
              </p>
            </>
          )}
        </div>
        {size(invitesData) ? (
          <Grid container spacing={2}>
            {invitesData.map((invite, index) => (
              <Grid item sm={12} md={6} lg={6} xl={6} key={index}>
                <LazyLoadComponent>
                  <Image
                    variant="withHover"
                    enableDeleteIcon={true}
                    enableShareIcon={true}
                    src={getImageUrl(invite.image.bucket_path)}
                    alt=""
                    onDeleteIconClick={() => openDeletePopup(invite.sequence)}
                    onShareIconClick={() => inviteGuest(invite)}
                  />
                </LazyLoadComponent>
              </Grid>
            ))}
          </Grid>
        ) : (
          <h2 className="text-center pr20-lh32 high-emphasis-color mt-5">
            You have no invites in this collection
          </h2>
        )}
      </div>
      {deletePopup.open && (
        <DeletePopup
          heading="DELETE IMAGE"
          text="The image will be deleted permanently. This cannot be undone."
          handleDelete={handleDeleteImageEvent}
          handleClose={closeDeletePopup}
        />
      )}
    </main>
  );
};

export default InvitesList;
