import "./infoscreen.css";

const AboutUs = () => {
  return (
    <div className="container">
      <h5 className="heading">About Us</h5>
      <div className="pr16-lh24">
        <p className="mb-2">
          Picsea is an online platform that allows guests at any social event to
          easily access relevant information.
        </p>
        <p className="mb-2">This includes-</p>
        <ul className="ml-4">
          <li>Guest invites</li>
          <li>Event updates</li>
          <li>Itineraries</li>
          <li>Event pictures</li>
        </ul>
      </div>
    </div>
  );
};

export default AboutUs;
