import { 
    REQUEST_MASTER_GUEST_INVITES_FETCH_EM,
    REQUEST_INVITE_GUEST_INVITES_FETCH_EM,
    REQUEST_ITINERARY_GUEST_INVITES_FETCH_EM,
    FETCH_INVITE_GUEST_INVITES_EM_SUCCESS,
    FETCH_MASTER_GUEST_INVITES_EM_SUCCESS,
    FETCH_ITINERARY_GUEST_INVITES_EM_SUCCESS,
    FETCH_GUEST_INVITES_EM_FAILURE,
    CLEAR_FETCH_GUEST_INVITES_EM_STATES
} from './guestInvites.types';

export const requestFetchMasterGuestInvitesEm = () => {
    return {
        type : REQUEST_MASTER_GUEST_INVITES_FETCH_EM,
    }
}

export const fetchMasterGuestInvitesEmSuccess = (response) => {
    return {
        type : FETCH_MASTER_GUEST_INVITES_EM_SUCCESS,
        payload : response
    }
}

export const requestFetchInviteGuestInvitesEm = () => {
    return {
        type : REQUEST_INVITE_GUEST_INVITES_FETCH_EM,
    }
}

export const fetchInviteGuestInvitesEmSuccess = (id, response) => {
    return {
        type : FETCH_INVITE_GUEST_INVITES_EM_SUCCESS,
        payload : {
            id, 
            response
        }
    }
}

export const requestFetchItineraryGuestInvitesEm = () => {
    return {
        type : REQUEST_ITINERARY_GUEST_INVITES_FETCH_EM,
    }
}

export const fetchInviteGuestItineraryEmSuccess = (id, response) => {
    return {
        type : FETCH_ITINERARY_GUEST_INVITES_EM_SUCCESS,
        payload : {
            id, 
            response
        }
    }
}

export const fetchGuestInvitesEmFailure = (error) => {
    return {
        type : FETCH_GUEST_INVITES_EM_FAILURE,
        payload : error
    }
}

export const clearFetchGuestInvitesEmStates = () => {
    return {
        type : CLEAR_FETCH_GUEST_INVITES_EM_STATES
    }
}