import { 
    REQUEST_FETCH_COLLECTION_EM,
    FETCH_COLLECTION_EM_SUCCESS,
    FETCH_COLLECTION_EM_FAILURE,
    CLEAR_FETCH_COLLECTION_EM_STATES
} from './collection.types.js';

let initialState = {
    reload: false,
    response : {},
    error : null,
    loading : false
}

const collectionsEmReducer = (state = initialState, action) => {

switch(action.type) {
    case REQUEST_FETCH_COLLECTION_EM:
        return {
            ...state,
            loading: true,
            error: null
        }

    case FETCH_COLLECTION_EM_SUCCESS:
        return {
            ...state,
            loading : false,
            response : action.payload,
            reload: false
        }

    case FETCH_COLLECTION_EM_FAILURE:
        return {
            ...state,
            loading : false,
            error : action.payload,
            reload: false
        }
    
    case CLEAR_FETCH_COLLECTION_EM_STATES:
        return {
            ...state,
            loading : false,
            response : {},
            error : null,
            reload: false
        }

    default:
        return state;
}
}

export default collectionsEmReducer