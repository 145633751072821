import {
  requestFetchCollectionsEm,
  fetchCollectionsEmSuccess,
  fetchCollectionsEmFailure,
} from "../redux/collections/collections.action";

import {
  requestFetchCollectionEm,
  fetchCollectionEmSuccess,
  fetchCollectionEmFailure,
} from "../redux/collection/collection.action";

import {
  fetchEventPermalinkEmFailure,
  fetchEventPermalinkEmSuccess,
  requestFetchEventPermalink,
} from "../redux/event-permalink/eventPermalink.action";

import ProtectedWebClient from "../utils/protected-web-client";
import {
  fetchOrgCollectionsEmFailure,
  fetchOrgCollectionsEmSuccess,
  requestFetchOrgCollectionsEm,
} from "../redux/org-collections/orgCollections.action";

export const getAllCollections = () => {
  return async (dispatch) => {
    try {
      dispatch(requestFetchCollectionsEm());
      const response = await ProtectedWebClient.get(`/event`);
      dispatch(fetchCollectionsEmSuccess(response?.data));
    } catch (error) {
      dispatch(fetchCollectionsEmFailure(error?.response));
    }
  };
};

export const getAllOrgCollections = (partnerId) => {
  return async (dispatch) => {
    try {
      dispatch(requestFetchOrgCollectionsEm());
      const response = await ProtectedWebClient.get(`/orgEvent/${partnerId}`);
      dispatch(fetchOrgCollectionsEmSuccess(response?.data));
    } catch (error) {
      dispatch(fetchOrgCollectionsEmFailure(error?.response));
    }
  };
};

export const getACollection = (collectionId) => {
  return async (dispatch) => {
    try {
      dispatch(requestFetchCollectionEm());
      const response = await ProtectedWebClient.get(`/event/${collectionId}`);
      dispatch(fetchCollectionEmSuccess(response?.data));
    } catch (error) {
      dispatch(fetchCollectionEmFailure(error?.response));
    }
  };
};

export const getACollectionLogo = (collectionId) => {
  return async (dispatch) => {
    try {
      dispatch(requestFetchCollectionEm());
      const response = await ProtectedWebClient.get(
        `/event/${collectionId}/logo`
      );
      dispatch(fetchCollectionEmSuccess(response?.data));
    } catch (error) {
      dispatch(fetchCollectionEmFailure(error?.response));
    }
  };
};

export const createCollection = (collectionDetail) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.post(`/event`, collectionDetail)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err?.response);
      });
  });
};

export const deleteCollection = (collectionId) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.delete(`/event/${collectionId}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err?.response);
      });
  });
};

export const updateCollection = (payload, collectionId) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.put(`/event/${collectionId}`, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err?.response);
      });
  });
};

export const fetchEventPermalink = (permalink) => {
  return async (dispatch) => {
    try {
      dispatch(requestFetchEventPermalink());
      const response = await ProtectedWebClient.get(
        `/event_permalink/${permalink}`
      );
      dispatch(fetchEventPermalinkEmSuccess(response?.data));
    } catch (error) {
      dispatch(fetchEventPermalinkEmFailure(error?.response));
    }
  };
};
