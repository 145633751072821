import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";

import InputBox from "../../../common/input-box/InputBox";
import ButtonAccent from "../../../common/button-accent/ButtonAccent";

import GameThumbnail from "../../../../assets/image/game_welcome.png";

import {
  fetchProfile,
  updateProfilePromise,
} from "../../../../services/profile.service";
import { getUserId } from "../../../../utils/utils";
import { STD_ERROR_MESSAGE } from "../../../../utils/constant";

import { changeGameStartedState } from "../../../../redux/games/games.action";

export default function Welcome() {
  const [profileLoading, setProfileLoading] = useState(true);
  const dispatch = useDispatch();
  const gameQuestionsState = useSelector((state) => state.gamesEm);
  const profileState = useSelector((state) => state.readProfileEm);

  const questionData = gameQuestionsState.response?.data;

  useEffect(() => {
    if (isEmpty(profileState.response)) {
      dispatch(fetchProfile(getUserId()));
    }
  }, []);

  const initialValues = {
    name: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Please enter your name"),
  });

  const onSubmit = (values) => {
    if (profileState.response?.data?.name !== values.name) {
      updateProfilePromise(values)
        .then((res) => {
          dispatch(changeGameStartedState(true));
          dispatch(fetchProfile(getUserId()));
        })
        .catch((err) => toast.error(STD_ERROR_MESSAGE));
    } else {
      dispatch(changeGameStartedState(true));
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (!profileState.loading && !isEmpty(profileState.response)) {
      if (profileState.response.data?.name)
        formik.setFieldValue("name", profileState.response.data.name);

      setProfileLoading(false);
    }

    if (!profileState.loading && !isEmpty(profileState.error)) {
      toast.error(STD_ERROR_MESSAGE);
    }
  }, [profileState]);

  return (
    <div
      style={{
        position: "relative",
        overflow: "hidden",
        flex: 1,
        border: "1px solid var(--gray-5-color)",
      }}
      className="d-flex flex-column justify-content-around"
    >
      <img
        alt=""
        src={GameThumbnail}
        style={{
          display: "inline-block",
          width: "100%",
          height: "auto",
          zIndex: 2,
          objectFit: "cover",
        }}
      />
      {!questionData?.total_questions ? (
        <div
          className="d-flex flex-column align-items-center justify-content-center"
          style={{
            zIndex: 3,
            padding: "1rem 2rem",
            paddingBottom: "3rem",
          }}
        >
          <h3
            className="pr18-lh32 high-emphasis-color"
            style={{ textAlign: "center" }}
          >
            Watch this space
          </h3>
        </div>
      ) : !profileLoading ? (
        <div
          className="d-flex flex-column align-items-center justify-content-end"
          style={{
            width: "100%",
            zIndex: 3,
            padding: "1rem 2rem",
            paddingBottom: "3rem",
          }}
        >
          <div style={{ maxWidth: "500px", width: "100%" }}>
            <form onSubmit={formik.handleSubmit}>
              <InputBox
                placeholder="Enter your name here"
                type="text"
                name="name"
                className="fullWidth"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                errorMsg={
                  Boolean(formik.touched.name) && Boolean(formik.errors.name)
                    ? formik.errors.name
                    : ""
                }
              />
              <ButtonAccent
                customStyling={{ width: "100%" }}
                label="Start Game"
                type="submit"
              />
            </form>
          </div>
        </div>
      ) : null}
    </div>
  );
}
