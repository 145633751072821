import {
  CLEAR_FETCH_ENTITY_PROFILE_AD_STATES,
  FETCH_ENTITY_PROFILE_AD_FAILURE,
  FETCH_ENTITY_PROFILE_AD_SUCCESS,
  REQUEST_ENTITY_PROFILE_FETCH_AD,
} from "./entity.location.types";

export const requestFetchEntityProfileAd = () => {
  return {
    type: REQUEST_ENTITY_PROFILE_FETCH_AD,
  };
};

export const fetchEntityProfileAdSuccess = (response) => {
  return {
    type: FETCH_ENTITY_PROFILE_AD_SUCCESS,
    payload: response,
  };
};

export const fetchEntityProfileAdFailure = (error) => {
  return {
    type: FETCH_ENTITY_PROFILE_AD_FAILURE,
    payload: error,
  };
};

export const clearFetchentityProfileAdState = () => {
  return {
    type: CLEAR_FETCH_ENTITY_PROFILE_AD_STATES,
  };
};
