import React from "react";
import "./simple-input-box.css";

export default function SimpleInputBox(props) {
  return (
    <input
      className="simple-input-box pb14-lh21 primary-color px-5 py-2"
      {...props}
    />
  );
}
