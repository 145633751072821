import {
  CHANGE_TO_NEXT_PREFRENCE_STATE,
  CLEAR_FETCH_PREFERENCE_EM_STATES,
  FETCH_PREFERENCE_QUESTIONS_EM_FAILURE,
  FETCH_PREFERENCE_QUESTIONS_EM_SUCCESS,
  REQUEST_PREFERENCE_QUESTIONS_FETCH_EM,
} from "./preferences.types";

let initialState = {
  reload: false,
  response: {},
  error: null,
  isStarted: false,
  loading: false,
};

const fetchPreferenceQuestionsEmReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_PREFERENCE_QUESTIONS_FETCH_EM:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_PREFERENCE_QUESTIONS_EM_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
        reload: false,
      };
    case FETCH_PREFERENCE_QUESTIONS_EM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        reload: false,
      };

    case CHANGE_TO_NEXT_PREFRENCE_STATE:
      return {
        ...state,
        response: action.payload,
      };

    case CLEAR_FETCH_PREFERENCE_EM_STATES:
      return {
        ...state,
        loading: false,
        response: {},
        error: null,
        reload: false,
        isStarted: false,
      };
    default:
      return state;
  }
};
export default fetchPreferenceQuestionsEmReducer;
