const uploadFileTypes = {
    SET_UPLOAD_FILES: 'SET_UPLOAD_FILES',
    SET_UPLOAD_PROGRESS: 'SET_UPLOAD_PROGRESS',
    SUCCESS_UPLOAD_FILE: 'SUCCESS_UPLOAD_FILE',
    FAILURE_UPLOAD_FILE: 'FAILURE_UPLOAD_FILE',
    RETRY_UPLOAD_FAILED_FILE: 'RETRY_UPLOAD_FAILED_FILE',
    CLEAR_UPLOAD_ERROR_STACK : 'CLEAR_UPLOAD_ERROR_STACK',
    CLEAR_UPLOAD_FILES : 'CLEAR_UPLOAD_FILES',
    SET_IMAGE_PREVIEW : 'SET_IMAGE_PREVIEW',
    SET_IMAGE_ID: 'SET_IMAGE_ID'
}

export default uploadFileTypes