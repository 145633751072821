import {
  CLEAR_FETCH_PREFERENCE_EM_STATES,
  FETCH_PREFERENCE_QUESTIONS_EM_FAILURE,
  FETCH_PREFERENCE_QUESTIONS_EM_SUCCESS,
  REQUEST_PREFERENCE_QUESTIONS_FETCH_EM,
  CHANGE_TO_NEXT_PREFRENCE_STATE,
} from "./preferences.types";

export const requestFetchPreferenceQuestionsEm = () => {
  return {
    type: REQUEST_PREFERENCE_QUESTIONS_FETCH_EM,
  };
};

export const fetchPreferenceQuestionsEmSuccess = (response) => {
  return {
    type: FETCH_PREFERENCE_QUESTIONS_EM_SUCCESS,
    payload: response,
  };
};

export const fetchPreferenceQuestionsEmFailure = (error) => {
  return {
    type: FETCH_PREFERENCE_QUESTIONS_EM_FAILURE,
    payload: error,
  };
};

export const clearFetchPreferenceEmStates = () => {
  return {
    type: CLEAR_FETCH_PREFERENCE_EM_STATES,
  };
};

export const changeToNextPreferenceState = (value) => {
  return {
    type: CHANGE_TO_NEXT_PREFRENCE_STATE,
    payload: value,
  };
};
