import {
  fetchGroupEmFailure,
  fetchGroupEmSuccess,
  requestFetchGroupEm,
} from "../redux/group/group.action";
import protectedWebClient from "../utils/protected-web-client";

export const getGroupByOwnerId = (collectionId) => {
  return async (dispatch) => {
    try {
      dispatch(requestFetchGroupEm());
      const response = await protectedWebClient.get(
        `/event/${collectionId}/group`
      );
      dispatch(fetchGroupEmSuccess(response?.data));
    } catch (error) {
      dispatch(fetchGroupEmFailure(error?.data));
    }
  };
};

export const addRSVPPromise = (collectionId, payload) => {
  return new Promise((resolve, reject) => {
    protectedWebClient
      .post(`/event/${collectionId}/rsvp`, payload)
      .then((res) => {
        resolve(res?.data);
      })
      .catch((error) => {
        reject(error?.data);
      });
  });
};
