import {
  fetchTestimonialAdFailure,
  fetchTestimonialAdSuccess,
  requestFetchtestimonialAdReducer,
} from "../redux/testimonials/testimonial.action";
import ProtectedWebClient from "../utils/protected-web-client";

export const createTestimonialPromise = (payload, entityId, id) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.post(`/entity/${entityId}/testimonial`, payload)
      .then((res) => {
        resolve({ id: id, res: res });
      })
      .catch((err) => {
        reject({ id: id, err: err?.response });
      });
  });
};

export const fetchTestimonial = (entityId) => {
  return async (dispatch) => {
    try {
      dispatch(requestFetchtestimonialAdReducer());
      const response = await ProtectedWebClient.get(
        `/entity/${entityId}/testimonial`
      );
      dispatch(fetchTestimonialAdSuccess(response?.data));
    } catch (error) {
      dispatch(fetchTestimonialAdFailure(error?.response));
    }
  };
};

export const deleteTestimonialbySequencePromise = (entityId, sequence) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.delete(`/entity/${entityId}/testimonial/${sequence}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err?.response);
      });
  });
};

export const updateTestimonialBySequencePromise = (
  payload,
  entityId,
  sequence
) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.put(
      `/entity/${entityId}/testimonial/${sequence}`,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err?.response);
      });
  });
};
