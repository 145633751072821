import React, {useState, useEffect, useRef} from 'react'

const useCountDownTimer = (props) => {

    const DEFAULT_COUNT_DOWN_TIME = 30

    const [time, setTime] = useState(DEFAULT_COUNT_DOWN_TIME)
    const [runTimer, setRunTimer] = useState(false)

    const interval = useRef()

    const startTimer = () => {
        interval.current = setInterval(() => {
            setTime(prevState => {
                if(prevState <= 0) {
                    clearInterval(interval.current)
                    setRunTimer(false)
                    return prevState
                } else {
                    return prevState - 1
                }
            })
        }, 1000)
    }

    function start(){
        if(time !== DEFAULT_COUNT_DOWN_TIME) setTime(DEFAULT_COUNT_DOWN_TIME)
        setRunTimer(true)
    }

    function isTicking(){
        return runTimer
    }

    useEffect(() => {
        if(runTimer){
            startTimer()
        }
        return () => {
            if(interval.current) clearInterval(interval.current)
        }
    }, [runTimer])

    return [time, start, isTicking]
}

export default useCountDownTimer