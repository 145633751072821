import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { isEmpty } from "lodash";
import "./user-couple-story.css";

import CustomSpinner from "../../common/spinner/CustomSpinner";

import {
  fetchKnowTheEntitySection,
  fetchKnowTheHostSection,
} from "../../../services/knowTheHost.service";

import { STD_ERROR_MESSAGE } from "../../../utils/constant";
import { getImageUrl } from "../../../utils/utils";

import PatternBackground from "../../../assets/image/pattern_bg.png";
import PatternBackGround1 from "../../../assets/image/pattern_bg_1.png";

import { EVENT_TYPES } from "../../../utils/constant";

const KTHSectionItem = (props) => {
  const { isMobile, name, description, imageUrl, layout, containerClassName } =
    props;

  switch (layout) {
    case 1:
      return (
        <Grid container spacing={2} className={containerClassName || ""}>
          <Grid className="d-flex align-items-center" item xs={6}>
            <div>
              <h4 className={isMobile ? "pb24-lh40" : "pb36-lh56"}>{name}</h4>
              <p className={`${isMobile ? "pr14-lh24" : "pr24-lh40"} spacing`}>
                {description}
              </p>
            </div>
          </Grid>
          <Grid
            item
            xs={6}
            className="d-flex justify-content-start align-items-center"
          >
            <img
              style={{
                width: "100%",
                maxWidth: "500px",
                aspectRatio: "1/1",
                borderRadius: "50%",
                objectFit: "cover",
                objectPosition: "center",
              }}
              src={imageUrl}
              alt=""
            />
          </Grid>
        </Grid>
      );
    default:
      return (
        <Grid container spacing={2} className={containerClassName || ""}>
          <Grid
            item
            xs={6}
            className="d-flex justify-content-start align-items-center"
          >
            <img
              style={{
                width: "100%",
                maxWidth: "500px",
                aspectRatio: "1/1",
                borderRadius: "50%",
                objectFit: "cover",
                objectPosition: "center",
              }}
              src={imageUrl}
              alt=""
            />
          </Grid>
          <Grid className="d-flex align-items-center" item xs={6}>
            <div>
              <h4 className={isMobile ? "pb24-lh40" : "pb36-lh56"}>{name}</h4>
              <p
                className={`${
                  isMobile ? "pr14-lh24" : "pr24-lh40"
                } mb-0 spacing`}
              >
                {description}
              </p>
            </div>
          </Grid>
        </Grid>
      );
  }
};

const UserCoupleStory = (props) => {
  const knowTheHostState = useSelector((state) => state.knowTheHostEm);
  const dispatch = useDispatch();
  const params = useParams();
  const [loading, setLoading] = useState(true);

  const knowTheHostSections = knowTheHostState?.response?.data;

  const isMobile = useMediaQuery("(max-width: 480px)");

  const isMobileOrTablet = useMediaQuery("(max-width: 599px)");
  const minHeight = isMobileOrTablet ? 56 + 56 + 40 : 64 + 56 + 40;

  useEffect(() => {
    if (isEmpty(knowTheHostState.response)) {
      if (props.component === "entity") {
        dispatch(fetchKnowTheEntitySection(props.entityId));
      } else {
        dispatch(fetchKnowTheHostSection(props.collectionId));
      }
    }
  }, []);

  useEffect(() => {
    if (!knowTheHostState.loading && !isEmpty(knowTheHostState.response)) {
      setLoading(false);
    }
    if (!knowTheHostState.loading && !isEmpty(knowTheHostState.error)) {
      setLoading(false);
      toast.error(
        knowTheHostState.error?.data?.error_data || STD_ERROR_MESSAGE
      );
    }
  }, [knowTheHostState]);

  return (
    <div style={{ minHeight: `calc(100vh - ${minHeight}px)` }}>
      {!loading ? (
        !isEmpty(knowTheHostSections) ? (
          <div
            style={{
              backgroundImage:
                props.eventType == "t" || props.eventType == "r"
                  ? null
                  : `url(${PatternBackground})`,
              backgroundRepeat: "repeat",
            }}
            className="py-5 px-4"
          >
            {props.eventType != "e" && (
              <h2
                className={`${
                  isMobile
                    ? "parisienne-normal-fs40-lh80"
                    : "parisienne-normal-fs72-lh80"
                } text-center`}
              >
                {props.eventType == EVENT_TYPES.wedding ? "Our Story" : null}
                {props.eventType == EVENT_TYPES.apartment ? "Assosiates" : null}
              </h2>
            )}
            <div
              className="mt-4"
              style={{
                maxWidth: "1200px",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              {knowTheHostSections.map((KTHSection, index) => (
                <KTHSectionItem
                  layout={index % 2}
                  key={KTHSection.id}
                  isMobile={isMobile}
                  name={KTHSection.title}
                  description={KTHSection.custom_text}
                  imageUrl={getImageUrl(KTHSection.image?.bucket_path)}
                  containerClassName="mb-3"
                />
              ))}
            </div>
          </div>
        ) : (
          <h4 className="text-center pr20-lh32 high-emphasis-color pt-5">
            You have no sections
          </h4>
        )
      ) : (
        <CustomSpinner />
      )}
    </div>
  );
};

export default UserCoupleStory;
