import PublicWebClient from "../utils/public-web-client";
import ProtectedWebClient from "../utils/protected-web-client";

export const signUpUser = (payload) => {
  return new Promise((resolve, reject) => {
    PublicWebClient.post("/user", payload)
      .then((res) => {
        resolve(res?.data);
      })
      .catch((err) => {
        reject(err?.response);
      });
  });
};

export const deactivateUser = (userId) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.delete(`/user/${userId}`)
      .then((res) => {
        resolve(res?.data);
      })
      .catch((err) => {
        reject(err?.response);
      });
  });
};

// export const signInUser = (payload) => {
//     return new Promise((resolve, reject) => {
//         PublicWebClient.post('/user/sign_in', payload)
//         .then(res => {
//             resolve(res?.data)
//         })
//         .catch(err => {
//             reject(err?.response)
//         })
//     })
// }

// export const forgetPassword = (payload) => {
//     return new Promise((resolve, reject) => {
//         PublicWebClient.post('/forgot_password', payload).then((res) => {
//             resolve(res)
//         }).catch(err => {
//             if(err.toJSON().message === 'Network Error'){
//                 reject('Connect to Internet')
//             } else {
//                 reject(err)
//             }
//         })
//     })
// }
