import {
  fetchEntityProfileAdFailure,
  fetchEntityProfileAdSuccess,
  requestFetchEntityProfileAd,
} from "../redux/entity-location/entity.location.action";

import ProtectedWebClient from "../utils/protected-web-client";

export const getAllLocationInEntity = (entityId) => {
  return async (dispatch) => {
    try {
      dispatch(requestFetchEntityProfileAd());
      const response = await ProtectedWebClient.get(
        `/entity/${entityId}/location`
      );
      dispatch(fetchEntityProfileAdSuccess(response?.data));
    } catch (error) {
      dispatch(fetchEntityProfileAdFailure(error?.response?.data));
    }
  };
};

export const createEntityLocationPromise = (entityId, payload) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.post(`/entity/${entityId}/location`, payload)
      .then((res) => {
        resolve(res?.data);
      })
      .catch((error) => {
        reject(error?.data);
      });
  });
};

export const deleteLocationImageBySequencePromise = (entityId, sequence) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.delete(`/entity/${entityId}/location/${sequence}`)
      .then((res) => {
        resolve(res?.data);
      })
      .catch((error) => {
        reject(error?.data);
      });
  });
};
