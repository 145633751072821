import React, { useState, useEffect } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import lottie from "lottie-web";
import animationData from "../../../../assets/lotties/confetti.json";

import { getImageUrl } from "../../../../utils/utils";
import GiftImg from "../../../../assets/others/gift.svg";
import {
  fetchGameQuestionByIdUser,
  submitOption,
} from "../../../../services/games.service";
import "./question.css";
import {
  SECTION_GAMES_USER_COMPONENTS,
  STD_ERROR_MESSAGE,
} from "../../../../utils/constant";
import { changeToNextQuestionState } from "../../../../redux/games/games.action";

function OutlineBox(props) {
  const { className, ...rest } = props;
  return (
    <div
      style={{
        border: "1px solid var(--primary-color)",
        borderRadius: "4px",
        padding: "0.25rem 1rem",
        minWidth: "5rem",
      }}
      className={`question-outline-btn d-flex align-items-center justify-content-center ${
        className || ""
      }`}
      {...rest}
    >
      {props.children}
    </div>
  );
}

function FlatButton(props) {
  const { className, ...rest } = props;

  return (
    <button
      className={`question-option-btn pr16-lh24 gray-1-color px-1 py-1 mb-2 ${
        className || ""
      }`}
      style={{ border: "none" }}
      {...rest}
    >
      {props.children}
    </button>
  );
}

const LottiePlayer = (props) => {
  const { id, animationData, loop, autoplay, onComplete } = props;

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: document.querySelector(`#${id}`),
      renderer: "svg",
      loop: typeof loop === "boolean" ? loop : false,
      autoplay: typeof autoplay === "boolean" ? autoplay : true,
      animationData: animationData,
    });

    if (onComplete !== undefined) {
      anim.addEventListener("complete", onComplete);
    }

    return () => {
      if (onComplete !== undefined) {
        anim.removeEventListener("complete", onComplete);
      }
      anim.destroy();
    };
  }, []);

  return <div id={id} />;
};

export default function Question(props) {
  const { changeCurrComponent } = props;

  const [nextGame, setNextGame] = useState(null);
  const [userResponse, setUserResponse] = useState(null);
  const [showConfetti, setShowConfetti] = useState(false);
  const gameQuestionsState = useSelector((state) => state.gamesEm);
  const dispatch = useDispatch();
  const params = useParams();

  const questionData = gameQuestionsState.response?.data;

  const correctOption = !isEmpty(questionData)
    ? questionData.current_game.options.find((option) => option.is_correct)
    : {};

  const collectionId = props.collectionId
    ? props.collectionId
    : params?.collectionId;

  useEffect(() => {
    setUserResponse(null);
    setNextGame(null);
  }, [gameQuestionsState.response]);

  const handleViewHighScoreClick = () => {
    if (isEmpty(questionData)) return;

    if (!isEmpty(nextGame)) dispatch(changeToNextQuestionState(nextGame));

    if (questionData.questions_answered !== questionData.total_questions)
      changeCurrComponent(SECTION_GAMES_USER_COMPONENTS.caution);
    else changeCurrComponent(SECTION_GAMES_USER_COMPONENTS.leaderBoard);
  };

  const handlePageChange = (action) => {
    const gameId =
      action === "next" ? questionData.next_game : questionData.previous_game;

    if (
      action === "next" &&
      !isEmpty(nextGame) &&
      nextGame.data?.current_game?.id === gameId
    ) {
      dispatch(changeToNextQuestionState(nextGame));
    } else {
      dispatch(fetchGameQuestionByIdUser(collectionId, gameId));
    }
  };

  const handleOptionSubmission = (id) => {
    submitOption(collectionId, questionData?.current_game?.id, { id })
      .then((res) => {
        const mUserResponse = {
          option_id: id,
          is_correct: correctOption.id === id,
        };
        setUserResponse(mUserResponse);
        setNextGame(res);
        if (
          !isEmpty(res?.data) &&
          !mUserResponse.is_correct &&
          res.data.total_questions === res.data.questions_answered
        ) {
          dispatch(changeToNextQuestionState(res));
          showHighScoreWithDelay(500);
        }
      })
      .catch((error) => toast.error(STD_ERROR_MESSAGE));
  };

  useEffect(() => {
    if (!isEmpty(userResponse)) {
      if (userResponse.is_correct) {
        setShowConfetti(true);
      }
    }
  }, [userResponse]);

  function showHighScoreWithDelay(delay) {
    setTimeout(() => {
      changeCurrComponent(SECTION_GAMES_USER_COMPONENTS.leaderBoard);
    }, delay);
  }

  function getOptionButtonClassName(id) {
    if (!isEmpty(questionData.user_response)) {
      if (questionData.user_response.option_id === id) {
        if (questionData.user_response.is_correct) {
          return "success-color-bg pointer-events-none";
        } else {
          return "error-color-bg pointer-events-none";
        }
      } else if (correctOption?.id === id) {
        return "success-color-bg pointer-events-none";
      } else {
        return "low-emphasis-color-bg pointer-events-none";
      }
    } else if (!isEmpty(userResponse)) {
      if (userResponse.option_id === id) {
        if (userResponse.is_correct) {
          return "success-color-bg pointer-events-none";
        } else {
          return "error-color-bg pointer-events-none";
        }
      } else if (correctOption.id === id) {
        return "success-color-bg pointer-events-none";
      } else {
        return "low-emphasis-color-bg pointer-events-none";
      }
    } else {
      return "accent-color-bg";
    }
  }

  function getMessage() {
    if (!isEmpty(questionData.user_response)) {
      if (questionData.user_response.is_correct) {
        return <span className="success-color pb14-lh21">Great!!</span>;
      } else {
        return <span className="error-color pb14-lh21">Oops! It's wrong.</span>;
      }
    } else if (!isEmpty(userResponse)) {
      if (userResponse.is_correct) {
        if (questionData.next_game) {
          return (
            <span className="success-color pb14-lh21">
              Great!! Try next one.
            </span>
          );
        } else {
          return <span className="success-color pb14-lh21">Great!!</span>;
        }
      } else {
        if (questionData.next_game) {
          return (
            <span className="error-color pb14-lh21">
              Oops! It's wrong. Try next one.
            </span>
          );
        } else {
          return (
            <span className="error-color pb14-lh21">Oops! It's wrong.</span>
          );
        }
      }
    } else {
      return (
        <span className="medium-emphasis-color pr14-lh24">tap and answer</span>
      );
    }
  }

  return !isEmpty(questionData) ? (
    <div
      style={{
        flex: 1,
        position: "relative",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          backgroundColor: "rgba(255, 255, 255, 0.75)",
          zIndex: 1,
        }}
        className="d-flex justify-content-between"
      >
        <OutlineBox>
          <h4 className="pb12-lh18 primary-color mb-0">
            Your Score: {questionData.user_score}
          </h4>
        </OutlineBox>
        <OutlineBox className="pt-cursor" onClick={handleViewHighScoreClick}>
          <h4 className="pb12-lh18 primary-color mb-0">View Top Score</h4>
          <ArrowForwardIosIcon
            sx={{ width: "0.75rem", height: "0.75rem", marginLeft: "0.25rem" }}
          />
        </OutlineBox>
      </div>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          overflow: "auto",
          padding: "2rem 0 2rem 0",
        }}
      >
        <div
          style={{ maxWidth: "500px", marginLeft: "auto", marginRight: "auto" }}
        >
          <div className="d-flex justify-content-center">
            <img
              alt=""
              src={GiftImg}
              style={{
                width: "6rem",
                height: "6rem",
              }}
            />
          </div>
          {questionData?.current_game?.question ? (
            <h1
              className="merriweather-black-fs20-lh36 primary-color mt-2"
              style={{ textAlign: "center" }}
            >
              {questionData.current_game.question}
            </h1>
          ) : null}

          {!isEmpty(questionData?.current_game?.image) ? (
            <div className="d-flex justify-content-center mb-2">
              <img
                alt=""
                src={getImageUrl(questionData.current_game?.image?.bucket_path)}
                style={{
                  maxWidth: "100%",
                  width: "auto",
                  maxHeight: "160px",
                  height: "auto",
                }}
              />
            </div>
          ) : null}
          {!isEmpty(questionData?.current_game?.options) ? (
            <div className="d-flex flex-column pt-2">
              {questionData?.current_game?.options.map((option) => (
                <FlatButton
                  key={option.id}
                  onClick={() => handleOptionSubmission(option.id)}
                  className={getOptionButtonClassName(option.id)}
                >
                  {option.title}
                </FlatButton>
              ))}
            </div>
          ) : null}
          <h5 className="mt-2 mb-4" style={{ textAlign: "center" }}>
            {getMessage()}
          </h5>
        </div>
      </div>
      <div
        className="d-flex justify-content-between align-items-center"
        style={{
          zIndex: 1,
          position: "absolute",
          bottom: 0,
          left: 0,
          width: "100%",
          backgroundColor: "rgba(255, 255, 255, 0.75)",
        }}
      >
        <OutlineBox
          className={`${
            !Boolean(questionData.previous_game) ? "disabled" : "pt-cursor"
          }`}
          onClick={() => handlePageChange("back")}
        >
          <ArrowBackIos
            sx={{ width: "0.75rem", height: "0.75rem", marginRight: "0.25rem" }}
          />
          <h4 className="pb12-lh18 primary-color mb-0">Back</h4>
        </OutlineBox>
        <div
          style={{
            padding: "0.25rem",
          }}
          className="d-flex align-items-center justify-content-center"
        >
          <h4 className="pr14-lh24 medium-emphasis-color mb-0">
            {questionData.current_question}/{questionData.total_questions}
          </h4>
        </div>
        <OutlineBox
          className={`${
            !Boolean(questionData.next_game) ? "disabled" : "pt-cursor"
          }`}
          onClick={() => handlePageChange("next")}
        >
          <h4 className="pb12-lh18 primary-color mb-0">Next</h4>
          <ArrowForwardIosIcon
            sx={{ width: "0.75rem", height: "0.75rem", marginLeft: "0.25rem" }}
          />
        </OutlineBox>
      </div>
      {showConfetti ? (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
        >
          <LottiePlayer
            id="lottie-player"
            animationData={animationData}
            onComplete={() => {
              setShowConfetti(false);
              if (
                !isEmpty(nextGame) &&
                nextGame?.data?.total_questions ===
                  nextGame?.data?.questions_answered
              ) {
                dispatch(changeToNextQuestionState(nextGame));
                showHighScoreWithDelay(250);
              }
            }}
          />
        </div>
      ) : null}
    </div>
  ) : null;
}
