import { useSelector } from "react-redux";
import { IOSSwitch } from "../../common/ios-switch/IOSSwitch";
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { AD_BUCKET_PATH } from "../../../utils/constant";
import AddEntityFolderPopup from "../add-entity-folder/AddEntityFolder";
import DeleteFolderPopup from "../delete-popup/DeletePopup";
import EditEntityFolderPopup from "../edit-entity-folder/EditEntityFolder";
import Image from "../../common/v2/image/Image";
import { STD_ERROR_MESSAGE, ENTITY_FOLDERS } from "../../../utils/constant";
import { updateEntity } from "../../../services/entities.service";
import EditEntityNamePopup from "../edit-entity-name/EditEntityName";
import "../sidebar/siderbar.css";
import { toast } from "react-toastify";
import { isEmpty } from "lodash";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { getImageUrl } from "../../../utils/utils";
import edit from "../../../assets/icon/edit.svg";
import editWhiteIcon from "../../../assets/icon/edit_white.svg";
import deleteIcon from "../../../assets/icon/delete.svg";
import {
  getPresignedUrlPromise,
  uploadImageUsingPresignedUrlPromise,
} from "../../../services/uploadImage.service";
import {
  deleteAEntityGalleryFolderPromise,
  getAllEntityGallery,
} from "../../../services/gallery.service";
import { fetchEntityAdSuccess } from "../../../redux/entity/entity.action";

const ListItem = (props) => {
  const {
    className,
    onItemClick,
    checked,
    onToggleChange,
    label,
    removeSwitch,
  } = props;
  const defaultClassName =
    "folder-container d-flex justify-content-between pt-cursor";
  const mClassName = className
    ? defaultClassName + ` ${className}`
    : defaultClassName;
  return (
    <div className={mClassName} onClick={onItemClick}>
      <li className="pr12-lh24 primary-color">{label}</li>
      {!removeSwitch && (
        <IOSSwitch checked={checked} onChange={onToggleChange} />
      )}
    </div>
  );
};

const AdminSidebar = (props) => {
  const { loading, selected, setSelected, disableItems } = props;
  const imageRef = useRef();
  const [editEntityNamePopup, setEditEntityNamePopup] = useState(false);
  const [addFolderPopup, setAddFolderPopup] = useState(false);
  const [editEntityFolderPopup, setEditEntityFolderPopup] = useState({
    open: false,
    folderInfo: null,
  });
  const [deleteFolderPopup, setDeleteFolderPopup] = useState({
    open: false,
    id: null,
  });
  const entityState = useSelector((state) => state.entityAd);
  const galleryState = useSelector((state) => state.galleryEm);
  const uploadFilesState = useSelector((state) => state.uploadFiles);
  const dispatch = useDispatch();
  const params = useParams();

  const entity = entityState.response?.data;
  const galleryData = galleryState.response?.data;

  const settings = entityState.response?.data?.settings
    ? JSON.parse(entityState.response?.data?.settings)
    : {};

  function handleFolderChange(folderId, subFolder = null) {
    setSelected({ folder: folderId, subFolder: subFolder });
  }

  function toggleEditEntityPopup() {
    setEditEntityNamePopup((ps) => !ps);
  }

  function openEditEntityFolderPopup(folderInfo) {
    setEditEntityFolderPopup({
      open: true,
      folderInfo,
    });
  }

  function isFilesUploading() {
    return !isEmpty(uploadFilesState.fileProgress);
  }

  function closeEditEntityFolderPopup() {
    setEditEntityFolderPopup({
      open: false,
      folderInfo: null,
    });
  }

  function toggleAddFolderPopup() {
    setAddFolderPopup((ps) => !ps);
  }

  function handleFolderDeleteClick(id) {
    setDeleteFolderPopup({
      open: true,
      id,
    });
  }

  function handleDeleteSubFolderEntity() {
    deleteAEntityGalleryFolderPromise(params.entityId, deleteFolderPopup.id)
      .then((res) => {
        dispatch(getAllEntityGallery(params.entityId));
        closeDeletePopup();
      })
      .catch((err) => {
        toast.error(err?.data?.error_data || STD_ERROR_MESSAGE);
      });
  }

  function closeDeletePopup() {
    setDeleteFolderPopup({
      open: false,
      id: null,
    });
  }
  function handleVisibilityChange(folder) {
    const currSettings = {
      know_the_host: settings.hasOwnProperty("know_the_host")
        ? Boolean(settings.know_the_host)
        : true,
      gallery: settings.hasOwnProperty("gallery")
        ? Boolean(settings.gallery)
        : true,
      live_updates: settings.hasOwnProperty("live_updates")
        ? Boolean(settings.live_updates)
        : true,
      testimonial: settings.hasOwnProperty("testimonial")
        ? Boolean(settings.testimonial)
        : true,
      contactUs: settings.hasOwnProperty("contactUs")
        ? Boolean(settings.contactUs)
        : false,
    };

    switch (folder) {
      case ENTITY_FOLDERS.profile:
        currSettings.profile = !currSettings.profile;
        break;
      case ENTITY_FOLDERS.know_the_entity:
        currSettings.know_the_host = !currSettings.know_the_host;
        break;
      case ENTITY_FOLDERS.live_feed:
        currSettings.live_updates = !currSettings.live_updates;
        break;
      case ENTITY_FOLDERS.photos_gallery:
        currSettings.gallery = !currSettings.gallery;
        break;
      case ENTITY_FOLDERS.testimonial:
        currSettings.testimonial = !currSettings.testimonial;
        break;
      case ENTITY_FOLDERS.permalink:
        currSettings.permalink = !currSettings.permalink;
        break;
      case ENTITY_FOLDERS.contactUs:
        currSettings.contactUs = !currSettings.contactUs;
        break;
      default:
    }

    updateEntity({ settings: JSON.stringify(currSettings) }, entity.id)
      .then((res) => {
        dispatch(fetchEntityAdSuccess(res.data));
      })
      .catch((err) =>
        toast.error(
          !isEmpty(err?.data?.error_data)
            ? err?.data?.error_data
            : STD_ERROR_MESSAGE
        )
      );
  }

  function getAppendingClassName(item) {
    let appendingClassName = "";
    if (selected.folder === item) appendingClassName += "selected ";
    if (isFilesUploading()) appendingClassName += "disabled ";
    return appendingClassName;
  }

  function handleEntityThumbnailChange(event) {
    const file = event.target.files[0];
    if (file) {
      let imageId = "";
      const presignedUrlPayload = [
        {
          bucket_path: `${AD_BUCKET_PATH.entity}/${entity.id}/${file.name}`,
          image_name: file.name,
        },
      ];

      getPresignedUrlPromise(presignedUrlPayload)
        .then((res) => {
          imageId = res?.res?.data?.data?.[0]?.image_id;
          return uploadImageUsingPresignedUrlPromise(
            res?.res?.data?.data?.[0]?.upload_url,
            file
          );
        })
        .then((res) => {
          return updateEntity({ cover_image: imageId }, entity.id);
        })
        .then((res) => {
          dispatch(fetchEntityAdSuccess(res.data));
        })
        .catch((err) => {
          toast.error(
            !isEmpty(err?.data?.error_data)
              ? err?.data?.error_data
              : STD_ERROR_MESSAGE
          );
        });
    }
  }
  return !loading.entity ? (
    !isEmpty(entity) ? (
      <aside
        style={{
          maxWidth: "280px",
          marginLeft: "auto",
          marginRight: "auto",
          overflow: "auto",
        }}
        className="p-4 w-100 h-100"
      >
        <div>
          <div className="d-flex align-items-center justify-content-start">
            <h4 className="truncate pb16-lh24 m-0">{entity?.name}</h4>
            <img
              className="edit-icon pt-cursor"
              src={edit}
              alt=""
              onClick={toggleEditEntityPopup}
            ></img>
          </div>
          <div className="my-1 d-flex align-items-center justify-content-between">
            {/* <span className="justify-self-flex-end pr12-lh24 medium-emphasis-color m-0">Unpublished</span> */}
          </div>
          <div className="position-relative w-100" style={{ height: "120px" }}>
            <LazyLoadComponent>
              <Image
                src={getImageUrl(entity?.image?.bucket_path)}
                alt=""
                containerCustomStyle={{
                  height: "130px",
                  overflow: "hidden",
                }}
                imageCustomStyle={{
                  objectFit: "cover",
                  objectPosition: "center",
                  width: "100%",
                  height: "100%",
                }}
              />
            </LazyLoadComponent>
            <button
              className="collection-thumbnail-edit pt-cursor"
              onClick={(event) => {
                event.preventDefault();
                imageRef.current.click();
              }}
            >
              <img
                className="edit-collection-thumbnail-icon"
                src={editWhiteIcon}
                alt=""
              />
            </button>
            <input
              type="file"
              style={{ display: "none" }}
              ref={imageRef}
              accept="image/png, image/jpeg, image/jpg"
              name="wallpaper"
              onChange={handleEntityThumbnailChange}
            />
          </div>
        </div>
        <div className="pt-3">
          <nav>
            <ul className="line-style-type-none">
              <ListItem
                className={getAppendingClassName(ENTITY_FOLDERS.profile)}
                onItemClick={() => handleFolderChange(ENTITY_FOLDERS.profile)}
                removeSwitch
                label="Profile"
              />
              <ListItem
                className={getAppendingClassName(
                  ENTITY_FOLDERS.know_the_entity
                )}
                onItemClick={() =>
                  handleFolderChange(ENTITY_FOLDERS.know_the_entity)
                }
                checked={!!settings?.know_the_host}
                onToggleChange={() =>
                  handleVisibilityChange(ENTITY_FOLDERS.know_the_entity)
                }
                label="About"
              />
              <ListItem
                className={getAppendingClassName(ENTITY_FOLDERS.live_feed)}
                onItemClick={() => handleFolderChange(ENTITY_FOLDERS.live_feed)}
                checked={!!settings?.live_updates}
                onToggleChange={() =>
                  handleVisibilityChange(ENTITY_FOLDERS.live_feed)
                }
                label="Live Feed"
              />
              <ListItem
                className={getAppendingClassName(ENTITY_FOLDERS.testimonial)}
                onItemClick={() =>
                  handleFolderChange(ENTITY_FOLDERS.testimonial)
                }
                checked={!!settings?.testimonial}
                onToggleChange={() =>
                  handleVisibilityChange(ENTITY_FOLDERS.testimonial)
                }
                label="Testimonial"
              />
              <ListItem
                className={getAppendingClassName(ENTITY_FOLDERS.permalink)}
                onItemClick={() => handleFolderChange(ENTITY_FOLDERS.permalink)}
                removeSwitch
                label="Permalink"
              />
              <ListItem
                className={getAppendingClassName(ENTITY_FOLDERS.contactUs)}
                onItemClick={() => handleFolderChange(ENTITY_FOLDERS.contactUs)}
                checked={!!settings?.contactUs}
                onToggleChange={() =>
                  handleVisibilityChange(ENTITY_FOLDERS.contactUs)
                }
                label="Contact Us"
              />
              <ListItem
                className={getAppendingClassName(ENTITY_FOLDERS.photos_gallery)}
                onItemClick={() =>
                  handleFolderChange(ENTITY_FOLDERS.photos_gallery)
                }
                checked={!!settings?.gallery}
                onToggleChange={() =>
                  handleVisibilityChange(ENTITY_FOLDERS.photos_gallery)
                }
                label="Photos Gallery"
              />
              <div>
                {!loading.gallery
                  ? !isEmpty(galleryData) &&
                    galleryData.map((galleryItem) => (
                      <div
                        key={galleryItem.id}
                        className={`sub-folder-container d-flex justify-content-between pt-cursor ${
                          selected.subFolder === galleryItem.id
                            ? "selected"
                            : ""
                        } ${isFilesUploading() ? "disabled" : ""}`}
                        onClick={() =>
                          handleFolderChange(
                            ENTITY_FOLDERS.photos_gallery,
                            galleryItem.id
                          )
                        }
                      >
                        <li className="pr10-lh15 medium-emphasis-color">
                          {galleryItem.title}
                        </li>
                        <div className="sub-folder-options">
                          <img
                            className="folder-item-icon mr-2"
                            src={edit}
                            onClick={() =>
                              openEditEntityFolderPopup(galleryItem)
                            }
                            alt=""
                          />
                          <img
                            className="folder-item-icon"
                            src={deleteIcon}
                            onClick={() =>
                              handleFolderDeleteClick(galleryItem.id)
                            }
                            alt=""
                          />
                        </div>
                      </div>
                    ))
                  : null}
                <div
                  className="add-folder-container pt-cursor d-flex flex-column"
                  onClick={toggleAddFolderPopup}
                >
                  <span className="pr10-lh15 medium-emphasis-color">
                    + Add Folder
                  </span>
                </div>
              </div>
            </ul>
          </nav>
        </div>
        {editEntityNamePopup && (
          <EditEntityNamePopup handleClose={toggleEditEntityPopup} />
        )}
        {addFolderPopup && (
          <AddEntityFolderPopup handleClose={toggleAddFolderPopup} />
        )}
        {deleteFolderPopup.open && (
          <DeleteFolderPopup
            heading="DELETE FOLDER"
            text="This folder will be deleted permanently. This cannot be undone."
            handleDelete={handleDeleteSubFolderEntity}
            handleClose={closeDeletePopup}
          />
        )}
        {editEntityFolderPopup.open && (
          <EditEntityFolderPopup
            handleClose={closeEditEntityFolderPopup}
            folderInfo={editEntityFolderPopup.folderInfo}
          />
        )}
      </aside>
    ) : null
  ) : null;
};

export default AdminSidebar;
