import { constant } from "lodash";

export const WEDDING_FOLDERS = {
  invites: "invites",
  register: "register",
  know_the_couple: "know_the_couple",
  itinerary: "itinerary",
  live_feed: "live_feed",
  photos_gallery: "photos_gallery",
  master_invite_list: "master_invite_list",
  games: "games",
  venue: "venue",
  notification: "notification",
  face_recognition: "face_recognition",
  rsvp: "RSVP",
  preferences: "preferences",
  home: "home",
  associates: "associates",
  residents: "residents",
  feed: "feed",
  events: "events",
  policies: "policies",
  permalink: "permalink",
};

export const ENTITY_FOLDERS = {
  profile: "profile",
  know_the_entity: "know_the_entity",
  live_feed: "live_feed",
  photos_gallery: "photos_gallery",
  testimonial: "testimonial",
  permalink: "permalink",
  contactUs: "contactUs",
};

export const SECTION_INVITES_COMPONENTS = {
  upload_invites: "upload_invites",
  invites_list: "invites_list",
  uploading_invites: "uploading_invites",
  master_invite_list: "master_invite_list",
  invite_guest_list: "invite_guest_list",
};

export const SECTION_ENTITY_PROFILE_COMPONENTS = {
  upload_location: "upload_location",
  location_list: "location_list",
  uploading_location: "uploading_location",
};

export const SECTION_VENUE_COMPONENTS = {
  add_venue: "add_venue",
  venue_list: "venue_list",
  edit_venue: "edit_venue",
  master_invite_list: "master_invite_list",
};

export const SECTION_ITINERARY_COMPONENTS = {
  upload_itinerary: "upload_itinerary",
  itinerary_list: "itinerary_list",
  uploading_itinerary: "uploading_itinerary",
  itinerary_guest_list: "itinerary_guest_list",
};

export const GUEST_COMPONENTS = {
  master: "master",
  itinerary: "itinerary",
  invite: "invite",
};

export const SECTION_PHOTO_GALLERY_COMPONENTS = {
  upload_photo_gallery: "upload_photo_gallery",
  photo_gallery_list: "photo_gallery_list",
  uploading_photo_gallery: "uploading_photo_gallery",
};

export const SECTION_GAMES_COMPONENTS = {
  saved_questions: "saved_questions",
  add_questions: "add_questions",
};
export const SECTION_RSVP_COMPONENETS = {
  respond_date: "respond_date",
};
export const SECTION_PEFERENCES_COMPONENTS = {
  saved_questions: "saved_questions",
  add_questions: "add_questions",
};

export const BUCKET_PATH = {
  know_the_host: "know_the_host",
  profile: "profile",
  collection: "collection",
  invites: "invites",
  itinerary: "itinerary",
  photoGallery: "photo_gallery",
  liveFeed: "live_feed",
  games: "games",
  user: "user",
  venue: "venue",
  rsvp: "rsvp",
  preferences: "preferences",
  home: "home",
  residents: "residents",
};

export const AD_BUCKET_PATH = {
  know_the_entity: "know_the_entity",
  profile: "profile",
  logo: "logo",
  entity: "entity",
  event: "event",
  photoGallery: "photo_gallery",
  liveFeed: "live_feed",
  testimonial: "testimonial",
};

export const SECTION_KNOW_THE_HOST_COMPONENTS = {
  list_know_the_host_sections: "list_know_the_host_sections",
  add_know_the_host_sections: "add_know_the_host_sections",
};

export const SECTION_RESIDENTS_COMPONENTS = {
  list_residents_sections: "list_residents_sections",
  add_residents_sections: "add_residents_sections",
};

export const SECTION_NEWS_COMPONENTS = {
  list_news_sections: "list_news_sections",
  add_news_sections: "add_news_sections",
};

export const SECTION_GAMES_USER_COMPONENTS = {
  welcome: "welcome",
  question: "question",
  leaderBoard: "leader_board",
  caution: "caution",
};
export const IMAGE_VARIANT = {
  processed_2000: "processed-2000",
  processed_4000: "processed-4000",
  original: "original",
};

export const SIGN_IN_OPTIONS = {
  emailSignIn: "email-sign-in",
  emailSignUp: "email-sign-up",
  phoneSignIn: "phone-sign-in",
  googleSignIn: "google-sign-in",
};

export const ROLE_CATEGORIES = {
  admin: "a",
  eventManager: "e",
  schoolManager: "s",
  orgManager: "c",
  other: "o",
};

export const EVENT_TYPES = {
  wedding: "w",
  birthday: "b",
  event: "e",
  apartment: "t",
  party: "p",
  conference: "c",
  annualevents: "a",
  sportsevents: "s",
  culturalevents: "d",
  bnichapter: "r",
};

export const ENTITY_TYPES = {
  venue: "v",
  planner: "p",
  other: "o",
};

export const STORAGE_KEYS = {
  userId: "user_id",
  authToken: "auth_token",
  userRole: "user_role",
  userName: "user_name",
  userStudioName: "user_enterprise_name",
  originalUrl: "original_url",
  // fData: 'f_data'
};

export const STD_ERROR_MESSAGE = "Something went wrong!";

export const DEV_CLIENT_URL = "https://staging.pic-sea.com";
export const DEV_SERVER_URL = "https://staging-api.pic-sea.com";

export const LOCAL_CLIENT_URL = "http://localhost:3000";
export const LOCAL_SERVER_URL = "http://localhost:8080";

export const PROD_CLIENT_URL = "https://www.pic-sea.com";
// export const PROD_SERVER_URL = "https://api.pic-sea.com"
export const PROD_SERVER_URL = "https://v2.pic-sea.com";

export const PROD_BASE_IMAGE_URL = "https://d37sv8013ksle6.cloudfront.net";
export const DEV_BASE_IMAGE_URL = "https://d3q04ea0b7lm5j.cloudfront.net";

export const AD_GOOGLE_FORM_URL = "https://forms.gle/eAGwt4vgMBvvMUue8";
