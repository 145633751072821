import "./infoscreen.css";

const ContactUs = () => {
  return (
    <div className="container">
      <h5 className="heading">Contact Us</h5>
      <p className="pr16-lh24">
        For any queries, reach out to{" "}
        <a
          href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=suneel@picsea.in"
          rel="noreferrer"
          target="_blank"
        >
          suneel@picsea.in
        </a>{" "}
        or call +91 9886 283 665
      </p>
      <p className="pr16-lh24">
        Alternatively, share your details{" "}
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSeZbsNpiWiIspIQyIr-RmKds1vBBEpgJtn9H9cw8Td6ctrJ4A/viewform"
          rel="noreferrer"
          target="_blank"
        >
          here
        </a>
        , and we'll get back to you.
      </p>
    </div>
  );
};

export default ContactUs;
