import React, { useState } from "react";
import ButtonDanger from "../../common/button-danger/ButtonDanger";
import ButtonTransparent from "../../common/button-transparent/ButtonTransparent";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";

const PoliciesView = (props) => {
  const { policy, description, link, onDeleteClick, id, handleEdit } = props;

  return (
    <div className="mt-4">
      <div>
        <div className="policy-outer-box">
          <div className="policy-item">
            <span className="textmessage">{policy}</span>
            <div className="divider ht-90pc" />
          </div>
          <div className="policy-item">
            <span className="textmessage">{description}</span>
            <div className="divider ht-90pc" />
          </div>
          <div className="policy-item">
            <span className="textmessage link">{link}</span>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-start">
        <ButtonTransparent
          customStyling={{
            border: "2px solid var(--low-emphasis)",
            borderRadius: "6px",
            padding: "0.6rem 0.10rem",
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
            width: "416px",
            fontWeight: "bold",
            fontSize: "16px",
          }}
          startSpanClassName="icon-medium margin-right-8 vertical-align-bottom"
          onClick={() => handleEdit(id)}
          label={"Edit Policies"}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "22px",
            width: "416px",
          }}
        >
          <ButtonDanger
            label="DELETE"
            className="pb14-lh21 f-grow-1"
            onClick={onDeleteClick}
          />
        </div>
      </div>
    </div>
  );
};

export default PoliciesView;
