import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import {
  getAllEntityFeeds,
  entityLiveFeedLikeOrUnlikePromise,
} from "../../../services/liveFeed.service";

import { STD_ERROR_MESSAGE } from "../../../utils/constant";
import { getImageUrl } from "../../../utils/utils";

import CustomSpinner from "../../common/spinner/CustomSpinner";
import LiveFeedItemEntity from "../live-feed-item/LiveFeedItem";

const EntityUserLiveUpdates = (props) => {
  const [loading, setLoading] = useState(true);
  const { entityId } = props;

  const liveFeedsState = useSelector((state) => state.liveFeedEm);
  const entityState = useSelector((state) => state.entityAd);

  const dispatch = useDispatch();

  const liveFeedsData = liveFeedsState?.response?.data;
  const entityData = entityState.response?.data;

  useEffect(() => {
    if (isEmpty(liveFeedsState?.response)) {
      dispatch(getAllEntityFeeds(entityId));
    }
  }, []);

  useEffect(() => {
    if (!liveFeedsState?.loading && !isEmpty(liveFeedsState?.response)) {
      setLoading(false);
    }

    if (!liveFeedsState?.loading && !isEmpty(liveFeedsState?.error)) {
      setLoading(false);
      toast.error(liveFeedsState.error?.data?.error_data || STD_ERROR_MESSAGE);
    }
  }, [liveFeedsState]);

  function handleLiveFeedLike(id, currLikeState) {
    entityLiveFeedLikeOrUnlikePromise(entityId, id, {
      action: currLikeState ? "dislike" : "like",
    })
      .then((res) => {
        dispatch(getAllEntityFeeds(entityId));
      })
      .catch((err) => {
        toast.error(
          !isEmpty(err?.data?.error_data)
            ? err?.data?.error_data
            : STD_ERROR_MESSAGE
        );
      });
  }

  return (
    <div
      style={{
        maxWidth: "480px",
        marginLeft: "auto",
        marginRight: "auto",
        paddingTop: "1rem",
        paddingBottom: "2rem",
        minHeight: "calc(100vh - 64px - 56px - 40px)",
      }}
    >
      {!loading ? (
        !isEmpty(liveFeedsData) ? (
          liveFeedsData.map((obj) => (
            <LiveFeedItemEntity
              key={obj?.live_feed?.id}
              thumbnail={getImageUrl(entityData?.image?.bucket_path)}
              title={entityData?.name}
              contentText={obj?.live_feed?.text}
              contentImageUrl={getImageUrl(obj?.live_feed?.image?.bucket_path)}
              onLikeClick={() =>
                handleLiveFeedLike(obj?.live_feed?.id, obj?.liked_by_user)
              }
              likesCount={obj?.live_feed?.likes_count}
              likeState={obj?.liked_by_user}
            />
          ))
        ) : (
          <h4 className="text-center pr20-lh32 high-emphasis-color mt-5">
            You have no feeds
          </h4>
        )
      ) : (
        <CustomSpinner />
      )}
    </div>
  );
};

export default EntityUserLiveUpdates;
