import React from 'react'
import ButtonAccent from '../../common/button-accent/ButtonAccent'
import InputBox from '../../common/input-box/InputBox'
import cancelIcon from '../../../assets/icon/cancel.svg';
import PopUp from '../pop-up/PopUp';

const SingleInputPopUp = (props) => {

    return (
        <PopUp overlayClassName={props.overlayClassName}>
            <img className="close-icon pt-cursor" onClick={props.handleClose} src={cancelIcon} alt=""></img>
            <h1 className="pb18-lh32 text-center mb-3">{props.heading}</h1>
            <form onSubmit={props.onSubmit}>
                <InputBox type="text" placeholder={props.inputPlaceholder} name={props.inputName} 
                value={props.inputValue} onChange={props.inputOnChange} onBlur={props.inputOnBlur} errorMsg={props.inputErrorMsg}/>
                <ButtonAccent type="submit" label={props.btnLabel} className="pb14-lh21 w-100"/>
            </form>
        </PopUp>
    )
}

export default SingleInputPopUp
