import { flatten, isEmpty, size } from "lodash";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import errorInfoIcon from "../../../assets/icon/info-red.svg";
import { Field, FieldArray, Form, Formik } from "formik";
import CollectionHeaderPartner from "../collection-header-partner/CollectionHeaderPartner";
import * as Yup from "yup";
import ButtonAccent from "../../common/button-accent/ButtonAccent";
import {
  createPoliciesPromise,
  fetchPolicies,
} from "../../../services/policies.service";
import { toast } from "react-toastify";
import { STD_ERROR_MESSAGE } from "../../../utils/constant";
import "./add-policies.css";

const AddPolicies = (props) => {
  const params = useParams();
  const [submitting, setSubmitting] = useState(false);

  const policiesState = useSelector((state) => state.policiesAd);
  const dispatch = useDispatch();

  const policiesData = policiesState?.response?.data;

  const initialValues = {
    policy_name: "",
    description: "",
    link: "",
  };

  const validationSchema = Yup.object().shape({
    policy_name: Yup.string().required("Please enter the policy name"),
    description: Yup.string().required("Please enter the description"),
    link: Yup.string().required("Please enter the link"),
  });

  function onSubmit(values, { resetForm }) {
    if (!submitting) {
      setSubmitting(true);
      console.log(values);
      return createPoliciesPromise(values, params.collectionId)
        .then((res) => {
          setSubmitting(false);
          toast.success("Posted");
          dispatch(fetchPolicies(params.collectionId));
          resetForm();
        })
        .catch((err) => {
          setSubmitting(false);
          toast.error(err?.data || STD_ERROR_MESSAGE);
        });
    }
  }

  return (
    <main
      style={{ maxWidth: "850px", marginLeft: "auto", marginRight: "auto" }}
    >
      <CollectionHeaderPartner title="Add Policies" />
      <div className="heading-box">
        <div className="heading-item">
          <span>Policy</span>
          <div className="divider" />
        </div>
        <div className="heading-item">
          <span>Description</span>
          <div className="divider" />
        </div>
        <div className="heading-item">
          <span>Link</span>
        </div>
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => (
          <Form>
            <div className="edit-outer-box">
              <Field name="policy_name">
                {(props) => {
                  const { field, form, meta } = props;
                  return (
                    <div className="edit-inner-box">
                      <div>
                        <textarea
                          className="edit-item"
                          placeholder="Add Policy Name"
                          {...field}
                        />
                        {meta.touched && meta.error ? (
                          <div className="d-flex align-items-center justify-content-start mt-2 mb-2">
                            <img src={errorInfoIcon} alt="" />
                            <span className="margin-left-5 pr10-lh15 error-color">
                              {meta.error}
                            </span>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  );
                }}
              </Field>
              <div className="divider ht-80pc"></div>
              <Field name="description">
                {(props) => {
                  const { field, form, meta } = props;
                  return (
                    <div className="edit-inner-box">
                      <div>
                        <textarea
                          className="edit-item"
                          placeholder="Add Description"
                          {...field}
                        />
                        {meta.touched && meta.error ? (
                          <div className="d-flex align-items-center justify-content-start mt-2 mb-2">
                            <img src={errorInfoIcon} alt="" />
                            <span className="margin-left-5 pr10-lh15 error-color">
                              {meta.error}
                            </span>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  );
                }}
              </Field>
              <div className="divider ht-80pc"></div>
              <Field name="link">
                {(props) => {
                  const { field, form, meta } = props;
                  return (
                    <div className="edit-inner-box">
                      <div>
                        <textarea
                          className="edit-item"
                          placeholder="Add Link"
                          {...field}
                        />
                        {meta.touched && meta.error ? (
                          <div className="d-flex align-items-center justify-content-start mt-2 mb-2">
                            <img src={errorInfoIcon} alt="" />
                            <span className="margin-left-5 pr10-lh15 error-color">
                              {meta.error}
                            </span>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  );
                }}
              </Field>
            </div>

            <ButtonAccent
              type="submit"
              className="button-w100-mt21 pb14-lh21"
              label="Add to table"
              disabled={submitting}
              loading={submitting}
            />
          </Form>
        )}
      </Formik>
    </main>
  );
};

export default AddPolicies;
