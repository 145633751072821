import { useEffect, useState } from "react";
import ring1 from "../../../assets/image/ring1.png";
import * as Yup from "yup";
import "./UserPreference.css";
import "./queries.css";
import { Field, FieldArray, Form, Formik } from "formik";
import ButtonAccent from "../../common/button-accent/ButtonAccent";
import { addTravelDetailsPromise } from "../../../services/preferences.service";
import { useDispatch, useSelector } from "react-redux";
import { getGroupByOwnerId } from "../../../services/group.service";
import { toast } from "react-toastify";
import { STD_ERROR_MESSAGE } from "../../../utils/constant";
import DanceCard from "../../../assets/image/Frame.png";
import { useMediaQuery } from "@mui/material";
import { isEmpty } from "lodash";
import { getRSVPDate } from "../../../services/rsvpDate.service";
import arrowRight from "../../../assets/icon/arrow_right.svg";

export default function TransportDetails({ collectionId, groupId, groupData }) {
  const isMobileOrTablet = useMediaQuery("(max-width: 599px)");
  const minHeight = isMobileOrTablet ? 56 + 56 + 40 : 64 + 56 + 40;
  const [selectedOption, setSelectedOption] = useState("Flight");
  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const [submitting, setSubmitting] = useState(false);
  const [skip, setSkip] = useState(false);
  const [selectedcheckbox, setSelectedcheckbox] = useState(false);
  const handleChange = () => {
    setSelectedcheckbox((ps) => !ps);
  };
  const dispatch = useDispatch();
  const initialValues = {
    transport_details: {
      mode: "",
      station_name: "",
      time_of_arrival: "",
      transportation_details: "",
      need_pickUp_service: false,
    },
  };

  const collectionState = useSelector((state) => state.collectionEm);

  const date = collectionState.response?.data.start_date;

  const validationSchema = Yup.object().shape({
    transport_details: Yup.object().shape({
      mode: Yup.string().required("Please select a mode of transport"),
      station_name: Yup.string().required(
        "e.g. Airport&terminal/Railway station name"
      ),
      time_of_arrival: Yup.string().required(
        "enter time of landing or arrival"
      ),
      transportation_details: Yup.string().required(
        "Flightno. / Train name / Bus service"
      ),
      need_pickUp_service: Yup.boolean().required(),
    }),
  });

  const onSubmit = (values) => {
    if (!submitting) {
      setSubmitting(true);
      const payload = {
        mode: values.transport_details.mode,
        station_name: values.transport_details.station_name,
        time_of_arrival: values.transport_details.time_of_arrival,
        transportation_details: values.transport_details.transportation_details,
        need_pickUp_service: values.transport_details.need_pickUp_service,
      };
      console.log("PAYLOAD:", payload);
      addTravelDetailsPromise(collectionId, groupId, payload)
        .then((res) => {
          dispatch(getGroupByOwnerId(collectionId));
          setSubmitting(false);
        })
        .catch((err) => {
          toast.error(err.data?.error_data || STD_ERROR_MESSAGE);
          setSubmitting(false);
        });
    }
  };

  let screen;
  if (!groupData?.event_user_group.transport_response && !skip) {
    screen = (
      <>
        <div className="d-flex justify-content-center">
          <img
            alt=""
            src={ring1}
            style={{
              width: "6rem",
              height: "6rem",
            }}
          />
        </div>
        <div className="skip">
          <div
            style={{
              display: "flex",
              gap: "0.5rem",
              border: "1px solid black",
              borderRadius: "52px",
              padding: "10px 25px",
              marginRight: "0px",
            }}
          >
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setSkip(true);
              }}
              style={{
                color: "black",
              }}
            >
              Skip Now
              <img
                src={arrowRight}
                style={{
                  width: "24px",
                  height: "24px",
                  top: "24px",
                  left: "24px",
                  padding: "6.5px, 3px, 6.5px, 3px",
                }}
              />
            </a>
          </div>
        </div>

        <h1
          className="merriweather-black-fs20-lh36 primary-color mt-2"
          style={{
            textAlign: "center",
            height: "6rem",
          }}
        >
          Share Your Transport Details with us
        </h1>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {(formik) => (
            <Form>
              <FieldArray name="transport_details">
                {(fieldArrayprops) => {
                  const { form } = fieldArrayprops;
                  const { values } = form;
                  return (
                    <>
                      <Field name="transport_details.mode">
                        {(props) => {
                          const { field, form, meta } = props;
                          return (
                            <div className="tranportMode" {...field}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  fontSize: "15px",
                                }}
                              >
                                <label className="mode">Mode</label>
                                <label className="radio">
                                  <input
                                    className="radioInput"
                                    type="radio"
                                    value="f"
                                    name="transport_details.mode"
                                    checked={selectedOption === "f"}
                                    onChange={handleRadioChange}
                                  />
                                  <div className="radioRadio"></div>
                                  Flight
                                </label>
                                <label className="radio">
                                  <input
                                    className="radioInput"
                                    type="radio"
                                    name="transport_details.mode"
                                    value="t"
                                    checked={selectedOption === "t"}
                                    onChange={handleRadioChange}
                                  />
                                  <div className="radioRadio"></div>
                                  Train
                                </label>
                                <label className="radio">
                                  <input
                                    className="radioInput"
                                    type="radio"
                                    name="transport_details.mode"
                                    value="b"
                                    checked={selectedOption === "b"}
                                    onChange={handleRadioChange}
                                  />
                                  <div className="radioRadio"></div>
                                  Bus
                                </label>
                                <label className="radio">
                                  <input
                                    className="radioInput"
                                    type="radio"
                                    name="transport_details.mode"
                                    value="c"
                                    checked={selectedOption === "c"}
                                    onChange={handleRadioChange}
                                  />
                                  <div className="radioRadio"></div>
                                  Car
                                </label>
                              </div>
                            </div>
                          );
                        }}
                      </Field>
                      <div className="transportStationName">
                        <div>
                          <label className="staionStyle">
                            Airport/Station name
                          </label>
                        </div>
                        <div>
                          <Field name="transport_details.station_name">
                            {(props) => {
                              const { field, form, meta } = props;
                              return (
                                <>
                                  <input
                                    style={{
                                      width: "100%",
                                      height: "2.7rem",
                                      border: "2px solid  #F2F2F2",
                                      borderRadius: "2px",
                                      backgroundColor: "#F2F2F2",
                                    }}
                                    type="text"
                                    placeholder="e.g. Airport&terminal/Railway station name"
                                    errormsg={
                                      meta.touched && meta.error
                                        ? meta.error
                                        : ""
                                    }
                                    {...field}
                                  />
                                </>
                              );
                            }}
                          </Field>
                        </div>
                      </div>
                      <div className="transportStationName">
                        <div>
                          <label className="staionStyle">Time of arrival</label>
                        </div>
                        <div>
                          <Field name="transport_details.time_of_arrival">
                            {(props) => {
                              const { field, meta } = props;

                              return (
                                <input
                                  style={{
                                    width: "100%",
                                    height: "2.7rem",
                                    border: "2px solid  #F2F2F2",
                                    borderRadius: "2px",
                                    backgroundColor: "#F2F2F2",
                                  }}
                                  {...field}
                                  type="text"
                                  placeholder="enter time of landing or arrival"
                                  errormsg={
                                    meta.touched && meta.error ? meta.error : ""
                                  }
                                />
                              );
                            }}
                          </Field>
                        </div>
                      </div>
                      <div className="transportStationName">
                        <div>
                          <label className="staionStyle">
                            Flight / Train / Bus details
                          </label>
                        </div>
                        <div>
                          <Field name="transport_details.transportation_details">
                            {(props) => {
                              const { field, meta } = props;
                              const { onChange, ...rest } = field;
                              return (
                                <>
                                  <input
                                    style={{
                                      width: "100%",
                                      height: "2.7rem",
                                      border: "2px solid  #F2F2F2",
                                      borderRadius: "2px",
                                      backgroundColor: "#F2F2F2",
                                    }}
                                    type="text"
                                    {...field}
                                    placeholder="Flightno. / Train name / Bus service"
                                    errormsg={
                                      meta.touched && meta.error
                                        ? meta.error
                                        : ""
                                    }
                                  />
                                </>
                              );
                            }}
                          </Field>
                        </div>
                      </div>
                      <Field name="transport_details.need_pickUp_service">
                        {(props) => {
                          const { field, meta, form } = props;
                          return (
                            <div className="pick-up" {...field}>
                              <input
                                type="checkbox"
                                name="transport_details.need_pickUp_service"
                                className="custom-checkbox"
                                checked={selectedcheckbox}
                                onChange={handleChange}
                              />
                              <label
                                style={{
                                  alignContent: "normal",
                                }}
                              >
                                Need a pick-up service
                              </label>
                            </div>
                          );
                        }}
                      </Field>
                      <div
                        className="d-flex justify-content-between align-items-center"
                        style={{
                          marginTop: "1rem",
                          backgroundColor: "rgba(255, 255, 255, 0.75)",
                        }}
                      >
                        <ButtonAccent
                          type="submit"
                          className="button-w100-mt21 pb14-lh21"
                          label="SAVE INFORMATION"
                          disabled={submitting}
                        />
                      </div>
                    </>
                  );
                }}
              </FieldArray>
            </Form>
          )}
        </Formik>
      </>
    );
  } else {
    screen = (
      <>
        <div className="d-flex justify-content-center">
          <div
            style={{
              backgroundImage: `url(${DanceCard})`,
              minHeight: "calc(100vh - 150px);",
              width: "458px",
            }}
          >
            <h3
              style={{
                fontFamily: "Poppins",
                fontSize: "18px",
                fontWeight: " 600",
                lineHeight: "32px",
                letterSpacing: " 0em",
                textAlign: "center",
                color: "white",
                marginTop: "28rem",
              }}
            >
              Preferences captured successfully
            </h3>
            <h4
              style={{
                fontFamily: "Poppins",
                fontSize: "18px",
                fontWeight: " 600",
                lineHeight: "32px",
                letterSpacing: " 0em",
                textAlign: "center",
                color: "white",
                marginTop: "2rem",
              }}
            >
              Looking forward to host you
            </h4>
            <h4
              style={{
                fontFamily: "Poppins",
                fontSize: "18px",
                fontWeight: " 600",
                lineHeight: "32px",
                letterSpacing: " 0em",
                textAlign: "center",
                color: "white",
              }}
            >
              {new Date(date).toDateString()}
            </h4>
            {!groupData.event_user_group.transport_response && (
              <div className="mt-4 d-flex justify-content-center">
                <div
                  style={{
                    display: "flex",
                    gap: "0.5rem",
                    border: "1px solid black",
                    borderRadius: "52px",
                    padding: "10px 25px",
                    marginBottom: "5px",
                  }}
                >
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      setSkip(false);
                    }}
                    style={{
                      color: "white",
                    }}
                  >
                    Add Travel Details
                    <img
                      src={arrowRight}
                      style={{
                        width: "24px",
                        height: "24px",
                        top: "24px",
                        left: "24px",
                        padding: "6.5px, 3px, 6.5px, 3px",
                      }}
                    />
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }

  return <>{screen}</>;
}
