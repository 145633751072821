import React, { useState } from "react";
import errorInfoIcon from "../../../assets/icon/info-red.svg";
import {
  fetchPolicies,
  updatePoliciesBySequencePromise,
} from "../../../services/policies.service";
import { STD_ERROR_MESSAGE } from "../../../utils/constant";
import { isEmpty } from "lodash";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import "./policies-edit.css";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import ButtonAccent from "../../common/button-accent/ButtonAccent";
import ButtonPrimary from "../../common/button-primary/ButtonPrimary";

const PoliciesEdit = (props) => {
  const { policy, description, link, handleCancel, sequence, id } = props;

  const params = useParams();
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);

  const initialValues = {
    policy_name: policy,
    description: description,
    link: link,
  };

  const validationSchema = Yup.object().shape({
    policy_name: Yup.string().required("Please enter the policy"),
    description: Yup.string().required("Please enter the description"),
    link: Yup.string().required("Please enter the link"),
  });

  const onSubmit = (values) => {
    setSubmitting(true);
    updatePoliciesBySequencePromise(values, params.collectionId, sequence)
      .then((res) => {
        setSubmitting(false);
        dispatch(fetchPolicies(params.collectionId));
        handleCancel(id);
      })
      .catch((err) => {
        setSubmitting(false);
        toast.error(
          !isEmpty(err?.data?.error_data)
            ? err?.data?.error_data
            : STD_ERROR_MESSAGE
        );
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {(formik) => (
        <Form>
          <div className="mt-4">
            <div>
              <div className="policy-outer-box">
                <Field name="policy_name">
                  {(props) => {
                    const { field, form, meta } = props;
                    return (
                      <>
                        <div className="policy-item">
                          <div>
                            <textarea
                              className="policy-text"
                              type="text"
                              {...field}
                              // errorMsg={meta.touched && meta.error ? meta.error : ""}
                            />
                            {meta.touched && meta.error ? (
                              <div className="d-flex align-items-center justify-content-start mt-2 mb-2">
                                <img src={errorInfoIcon} alt="" />
                                <span className="margin-left-5 pr10-lh15 error-color">
                                  {meta.error}
                                </span>
                              </div>
                            ) : null}
                          </div>
                          <div className="divider ht-90pc" />
                        </div>
                      </>
                    );
                  }}
                </Field>
                <Field name="description">
                  {(props) => {
                    const { field, form, meta } = props;
                    return (
                      <>
                        <div className="policy-item">
                          <div>
                            <textarea
                              className="policy-text"
                              type="text"
                              {...field}
                            />
                            {meta.touched && meta.error ? (
                              <div className="d-flex align-items-center justify-content-start mt-2 mb-2">
                                <img src={errorInfoIcon} alt="" />
                                <span className="margin-left-5 pr10-lh15 error-color">
                                  {meta.error}
                                </span>
                              </div>
                            ) : null}
                          </div>
                          <div className="divider ht-90pc" />
                        </div>
                      </>
                    );
                  }}
                </Field>
                <Field name="link">
                  {(props) => {
                    const { field, form, meta } = props;
                    return (
                      <>
                        <div className="policy-item">
                          <div>
                            <textarea
                              className="policy-text"
                              type="text"
                              {...field}
                              // errorMsg={meta.touched && meta.error ? meta.error : ""}
                            />
                            {meta.touched && meta.error ? (
                              <div className="d-flex align-items-center justify-content-start mt-2 mb-2">
                                <img src={errorInfoIcon} alt="" />
                                <span className="margin-left-5 pr10-lh15 error-color">
                                  {meta.error}
                                </span>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </>
                    );
                  }}
                </Field>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-start">
              <ButtonAccent
                type="submit"
                className="button-w49-mt21"
                label="Update"
                disabled={submitting}
                loading={submitting}
              />
              <ButtonPrimary
                className="button-w49-mt21"
                label="Cancel"
                onClick={() => handleCancel(id)}
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default PoliciesEdit;
