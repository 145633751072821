import PatternBackground from "../../../assets/image/pattern_bg.png";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { isEmpty, times } from "lodash";
import { getAllInvitesInCollection } from "../../../services/invites.service.js";
import { toast } from "react-toastify";
import { STD_ERROR_MESSAGE } from "../../../utils/constant.js";
import "./user-rsvp.css";
import "./queries.css";
import { Form, Field, FieldArray, Formik, useFormik } from "formik";
import {
  addRSVPPromise,
  getGroupByOwnerId,
} from "../../../services/group.service.js";
import CustomSpinner from "../../common/spinner/CustomSpinner.js";
import ButtonAccent from "../../common/button-accent/ButtonAccent.js";
import UserPreference from "../user-preference/UserPreference.js";
import { getRSVPDate } from "../../../services/rsvpDate.service.js";
import AccentLoadingButton from "../../common/v2/loading-button/AccentLoadingButton.jsx";

const UserRSVP = (props) => {
  const isMobileOrTablet = useMediaQuery("(max-width: 599px)");
  const minHeight = isMobileOrTablet ? 56 + 56 + 40 : 64 + 56 + 40;

  const [loading, setLoading] = useState(true);
  const [dateLoading, setDateLoading] = useState(true);
  const [arriving, setArriving] = useState(false);

  const [submitting, setSubmitting] = useState(false);
  const invitesState = useSelector((state) => state.invitesEm);
  const invitesData = invitesState.response?.data;
  const dispatch = useDispatch();
  const groupState = useSelector((state) => state.groupEm);
  const rsvpDateState = useSelector((state) => state.rsvpDateEm);
  const groupData = groupState.response?.data;
  const params = useParams();
  const [date, setDate] = useState("");

  console.log("invites:", invitesData);

  const initialValues = {
    rsvp: invitesData
      ?.sort((a, b) => a.sequence - b.sequence)
      .map((invite) => ({
        sequence: invite.sequence,
        name: invite.image?.image_name,
        accept: false,
        guestType: [],
      })),
    event_user_group_kids: [
      {
        name: "",
        age: 0,
      },
    ],
  };

  function onSubmit(values) {
    if (!submitting) {
      setSubmitting(true);
      const guestTypePayload = {
        ...groupData,
        group_id: groupData.event_user_group.id,
        event_group_invites: groupData.event_group_invites.map((i) => {
          return {
            ...i,
            rsvp: values.rsvp
              .filter((r) => r.sequence == i.sequence)[0]
              .guestType.includes(i.guest_type),
          };
        }),
        event_user_group_kids: values.event_user_group_kids.map((k) => {
          return {
            group_id: groupData.event_user_group.id,
            name: k.name,
            age: +k.age,
          };
        }),
      };

      addRSVPPromise(params.collectionId, guestTypePayload)
        .then((response) => {
          dispatch(getGroupByOwnerId(params.collectionId));
          setSubmitting(false);
        })
        .catch((err) => {
          setSubmitting(false);
          toast.error(
            !isEmpty(err?.data?.error_data)
              ? err?.data?.error_data
              : STD_ERROR_MESSAGE
          );
        });
    }
  }

  useEffect(() => {
    if (isEmpty(invitesState.response)) {
      dispatch(getAllInvitesInCollection(params.collectionId));
    }
    if (isEmpty(rsvpDateState.response)) {
      dispatch(getRSVPDate(params.collectionId));
    }
  }, []);

  useEffect(() => {
    if (!invitesState.loading && !isEmpty(invitesState.response)) {
      setLoading(false);
    }
    if (!invitesState.loading && !isEmpty(invitesState.error)) {
      setLoading(false);
      toast.error(invitesState.error?.error_data || STD_ERROR_MESSAGE);
    }
  }, [invitesState]);

  useEffect(() => {
    if (!rsvpDateState.loading && !isEmpty(rsvpDateState.response)) {
      setDateLoading(false);
      setDate(
        new Date(rsvpDateState?.response?.data.respond_date).toDateString()
      );
    }
    if (!rsvpDateState.loading && !isEmpty(rsvpDateState.error)) {
      setDateLoading(false);
      toast.error(rsvpDateState.error?.error_data || STD_ERROR_MESSAGE);
    }
  }, [rsvpDateState]);
  console.log("Date", date);

  function checkDisabled(sequence, guestType) {
    return (
      groupData.event_group_invites
        .filter((r) => r.sequence == sequence)
        .filter((r) => r.guest_type == guestType).length == 0
    );
  }

  function checkCount(guestType) {
    return (
      groupData.event_user_group_config
        .filter((r) => r.guest_type == guestType)
        .map((r) => r.count) -
      groupData.event_user_group_members.filter(
        (r) => r.guest_type == guestType
      ).length
    );
  }

  function checkCountRadio(guestType) {
    return groupData.event_user_group_config
      .filter((r) => r.guest_type == guestType)
      .map((r) => r.count);
  }

  return (
    <>
      {loading && <CustomSpinner />}
      {!loading && !groupData.event_user_group.rsvp_response && (
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          {(formik) => (
            <Form>
              <div
                className="main"
                style={{
                  backgroundImage: `url(${PatternBackground})`,
                  minHeight: `calc(100vh - ${minHeight}px)`,
                }}
              >
                <div className="pt-5 px-4 container">
                  <div className="rsvpbody">
                    <h3 className="rsvp">R.S.V.P</h3>
                    {date && (
                      <h6
                        className={`${
                          isMobileOrTablet ? "p1" : "p1"
                        } high-emphasis-color`}
                      >
                        Please respond by {date}
                      </h6>
                    )}
                    <h5 className="invitee">
                      Mr/Mrs{" "}
                      {
                        groupData.user
                          .filter(
                            (c) => c.id == groupData.event_user_group.owner_id
                          )
                          .map((c) => c.name)[0]
                      }
                    </h5>
                    <div className="userGroup">
                      <div className="subGroup">
                        {!loading &&
                          invitesData
                            .sort((a, b) => a.sequence - b.sequence)
                            .map((inviteData, position) => (
                              <div key={inviteData.sequence}>
                                <div className="invite">
                                  {inviteData.image?.image_name.replace(
                                    ".jpg",
                                    ""
                                  )}
                                  <div className="inviteInfo">
                                    <Field name={`rsvp[${position}].accept`}>
                                      {(props) => {
                                        const {
                                          field,
                                          form: { values, setFieldValue },
                                          meta,
                                        } = props;
                                        const { onChange, ...rest } = field;
                                        return (
                                          <>
                                            <label
                                              htmlFor={`radio-accept-${position}`}
                                              className="radio"
                                            >
                                              <input
                                                id={`radio-accept-${position}`}
                                                className="radioInput"
                                                type="radio"
                                                name={`radio-${position}`}
                                                value={inviteData.accept}
                                                onChange={(e) => {
                                                  onChange(e);
                                                  setFieldValue(
                                                    `rsvp[${position}].accept`,
                                                    true
                                                  );
                                                }}
                                                {...rest}
                                              />
                                              <div className="radioRadio"></div>
                                              Accept
                                            </label>
                                            <label
                                              htmlFor={`radio-decline-${position}`}
                                              className="radio"
                                            >
                                              <input
                                                id={`radio-decline-${position}`}
                                                className="radioInput"
                                                type="radio"
                                                name={`radio-${position}`}
                                                value={!inviteData.accept}
                                                onChange={(e) => {
                                                  onChange(e);
                                                  setFieldValue(
                                                    `rsvp[${position}].accept`,
                                                    false
                                                  );
                                                }}
                                                {...rest}
                                              />
                                              <div className="radioRadio"></div>
                                              Decline
                                            </label>
                                          </>
                                        );
                                      }}
                                    </Field>
                                  </div>
                                </div>
                                <FieldArray
                                  name={`rsvp[${position}].guestType`}
                                >
                                  {(fieldArrayProps) => {
                                    const { push, form, remove } =
                                      fieldArrayProps;
                                    const { values } = form;
                                    const { rsvp } = values;
                                    return (
                                      <div className="arriving">
                                        <label className="guestType">
                                          Arriving
                                        </label>
                                        <div className="guest">
                                          <input
                                            type="checkbox"
                                            className="checkboxes"
                                            disabled={
                                              !values.rsvp[`${position}`].accept
                                            }
                                            onChange={(e) =>
                                              e.target.checked
                                                ? push("s")
                                                : remove(
                                                    rsvp[
                                                      position
                                                    ].guestType.findIndex(
                                                      (gt) => gt == "s"
                                                    )
                                                  )
                                            }
                                          />
                                          <label className="guestType">
                                            Self
                                          </label>
                                          <input
                                            type="checkbox"
                                            className="checkboxes"
                                            disabled={
                                              !values.rsvp[`${position}`].accept
                                            }
                                            onChange={(e) =>
                                              e.target.checked
                                                ? push("p")
                                                : remove(
                                                    rsvp[
                                                      position
                                                    ].guestType.findIndex(
                                                      (gt) => gt == "p"
                                                    )
                                                  )
                                            }
                                          />
                                          <label className="guestType">
                                            Partner
                                          </label>
                                          <input
                                            type="checkbox"
                                            className="checkboxes"
                                            disabled={
                                              !values.rsvp[`${position}`].accept
                                            }
                                            onChange={(e) =>
                                              e.target.checked
                                                ? push("q")
                                                : remove(
                                                    rsvp[
                                                      position
                                                    ].guestType.findIndex(
                                                      (gt) => gt == "q"
                                                    )
                                                  )
                                            }
                                          />
                                          <label className="guestType">
                                            Parents
                                          </label>
                                          <input
                                            type="checkbox"
                                            className="checkboxes"
                                            disabled={
                                              !values.rsvp[`${position}`].accept
                                            }
                                            onChange={(e) =>
                                              e.target.checked
                                                ? push("k")
                                                : remove(
                                                    rsvp[
                                                      position
                                                    ].guestType.findIndex(
                                                      (gt) => gt == "k"
                                                    )
                                                  )
                                            }
                                          />
                                          <label className="guestType">
                                            Kids
                                          </label>
                                        </div>
                                      </div>
                                    );
                                  }}
                                </FieldArray>
                              </div>
                            ))}
                        {/* <div className="self">
                          <input type="checkbox" className="checkboxes" />
                          <label className="guestType">Self</label>
                        </div> */}
                        <div className="self">
                          {/* <input type="checkbox" className="checkboxes" /> */}
                          <label className="guestType">Partner</label>
                        </div>
                        <div className="guestInfo">
                          <input
                            type="name"
                            placeholder="Enter Name"
                            className="inputBox"
                            value={
                              groupData.event_user_group_members
                                .filter((c) => c.guest_type == "p")
                                .map((p) =>
                                  groupData.user
                                    .filter((c) => c.id == p.user_id)
                                    .map((c) => c.name)
                                )[0]
                            }
                          />
                          <input
                            type="text"
                            placeholder="Mobile Number"
                            className="inputBox"
                            value={
                              groupData.event_user_group_members
                                .filter((c) => c.guest_type == "p")
                                .map((p) =>
                                  groupData.user
                                    .filter((c) => c.id == p.user_id)
                                    .map((c) => c.mobile)
                                )[0]
                            }
                          />
                        </div>
                        <div className="parent">
                          <div className="self">
                            {/* <input type="checkbox" className="checkboxes" /> */}
                            <label className="guestType">Parents</label>
                          </div>
                          {/* <div className="guestCount">
                            {times(checkCountRadio("q"), (i) => (
                              <label key={i} className="radio">
                                <input type="radio" className="radioInput" />
                                <div className="radioRadio"></div>
                                {i + 1}
                              </label>
                            ))}
                          </div> */}
                        </div>
                        {groupData.event_user_group_members
                          .filter((g) => g.guest_type == "q")
                          .map(
                            (q) =>
                              groupData.user.filter((c) => c.id == q.user_id)[0]
                          )
                          .map((u) => (
                            <div className="guestInfo">
                              <input
                                type="name"
                                value={u.name}
                                placeholder="Enter Name"
                                className="inputBox"
                              />
                              <input
                                type="text"
                                value={u.mobile}
                                placeholder="Mobile Number"
                                className="inputBox"
                              />
                            </div>
                          ))}
                        {times(checkCount("q"), (i) => (
                          <div className="guestInfo">
                            <input
                              type="name"
                              placeholder="Enter Name"
                              className="inputBox"
                            />
                            <input
                              type="text"
                              placeholder="Mobile Number"
                              className="inputBox"
                            />
                          </div>
                        ))}

                        <div className="kids">
                          <div className="self">
                            {/* <input type="checkbox" className="checkboxes" /> */}
                            <label className="guestType">Kids</label>
                          </div>
                          {/* <div className="guestCountKids">
                            {times(checkCountRadio("k"), (i) => (
                              <label className="radio">
                                <input type="radio" className="radioInput" />
                                <div className="radioRadio"></div>
                                {i + 1}
                              </label>
                            ))}
                          </div> */}
                        </div>
                        {times(checkCount("k"), (i) => (
                          <div className="guestInfo">
                            <Field name={`event_user_group_kids[${i}].name`}>
                              {(props) => {
                                const { field, meta } = props;
                                return (
                                  <input
                                    type="text"
                                    name={`event_user_group_kids[${i}].name`}
                                    placeholder="Enter Name"
                                    className="inputBox"
                                    {...field}
                                  />
                                );
                              }}
                            </Field>
                            <Field name={`event_user_group_kids[${i}].age`}>
                              {(props) => {
                                const { field, meta } = props;
                                return (
                                  <input
                                    type="number"
                                    placeholder="Enter Age"
                                    name={`event_user_group_kids[${i}].age`}
                                    className="inputBox"
                                    {...field}
                                  />
                                );
                              }}
                            </Field>
                          </div>
                        ))}
                      </div>
                    </div>
                    <ButtonAccent
                      type="submit"
                      className="button-w100-mt21 pb14-lh25"
                      label="Confirm and proceed"
                      disabled={submitting}
                    />{" "}
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
      {!loading && groupData.event_user_group.rsvp_response && (
        <UserPreference />
      )}
    </>
  );
};
export default UserRSVP;
