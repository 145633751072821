import {
  SIGN_UP_PHOTOGRAPHER_REQUEST,
  SIGN_UP_PHOTOGRAPHER_SUCCESS,
  SIGN_UP_PHOTOGRAPHER_FAILURE,
  CLEAR_AUTH_STATES,
  SIGN_IN_PHOTOGRAPHER_REQUEST,
  SIGN_IN_PHOTOGRAPHER_SUCCESS,
  SIGN_IN_PHOTOGRAPHER_FAILURE,
} from "./partnerAuthentication.types";

const initialState = {
  loading: false,
  response: {},
  error: null,
};

const PhotographerAuthenticationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGN_UP_PHOTOGRAPHER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case SIGN_UP_PHOTOGRAPHER_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };

    case SIGN_UP_PHOTOGRAPHER_FAILURE:
      return {
        ...state,
        loading: false,
        response: {},
        error: action.payload,
      };
    case CLEAR_AUTH_STATES:
      return {
        ...state,
        loading: false,
        response: {},
        error: null,
      };

    case SIGN_IN_PHOTOGRAPHER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case SIGN_IN_PHOTOGRAPHER_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };

    case SIGN_IN_PHOTOGRAPHER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default PhotographerAuthenticationReducer;
