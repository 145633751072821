import { combineReducers } from "redux";

import PhotographerAuthenticationReducer from "./partner-authentication/partnerAuthentication.reducer";
import UploadFilesReducer from "./upload-files/uploadFiles.reducer";

//v2
import collectionsEmReducer from "./collections/collections.reducer";
import collectionEmReducer from "./collection/collection.reducer";
import knowTheHostEmReducer from "./know-the-host/knowTheHost.reducer";
import invitesEmReducer from "./invites/invites.reducer";
import readProfileReducer from "./profile-read/readProfile.reducer";
import itineraryEmReducer from "./itinerary/itinerary.reducer";
import galleryEmReducer from "./gallery/gallery.reducer";
import galleryFoldersEmReducer from "./gallery-folders/galleryFolders.reducer";
import myPhotosGalleryFoldersEmReducer from "./gallery-folders/myPhotosGalleryFolders.reducer";
import liveFeedEmReducer from "./live-feed/liveFeed.reducer";
import guestInvitesEmReducer from "./guest-invites/guestInvites.reducer";
import fetchQuestionsEmReducer from "./games/games.reducer";
import fetchLeaderBoardUserReducer from "./leader-board-user/leaderBoardUser.reducer";
import fetchVenueEmReducer from "./venue/venue.reducer";
import fetchEntityProfileAdReducer from "./entity-location/entity.location.reducer";
import entitiesAdReducer from "./entities/entities.reducer";
import entityAdReducer from "./entity/entity.reducer";
import fetchTestimonialAdReducer from "./testimonials/testimonial.reducer";
import permalinkAdReducer from "./permalink/permalink.reducer";
import fetchGroupEmReducer from "./group/group.reducer";
import fetchPreferenceQuestionsEmReducer from "./prefrences/preferences.reducer";
import contactsEmReducer from "./contacts/contact.reducer";
import fetchRSVPDateEmReducer from "./rsvpDate/rsvpDate.reducer";
import orgCollectionsEmReducer from "./org-collections/orgCollections.reducer";
import fetchResidentsAdReducer from "./residents/residents.reducer";
import fetchNewsAdReducer from "./news/news.reducer";
import fetchPoliciesAdReducer from "./policies/policies.reducer";
import eventPermalinkEmReducer from "./event-permalink/eventPermalink.reducer";

const appReducer = combineReducers({
  authenticatePhotographer: PhotographerAuthenticationReducer,
  uploadFiles: UploadFilesReducer,

  //v2
  //event manager
  collectionsEm: collectionsEmReducer,
  collectionEm: collectionEmReducer,
  knowTheHostEm: knowTheHostEmReducer,
  invitesEm: invitesEmReducer,
  readProfileEm: readProfileReducer,
  itineraryEm: itineraryEmReducer,
  galleryEm: galleryEmReducer,
  galleryFoldersEm: galleryFoldersEmReducer,
  myPhotosGalleryFoldersEm: myPhotosGalleryFoldersEmReducer,
  liveFeedEm: liveFeedEmReducer,
  guestInvitesEm: guestInvitesEmReducer,
  gamesEm: fetchQuestionsEmReducer,
  venueEm: fetchVenueEmReducer,
  groupEm: fetchGroupEmReducer,
  rsvpDateEm: fetchRSVPDateEmReducer,
  preferenceEm: fetchPreferenceQuestionsEmReducer,
  contactsEm: contactsEmReducer,
  orgCollectionsEm: orgCollectionsEmReducer,
  eventPermalinkEm: eventPermalinkEmReducer,

  //User
  leaderBoardUser: fetchLeaderBoardUserReducer,

  //Admin
  entitiesAd: entitiesAdReducer,
  entityAd: entityAdReducer,
  entityProfileAd: fetchEntityProfileAdReducer,
  testimonialAd: fetchTestimonialAdReducer,
  residentsAd: fetchResidentsAdReducer,
  permalinkAd: permalinkAdReducer,
  newsAd: fetchNewsAdReducer,
  policiesAd: fetchPoliciesAdReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
