import './infoscreen.css'

const Help = () => {
    return (
            <div className='container'>
                <h5 className="heading">Help</h5>
                <br />

                <h4 className='pb24-lh40 mb-0'>Q. What are the various sections within a wedding event?</h4>
                <br />
                <p className='pr16-lh24'>There are 5 sections within an event :</p>
                <ol className='ml-5 pr16-lh24' type="1">
                    <li>Welcome - Over here guests will see invites to the various functions at the wedding</li>
                    <li>Know the Couple - A picture story of the couple, their families and friends</li>
                    <li>Gallery - Photos of the couple past functions and anything else the couple wish to share</li>
                    <li>Itinerary - A timeline of all the functions and schedules for the wedding</li>
                    <li>Live Updates - Real time updates that need to shared with everyone attending the wedding</li>
                </ol>
                <br />
                <h4 className='pb24-lh40 mb-0'>Q. Can I invite specific people to specific functions?</h4>
                <br />
                <p className='pr16-lh24'>Yes. You can create as many invites as the number of functions at your wedding. Thereafter you can specify guest lists for each invite. Guests will only see the invites to functions that they are a part of.</p>
                <br />
                <h4 className='pb24-lh40 mb-0'>Q. Can guests have different itineraries?</h4>
                <br />
                <p className='pr16-lh24'>Yes. You can have different itineraries for different groups of guests (close family, friends, work friends, neighbours,....). Guests will only see the itinerary that you have decided for them.</p>
                <br />
                <h4 className='pb24-lh40 mb-0'>Q. How many photos can I upload?</h4>
                <br />
                <p className='pr16-lh24' >You can upload upto 100 GB of photos</p>
                <br />
                <h4 className='pb24-lh40 mb-0'>Q. How do I create my wedding event and invite guests?</h4>
                <br />
                <p className='pr16-lh24'>You can reach out to us at <a href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=suneel@pic-sea.com" rel="noreferrer" target="_blank">suneel@pic-sea.com</a> and we’ll set you up. If you have a wedding planner you can ask them to get in touch with us and we'll be happy to coordinate with them.</p>
                <br />
            </div>                    
        
    )   
}

export default Help
