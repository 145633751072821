import React from 'react'
import './pop-up.css'

const PopUp = (props) => {

    function modalClick(e){
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        return false
    }

    switch(props.variant){
        case "simple":
            return (
                <div className={`pop-up ${props.overlayClassName}`} onClick={props.handleClose}>
                    <div className={`container-simple ${props.containerClassName}`} onClick={modalClick}>
                        {props.children}
                    </div>
                </div>
            )
        case "outline":
            return (
                <div className={`pop-up ${props.overlayClassName}`} onClick={props.handleClose}>
                    <div className={`container-outline ${props.containerClassName}`} onClick={modalClick}>
                        {props.children}
                    </div>
                </div>
            )
        default:
            return (
                <div className={`pop-up ${props.overlayClassName}`} onClick={props.handleClose}>
                    <div className={`container-outline ${props.containerClassName}`} onClick={modalClick}>
                        {props.children}
                    </div>
                </div>
            )
    }
}

export default PopUp
