import React, { useState } from "react";
import { isEmpty } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import CollectionHeaderPartner from "../collection-header-partner/CollectionHeaderPartner";
import GamesQuestionItem from "../games-question-item/GamesQuestionItem";
import DeletePopup from "../delete-popup/DeletePopup";
import EditQuestionGamesEm from "../edit-question-games-em/EditQuestionGamesEm";

import { truncate } from "../../../utils/utils";

import {
  deleteQuestionById,
  fetchQuestionsForGamesEm,
  updateQuestionById,
  resetGameById,
} from "../../../services/games.service";
import { STD_ERROR_MESSAGE } from "../../../utils/constant";

export default function ListQuestionsGamesEm(props) {
  const { onAddQuestionButtonClick } = props;

  const [deleteQuestionPopup, setDeleteQuestionPopup] = useState({
    open: false,
    data: null,
  });
  const [editItem, setEditItem] = useState([]);
  const gamesState = useSelector((state) => state.gamesEm);
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();

  const gamesData = gamesState?.response.data;

  const handleBackToCollectionsBtnClick = () => {
    history.goBack();
  };

  const handleOnPublishChangeEvent = (gameData) => {
    const publishStatus = gameData?.published;
    const gameId = gameData?.id;
    if (typeof publishStatus === "boolean" && gameId) {
      updateQuestionById(params.collectionId, gameId, {
        published: !publishStatus,
      })
        .then((res) => {
          dispatch(fetchQuestionsForGamesEm(params.collectionId));
        })
        .catch((error) => toast.error(STD_ERROR_MESSAGE));
    } else {
      toast.error(STD_ERROR_MESSAGE);
    }
  };

  const handleOnEditQuestionClick = (gameData) => {
    setEditItem((ps) => {
      return [...ps, gameData.id];
    });
  };

  const handleOnResetAnswersClick = () => {
    resetGameById(params.collectionId)
      .then((res) => toast.success("Resetting users' answers successfully."))
      .catch((error) => toast.error(STD_ERROR_MESSAGE));
  };

  const handleOnDeleteQuestionClick = (gameData) => {
    setDeleteQuestionPopup({ open: true, data: gameData });
  };

  const handleDeleteQuestionAfterConfirmation = () => {
    const gameId = deleteQuestionPopup.data?.id;
    if (!isEmpty(gameId)) {
      deleteQuestionById(params.collectionId, gameId)
        .then((res) => {
          dispatch(fetchQuestionsForGamesEm(params.collectionId));
          setDeleteQuestionPopup({ open: false, data: null });
        })
        .catch((error) => {
          toast.error(STD_ERROR_MESSAGE);
        });
    } else {
      toast.error(STD_ERROR_MESSAGE);
    }
  };

  function handleCancelEditBtnClick(id) {
    setEditItem((ps) => {
      return ps.filter((item) => item !== id);
    });
  }

  return (
    <main
      style={{ maxWidth: "850px", marginLeft: "auto", marginRight: "auto" }}
    >
      <CollectionHeaderPartner
        title="Saved Questions"
        backToCollectionsBtn={true}
        handleBackToCollectionsBtn={handleBackToCollectionsBtnClick}
        generalBtn={true}
        generalBtnLabel="Add Questions"
        onGeneralBtnClick={onAddQuestionButtonClick}
        generalBtn2={true}
        generalBtn2Label="Reset Answers"
        onGeneralBtn2Click={handleOnResetAnswersClick}
      />
      <div className="mt-3">
        {!isEmpty(gamesData) ? (
          gamesData.map((gameData) =>
            editItem.includes(gameData.id) ? (
              <EditQuestionGamesEm
                gameData={gameData}
                key={gameData.id}
                onCancelBtnClick={() => handleCancelEditBtnClick(gameData.id)}
              />
            ) : (
              <GamesQuestionItem
                gameData={gameData}
                key={gameData.id}
                onDeleteQuestionClick={() =>
                  handleOnDeleteQuestionClick(gameData)
                }
                onEditQuestionClick={() => handleOnEditQuestionClick(gameData)}
                onPublishChangeClick={() =>
                  handleOnPublishChangeEvent(gameData)
                }
              />
            )
          )
        ) : (
          <h2 className="text-center pr20-lh32 high-emphasis-color mt-5">
            You have no questions added in this section
          </h2>
        )}
      </div>
      {deleteQuestionPopup.open ? (
        <DeletePopup
          heading="DELETE QUESTION"
          text={`The question "${truncate(
            deleteQuestionPopup.data?.question || "",
            10
          )}" will be deleted permanently. This cannot be undone.`}
          handleClose={() =>
            setDeleteQuestionPopup({ open: false, data: null })
          }
          handleDelete={handleDeleteQuestionAfterConfirmation}
        />
      ) : null}
    </main>
  );
}
