import { 
    REQUEST_GALLERY_FETCH_EM,
    FETCH_GALLERY_EM_SUCCESS,
    FETCH_GALLERY_EM_FAILURE,
    CLEAR_FETCH_GALLERY_EM_STATES
} from './gallery.types';


export const requestGalleryFetchEm = () => {
    return {
        type : REQUEST_GALLERY_FETCH_EM,
    }
}

export const fetchGalleryEmSuccess = (response) => {
    return {
        type : FETCH_GALLERY_EM_SUCCESS,
        payload : response
    }
}

export const fetchGalleryEmFailure = (error) => {
    return {
        type : FETCH_GALLERY_EM_FAILURE,
        payload : error
    }
}

export const clearFetchGalleryEmStates = () => {
    return {
        type : CLEAR_FETCH_GALLERY_EM_STATES
    }
}