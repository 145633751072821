import React, { useEffect } from 'react'
import { size, toArray } from 'lodash'
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';

import './files-uploading.css'
import imagePlaceholder from '../../../assets/placeholder/image_thumbnail_fill_200x200.svg';
import imageIcon from '../../../assets/icon/image.svg';
import errorIconRed from '../../../assets/icon/error_icon_red.svg';
import { uploadFiles } from '../../../services/uploadImages.service';
import { STATUS_UPLOAD } from '../../../redux/upload-files/uploadFiles.constants';

const FileUploading = (props) => {

    const uploadFilesState = useSelector(state => state.uploadFiles)
    const fileProgress = uploadFilesState.fileProgress

    const dispatch = useDispatch()

    const uploadedFileAmount = size(fileProgress)

    useEffect(() => {
        const filesToUpload = toArray(fileProgress).filter(file => file.progress === STATUS_UPLOAD.uploading)
        dispatch(uploadFiles(filesToUpload))
    }, [uploadedFileAmount])

    return (
        uploadedFileAmount > 0 ?
            <Grid container spacing={2} className="mt-5"> 
                {
                    size(fileProgress) ? 
                        toArray(fileProgress).map( file => (
                            <Grid item xs={12} md={6} lg={4} key={file.id}>
                                {
                                    file.status !== STATUS_UPLOAD.success ? 
                                        <div className="upload-image-placeholder d-flex flex-column position-relative justify-content-center align-items-center">
                                            {
                                                file.status === STATUS_UPLOAD.uploading ? 
                                                    <img src={imageIcon} alt="" /> 
                                                    : null 
                                            }
                                            { 
                                                file.status === STATUS_UPLOAD.failed ? 
                                                    <> 
                                                        <img src={errorIconRed} alt="" />
                                                        <h5 className="pb14-lh21 mt-3 mb-0 text-center error-color">Error uploading!</h5>
                                                        <h6 className="pr12-lh24 mt-2 mb-0 max-lines-two text-center secondary-color">{file.file.name}</h6> 
                                                    </> 
                                                    : null 
                                            }
                                            <div className="progressBar">
                                                <div 
                                                    className={file.status === STATUS_UPLOAD.uploading ? "bg-success-color" : "bg-error-color"} 
                                                    style={{ width: `${file.progress}%` }}
                                                />
                                            </div>
                                        </div>
                                        :
                                        <img 
                                            key={file.id} 
                                            style={{
                                                width: '100%',
                                                height: '200px',
                                                objectPosition: 'center',
                                                objectFit: 'cover'
                                            }}
                                            src={file.fileUrl ? file.fileUrl : imagePlaceholder} 
                                            alt=""
                                        />
                                }
                            </Grid>
                        ))
                        : null 
                }
            </Grid>
            : null
    )
}

export default FileUploading