import React from 'react'
import placeholder from '../../../assets/placeholder/collection_thumbnail_fill_200x112.svg'
import './image-placeholder.css'

const ImagePlaceholder = (props) => {
    
    const className = props.className ? `placeholder ${props.className}` : "placeholder"

    return (
        <img className={className} alt='' src={placeholder}/>
    )
}

export default ImagePlaceholder
