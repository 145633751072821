import {
  fetchEventPermalinkEmFailure,
  fetchEventPermalinkEmSuccess,
  requestFetchEventPermalink,
} from "../redux/event-permalink/eventPermalink.action";
import ProtectedWebClient from "../utils/protected-web-client";

export const createEventPermaLink = (collectionId, payload) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.post(`/event/${collectionId}/permalink`, payload)
      .then((res) => {
        resolve(res?.data);
      })
      .catch((error) => {
        reject(error?.response);
      });
  });
};

export const getEventPermalinkById = (collectionId) => {
  return async (dispatch) => {
    try {
      dispatch(requestFetchEventPermalink());
      const fetchEventPermalinkResponse = await ProtectedWebClient.get(
        `/event/${collectionId}/permalink`
      );
      dispatch(fetchEventPermalinkEmSuccess(fetchEventPermalinkResponse?.data));
    } catch (error) {
      dispatch(fetchEventPermalinkEmFailure(error?.response));
    }
  };
};

export const updateEventPermalinkById = (collectionId, payload) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.put(`/event/${collectionId}/permalink`, payload)
      .then((res) => {
        resolve(res?.data);
      })
      .catch((error) => {
        reject(error?.response);
      });
  });
};

/// to get permalink url

export const getEventPermalinkByEventId = (collectionId) => {
  return async (dispatch) => {
    try {
      dispatch(requestFetchEventPermalink());
      const fetchEventPermalinkEmResponse = await ProtectedWebClient.get(
        `/event/${collectionId}/permalink`
      );
      dispatch(
        fetchEventPermalinkEmSuccess(fetchEventPermalinkEmResponse?.data)
      );
    } catch (error) {
      dispatch(fetchEventPermalinkEmFailure(error?.response));
    }
  };
};
