import {
  REQUEST_QUESTIONS_FETCH_EM,
  FETCH_QUESTIONS_EM_SUCCESS,
  FETCH_QUESTIONS_EM_FAILURE,
  CLEAR_FETCH_QUESTIONS_EM_STATES,
  CHANGE_GAME_STARTED_STATE,
  SAVE_USER_RESPONSE_STATE,
  CHANGE_TO_NEXT_QUESTION_STATE,
} from "./games.types";

export const requestFetchQuestionsEm = () => {
  return {
    type: REQUEST_QUESTIONS_FETCH_EM,
  };
};

export const fetchQuestionsEmSuccess = (response) => {
  return {
    type: FETCH_QUESTIONS_EM_SUCCESS,
    payload: response,
  };
};

export const fetchQuestionsEmFailure = (error) => {
  return {
    type: FETCH_QUESTIONS_EM_FAILURE,
    payload: error,
  };
};

export const changeGameStartedState = (value) => {
  return {
    type: CHANGE_GAME_STARTED_STATE,
    payload: value,
  };
};

export const saveUserResponseState = (value) => {
  return {
    type: SAVE_USER_RESPONSE_STATE,
    payload: value,
  };
};

export const changeToNextQuestionState = (value) => {
  return {
    type: CHANGE_TO_NEXT_QUESTION_STATE,
    payload: value,
  };
};

export const clearFetchQuestionsEmStates = () => {
  return {
    type: CLEAR_FETCH_QUESTIONS_EM_STATES,
  };
};
