import { isEmpty } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { getAllCollections } from "../../../services/collections.service";
import {
  ENTITY_TYPES,
  EVENT_TYPES,
  ROLE_CATEGORIES,
  STD_ERROR_MESSAGE,
} from "../../../utils/constant";
import { getImageUrl, getUserId } from "../../../utils/utils";

import CustomSpinner from "../../../components/common/spinner/CustomSpinner";
import ClientFoldersLayout from "../../../components/layout/client-folders-layout/ClientFoldersLayout";
import CustomNavbar from "../../../components/layout/navbar/Navbar";
import { getAllEntities } from "../../../services/entities.service";
import { fetchProfile } from "../../../services/profile.service";
import { clearFetchCollectionsEmStates } from "../../../redux/collections/collections.action";

const Collections = () => {
  const collectionsState = useSelector((state) => state.collectionsEm);
  const entityContainerState = useSelector((state) => state.entitiesAd);
  const profileState = useSelector((state) => state.readProfileEm);

  const profileData = profileState?.response?.data;
  const dispatch = useDispatch();

  const [collectionLoading, setCollectionLoading] = useState(true);
  const [entityLoading, setEntityLoading] = useState(true);

  const [organizedCollections, setOrganizedCollections] = useState([]);
  const [organizedEntities, setOrganizedEntities] = useState([]);
  const [orgOrganizedCollection, setOrgOrganizedCollection] = useState([]);

  const collections = collectionsState?.response?.data;
  const entityContainers = entityContainerState?.response?.data;

  const history = useHistory();

  useEffect(() => {
    if (isEmpty(collectionsState.response) || collectionsState.reload)
      dispatch(getAllCollections());
    if (isEmpty(entityContainerState.response) || entityContainerState.reload)
      dispatch(getAllEntities());
    if (isEmpty(profileState.response)) {
      dispatch(fetchProfile(getUserId()));
    }
  }, []);

  useEffect(() => {
    if (!collectionsState.loading && !isEmpty(collectionsState.response)) {
      setCollectionLoading(false);
      setOrganizedCollections(organizeCollection(collections));
      setOrgOrganizedCollection(orgOrganizeCollection(collections));
    }
    if (!collectionsState.loading && collectionsState.error) {
      setCollectionLoading(false);
      toast.error(
        collectionsState?.error?.data?.error_data || STD_ERROR_MESSAGE
      );
    }
  }, [collectionsState]);

  useEffect(() => {
    if (!profileState.loading && !isEmpty(profileState.error)) {
      toast.error("Couldn't load user profile.");
    }
  }, [profileState]);

  useEffect(() => {
    if (
      !entityContainerState.loading &&
      !isEmpty(entityContainerState.response)
    ) {
      setEntityLoading(false);
      setOrganizedEntities(organizeEntities(entityContainers));
    }
    if (!entityContainerState.loading && entityContainerState.error) {
      setEntityLoading(false);
      toast.error(
        entityContainerState?.error?.data?.error_data || STD_ERROR_MESSAGE
      );
    }
  }, [entityContainerState]);

  function organizeCollection(collections) {
    if (!collections) return [];

    const weddingEvents = [];
    const birthdayEvents = [];
    const otherEvents = [];

    const apartmentEvents = [];
    const bnichapters = [];

    const publishedEvents = collections.filter(
      (container) => container?.permalink?.publish
    );

    for (const collection of collections) {
      if (collection.event.category_id === EVENT_TYPES.wedding)
        weddingEvents.push(collection);
      else if (collection.event.category_id === EVENT_TYPES.birthday)
        birthdayEvents.push(collection);
      else if (collection.event.category_id === EVENT_TYPES.event)
        otherEvents.push(collection);
      else if (collection.category_id === EVENT_TYPES.apartment)
        apartmentEvents.push(collection);
      else if (collection.category_id === EVENT_TYPES.bnichapter)
        bnichapters.push(collection);
    }

    const resultCollections = [];
    if (!isEmpty(weddingEvents))
      resultCollections.push({ name: "Weddings", events: weddingEvents });
    if (!isEmpty(birthdayEvents))
      resultCollections.push({ name: "Birthdays", events: birthdayEvents });
    if (!isEmpty(otherEvents))
      resultCollections.push({ name: "Events", events: otherEvents });
    if (!isEmpty(apartmentEvents))
      resultCollections.push({ name: "Apartments", events: apartmentEvents });
    if (!isEmpty(bnichapters))
      resultCollections.push({ name: "BNI Chapters", events: bnichapters });

    return resultCollections;
  }

  function orgOrganizeCollection(collections) {
    if (!collections) return [];

    const schoolEvents = [];
    const apartmentEvents = [];

    for (const collection of collections) {
      if (
        collection.event.partner.categoryAlias ===
          ROLE_CATEGORIES.schoolManager &&
        !schoolEvents.map((p) => p.id).includes(collection.event.partner.id)
      ) {
        schoolEvents.push(collection.event.partner);
      } else if (
        collection.event.partner.categoryAlias === ROLE_CATEGORIES.orgManager &&
        !apartmentEvents.map((p) => p.id).includes(collection.event.partner.id)
      ) {
        apartmentEvents.push(collection.event.partner);
      }
    }
    const resultCollections = [];
    if (!isEmpty(schoolEvents))
      resultCollections.push({ name: "Schools", events: schoolEvents });
    if (!isEmpty(apartmentEvents))
      resultCollections.push({ name: "Apartments", events: apartmentEvents });

    return resultCollections;
  }

  function organizeEntities(entityContainers) {
    if (!entityContainers) return [];

    const venueEntity = [];
    const plannerEntity = [];
    const otherEntity = [];

    const publishedEntities = entityContainers.filter(
      (container) => container?.permalink?.publish
    );
    for (const publishedEntity of publishedEntities) {
      if (publishedEntity.entity.entity_type === ENTITY_TYPES.venue)
        venueEntity.push(publishedEntity);
      else if (publishedEntity.entity.entity_type === ENTITY_TYPES.planner)
        plannerEntity.push(publishedEntity);
      else if (publishedEntity.entity.entity_type === ENTITY_TYPES.other)
        otherEntity.push(publishedEntity);
    }

    const resultEntities = [];
    if (!isEmpty(venueEntity))
      resultEntities.push({ name: "Venues", entities: venueEntity });
    if (!isEmpty(plannerEntity))
      resultEntities.push({ name: "Planners", entities: plannerEntity });
    if (!isEmpty(otherEntity))
      resultEntities.push({ name: "Other hosts", entities: otherEntity });

    return resultEntities;
  }

  const handleCollectionTrigger = (collection) => {
    if (collection?.permalink?.publish) {
      history.push(`/${collection.permalink.permalink}`, "_blank");
      if (window.scrollY) {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
    } else {
      history.push(`/collection/${collection.event.id}`);
      if (window.scrollY) {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
    }
  };

  const handleOrganizationTrigger = (partnerId) => {
    history.push(`/orgEvents/${partnerId}`);
    if (window.scrollY) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  };
  const handleEntityTrigger = (entityContainers) => {
    if (entityContainers?.permalink?.publish) {
      history.push(`/${entityContainers.permalink.permalink}`, "_blank");
      if (window.scrollY) {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
    } else {
      history.push(`/entity/${entityContainers.entity.id}`);
      if (window.scrollY) {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
    }
  };

  return (
    <>
      <CustomNavbar userProfile={profileData} />
      {!collectionLoading && !entityLoading ? (
        <div className="container pt-4 pb-1">
          {!isEmpty(organizedCollections) &&
            organizedCollections.map((eventsGroup, index) => (
              <div className="mb-4" key={index}>
                <h1 className="pb20-lh32 mb-3">{eventsGroup.name}</h1>
                <div className="collection-grid">
                  {eventsGroup.events.map((collection) => (
                    <LazyLoadComponent key={collection.event.id}>
                      <ClientFoldersLayout
                        thumbnail={getImageUrl(
                          collection?.event?.image?.bucket_path
                        )}
                        collectionName={collection?.event?.name}
                        // enterpriseName={collection.event.creator.enterprise_name}
                        createdAt={moment(collection?.event.start_date).format(
                          "MMMM Do, YYYY"
                        )}
                        handleCardClick={() =>
                          handleCollectionTrigger(collection)
                        }
                      />
                    </LazyLoadComponent>
                  ))}
                </div>
              </div>
            ))}

          {!isEmpty(orgOrganizedCollection) &&
            orgOrganizedCollection.map((eventsGroup, index) => (
              <div className="mb-4" key={index}>
                <h1 className="pb20-lh32 mb-3">{eventsGroup.name}</h1>
                <div className="collection-grid">
                  {eventsGroup.events.map((partner) => (
                    <LazyLoadComponent key={partner?.id}>
                      <ClientFoldersLayout
                        thumbnail={getImageUrl(partner?.image?.bucket_path)}
                        collectionName={partner?.name}
                        handleCardClick={() =>
                          handleOrganizationTrigger(partner?.id)
                        }
                      />
                    </LazyLoadComponent>
                  ))}
                </div>
              </div>
            ))}

          {isEmpty(organizedCollections) && isEmpty(orgOrganizedCollection) && (
            <div className="userCenter">
              <h1 className="mb-4 pr20-lh32 high-emphasis-color text-center">
                You have no events yet.
              </h1>
            </div>
          )}

          {!isEmpty(organizedEntities)
            ? organizedEntities.map((entitiesGroup, index) => (
                <div className="mb-4" key={index}>
                  <h1 className="pb20-lh32 mb-3">{entitiesGroup.name}</h1>
                  <div className="collection-grid">
                    {entitiesGroup.entities.map((entityGroup) => (
                      <LazyLoadComponent key={entityGroup.entity.id}>
                        <ClientFoldersLayout
                          thumbnail={getImageUrl(
                            entityGroup.entity?.image?.bucket_path
                          )}
                          entityName={entityGroup.entity.name}
                          handleCardClick={() =>
                            handleEntityTrigger(entityGroup)
                          }
                        />
                      </LazyLoadComponent>
                    ))}
                  </div>
                </div>
              ))
            : null}
        </div>
      ) : (
        // ) : null
        <CustomSpinner />
      )}
    </>
  );
};

export default Collections;
