import {
  fetchItineraryEmFailure,
  fetchItineraryEmSuccess,
  requestFetchItineraryEm,
} from "../redux/itinerary/itinerary.action";
import protectedWebClient from "../utils/protected-web-client";

export const getAllItineraryInCollection = (collectionId) => {
  return async (dispatch) => {
    try {
      dispatch(requestFetchItineraryEm());
      const response = await protectedWebClient.get(
        `/event/${collectionId}/itinerary`
      );
      dispatch(fetchItineraryEmSuccess(response.data));
    } catch (err) {
      dispatch(fetchItineraryEmFailure(err?.response));
    }
  };
};

export const createItineraryPromise = (collectionId, payload) => {
  return new Promise((resolve, reject) => {
    protectedWebClient
      .post(`/event/${collectionId}/itinerary`, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteItineraryPromise = (collectionId, itineraryId) => {
  return new Promise((resolve, reject) => {
    protectedWebClient
      .delete(`/event/${collectionId}/itinerary/${itineraryId}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
