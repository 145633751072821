import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import EnableIcon from "../../../../assets/icon/publish_web.svg";
import DisableIcon from "../../../../assets/icon/unpublish.svg";
import { updateCollection } from "../../../../services/collections.service";
import { AD_BUCKET_PATH, STD_ERROR_MESSAGE } from "../../../../utils/constant";
import ButtonAccent from "../../../common/button-accent/ButtonAccent";

import { isEmpty } from "lodash";

import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import { useRef, useState } from "react";
import Error from "../../../common/v2/error/Error";

import { Form, Formik } from "formik";
import { useEffect } from "react";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { fetchCollectionEmSuccess } from "../../../../redux/collection/collection.action";
import {
  getPresignedUrlPromise,
  uploadImageUsingPresignedUrlPromise,
} from "../../../../services/uploadImage.service";
import { getImageUrl } from "../../../../utils/utils";
import CollectionHeaderPartner from "../../collection-header-partner/CollectionHeaderPartner";

export const PreviewLogo = (props) => {
  const { className, src, onClick } = props;
  return (
    <div
      style={{
        padding: "12px 24px",
        border: "1px solid var(--high-emphasis)",
        minWidth: "140px",
        borderRadius: "4px",
        marginRight: "1rem",
      }}
      className={`d-flex justify-content-center ${className || ""}`}
      onClick={onClick}
    >
      <LazyLoadComponent>
        <img
          style={{
            maxHeight: "28px",
            height: "auto",
            width: "auto",
          }}
          src={src}
          alt=""
        />
      </LazyLoadComponent>
    </div>
  );
};

const SectionRegister = () => {
  const [logoSubmitting, setLogoSubmitting] = useState(false);
  const collectionState = useSelector((state) => state.collectionEm);
  const collectionData = collectionState?.response?.data;
  const dispatch = useDispatch();

  const settings = collectionData.settings
    ? JSON.parse(collectionData.settings)
    : {};

  const [imageFiles, setImageFiles] = useState({
    logo: null, //{[key = mobile | desktop | tablet | logo]: {file: null, preview: null}}
  });

  useEffect(() => {
    if (imageFiles.current && imageFiles[imageFiles.current]?.file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageFiles((ps) => {
          return {
            ...ps,
            current: null,
            [ps.current]: {
              ...ps[ps.current],
              preview: reader.result,
            },
          };
        });
      };
      reader.readAsDataURL(imageFiles[imageFiles.current].file);
    }
  }, [imageFiles]);

  function onToggleChange() {
    settings.register = !settings.register;
    updateCollection({ settings: JSON.stringify(settings) }, collectionData.id)
      .then((res) => {
        dispatch(fetchCollectionEmSuccess(res.data));
        toast.info(
          `${settings.register ? "Enabled" : "Disabled"}` + " successfully."
        );
      })
      .catch((err) =>
        toast.error(
          !isEmpty(err?.data?.error_data)
            ? err?.data?.error_data
            : STD_ERROR_MESSAGE
        )
      );
  }

  const [errorMessage, setErrorMessage] = useState({});
  function uploadImagePromise(imageFile, device) {
    let imageId = "";
    const presignedUrlPayload = [
      {
        bucket_path: `${AD_BUCKET_PATH.event}/${collectionData.id}/${AD_BUCKET_PATH.logo}/${imageFile.name}`,
        image_name: imageFile.name,
      },
    ];
    return getPresignedUrlPromise(presignedUrlPayload, { device })
      .then((response) => {
        imageId = response?.res?.data?.data?.[0]?.image_id;
        return uploadImageUsingPresignedUrlPromise(
          response?.res?.data?.data?.[0]?.upload_url,
          imageFile,
          { device, imageId }
        );
      })
      .then((res) => {
        return updateCollection({ logo_image_id: imageId }, collectionData.id);
      })
      .then((res) => {
        dispatch(fetchCollectionEmSuccess(res.data));
        setLogoSubmitting(false);
        toast.info("Logo added successfully");
      })
      .catch((err) => {
        setLogoSubmitting(false);
        toast.error(
          !isEmpty(err?.data?.error_data)
            ? err?.data?.error_data
            : STD_ERROR_MESSAGE
        );
      });
  }

  const onSubmitLogo = () => {
    if (!imageFiles.logo?.file) {
      setErrorMessage({
        ...(!imageFiles.logo?.file
          ? { logoImage: "Please upload the event logo" }
          : {}),
      });
    } else {
      if (!logoSubmitting) {
        setLogoSubmitting(true);
        uploadImagePromise(imageFiles.logo?.file, "logo");
      }
    }
  };

  function handleImageChange(event, device) {
    const file = event.target.files[0];
    if (file && file.type.substr(0, 5) === "image") {
      if (errorMessage?.logoImage && device === "logo") {
        setErrorMessage((ps) => {
          const { logoImage, ...rest } = ps;
          return {
            ...rest,
          };
        });
      }
      setImageFiles((ps) => {
        return {
          ...ps,
          current: device,
          [device]: {
            file,
          },
        };
      });
    }
  }

  const imagesRef = useRef({
    logo: null,
  });

  return (
    <>
      <div>
        <div>
          Registration is currently{" "}
          {settings?.register ? "enabled." : "disabled."}
        </div>
        <ButtonAccent
          variant="icon-button"
          className="d-flex align-items-center justify-content-center"
          startIcon={
            collectionData.settings?.register ? DisableIcon : EnableIcon
          }
          label={settings?.register ? "Disable" : "Enable"}
          labelClassName="ml-2"
          startIconClassName="btn-icon-small"
          customStyling={{
            width: "20%",
            padding: "6px 16px",
            marginTop: "20px",
          }}
          onClick={onToggleChange}
        />
        <Formik initialValues={{}} onSubmit={onSubmitLogo}>
          {(formik) => (
            <Form>
              <div className="mt-5">
                <CollectionHeaderPartner
                  title={"Add Logo"}
                  loadingBtn={true}
                  loadingBtnProps={{
                    type: "submit",
                    loading: logoSubmitting,
                  }}
                  loadingBtnLabel={"Save logo"}
                />
                <div className="d-flex align-items-end">
                  {!isEmpty(collectionData?.logo) ||
                  imageFiles.logo?.preview ? (
                    <PreviewLogo
                      src={
                        imageFiles.logo?.preview ||
                        getImageUrl(collectionData.logo?.bucket_path)
                      }
                    />
                  ) : null}
                  <Button
                    variant="outlined"
                    startIcon={
                      !isEmpty(collectionData?.logo) ||
                      imageFiles.logo?.file ? (
                        <EditIcon />
                      ) : (
                        <AddIcon />
                      )
                    }
                    style={{ marginTop: "2rem" }}
                    sx={{
                      textTransform: "none",
                      fontFamily: "'Poppins', sans-serif",
                      fontStyle: "normal",
                      fontWeight: "600",
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: "var(--high-emphasis)",
                      borderColor: "var(--high-emphasis)",
                      "&:hover": {
                        backgroundColor: "transparent",
                        borderColor: "var(--high-emphasis)",
                      },
                    }}
                    onClick={(event) => {
                      event.preventDefault();
                      imagesRef.current["logo"].click();
                    }}
                  >
                    {!isEmpty(collectionData?.logo) || imageFiles.logo?.file
                      ? "Change the logo"
                      : "Add a logo"}
                  </Button>
                  <input
                    ref={(element) => (imagesRef.current["logo"] = element)}
                    type="file"
                    style={{ display: "none" }}
                    accept="image/png,image/jpg,image/jpeg,image/svg+xml"
                    name={`logoImage`}
                    onChange={(event) => handleImageChange(event, "logo")}
                  />
                </div>
                {Boolean(errorMessage.logoImage) ? (
                  <Error errorMsg={errorMessage.logoImage} className="mt-2" />
                ) : null}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default SectionRegister;
