import ProtectedWebClient from "../utils/protected-web-client";
import {
  requestFetchVenueEm,
  fetchVenueEmSuccess,
  fetchVenueEmFailure,
} from "../redux/venue/venue.action";

export const getAllVenuesInCollection = (collectionId) => {
  return async (dispatch) => {
    try {
      dispatch(requestFetchVenueEm());
      const response = await ProtectedWebClient.get(
        `/event/${collectionId}/venue`
      );
      dispatch(fetchVenueEmSuccess(response?.data));
    } catch (error) {
      dispatch(fetchVenueEmFailure(error?.response?.data));
    }
  };
};

export const createVenuePromise = (collectionId, payload) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.post(`/event/${collectionId}/venue`, payload)
      .then((res) => {
        resolve(res?.data);
      })
      .catch((error) => {
        reject(error?.data);
      });
  });
};

export const updateVenueByIdPromise = (collectionId, venueId, payload) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.put(`/event/${collectionId}/venue/${venueId}`, payload)
      .then((res) => {
        resolve(res?.data);
      })
      .catch((error) => {
        reject(error?.data);
      });
  });
};

export const deleteVenueByIdPromise = (collectionId, venueId) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.delete(`/event/${collectionId}/venue/${venueId}`)
      .then((res) => {
        resolve(res?.data);
      })
      .catch((error) => {
        reject(error?.data);
      });
  });
};
