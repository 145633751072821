import {
  REQUEST_GROUP_FETCH_EM,
  FETCH_GROUP_EM_SUCCESS,
  FETCH_GROUP_EM_FAILURE,
  CLEAR_FETCH_GROUP_EM_STATES,
} from "./group.types";

export const requestFetchGroupEm = () => {
  return {
    type: REQUEST_GROUP_FETCH_EM,
  };
};

export const fetchGroupEmSuccess = (response) => {
  return {
    type: FETCH_GROUP_EM_SUCCESS,
    payload: response,
  };
};

export const fetchGroupEmFailure = (error) => {
  return {
    type: FETCH_GROUP_EM_FAILURE,
    payload: error,
  };
};

export const clearFetchGroupEmStates = () => {
  return {
    type: CLEAR_FETCH_GROUP_EM_STATES,
  };
};
