import ButtonAccent from "../../../common/button-accent/ButtonAccent";
import EnableIcon from "../../../../assets/icon/publish_web.svg";
import DisableIcon from "../../../../assets/icon/unpublish.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  getACollection,
  updateCollection,
} from "../../../../services/collections.service";
import { toast } from "react-toastify";
import { STD_ERROR_MESSAGE } from "../../../../utils/constant";

const SectionFaceRecognition = () => {
  const collectionState = useSelector((state) => state.collectionEm);
  const collectionData = collectionState?.response?.data;
  const dispatch = useDispatch();

  const toggleFaceRecognition = () => {
    const faceRecStatus = collectionData?.face_rec;
    if (typeof faceRecStatus === "boolean") {
      updateCollection({ face_rec: !faceRecStatus }, collectionData?.id)
        .then((res) => {
          dispatch(getACollection(collectionData?.id));
          toast.info(
            "Face recognition " +
              `${faceRecStatus ? "disabled" : "enabled"}` +
              " successfully."
          );
        })
        .catch((error) =>
          toast.error(error?.data?.error_data || STD_ERROR_MESSAGE)
        );
    } else {
      toast.error(STD_ERROR_MESSAGE);
    }
  };

  return (
    <div>
      <div>
        Face recognition is currently{" "}
        {collectionData?.face_rec ? "enabled." : "disabled."}
      </div>
      <ButtonAccent
        variant="icon-button"
        className="d-flex align-items-center justify-content-center"
        startIcon={collectionData?.face_rec ? DisableIcon : EnableIcon}
        label={collectionData?.face_rec ? "Disable" : "Enable"}
        labelClassName="ml-2"
        startIconClassName="btn-icon-small"
        customStyling={{
          width: "20%",
          padding: "6px 16px",
          marginTop: "20px",
        }}
        onClick={toggleFaceRecognition}
      />
    </div>
  );
};

export default SectionFaceRecognition;
