import {
  REQUEST_MYPHOTOS_GALLERY_FOLDERS_FETCH_EM,
  FETCH_MYPHOTOS_GALLERY_FOLDERS_EM_SUCCESS,
  FETCH_MYPHOTOS_GALLERY_FOLDERS_EM_FAILURE,
  CLEAR_MYPHOTOS_GALLERY_FOLDERS_EM_STATES,
} from "./myPhotosGalleryFolders.types";

export const requestMyPhotosGalleryFoldersFetchEm = () => {
  return {
    type: REQUEST_MYPHOTOS_GALLERY_FOLDERS_FETCH_EM,
  };
};

export const fetchMyPhotosGalleryFoldersEmSuccess = (id, response) => {
  return {
    type: FETCH_MYPHOTOS_GALLERY_FOLDERS_EM_SUCCESS,
    payload: {
      id,
      response,
    },
  };
};

export const fetchMyPhotosGalleryFoldersEmFailure = (error) => {
  return {
    type: FETCH_MYPHOTOS_GALLERY_FOLDERS_EM_FAILURE,
    payload: error,
  };
};

export const clearMyPhotosGalleryFoldersEmStates = () => {
  return {
    type: CLEAR_MYPHOTOS_GALLERY_FOLDERS_EM_STATES,
  };
};
