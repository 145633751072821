import axios from "axios";
import { toArray } from "lodash";
import {
  clearErrorStack,
  clearUploadFilesState,
  failureUploadFile,
  retryUploadFailedFile,
  setImageId,
  setUploadProgress,
  successUploadFile,
} from "../redux/upload-files/uploadFiles.action";
import { STATUS_UPLOAD } from "../redux/upload-files/uploadFiles.constants";
import ProtectedWebClient from "../utils/protected-web-client";

export const uploadFiles = (files) => (dispatch) => {
  if (files.length) {
    for (const file of files) {
      ProtectedWebClient.post(`/image`, file.payload)
        .then((response) => {
          const presignUrl = response?.data?.data?.[0]?.upload_url;
          dispatch(setImageId(file.id, response?.data?.data?.[0]?.image_id));
          axios
            .put(presignUrl, file.file, {
              cancelToken: file.cancelSource.token,
              headers: { "Content-Type": "image/jpeg" },
              onUploadProgress: (progress) => {
                const { loaded, total } = progress;
                const percentageProgress = Math.floor((loaded / total) * 100);
                dispatch(setUploadProgress(file.id, percentageProgress));
              },
            })
            .then((s3Response) => {
              filePreview(file.file).then((result) => {
                dispatch(successUploadFile(file.id, result));
              });
            })
            .catch((err) => {
              if (axios.isCancel(err)) {
                // Do something when user cancel upload
                // console.log('cancelled by user')
              } else {
                //console.error(error)
                dispatch(failureUploadFile(file.id));
              }
            });
        })
        .catch((err) => {
          dispatch(failureUploadFile(file.id));
        });
    }
  }
};

export const cancelUploadingFiles = () => (dispatch, getState) => {
  const { fileProgress } = getState().uploadFiles;
  const filesToUpload = toArray(fileProgress).filter(
    (file) => file.status === STATUS_UPLOAD.uploading
  );
  for (const fileToUpload of filesToUpload) {
    fileToUpload.cancelSource.cancel();
  }
  dispatch(clearUploadFilesState());
};

export const retryUploadErrors = () => (dispatch, getState) => {
  const { fileProgress, uploadError } = getState().uploadFiles;
  dispatch(clearErrorStack());
  let reUploadFiles = [];
  uploadError.ids.forEach((id) => {
    dispatch(retryUploadFailedFile(id));
    reUploadFiles.push(fileProgress[id]);
  });
  dispatch(uploadFiles(reUploadFiles));
};

function filePreview(imageFile) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = (ev) => {
      resolve(reader.result);
    };
    reader.readAsDataURL(imageFile);
  });
}
