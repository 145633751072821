import { useDispatch, useSelector } from "react-redux";
import ring1 from "../../../assets/image/ring1.png";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import "./UserPreference.css";
import "./queries.css";
import { STD_ERROR_MESSAGE } from "../../../utils/constant";
import { toast } from "react-toastify";
import {
  fetchPreferenceQuestionUser,
  submitPrefOption,
} from "../../../services/preferences.service";
import {
  fetchPreferenceQuestionsEmFailure,
  fetchPreferenceQuestionsEmSuccess,
} from "../../../redux/prefrences/preferences.action";
import TransportDetails from "./TransportDetails";
import CustomSpinner from "../../common/spinner/CustomSpinner";
import { isEmpty } from "lodash";
import ButtonAccent from "../../common/button-accent/ButtonAccent";

function FlatButton(props) {
  const { className, ...rest } = props;

  return (
    <button
      className={`question-option-btn pr16-lh24 gray-1-color px-1 py-1 mb-2 ${
        className || ""
      }`}
      style={{ border: "none" }}
      {...rest}
    >
      {props.children}
    </button>
  );
}

export default function UserPreference() {
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const preferenceState = useSelector((state) => state.preferenceEm);
  const preferenceData = preferenceState.response?.data;
  const groupState = useSelector((state) => state.groupEm);
  const groupData = groupState.response?.data;
  const [userOption, setUserOption] = useState();
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    if (isEmpty(preferenceState.response))
      dispatch(fetchPreferenceQuestionUser(params.collectionId));
  }, []);

  useEffect(() => {
    if (!preferenceState.loading && !isEmpty(preferenceState.response)) {
      setLoading(false);
    }
    if (!preferenceState.loading && !isEmpty(preferenceState.error)) {
      toast.error(preferenceState.error?.error_data || STD_ERROR_MESSAGE);
    }
  }, [preferenceState]);

  const onSubmit = () => {
    if (!submitting) {
      setSubmitting(true);
      submitPrefOption(
        params.collectionId,
        preferenceData.current_preference.id,
        { id: userOption }
      )
        .then((res) => {
          dispatch(fetchPreferenceQuestionsEmSuccess(res));
          setSubmitting(false);
        })
        .catch((err) => {
          dispatch(fetchPreferenceQuestionsEmFailure(err?.response));
          setSubmitting(false);
          toast.error(err.data?.error_data || STD_ERROR_MESSAGE);
        });
    }
  };

  let screen = null;

  if (preferenceData?.total_questions > preferenceData?.questions_responsed) {
    screen = (
      <>
        <div className="d-flex justify-content-center">
          <img
            alt=""
            src={ring1}
            style={{
              width: "6rem",
              height: "6rem",
            }}
          />
        </div>
        <h1
          className="merriweather-black-fs20-lh36 primary-color mt-2"
          style={{
            textAlign: "center",
            height: "6rem",
          }}
        >
          {preferenceData.current_preference.question}
        </h1>
        <div className="d-flex flex-column pt-2">
          {preferenceData?.current_preference?.options.map((option) => (
            <FlatButton
              key={option.id}
              onClick={() => setUserOption(option.id)}
              className={userOption == option.id ? "accent-color-bg" : null}
            >
              {option.title}
            </FlatButton>
          ))}
          <div
            className="d-flex justify-content-between align-items-center"
            style={{
              marginTop: "1rem",
              backgroundColor: "rgba(255, 255, 255, 0.75)",
            }}
          >
            <ButtonAccent
              className="button-w100-mt21 pb14-lh21"
              label="Save"
              disabled={submitting}
              onClick={onSubmit}
            />
          </div>
        </div>
      </>
    );
  } else {
    screen = (
      <TransportDetails
        collectionId={params.collectionId}
        groupId={groupData?.event_user_group.id}
        groupData={groupData}
      />
    );
  }

  return (
    <>
      {loading && <CustomSpinner />}
      <div className="main">
        <div className="preference">
          <div className="pbox">
            <div
              style={{
                maxWidth: "500px",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              {screen}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
