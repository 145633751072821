import {
  fetchPoliciesAdFailure,
  fetchPoliciesAdSuccess,
  requestFetchPoliciesAd,
} from "../redux/policies/policies.action";
import protectedWebClient from "../utils/protected-web-client";

export const createPoliciesPromise = (payload, collectionId) => {
  return new Promise((resolve, reject) => {
    protectedWebClient
      .post(`/event/${collectionId}/policy`, payload)
      .then((res) => {
        resolve({ res: res });
      })
      .catch((err) => {
        reject({ err: err?.response });
      });
  });
};

export const fetchPolicies = (collectionId) => {
  return async (dispatch) => {
    try {
      dispatch(requestFetchPoliciesAd());
      const response = await protectedWebClient.get(
        `/event/${collectionId}/policy`
      );
      dispatch(fetchPoliciesAdSuccess(response?.data));
    } catch (error) {
      dispatch(fetchPoliciesAdFailure(error?.response));
    }
  };
};

export const deletePoliciesbySequencePromise = (collectionId, sequence) => {
  return new Promise((resolve, reject) => {
    protectedWebClient
      .delete(`/event/${collectionId}/policy/${sequence}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err?.response);
      });
  });
};

export const updatePoliciesBySequencePromise = (
  payload,
  collectionId,
  sequence
) => {
  return new Promise((resolve, reject) => {
    protectedWebClient
      .put(`/event/${collectionId}/policy/${sequence}`, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err?.response);
      });
  });
};
