import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, size } from "lodash";
import { toast } from "react-toastify";
// import { LazyLoadImage } from "react-lazy-load-image-component";
// import { LazyLoadComponent } from "react-lazy-load-image-component";

import Image from "../../common/v2/image/Image";
import { getImageUrl } from "../../../utils/utils";
import CollectionHeaderPartner from "../collection-header-partner/CollectionHeaderPartner";
import ButtonAccent from "../../common/button-accent/ButtonAccent";
import AddFolder from "../add-folder/AddFolder";
import {
  getImagesOfGalleryFolder,
  deleteAImageInGalleryFolderPromise,
} from "../../../services/gallery.service";
import { STD_ERROR_MESSAGE } from "../../../utils/constant";
import DeletePopup from "../delete-popup/DeletePopup";

const PhotoGalleryList = (props) => {
  const { currGalleryFolderInfo, setCurrGalleryFolderInfo } = props;

  const [addFolderPopup, setAddFolderPopup] = useState(false);
  const [loading, setLoading] = useState(true);
  const [deletePopup, setDeletePopup] = useState({ open: false, id: null });

  const galleryState = useSelector((state) => state.galleryEm);
  const galleryFoldersState = useSelector((state) => state.galleryFoldersEm);

  const dispatch = useDispatch();

  const galleryData = galleryState.response?.data;
  const currGalleryFolderData =
    galleryFoldersState.response?.[props.selected?.subFolder]?.data;

  const params = useParams();

  useEffect(() => {
    if (!isEmpty(galleryData)) {
      if (!props?.selected?.subFolder) {
        props.setSelected((ps) => {
          return {
            ...ps,
            subFolder: galleryData[0].id,
          };
        });
      } else {
        const isValid = galleryData.some(
          (galleryFolder) => galleryFolder.id === props.selected?.subFolder
        );
        if (!isValid) {
          props.setSelected((ps) => {
            return {
              ...ps,
              subFolder: galleryData[0].id,
            };
          });
        }
      }
    } else {
      if (props.selected?.subFolder) {
        props.setSelected((ps) => {
          return {
            ...ps,
            subFolder: null,
          };
        });
      }
    }

    if (!isEmpty(galleryData) && props.selected?.subFolder) {
      if (isEmpty(galleryFoldersState.response?.[props.selected?.subFolder])) {
        dispatch(
          getImagesOfGalleryFolder(
            params.collectionId,
            props.selected?.subFolder
          )
        );
      }
      setCurrGalleryFolderInfo((ps) => {
        return galleryData.find(
          (folder) => folder.id === props.selected?.subFolder
        );
      });
    }
  }, [props.selected?.subFolder, galleryData]);

  useEffect(() => {
    if (!isEmpty(galleryData)) {
      if (
        !galleryFoldersState.loading &&
        !isEmpty(galleryFoldersState.response?.[props.selected?.subFolder])
      ) {
        setLoading(false);
      }

      if (!galleryFoldersState.loading && !isEmpty(galleryFoldersState.error)) {
        setLoading(false);
        toast.error(
          galleryFoldersState.error?.data?.error_data || STD_ERROR_MESSAGE
        );
      }
    } else {
      setLoading(false);
    }
  }, [galleryFoldersState, galleryData, props.selected?.subFolder]);

  function toggleAddFolderPopup() {
    setAddFolderPopup((ps) => !ps);
  }

  function handleDeleteImageEvent() {
    deleteAImageInGalleryFolderPromise(
      params.collectionId,
      currGalleryFolderInfo.id,
      deletePopup.id
    )
      .then((res) => {
        dispatch(
          getImagesOfGalleryFolder(
            params.collectionId,
            currGalleryFolderInfo.id
          )
        );
        closeDeletePopup();
      })
      .catch((err) => {
        toast.error(err?.error_data || STD_ERROR_MESSAGE);
      });
  }

  function closeDeletePopup() {
    setDeletePopup({ open: false, id: null });
  }

  function openDeletePopup(id) {
    setDeletePopup({ open: true, id: id });
  }

  return (
    <main
      style={{ maxWidth: "850px", marginLeft: "auto", marginRight: "auto" }}
    >
      {!loading ? (
        isEmpty(galleryData) ? (
          <div>
            <CollectionHeaderPartner
              title="Photo Gallery"
              backToCollectionsBtn={true}
              generalBtn={true}
              generalBtnLabel={"Master Invite List"}
              onGeneralBtnClick={props.onMasterInviteBtnClick}
            />
            <h3 className="text-center pr20-lh32 high-emphasis-color mt-5">
              You don't have any folders present.
            </h3>
            <div className="d-flex justify-content-center">
              <ButtonAccent
                label="Add folder"
                variant="span-button"
                startSpan="+"
                startSpanClassName="mr-1"
                onClick={toggleAddFolderPopup}
              />
            </div>
          </div>
        ) : (
          <>
            <CollectionHeaderPartner
              title={`${currGalleryFolderInfo?.title}${
                !isEmpty(currGalleryFolderData)
                  ? `(${size(currGalleryFolderData)})`
                  : ""
              }`}
              backToCollectionsBtn={true}
              addPhotosBtn={true}
              addPhotosLabel="Add Photos"
              handleAddPhotosBtn={props.onAddPhotosButtonClick}
              generalBtn={true}
              generalBtnLabel={"Master Invite List"}
              onGeneralBtnClick={props.onMasterInviteBtnClick}
            />
            <div style={{ marginTop: "27px" }}>
              {size(currGalleryFolderData) ? (
                <Grid container spacing={2}>
                  {currGalleryFolderData.map((galleryFolderItem) => (
                    <Grid
                      item
                      sm={12}
                      md={6}
                      lg={6}
                      xl={6}
                      key={galleryFolderItem.id}
                    >
                      <Image
                        variant="withHover"
                        enableDeleteIcon={true}
                        // enableShareIcon={true}
                        src={getImageUrl(galleryFolderItem?.image?.bucket_path)}
                        alt=""
                        onDeleteIconClick={() =>
                          openDeletePopup(galleryFolderItem.id)
                        }
                      />
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <h2 className="text-center pr20-lh32 high-emphasis-color mt-5">
                  You have no photos in this folder
                </h2>
              )}
            </div>
          </>
        )
      ) : null}
      {addFolderPopup && <AddFolder handleClose={toggleAddFolderPopup} />}
      {deletePopup.open && (
        <DeletePopup
          heading="DELETE IMAGE"
          text="The image will be deleted permanently. This cannot be undone."
          handleDelete={handleDeleteImageEvent}
          handleClose={closeDeletePopup}
        />
      )}
    </main>
  );
};

export default PhotoGalleryList;
