import axios from 'axios';
import { size } from 'lodash'
import { STATUS_UPLOAD } from './uploadFiles.constants';

export const modifyFiles = (existingFiles, newFileDatas) => {
    let fileToUpload = {}
    for (let i = 0; i < size(newFileDatas); i++) {
        const id = newFileDatas[i]?.sequence ? newFileDatas[i]?.sequence : size(existingFiles)+i+1
        const CancelToken = axios.CancelToken  //--> can be used for cancelling upload progress
        const source = CancelToken.source()
        fileToUpload = {
            ...fileToUpload,
            [id]: {
                id,
                file: newFileDatas[i].file,
                payload : newFileDatas[i].payload,
                fileUrl : '',
                progress: 0,
                cancelSource: source,
                status: STATUS_UPLOAD.uploading,
            },
        }
    }
    return fileToUpload
}

export const addIds = (idsArray, idToBeAdded) => {
    return size(idsArray) > 0 ? [...idsArray, idToBeAdded] : [ idToBeAdded ]
}