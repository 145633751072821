import React, { useState } from "react";
import { isEmpty } from "lodash";
import CollectionHeaderPartner from "../collection-header-partner/CollectionHeaderPartner";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { truncate } from "../../../utils/utils";
import { STD_ERROR_MESSAGE } from "../../../utils/constant";
import DeletePopup from "../delete-popup/DeletePopup";
import EditQuestionGamesEm from "../edit-question-games-em/EditQuestionGamesEm";

import {
  updatePreferenceQuestionById,
  deletePreferenceQuestionById,
  fetchQuestionsForPreferencesEm,
} from "../../../services/preferences.service";
import PreferenceQuestionItem from "../prefrence-question-item/PrefrenceQuestionItem";

export default function ListQuestionsPreferencesEm(props) {
  const { onAddQuestionButtonClick } = props;
  const [deleteQuestionPopup, setDeleteQuestionPopup] = useState({
    open: false,
    data: null,
  });
  const [editItem, setEditItem] = useState([]);
  const preferenceState = useSelector((state) => state.preferenceEm);
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const preferencesData = preferenceState?.response.data;

  const handleBackToCollectionsBtnClick = () => {
    history.goBack();
  };

  const handleOnPublishChangeEvent = (preferenceData) => {
    const publishStatus = preferenceData?.published;
    const prefId = preferenceData?.id;
    if (typeof publishStatus === "boolean" && prefId) {
      updatePreferenceQuestionById(params.collectionId, prefId, {
        published: !publishStatus,
      })
        .then((res) => {
          dispatch(fetchQuestionsForPreferencesEm(params.collectionId));
        })
        .catch((error) => toast.error(STD_ERROR_MESSAGE));
    } else {
      toast.error(STD_ERROR_MESSAGE);
    }
  };

  const hadleOnEditPreferenceQuestionClick = (preferenceData) => {
    setEditItem((ps) => {
      return [...ps, preferenceData.id];
    });
  };

  const handleOnDeleteQuestionClick = (preferenceData) => {
    setDeleteQuestionPopup({ open: true, data: preferenceData });
  };

  const handleDeletePreferenceQuestionAfterConfirmation = () => {
    const prefId = deleteQuestionPopup.data?.id;
    if (!isEmpty(prefId)) {
      deletePreferenceQuestionById(params.collectionId, prefId)
        .then((res) => {
          dispatch(fetchQuestionsForPreferencesEm(params.collectionId));
          setDeleteQuestionPopup({ open: false, data: null });
        })
        .catch((error) => {
          toast.error(STD_ERROR_MESSAGE);
        });
    } else {
      toast.error(STD_ERROR_MESSAGE);
    }
  };

  function handleCancelEditBtnClick(id) {
    setEditItem((ps) => {
      return ps.filter((item) => item !== id);
    });
  }

  return (
    <main
      style={{ maxWidth: "850px", marginLeft: "auto", marginRight: "auto" }}
    >
      <CollectionHeaderPartner
        title="Saved Questions"
        backToCollectionsBtn={true}
        handleBackToCollectionsBtn={handleBackToCollectionsBtnClick}
        generalBtn={true}
        generalBtnLabel="Add Questions"
        onGeneralBtnClick={onAddQuestionButtonClick}
        generalBtn2={true}
      />
      <div className="mt-3">
        {!isEmpty(preferencesData) ? (
          preferencesData.map((preferenceData) =>
            editItem.includes(preferenceData.id) ? (
              <EditQuestionGamesEm
                preferenceData={preferenceData}
                key={preferenceData.id}
                onCancelBtnClick={() =>
                  handleCancelEditBtnClick(preferenceData.id)
                }
              />
            ) : (
              <PreferenceQuestionItem
                preferenceData={preferenceData}
                key={preferenceData.id}
                onDeleteQuestionClick={() =>
                  handleOnDeleteQuestionClick(preferenceData)
                }
                onEditQuestionClick={() =>
                  hadleOnEditPreferenceQuestionClick(preferenceData)
                }
                onPublishChangeClick={() =>
                  handleOnPublishChangeEvent(preferenceData)
                }
              />
            )
          )
        ) : (
          <h2 className="text-center pr20-lh32 high-emphasis-color mt-5">
            You have no questions added in this section
          </h2>
        )}
      </div>
      {deleteQuestionPopup.open ? (
        <DeletePopup
          heading="DELETE QUESTION"
          text={`The question "${truncate(
            deleteQuestionPopup.data?.question || "",
            10
          )}" will be deleted permanently. This cannot be undone.`}
          handleClose={() =>
            setDeleteQuestionPopup({ open: false, data: null })
          }
          handleDelete={handleDeletePreferenceQuestionAfterConfirmation}
        />
      ) : null}
    </main>
  );
}
