import {
  CLEAR_FETCH_PERMALINK_AD_STATES,
  REQUEST_FETCH_PERMALINK_AD,
  FETCH_PERMALINK_AD_FAILURE,
  FETCH_PERMALINK_AD_SUCCESS,
} from "./permalink.types";

export const requestFetchPermalinkAd = () => {
  return {
    type: REQUEST_FETCH_PERMALINK_AD,
  };
};

export const fetchPermalinkAdSuccess = (response) => {
  return {
    type: FETCH_PERMALINK_AD_SUCCESS,
    payload: response,
  };
};

export const fetchPermalinkAdFailure = (error) => {
  return {
    type: FETCH_PERMALINK_AD_FAILURE,
    payload: error,
  };
};

export const clearFetchPermalinkAdStates = () => {
  return {
    type: CLEAR_FETCH_PERMALINK_AD_STATES,
  };
};
