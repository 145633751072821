import React from "react";
import Dropzone from "react-dropzone";

import ButtonAccent from "../../common/button-accent/ButtonAccent";
import uploadIcon from "../../../assets/icon/upload.svg";

import "../upload-photos/upload-photos.css";

const UploadFiles = (props) => {
  return (
    <div className="upload-card-border padding-32">
      <div className="margin-bottom-20">
        <h3 className="pb20-lh32 grey-2-color text-center m-0">
          {props.heading}
        </h3>
        <h5 className="pr12-lh24 high-emphasis-color text-center m-0">
          {props.subHeading}
        </h5>
      </div>
      <Dropzone
        onDrop={props.handleDropEvent}
        accept="image/jpeg"
        noDrag={true}
        minSize={1024}
        maxSize={52428800}
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} />
            <img
              className="upload-icon-size mb-3"
              src={uploadIcon}
              alt="Upload Icon"
            ></img>
            <ButtonAccent
              className="upload-btn-padding pb14-lh21"
              label={props.buttonLabel}
            />
          </div>
        )}
      </Dropzone>
    </div>
  );
};

export default UploadFiles;
