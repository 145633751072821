import { 
    REQUEST_GALLERY_FOLDERS_FETCH_EM,
    FETCH_GALLERY_FOLDERS_EM_SUCCESS,
    FETCH_GALLERY_FOLDERS_EM_FAILURE,
    CLEAR_FETCH_GALLERY_FOLDERS_EM_STATES
} from './galleryFolders.types';


export const requestGalleryFoldersFetchEm = () => {
    return {
        type : REQUEST_GALLERY_FOLDERS_FETCH_EM,
    }
}

export const fetchGalleryFoldersEmSuccess = (id, response) => {
    return {
        type : FETCH_GALLERY_FOLDERS_EM_SUCCESS,
        payload : {
            id,
            response
        }
    }
}

export const fetchGalleryFoldersEmFailure = (error) => {
    return {
        type : FETCH_GALLERY_FOLDERS_EM_FAILURE,
        payload : error
    }
}

export const clearFetchGalleryFoldersEmStates = () => {
    return {
        type : CLEAR_FETCH_GALLERY_FOLDERS_EM_STATES
    }
}