import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { isEmpty } from "lodash";
import { toast } from "react-toastify";
import {
  SECTION_RESIDENTS_COMPONENTS,
  STD_ERROR_MESSAGE,
} from "../../../../utils/constant.js";
import { fetchResidents } from "../../../../services/residents.service";
import Residents from "../../residents/Residents.jsx";
import AddResidents from "../../add-apartment-residents/AddResidents.js";

const SectionResidents = (props) => {
  const [loading, setLoading] = useState(true);
  const residentsState = useSelector((state) => state.residentsAd);
  const dispatch = useDispatch();
  const params = useParams();

  const [currentMainComponent, setCurrentMainComponent] = useState({
    currComponent: null,
    prevComponent: null,
  });
  useEffect(() => {
    if (isEmpty(residentsState.response)) {
      dispatch(fetchResidents(params.collectionId));
    }
  }, []);

  useEffect(() => {
    if (!residentsState.loading && !isEmpty(residentsState.response)) {
      if (!isEmpty(residentsState.response.data)) {
        setCurrentMainComponent((prevState) => {
          return {
            currComponent: SECTION_RESIDENTS_COMPONENTS.list_residents_sections,
            prevComponent: prevState.component,
          };
        });
      } else {
        setCurrentMainComponent((prevState) => {
          return {
            currComponent: SECTION_RESIDENTS_COMPONENTS.add_residents_sections,
            prevComponent: prevState.component,
          };
        });
      }
      setLoading(false);
    }
    if (!residentsState.loading && !isEmpty(residentsState.error)) {
      setLoading(false);
      toast.error(residentsState.error?.data?.error_data || STD_ERROR_MESSAGE);
    }
  }, [residentsState]);
  function getMainComponent() {
    switch (currentMainComponent.currComponent) {
      case SECTION_RESIDENTS_COMPONENTS.add_residents_sections:
        return (
          <AddResidents
            setCurrentMainComponent={setCurrentMainComponent}
            currentMainComponent={currentMainComponent}
          />
        );
      case SECTION_RESIDENTS_COMPONENTS.list_residents_sections:
        return (
          <Residents
            setCurrentMainComponent={setCurrentMainComponent}
            currentMainComponent={currentMainComponent}
          />
        );
      default:
        return null;
    }
  }
  return <>{!loading ? getMainComponent() : null}</>;
};
export default SectionResidents;
