import { reject } from "lodash";
import {
  fetchPreferenceQuestionsEmFailure,
  fetchPreferenceQuestionsEmSuccess,
  requestFetchPreferenceQuestionsEm,
} from "../redux/prefrences/preferences.action";
import ProtectedWebClient from "../utils/protected-web-client";

export const fetchQuestionsForPreferencesEm = (collectionId) => {
  return async (dispatch) => {
    try {
      dispatch(requestFetchPreferenceQuestionsEm());
      const fetchedPrefrenceQuestionsResponse = await ProtectedWebClient.get(
        `/event/${collectionId}/preferences`
      );
      dispatch(
        fetchPreferenceQuestionsEmSuccess(
          fetchedPrefrenceQuestionsResponse?.data
        )
      );
    } catch (error) {
      dispatch(fetchPreferenceQuestionsEmFailure(error?.response));
    }
  };
};

export const addQuestionsForPreferencesEm = (collectionId, payload) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.post(`/event/${collectionId}/preferences`, payload)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error?.response));
  });
};

export const updatePreferenceQuestionById = (collectionId, prefId, payload) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.put(
      `/event/${collectionId}/preferences/${prefId}`,
      payload
    )
      .then((response) => resolve(response.data))
      .catch((error) => reject(error?.response));
  });
};

export const deletePreferenceQuestionById = (collectionId, prefId) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.delete(`/event/${collectionId}/preferences/${prefId}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error?.response));
  });
};

export const fetchPreferenceQuestionUser = (collectionId) => {
  return async (dispatch) => {
    try {
      dispatch(requestFetchPreferenceQuestionsEm());
      const response = await ProtectedWebClient.get(
        `/event/${collectionId}/publishedPreferences`
      );
      dispatch(fetchPreferenceQuestionsEmSuccess(response?.data));
    } catch (error) {
      dispatch(fetchPreferenceQuestionsEmFailure(error?.response));
    }
  };
};

export const submitPrefOption = (collectionId, prefId, payload) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.post(
      `/event/${collectionId}/preferences/${prefId}/submit`,
      payload
    )
      .then((response) => resolve(response.data))
      .catch((error) => reject(error?.response));
  });
};

export const fetchPreferenceQuestionByIdUser = (collectionId, prefId) => {
  return async (dispatch) => {
    try {
      dispatch(requestFetchPreferenceQuestionsEm());
      const response = await ProtectedWebClient.get(
        `/event/${collectionId}/preferences/${prefId}/submit`
      );
      dispatch(fetchPreferenceQuestionsEmSuccess(response?.data));
    } catch (error) {
      dispatch(fetchPreferenceQuestionsEmFailure(error?.response));
    }
  };
};

export const addTravelDetailsPromise = (collectionId, prefId, payload) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.post(
      `/event/${collectionId}/preferences/${prefId}/transport`,
      payload
    )
      .then((response) => resolve(response.data))
      .catch((error) => reject(error?.response));
  });
};

export const addAccomodationDetailsPromise = (
  collectionId,
  groupId,
  payload
) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.post(
      `/event/${collectionId}/preferences/${groupId}/accomodation`,
      payload
    )
      .then((response) => resolve(response.data))
      .catch((error) => reject(error?.response));
  });
};
