import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { isEmpty } from "lodash";
import * as Yup from "yup";
import { Formik, Field, Form } from "formik";
import { toast } from "react-toastify";

import "./sign-in-form.css";

import InputBox from "../../common/input-box/InputBox";
import ButtonPrimary from "../../common/button-accent/ButtonAccent";

import {
  signInByEmailAndPassword,
  forgetPassword,
} from "../../../services/firebase-auth.service";
import { signUpUser } from "../../../services/auth.service";

import {
  ROLE_CATEGORIES,
  STD_ERROR_MESSAGE,
  SIGN_IN_OPTIONS,
} from "../../../utils/constant";
import { storeUserCredentials } from "../../../utils/utils";

import config from "../../../config";
const { CLIENT_URL } = config();

function SignIn(props) {
  const { category } = props;
  const history = useHistory();

  const initialValues = {
    email: "",
    password: "",
    category: category,
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .required("Please enter your email.")
      .email("Invalid email Id"),
    password: Yup.string().required("Please enter your password."),
  });

  const onSubmit = (values) => {
    signInByEmailAndPassword(values.email, values.password)
      .then((res) => {
        const { password, ...rest } = values;
        const payload = { ...rest, firebase_id: res?.user?.uid };
        signUpUser(payload)
          .then((response) => {
            if (category === ROLE_CATEGORIES.eventManager) {
              storeUserCredentials({
                authToken: response.data.access_token,
                userId: response.data.user.id,
                userRole:
                  response.data.user.category || ROLE_CATEGORIES.eventManager,
                userName: response.data.user.name,
                studioName: response.data.user.enterprise_name,
              });
              history.push(`/partner/${response.data.user.id}`);
            } else if (category === ROLE_CATEGORIES.other) {
              storeUserCredentials({
                authToken: response.data.access_token,
                userId: response.data.user.id,
                userRole: response.data.user.category || ROLE_CATEGORIES.other,
              });
              history.push("/collections");
            } else if (category === ROLE_CATEGORIES.admin) {
              storeUserCredentials({
                authToken: response.data.access_token,
                userId: response.data.user.id,
                userRole: response.data.user.category || ROLE_CATEGORIES.admin,
                userName: response.data.user.name,
              });
              history.push(`/admin/${response.data.user.id}`);
            }
          })
          .catch((err) => {
            toast.error(err?.data?.error_data || STD_ERROR_MESSAGE);
          });
      })
      .catch((error) => {
        toast.error(error.message || STD_ERROR_MESSAGE);
      });
  };

  function handleForgetPassword(emailId) {
    forgetPassword(emailId, {
      url:
        category === ROLE_CATEGORIES.eventManager
          ? `${CLIENT_URL}/partner/sign-in`
          : `${CLIENT_URL}/sign-in?opt=${SIGN_IN_OPTIONS.emailSignIn}`,
    })
      .then((res) => {
        toast.success(
          "Please check your registered email. A password reset link has been shared."
        );
      })
      .catch((error) => {
        toast.error(error.code || STD_ERROR_MESSAGE);
      });
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => {
          return (
            <Form>
              <Field name="email">
                {(props) => {
                  const { field, meta, form } = props;
                  return (
                    <>
                      <InputBox
                        type="email"
                        placeholder="Email ID"
                        {...field}
                        errorMsg={meta.touched && meta.error ? meta.error : ""}
                      />
                    </>
                  );
                }}
              </Field>
              <Field name="password">
                {(props) => {
                  const { field, meta, form } = props;
                  return (
                    <>
                      <InputBox
                        type="password"
                        placeholder="Password"
                        {...field}
                        errorMsg={
                          meta.touched && meta.error ? meta.error : null
                        }
                      />
                    </>
                  );
                }}
              </Field>
              <ButtonPrimary
                type="submit"
                className="button-w100-mt21 pb14-lh21"
                label="Sign in"
              />
              <h5
                className="option-text pb10-lh15 pt-cursor"
                onClick={() => {
                  formik.setFieldTouched("email");
                  if (!formik.errors.email && formik.values.email) {
                    handleForgetPassword(formik.values.email);
                  }
                }}
              >
                Forgot Password?
              </h5>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

export default SignIn;
