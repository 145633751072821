export const REQUEST_MASTER_GUEST_INVITES_FETCH_EM = "REQUEST_MASTER_GUEST_INVITES_FETCH_EM"
export const FETCH_MASTER_GUEST_INVITES_EM_SUCCESS = "FETCH_MASTER_GUEST_INVITES_EM_SUCCESS"

export const REQUEST_INVITE_GUEST_INVITES_FETCH_EM = "REQUEST_INVITE_GUEST_INVITES_FETCH_EM"
export const FETCH_INVITE_GUEST_INVITES_EM_SUCCESS = "FETCH_INVITE_GUEST_INVITES_EM_SUCCESS"

export const REQUEST_ITINERARY_GUEST_INVITES_FETCH_EM = "REQUEST_ITINERARY_GUEST_INVITES_FETCH_EM"
export const FETCH_ITINERARY_GUEST_INVITES_EM_SUCCESS = "FETCH_ITINERARY_GUEST_INVITES_EM_SUCCESS"

export const FETCH_GUEST_INVITES_EM_FAILURE = "FETCH_GUEST_INVITES_EM_FAILURE"

export const CLEAR_FETCH_GUEST_INVITES_EM_STATES = "CLEAR_FETCH_GUEST_INVITES_EM_STATES"