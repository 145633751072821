import {
  REQUEST_MYPHOTOS_GALLERY_FOLDERS_FETCH_EM,
  FETCH_MYPHOTOS_GALLERY_FOLDERS_EM_SUCCESS,
  FETCH_MYPHOTOS_GALLERY_FOLDERS_EM_FAILURE,
  CLEAR_MYPHOTOS_GALLERY_FOLDERS_EM_STATES,
} from "./myPhotosGalleryFolders.types";

let initialState = {
  reload: false,
  response: {},
  error: null,
  loading: false,
};

const fetchMyPhotosGalleryFoldersEmReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_MYPHOTOS_GALLERY_FOLDERS_FETCH_EM:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_MYPHOTOS_GALLERY_FOLDERS_EM_SUCCESS:
      return {
        ...state,
        loading: false,
        response: {
          ...state.response,
          [action.payload.id]: action.payload.response,
        },
        reload: false,
      };

    case FETCH_MYPHOTOS_GALLERY_FOLDERS_EM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        reload: false,
      };

    case CLEAR_MYPHOTOS_GALLERY_FOLDERS_EM_STATES:
      return {
        ...state,
        loading: false,
        response: {},
        error: null,
        reload: false,
      };

    default:
      return state;
  }
};

export default fetchMyPhotosGalleryFoldersEmReducer;
