import React from "react";
import ButtonAccent from "../../common/button-accent/ButtonAccent";
import InputBox from "../../common/input-box/InputBox";
import cancelIcon from "../../../assets/icon/cancel.svg";
import { useDispatch } from "react-redux";
import SelectBox from "../../common/select-box/SelectBox";
import * as Yup from "yup";
import { Formik, Form, Field, FieldArray } from "formik";
import {
  ENTITY_TYPES,
  ROLE_CATEGORIES,
  STD_ERROR_MESSAGE,
} from "../../../utils/constant";
import { toast } from "react-toastify";
import PhoneInput from "../../common/v2/phone-input/PhoneInput";
import Error from "../../common/v2/error/Error";
import { isValidPhoneNumber } from "react-phone-number-input";

import "./create-entity-popup.css";
import {
  deleteNewUser,
  signUpByEmailAndPassword,
} from "../../../services/firebase-auth.service";
import { deactivateUser, signUpUser } from "../../../services/auth.service";
import {
  createEntity,
  getAllEntities,
} from "../../../services/entities.service";

const options = [
  {
    value: ENTITY_TYPES.venue,
    label: "Venue",
  },
  {
    value: ENTITY_TYPES.planner,
    label: "Wedding Planner",
  },
  {
    value: ENTITY_TYPES.other,
    label: "Other",
  },
];

const CreateEntityPopup = (props) => {
  const { handleClose } = props;
  const dispatch = useDispatch();

  const initialValues = {
    entity: {
      entity_type: "o",
      name: "",
    },
    client: {
      name: "",
      website: "",
      email: "",
      mobile: "",
      password: "",
      category: ROLE_CATEGORIES.eventManager,
    },
  };

  const onSubmit = (values) => {
    const { entity, client } = values;
    signUpByEmailAndPassword(client.email, client.password)
      .then((res) => {
        const { password, ...rest } = client;
        const payload = {
          ...rest,
          firebase_id: res?.user?.uid,
          enterprise_name: entity.name,
        };
        signUpUser(payload)
          .then((response) => {
            const payload = { em_id: response?.data.user.id, ...entity };
            const payloadCopy = JSON.parse(JSON.stringify(payload));
            createEntity(payloadCopy)
              .then((response) => {
                handleClose();
                dispatch(getAllEntities());
              })
              .catch((err) => {
                deactivateUser(response?.data.user.id);
                deleteNewUser(res.user);
                toast.error(err?.data?.error_data || STD_ERROR_MESSAGE);
              });
          })
          .catch((err) => {
            deleteNewUser(res.user);
            toast.error(err?.data?.error_data || STD_ERROR_MESSAGE);
          });
      })
      .catch((error) => {
        toast.error(error?.message || STD_ERROR_MESSAGE);
      });
  };

  const validationSchema = Yup.object().shape({
    entity: Yup.object().shape({
      name: Yup.string().required("Please enter the entity name"),
      entity_type: Yup.string().required("Please select the entity type"),
    }),
    client: Yup.object().shape({
      name: Yup.string().required("Please enter client name"),
      website: Yup.string()
        .required("Please enter your website.")
        .url("Invalid url"),
      email: Yup.string()
        .required("Please enter client email")
        .email("Invalid email"),
      mobile: Yup.string()
        .required("Please enter your mobile number.")
        .test("validatePhoneNumber", "Invalid mobile number", (val) => {
          return val && isValidPhoneNumber(val);
        }),
      password: Yup.string()
        .required("Please enter your password.")
        .test(
          "len",
          "Must contain atleast 1 character and must not contain exceeds more than 15 Characters",
          (val) => {
            return val && val.trim().length < 16 && val.trim().length > 0;
          }
        ),
    }),
  });

  return (
    <div className="popup">
      <div className="box">
        <img
          className="close-icon"
          onClick={handleClose}
          src={cancelIcon}
          alt=""
        />
        <h1 className="pb20-lh32 mb-3"> CREATE NEW ENTITY</h1>
        <div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {(formik) => (
              <Form>
                <Field name="entity.name">
                  {(props) => {
                    const { field, meta } = props;
                    return (
                      <>
                        <InputBox
                          type="text"
                          placeholder="Enter your Entity Name"
                          {...field}
                          errorMsg={
                            meta.touched && meta.error ? meta.error : ""
                          }
                        />
                      </>
                    );
                  }}
                </Field>
                <div className="form-inline">
                  <Field name="entity.entity_type">
                    {(props) => {
                      const { field, meta } = props;
                      return (
                        <>
                          <SelectBox
                            options={options}
                            className="mb-3 col col-sm-6 p-0"
                            placeholder="Entity Type"
                            {...field}
                            errorMsg={
                              meta.touched && meta.error ? meta.error : ""
                            }
                          />
                        </>
                      );
                    }}
                  </Field>
                </div>
                <FieldArray name="client">
                  {(fieldArrayProps) => {
                    const { push, form } = fieldArrayProps;
                    const { values } = form;
                    const { client } = values;
                    return (
                      <div>
                        <h2 className="pr14-lh24">Enter Client Details</h2>
                        <Field name="client.name">
                          {(props) => {
                            const { field, meta } = props;
                            return (
                              <>
                                <InputBox
                                  type="text"
                                  placeholder="Name"
                                  {...field}
                                  errorMsg={
                                    meta.touched && meta.error ? meta.error : ""
                                  }
                                />
                              </>
                            );
                          }}
                        </Field>
                        <Field name="client.website">
                          {(props) => {
                            const { field, meta } = props;
                            return (
                              <>
                                <InputBox
                                  type="text"
                                  placeholder="Enter Website"
                                  {...field}
                                  errorMsg={
                                    meta.touched && meta.error ? meta.error : ""
                                  }
                                />
                              </>
                            );
                          }}
                        </Field>
                        <Field name="client.mobile">
                          {(props) => {
                            const { field, form, meta } = props;
                            return (
                              <div className="mb-3">
                                <PhoneInput
                                  value={field.value}
                                  onChange={(value) =>
                                    form.setFieldValue(field.name, value)
                                  }
                                  name={field.name}
                                  placeholder="Mobile Number"
                                  onBlur={form.handleBlur}
                                  error={meta.touched && meta.error}
                                />
                                {meta.touched && meta.error && (
                                  <Error
                                    errorMsg={meta.error}
                                    className="mt-2"
                                  />
                                )}
                              </div>
                            );
                          }}
                        </Field>
                        <Field name="client.email">
                          {(props) => {
                            const { field, meta } = props;
                            return (
                              <InputBox
                                type="email"
                                placeholder="Email Id"
                                {...field}
                                errorMsg={
                                  meta.touched && meta.error ? meta.error : ""
                                }
                              />
                            );
                          }}
                        </Field>
                        <Field name="client.password">
                          {(props) => {
                            const { field, meta } = props;
                            return (
                              <InputBox
                                type="password"
                                placeholder="Enter Password"
                                {...field}
                                errorMsg={
                                  meta.touched && meta.error ? meta.error : ""
                                }
                              />
                            );
                          }}
                        </Field>
                      </div>
                    );
                  }}
                </FieldArray>
                <ButtonAccent
                  type="submit"
                  className="button-w100-mt21 pb14-lh21"
                  label="CREATE ENTITY"
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default CreateEntityPopup;
