import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { isEmpty } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { getAllVenuesInCollection } from "../../../../services/venue.service";
import {
  SECTION_VENUE_COMPONENTS,
  STD_ERROR_MESSAGE,
  WEDDING_FOLDERS,
} from "../../../../utils/constant";

import AddOrEditVenue from "../../add-or-edit-venue/AddOrEditVenue";
import VenueList from "../../venue-list/VenueList";

const SectionVenue = (props) => {
  const { setSelected } = props;
  const [loading, setLoading] = useState(true);
  const [currentMainComponent, setCurrentMainComponent] = useState({
    currComponent: null,
    prevComponent: null,
  });

  const venueState = useSelector((state) => state.venueEm);
  const dispatch = useDispatch();
  const params = useParams();

  const venueData = venueState.response?.data;

  useEffect(() => {
    if (isEmpty(venueState.response)) {
      dispatch(getAllVenuesInCollection(params.collectionId));
    }
  }, []);

  useEffect(() => {
    if (!venueState.loading && !isEmpty(venueState.response)) {
      setLoading(false);
      if (!isEmpty(venueData)) {
        setCurrentMainComponent((prevState) => {
          return {
            currComponent: SECTION_VENUE_COMPONENTS.venue_list,
            prevComponent: prevState.currComponent,
          };
        });
      } else {
        setCurrentMainComponent((prevState) => {
          return {
            currComponent: SECTION_VENUE_COMPONENTS.add_venue,
            prevComponent: prevState.currComponent,
          };
        });
      }
    }

    if (!venueState.loading && !isEmpty(venueState.error)) {
      setLoading(false);
      toast.error(venueState.error?.error_data || STD_ERROR_MESSAGE);
    }
  }, [venueState]);

  function handleCancelClick() {
    setCurrentMainComponent((prevState) => {
      return {
        currComponent:
          prevState.prevComponent || SECTION_VENUE_COMPONENTS.venue_list,
        prevComponent: prevState.currComponent,
      };
    });
  }

  function changeNextComponent(component) {
    setCurrentMainComponent((prevState) => {
      return {
        currComponent: component,
        prevComponent: prevState.currComponent,
      };
    });
  }

  function getMainComponent() {
    switch (currentMainComponent.currComponent) {
      case SECTION_VENUE_COMPONENTS.venue_list:
        return (
          <VenueList
            onMasterInviteBtnClick={() =>
              setSelected({
                folder: WEDDING_FOLDERS.master_invite_list,
                subFolder: null,
              })
            }
            changeNextComponent={changeNextComponent}
          />
        );
      case SECTION_VENUE_COMPONENTS.add_venue:
        return (
          <AddOrEditVenue
            action="add"
            onCancelClick={handleCancelClick}
            changeNextComponent={changeNextComponent}
          />
        );
      case SECTION_VENUE_COMPONENTS.edit_venue:
        return (
          <AddOrEditVenue
            action="edit"
            onCancelClick={handleCancelClick}
            changeNextComponent={changeNextComponent}
          />
        );
      default:
        return null;
    }
  }

  return !loading ? getMainComponent() : null;
};

export default SectionVenue;
