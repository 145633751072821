import {
  REQUEST_GROUP_FETCH_EM,
  FETCH_GROUP_EM_SUCCESS,
  FETCH_GROUP_EM_FAILURE,
  CLEAR_FETCH_GROUP_EM_STATES,
} from "./group.types";

let initialState = {
  reload: false,
  response: {},
  error: null,
  loading: false,
};

const fetchGroupEmReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_GROUP_FETCH_EM:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_GROUP_EM_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
        reload: false,
      };

    case FETCH_GROUP_EM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        reload: false,
      };

    case CLEAR_FETCH_GROUP_EM_STATES:
      return {
        ...state,
        loading: false,
        response: {},
        error: null,
        reload: false,
      };

    default:
      return state;
  }
};

export default fetchGroupEmReducer;
