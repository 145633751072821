import axios from 'axios';

import uploadFileTypes from './uploadFiles.types';
import { modifyFiles, addIds } from './uploadFiles.utils';
import {STATUS_UPLOAD} from './uploadFiles.constants';

const INITIAL_STATE = {
    fileProgress: {
    // format will be like below
    // 1: {
    //   id: 1,
    //   file,
    //   payload,
    //   fileUrl,
    //   progress: 0,
    //   cancelSource: source,
    //   status: 0,
    //   imageId: null
    // },
    },
    uploadSuccess : {
        ids : []
    },
    uploadError : {
        ids : []
    }
}

const fileProgressReducer = (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case uploadFileTypes.SET_UPLOAD_FILES:
        return {
            ...state,
            fileProgress: {
                ...state.fileProgress,
                ...modifyFiles(state.fileProgress, action.payload),
            },
        }

        case uploadFileTypes.SET_UPLOAD_PROGRESS:
            return {
                ...state,
                fileProgress: {
                    ...state.fileProgress,
                    [action.payload.id]: {
                        ...state.fileProgress[action.payload.id],
                        progress: action.payload.progress,
                    },
                },
            }

        case uploadFileTypes.SUCCESS_UPLOAD_FILE:
            return {
                ...state,
                fileProgress: {
                    ...state.fileProgress,
                    [action.payload.id]: {
                        ...state.fileProgress[action.payload.id],
                        status: STATUS_UPLOAD.success,
                        fileUrl: action.payload.url,
                    },
                },
                uploadSuccess : {
                    ...state.uploadSuccess,
                    ids : addIds(state.uploadSuccess.ids, action.payload.id)
                }
            }

        case uploadFileTypes.SET_IMAGE_PREVIEW:
            return {
                ...state,
                fileProgress: {
                    ...state.fileProgress,
                    [action.payload.id]: {
                        ...state.fileProgress[action.payload.id],
                        fileUrl : action.payload.data
                    }
                }
            }
        
        case uploadFileTypes.SET_IMAGE_ID:
            return {
                ...state,
                fileProgress: {
                    ...state.fileProgress,
                    [action.payload.id]: {
                        ...state.fileProgress[action.payload.id],
                        imageId : action.payload.imageId
                    }
                }
            }

        case uploadFileTypes.FAILURE_UPLOAD_FILE:
            return {
                ...state,
                fileProgress: {
                    ...state.fileProgress,
                    [action.payload]: {
                        ...state.fileProgress[action.payload],
                        status: STATUS_UPLOAD.failed,
                        progress: 0,
                    },
                },
                uploadError : {
                    ...state.uploadError,
                    ids : addIds(state.uploadError.ids, action.payload)
                }
            }

        case uploadFileTypes.CLEAR_UPLOAD_ERROR_STACK:
            return {
                ...state,
                uploadError : {
                    ...state.uploadError,
                    ids : []
                }
            }

        case uploadFileTypes.CLEAR_UPLOAD_FILES:
            return {
                ...state,
                fileProgress : {},
                uploadSuccess : {
                    ids : []
                },
                uploadError : {
                    ids : []
                }
            }

        case uploadFileTypes.RETRY_UPLOAD_FAILED_FILE:
            const CancelToken = axios.CancelToken
            const cancelSource = CancelToken.source()

            return {
                ...state,
                fileProgress: {
                    ...state.fileProgress,
                    [action.payload]: {
                        ...state.fileProgress[action.payload],
                        status: STATUS_UPLOAD.uploading,
                        progress: 0,
                        cancelSource,
                        imageId: null
                    }
                }
            }

        default:
            return state
    }
}

export default fileProgressReducer