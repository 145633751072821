import {
  DEV_CLIENT_URL,
  DEV_SERVER_URL,
  DEV_BASE_IMAGE_URL,
  LOCAL_CLIENT_URL,
  LOCAL_SERVER_URL,
  PROD_CLIENT_URL,
  PROD_SERVER_URL,
  PROD_BASE_IMAGE_URL,
} from "../utils/constant";

const config = () => {
  const env = process.env.REACT_APP_ENV;

  switch (env) {
    case "staging":
      return {
        CLIENT_URL: DEV_CLIENT_URL,
        SERVER_URL: DEV_SERVER_URL,
        BASE_IMAGE_URL: DEV_BASE_IMAGE_URL,
      };
    case "production":
      return {
        CLIENT_URL: PROD_CLIENT_URL,
        SERVER_URL: PROD_SERVER_URL,
        BASE_IMAGE_URL: PROD_BASE_IMAGE_URL,
      };
    default:
      return {
        CLIENT_URL: LOCAL_CLIENT_URL,
        SERVER_URL: LOCAL_SERVER_URL,
        BASE_IMAGE_URL: DEV_BASE_IMAGE_URL,
      };
  }
};

export default config;
