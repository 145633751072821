import {
  CLEAR_FETCH_ENTITIES_AD_STATES,
  REQUEST_FETCH_ENTITIES_AD,
  FETCH_ENTITIES_AD_FAILURE,
  FETCH_ENTITIES_AD_SUCCESS,
} from "./entities.types";

export const requestFetchEntitiesAd = () => {
  return {
    type: REQUEST_FETCH_ENTITIES_AD,
  };
};

export const fetchEntitiesAdSuccess = (response) => {
  return {
    type: FETCH_ENTITIES_AD_SUCCESS,
    payload: response,
  };
};

export const fetchEntitiesAdFailure = (error) => {
  return {
    type: FETCH_ENTITIES_AD_FAILURE,
    payload: error,
  };
};

export const clearFetchEntitiesAdStates = () => {
  return {
    type: CLEAR_FETCH_ENTITIES_AD_STATES,
  };
};
