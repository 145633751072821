import React from "react";
import "./input-box.css";

import Error from "../v2/error/Error";

const InputBox = (props) => {
  let floatingLabelClasses = props.errorMsg
    ? "error-floating-label-content pr18-lh32"
    : "floating-label-content pr18-lh32";

  return (
    <div
      className={
        props.errorMsg
          ? `margin-bottom-12 ${props.className}`
          : `margin-bottom-16 ${props.className}`
      }
    >
      <div
        className={`input-box-container ${
          Boolean(props.disabled) ? "disabled" : ""
        }`}
      >
        <input
          type={props.type}
          name={props.name}
          placeholder={props.placeholder}
          value={props.value}
          className={
            props.errorMsg ? "input-box pb16-lh24 error" : "input-box pb16-lh24"
          }
          onBlur={props.onBlur}
          onChange={props.onChange}
          autoComplete="off"
        />
        <label className="floating-label">
          <span className={floatingLabelClasses}>{props.placeholder}</span>
        </label>
      </div>
      {props.errorMsg ? (
        <Error errorMsg={props.errorMsg} className="mt-2" />
      ) : null}
    </div>
  );
};

export default InputBox;
