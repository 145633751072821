import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import Grid from "@mui/material/Grid";
import * as Yup from "yup";
import Chip from "@mui/material/Chip";
import { toast } from "react-toastify";
import { isEmpty, size, startsWith } from "lodash";
import { isValidPhoneNumber } from "react-phone-number-input";

import CollectionHeaderPartner from "../collection-header-partner/CollectionHeaderPartner";
import ButtonAccent from "../../common/button-accent/ButtonAccent";
import errorInfoIcon from "../../../assets/icon/info-red.svg";
import {
  getMasterGuestInvitesOfCollection,
  inviteGuestsToMasterPromise,
  removeInvitedGuestFromMasterPromise,
  inviteGuestsToInvitationPromise,
  getGuestOfInvitesCollection,
  removeGuestFromInvitationPromise,
  inviteGuestsToItineraryPromise,
  getGuestOfItineraryCollection,
  removeGuestFromItineraryPromise,
} from "../../../services/guest-invite.service";
import { GUEST_COMPONENTS, STD_ERROR_MESSAGE } from "../../../utils/constant";

const GuestInviteList = (props) => {
  const { component, fileData } = props;
  const [loading, setLoading] = useState(true);
  const inputRef = useRef(null);
  const params = useParams();

  const dispatch = useDispatch();
  const guestInviteListState = useSelector((state) => state.guestInvitesEm);

  const masterGuestInvitesData = guestInviteListState?.masterGuestListResponse;
  const invitationGuestInvitesData =
    guestInviteListState?.inviteGuestListResponse;
  const itineraryGuestInvitesData =
    guestInviteListState?.itineraryGuestListResponse;

  function onSubmit(values, { resetForm }) {
    const emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const payload = [];
    for (const value of values.guest) {
      if (value.match(emailRegex)) {
        payload.push({ email: value });
      } else {
        payload.push({ mobile: value });
      }
    }

    switch (component) {
      case GUEST_COMPONENTS.master: {
        inviteGuestsToMasterPromise(params.collectionId, payload)
          .then((res) => {
            dispatch(getMasterGuestInvitesOfCollection(params.collectionId));
            inputRef.current.value = "";
            resetForm();
          })
          .catch((err) => {
            toast.error(err?.error_data || STD_ERROR_MESSAGE);
          });
        break;
      }

      case GUEST_COMPONENTS.invite: {
        inviteGuestsToInvitationPromise(
          params.collectionId,
          fileData?.sequence,
          payload
        )
          .then((res) => {
            dispatch(
              getGuestOfInvitesCollection(
                params.collectionId,
                fileData?.sequence
              )
            );
            inputRef.current.value = "";
            resetForm();
          })
          .catch((err) => {
            toast.error(err?.error_data || STD_ERROR_MESSAGE);
          });
        break;
      }

      case GUEST_COMPONENTS.itinerary: {
        inviteGuestsToItineraryPromise(
          params.collectionId,
          fileData?.sequence,
          payload
        )
          .then((res) => {
            dispatch(
              getGuestOfItineraryCollection(
                params.collectionId,
                fileData?.sequence
              )
            );
            inputRef.current.value = "";
            resetForm();
          })
          .catch((err) => {
            toast.error(err?.error_data || STD_ERROR_MESSAGE);
          });
        break;
      }

      default:
    }
  }

  useEffect(() => {
    switch (component) {
      case GUEST_COMPONENTS.master: {
        if (isEmpty(masterGuestInvitesData)) {
          dispatch(getMasterGuestInvitesOfCollection(params.collectionId));
        }
        break;
      }
      case GUEST_COMPONENTS.invite: {
        if (isEmpty(invitationGuestInvitesData?.[fileData?.sequence])) {
          dispatch(
            getGuestOfInvitesCollection(params.collectionId, fileData?.sequence)
          );
        }
        break;
      }
      case GUEST_COMPONENTS.itinerary: {
        if (isEmpty(itineraryGuestInvitesData?.[fileData?.sequence])) {
          dispatch(
            getGuestOfItineraryCollection(
              params.collectionId,
              fileData?.sequence
            )
          );
        }
        break;
      }
      default:
    }
  }, []);

  useEffect(() => {
    switch (component) {
      case GUEST_COMPONENTS.master: {
        if (
          !guestInviteListState.loading &&
          !isEmpty(guestInviteListState.masterGuestListResponse)
        ) {
          setLoading(false);
        }
        break;
      }
      case GUEST_COMPONENTS.invite: {
        if (
          !guestInviteListState.loading &&
          !isEmpty(guestInviteListState.inviteGuestListResponse)
        ) {
          setLoading(false);
        }
        break;
      }
      case GUEST_COMPONENTS.itinerary: {
        if (
          !guestInviteListState.loading &&
          !isEmpty(guestInviteListState.itineraryGuestListResponse)
        ) {
          setLoading(false);
        }
        break;
      }
      default:
    }
    if (!guestInviteListState.loading && !isEmpty(guestInviteListState.error)) {
      setLoading(false);
      toast.error(guestInviteListState?.error?.error_data || STD_ERROR_MESSAGE);
    }
  }, [guestInviteListState]);

  const initialValues = {
    guest: [],
  };

  const validationSchema = Yup.object().shape({
    guest: Yup.array()
      .of(
        Yup.string().test("text_validation", "Invalid", async (value) => {
          if (!value) return false;
          const emailSchema = Yup.string().email().required();

          const isValidEmail = await emailSchema.isValid(value);
          const isValidNumber = isValidPhoneNumber(value);

          return isValidEmail || isValidNumber;
        })
      )
      .min(1, "Enter at least a mobile number / email ID."),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  });

  function handleEnter(event) {
    if (
      event.key.toLowerCase() === "enter" ||
      event.key.toLowerCase() === ","
    ) {
      const value = event.target.value;
      if (value) {
        const existingValues = formik.values.guest;
        const values = value.split(" ");
        const filteredValues = values
          .map((item) =>
            item.length > 10 && !item.startsWith("+") ? "+" + item : item
          )
          .map((item) => (item.length === 10 ? "+91" + item : item))
          .filter((item) => !existingValues.includes(item));
        formik.setFieldValue("guest", [...existingValues, ...filteredValues]);
        inputRef.current.value = "";
      }
      event.preventDefault();
    }
  }

  const handleDelete = (index, value) => {
    const filteredValues = formik.values.guest?.filter(
      (item, i) => i !== index
    );
    formik.setFieldValue("guest", [...filteredValues]);
  };

  const onRemoveTriggered = (id) => {
    const payload = {
      all: false,
      users: [{ id: id }],
    };
    switch (component) {
      case GUEST_COMPONENTS.master: {
        removeInvitedGuestFromMasterPromise(params.collectionId, payload)
          .then((res) => {
            dispatch(getMasterGuestInvitesOfCollection(params.collectionId));
          })
          .catch((err) => {
            toast.error(err?.error_data || STD_ERROR_MESSAGE);
          });
        break;
      }
      case GUEST_COMPONENTS.invite: {
        removeGuestFromInvitationPromise(
          params.collectionId,
          fileData?.sequence,
          payload
        )
          .then((res) => {
            dispatch(
              getGuestOfInvitesCollection(
                params.collectionId,
                fileData?.sequence
              )
            );
          })
          .catch((err) => {
            toast.error(err?.error_data || STD_ERROR_MESSAGE);
          });
        break;
      }
      case GUEST_COMPONENTS.itinerary: {
        removeGuestFromItineraryPromise(
          params.collectionId,
          fileData?.sequence,
          payload
        )
          .then((res) => {
            dispatch(
              getGuestOfItineraryCollection(
                params.collectionId,
                fileData?.sequence
              )
            );
          })
          .catch((err) => {
            toast.error(err?.error_data || STD_ERROR_MESSAGE);
          });
        break;
      }
      default:
    }
  };

  function getGuests() {
    switch (component) {
      case GUEST_COMPONENTS.master:
        return masterGuestInvitesData?.data;
      case GUEST_COMPONENTS.invite:
        return invitationGuestInvitesData?.[fileData.sequence]?.data;
      case GUEST_COMPONENTS.itinerary:
        return itineraryGuestInvitesData?.[fileData.sequence]?.data;
      default:
    }
  }

  function getTitle() {
    switch (component) {
      case GUEST_COMPONENTS.master:
        return `Master Invite List${
          !isEmpty(masterGuestInvitesData?.data)
            ? `(${size(masterGuestInvitesData?.data)})`
            : ""
        }`;
      case GUEST_COMPONENTS.invite:
        return `${fileData?.image?.image_name} Invite List${
          !isEmpty(invitationGuestInvitesData?.data)
            ? `(${size(invitationGuestInvitesData?.data)})`
            : ""
        }`;
      case GUEST_COMPONENTS.itinerary:
        return `${fileData?.image?.image_name} Invite List${
          !isEmpty(itineraryGuestInvitesData?.data)
            ? `(${size(itineraryGuestInvitesData?.data)})`
            : ""
        }`;
      default:
    }
  }

  return (
    <main
      style={{ maxWidth: "850px", marginLeft: "auto", marginRight: "auto" }}
    >
      {!loading ? (
        <>
          <CollectionHeaderPartner
            title={getTitle()}
            cancelBtn={Boolean(component !== GUEST_COMPONENTS.master)}
            handleCancelBtnClick={props.onCancelBtnClick}
          />
          <div style={{ marginTop: "27px" }}>
            <form onSubmit={formik.handleSubmit}>
              <div className="d-flex align-items-start">
                <div className="mr-3" style={{ flex: "1 1 auto" }}>
                  <div
                    style={{
                      border: "1px solid var(--information-color)",
                      background: "white",
                      borderRadius: "4px",
                      padding: "0.25rem",
                      display: "flex",
                      flexWrap: "wrap",
                    }}
                  >
                    {formik.values.guest.map((item, index) => (
                      <Chip
                        sx={{
                          margin: "0.25rem",
                        }}
                        key={item}
                        label={item}
                        variant="outlined"
                        onDelete={() => handleDelete(index, item)}
                        {...(formik.errors.guest?.[index]
                          ? { color: "error" }
                          : {})}
                      />
                    ))}
                    <input
                      ref={inputRef}
                      placeholder="Enter email ID / mobile number"
                      className="pr16-lh24"
                      style={{
                        minWidth: "300px",
                        border: "none",
                        outline: "none",
                        margin: "0.25rem",
                      }}
                      onKeyDown={handleEnter}
                    />
                  </div>
                  {!isEmpty(formik.errors.guest) ? (
                    formik.errors.guest instanceof Array ? (
                      formik.errors.guest?.includes("Invalid") ? (
                        <div className="d-flex align-items-center justify-content-start mt-2">
                          <img src={errorInfoIcon} alt="" />
                          <span className="margin-left-5 pr10-lh15 error-color">
                            Invalid
                          </span>
                        </div>
                      ) : null
                    ) : (
                      <div className="d-flex align-items-center justify-content-start mt-2">
                        <img src={errorInfoIcon} alt="" />
                        <span className="margin-left-5 pr10-lh15 error-color">
                          {formik.errors.guest}
                        </span>
                      </div>
                    )
                  ) : null}
                </div>
                <ButtonAccent
                  label="Add to List"
                  type="submit"
                  customStyling={{ whiteSpace: "nowrap" }}
                />
              </div>
            </form>
            <div style={{ marginTop: "20px" }}>
              <Grid container spacing={2}>
                {getGuests()?.map((guest) => (
                  <Grid
                    key={guest.id}
                    item
                    md={6}
                    sx={{ paddingRight: "20px" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "50%",
                            background: "blue",
                          }}
                        />
                        <div
                          style={{
                            paddingLeft: "5px",
                          }}
                        >
                          <h4
                            className="pb14-lh21 primary-color"
                            style={{
                              marginBottom: "2px",
                            }}
                          >
                            {guest.name || "Name"}
                          </h4>
                          {guest.email && (
                            <p
                              className="pr12-lh18 medium-emphasis-color"
                              style={{
                                marginBottom: "0px",
                              }}
                            >
                              {guest.email}
                            </p>
                          )}
                          {guest.mobile && (
                            <p
                              className="pr12-lh18 medium-emphasis-color"
                              style={{
                                marginBottom: "0px",
                              }}
                            >
                              {guest.mobile}
                            </p>
                          )}
                        </div>
                      </div>
                      <div style={{ marginLeft: "auto" }}>
                        <p
                          className="pb12-lh18 error-color pt-cursor"
                          style={{
                            marinBottom: "0px",
                          }}
                          onClick={() => onRemoveTriggered(guest.id)}
                        >
                          Remove
                        </p>
                      </div>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </div>
          </div>
        </>
      ) : null}
    </main>
  );
};

export default GuestInviteList;
