import {
  CLEAR_FETCH_POLICIES_AD_STATES,
  FETCH_POLICIES_AD_FAILURE,
  FETCH_POLICIES_AD_SUCCESS,
  REQUEST_POLICIES_FETCH_AD,
} from "./policies.types";

export const requestFetchPoliciesAd = () => {
  return {
    type: REQUEST_POLICIES_FETCH_AD,
  };
};

export const fetchPoliciesAdSuccess = (response) => {
  return {
    type: FETCH_POLICIES_AD_SUCCESS,
    payload: response,
  };
};

export const fetchPoliciesAdFailure = (error) => {
  return {
    type: FETCH_POLICIES_AD_FAILURE,
    payload: error,
  };
};

export const clearFetchPoliciesAdStates = () => {
  return {
    type: CLEAR_FETCH_POLICIES_AD_STATES,
  };
};
