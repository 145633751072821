import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { isEmpty } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import SingleInputPopUp from "../../common/single-input-pop-up/SingleInputPopUp";
import { toast } from "react-toastify";
import {
  createGalleryPromise,
  getAllGallery,
} from "../../../services/gallery.service";
import { STD_ERROR_MESSAGE } from "../../../utils/constant";

const AddFolder = (props) => {
  const params = useParams();

  const dispatch = useDispatch();

  const initialValues = {
    title: "",
  };

  const validationSchema = Yup.object({
    title: Yup.string().required("Please enter the name of the folder"),
  });

  const onSubmit = (values) => {
    createGalleryPromise(params.collectionId, values)
      .then((res) => {
        dispatch(getAllGallery(params.collectionId));
        props.handleClose();
      })
      .catch((err) => {
        toast.error(err.data?.error_data || STD_ERROR_MESSAGE);
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <SingleInputPopUp
      handleClose={props.handleClose}
      heading="NEW FOLDER"
      inputPlaceholder="Name your Folder"
      inputName="title"
      inputOnChange={formik.handleChange}
      inputValue={formik.values.title}
      inputErrorMsg={formik.touched.title && formik.errors.title}
      inputOnBlur={formik.handleBlur}
      btnLabel="Add Folder"
      onSubmit={formik.handleSubmit}
      overlayClassName={props.overlayClassName}
    />
  );
};

export default AddFolder;
