import React, { useState, useEffect } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { LazyLoadComponent } from "react-lazy-load-image-component";

import PatternBackground from "../../../assets/image/pattern_bg.png";

import { getAllItineraryInCollection } from "../../../services/itinerary.service.js";

import { STD_ERROR_MESSAGE } from "../../../utils/constant";
import { getImageUrl } from "../../../utils/utils";

import PatternBackGround1 from "../../../assets/image/pattern_bg_1.png";

import CustomSpinner from "../../common/spinner/CustomSpinner.js";
import { Carousel } from "react-bootstrap";

const UserTimeline = (props) => {
  const [loading, setLoading] = useState(true);

  const itineraryState = useSelector((state) => state.itineraryEm);
  const dispatch = useDispatch();
  const params = useParams();
  const [currItineraryIndex, setCurrItineraryIndex] = useState(0);
  const itineraryData = itineraryState?.response.data;

  const isMobileOrTablet = useMediaQuery("(max-width: 599px)");
  const minHeight = isMobileOrTablet ? 56 + 56 + 40 : 64 + 56 + 40;

  const handleSelect = (selectedIndex, e) => {
    setCurrItineraryIndex(selectedIndex);
  };
  useEffect(() => {
    if (isEmpty(itineraryState.response)) {
      dispatch(getAllItineraryInCollection(props.collectionId));
    }
  }, []);

  useEffect(() => {
    if (!itineraryState.loading && !isEmpty(itineraryState.response)) {
      setLoading(false);
    }

    if (!itineraryState.loading && !isEmpty(itineraryState.error)) {
      toast.error(itineraryState.error?.error_data || STD_ERROR_MESSAGE);
    }
  }, [itineraryState]);

  return (
    <div
      style={{
        paddingBottom: "2rem",
        backgroundImage: `url(${PatternBackground})`,
        backgroundRepeat: "repeat",
        backgroundColor: "var(--secondary-color)",
        minHeight: `calc(100vh - ${minHeight}px)`,
      }}
    >
      <div
        style={{
          maxWidth: "480px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      ></div>
      {!loading ? (
        !isEmpty(itineraryData) ? (
          <LazyLoadComponent>
            <div className="pt-5 px-4 container">
              <h4
                className={`${
                  isMobileOrTablet
                    ? "parisienne-normal-fs40-lh80 mb-3"
                    : "parisienne-normal-fs72-lh80 mb-5"
                } high-emphasis-color`}
              >
                Timeline
              </h4>
              <div
                style={{
                  ...(isMobileOrTablet
                    ? { maxWidth: "100%" }
                    : { maxWidth: "650px" }),
                  height: "auto",
                }}
              >
                <Carousel
                  indicators={true}
                  interval={null}
                  wrap={false}
                  activeIndex={currItineraryIndex}
                  onSelect={handleSelect}
                >
                  {itineraryData.map((itinerary) => (
                    <Carousel.Item key={itinerary.id}>
                      <img
                        style={{
                          ...(isMobileOrTablet
                            ? { maxWidth: "100%" }
                            : { maxWidth: "650px" }),
                          height: "auto",
                        }}
                        src={getImageUrl(itinerary.image?.bucket_path)}
                        alt=""
                      />
                    </Carousel.Item>
                  ))}
                </Carousel>
              </div>
            </div>
          </LazyLoadComponent>
        ) : (
          <h4 className="text-center pr20-lh32 high-emphasis-color pt-5">
            You have no itinerary
          </h4>
        )
      ) : (
        <CustomSpinner />
      )}
    </div>
  );
};

export default UserTimeline;
