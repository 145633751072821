import React from "react";
import ButtonAccent from "../../common/button-accent/ButtonAccent";
import InputBox from "../../common/input-box/InputBox";
import cancelIcon from "../../../assets/icon/cancel.svg";
import { useDispatch } from "react-redux";
import SelectBox from "../../common/select-box/SelectBox";
import * as Yup from "yup";
import { Formik, Form, Field, FieldArray } from "formik";
import { getUserId, getUserRole } from "../../../utils/utils";
import {
  EVENT_TYPES,
  ROLE_CATEGORIES,
  STD_ERROR_MESSAGE,
} from "../../../utils/constant";
import { toast } from "react-toastify";
import {
  createCollection,
  getAllCollections,
} from "../../../services/collections.service";
import PhoneInput from "../../common/v2/phone-input/PhoneInput";
import Error from "../../common/v2/error/Error";

import { isValidPhoneNumber } from "react-phone-number-input";

import "./create-collection-popup.css";

const options = [
  {
    value: EVENT_TYPES.birthday,
    label: "Birthday",
  },
  {
    value: EVENT_TYPES.wedding,
    label: "Wedding",
  },
  {
    value: EVENT_TYPES.event,
    label: "Event",
  },
  {
    value: EVENT_TYPES.apartment,
    label: "Apartment",
  },
  {
    value: EVENT_TYPES.bnichapter,
    label: "BNI Chapter",
  },
];

const orgOptions = [
  {
    value: EVENT_TYPES.annualevents,
    label: "Annual Events",
  },
  {
    value: EVENT_TYPES.sportsevents,
    label: "Sports Events",
  },
  {
    value: EVENT_TYPES.culturalevents,
    label: "Cultural Events",
  },
];

const CreateCollectionPopup = (props) => {
  const { handleClose } = props;
  const dispatch = useDispatch();

  const emptyClient = {
    name: "",
    email: "",
    mobile: "",
  };

  const initialValues = {
    event: {
      em_id: "",
      category_id: "",
      name: "",
      start_date: "",
      end_date: null,
    },
    clients: [emptyClient],
  };

  const onSubmit = (values) => {
    const valuesCopy = JSON.parse(JSON.stringify(values));
    valuesCopy.event.em_id = getUserId();
    createCollection(valuesCopy)
      .then((response) => {
        handleClose();
        dispatch(getAllCollections());
      })
      .catch((err) => {
        toast.error(err?.data?.error_data || STD_ERROR_MESSAGE);
      });
  };

  const validationSchema = Yup.object().shape({
    event: Yup.object().shape({
      name: Yup.string().required("Please enter the collection name"),
      start_date: Yup.date().required("Please select the event date"),
      category_id: Yup.string().required("Please select the collection type"),
    }),

    clients: Yup.array().when({
      is:
        getUserRole() != ROLE_CATEGORIES.orgManager ||
        getUserRole() != ROLE_CATEGORIES.schoolManager,
      then: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required("Please enter client name"),
          email: Yup.string()
            .required("Please enter client email")
            .email("Invalid email"),
          mobile: Yup.string()
            .required("Please enter your mobile number.")
            .test("validatePhoneNumber", "Invalid mobile number", (val) => {
              return val && isValidPhoneNumber(val);
            }),
        })
      ),
    }),
  });

  return (
    <div className="popup">
      <div className="box">
        <img
          className="close-icon"
          onClick={handleClose}
          src={cancelIcon}
          alt=""
        />
        <h1 className="pb20-lh32 mb-3"> CREATE NEW COLLECTION</h1>
        <div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {(formik) => (
              <Form>
                <Field name="event.name">
                  {(props) => {
                    const { field, meta } = props;
                    return (
                      <>
                        <InputBox
                          type="text"
                          placeholder="Enter your collection Name"
                          {...field}
                          errorMsg={
                            meta.touched && meta.error ? meta.error : ""
                          }
                        />
                      </>
                    );
                  }}
                </Field>
                <div className="form-inline">
                  <Field name="event.start_date">
                    {(props) => {
                      const { field, meta } = props;
                      return (
                        <>
                          <InputBox
                            type="date"
                            className="col-sm-6 pl-0 padding-right-24"
                            placeholder="Event date"
                            {...field}
                            errorMsg={
                              meta.touched && meta.error ? meta.error : ""
                            }
                          />
                        </>
                      );
                    }}
                  </Field>
                  <Field name="event.category_id">
                    {(props) => {
                      const { field, meta } = props;
                      return (
                        <>
                          <SelectBox
                            options={
                              getUserRole() != ROLE_CATEGORIES.orgManager &&
                              getUserRole() != ROLE_CATEGORIES.schoolManager
                                ? options
                                : orgOptions
                            }
                            className="mb-3 col col-sm-6 p-0"
                            placeholder="Collection Type"
                            {...field}
                            errorMsg={
                              meta.touched && meta.error ? meta.error : ""
                            }
                          />
                        </>
                      );
                    }}
                  </Field>
                </div>
                {getUserRole() &&
                  (getUserRole() != ROLE_CATEGORIES.orgManager &&
                  getUserRole() != ROLE_CATEGORIES.schoolManager ? (
                    <FieldArray name="clients">
                      {(fieldArrayProps) => {
                        const { push, form } = fieldArrayProps;
                        const { values } = form;
                        const { clients } = values;
                        return (
                          <div>
                            {clients.map((client, index) => (
                              <div key={index}>
                                <h2 className="pr14-lh24">{`Enter Client ${
                                  index + 1
                                } Details`}</h2>
                                <Field name={`clients[${index}].name`}>
                                  {(props) => {
                                    const { field, meta } = props;
                                    return (
                                      <>
                                        <InputBox
                                          type="text"
                                          placeholder="Name"
                                          {...field}
                                          errorMsg={
                                            meta.touched && meta.error
                                              ? meta.error
                                              : ""
                                          }
                                        />
                                      </>
                                    );
                                  }}
                                </Field>
                                <Field name={`clients[${index}].mobile`}>
                                  {(props) => {
                                    const { field, form, meta } = props;
                                    return (
                                      <div className="mb-3">
                                        <PhoneInput
                                          value={field.value}
                                          onChange={(value) =>
                                            form.setFieldValue(
                                              field.name,
                                              value
                                            )
                                          }
                                          name={field.name}
                                          placeholder="Mobile Number"
                                          onBlur={form.handleBlur}
                                          error={meta.touched && meta.error}
                                        />
                                        {meta.touched && meta.error && (
                                          <Error
                                            errorMsg={meta.error}
                                            className="mt-2"
                                          />
                                        )}
                                      </div>
                                    );
                                  }}
                                </Field>
                                <Field name={`clients[${index}].email`}>
                                  {(props) => {
                                    const { field, meta } = props;
                                    return (
                                      <InputBox
                                        type="email"
                                        placeholder="Email Id"
                                        {...field}
                                        errorMsg={
                                          meta.touched && meta.error
                                            ? meta.error
                                            : ""
                                        }
                                      />
                                    );
                                  }}
                                </Field>
                              </div>
                            ))}
                            <span
                              className={
                                values.clients.length === 1
                                  ? "float-right pb14-lh21 pt-cursor"
                                  : "d-none"
                              }
                              onClick={() => push(emptyClient)}
                            >
                              +Add client
                            </span>
                          </div>
                        );
                      }}
                    </FieldArray>
                  ) : null)}
                <ButtonAccent
                  type="submit"
                  className="button-w100-mt21 pb14-lh21"
                  label="CREATE COLLECTION"
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default CreateCollectionPopup;
