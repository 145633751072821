import React, { useRef, useState, useEffect } from "react";
import { isEmpty, size } from "lodash";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import { toast } from "react-toastify";
import * as Yup from "yup";

import editWhiteIcon from "../../../assets/icon/edit_white.svg";
import { Field, Form, Formik } from "formik";
import {
  getPresignedUrlPromise,
  uploadImageUsingPresignedUrlPromise,
} from "../../../services/uploadImage.service";

import { AD_BUCKET_PATH, STD_ERROR_MESSAGE } from "../../../utils/constant";
import { getImageUrl } from "../../../utils/utils.js";
import ButtonPrimary from "../../common/button-primary/ButtonPrimary";
import { fetchKnowTheHostSection } from "../../../services/knowTheHost.service";
import errorInfoIcon from "../../../assets/icon/info-red.svg";
import {
  fetchTestimonial,
  updateTestimonialBySequencePromise,
} from "../../../services/testimonial.service";

const TestimonialEdit = (props) => {
  const imageRef = useRef();
  const params = useParams();
  const dispatch = useDispatch();

  const [submitting, setSubmitting] = useState(false);

  const [imageFile, setImageFile] = useState(null);

  const [previewImg, setPreviewImg] = useState(null);

  const initialValues = {
    title: props.kthSection.title ? props.kthSection.title : "",
    text: props.kthSection.text ? props.kthSection.text : "",
  };

  useEffect(() => {
    if (imageFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImg(reader.result);
      };
      reader.readAsDataURL(imageFile);
    }
  }, [imageFile]);

  function onSubmit(values) {
    if (!submitting) {
      setSubmitting(true);
      if (imageFile) {
        let imageId = "";
        const presignedUrlPayload = [
          {
            bucket_path: `${AD_BUCKET_PATH.entity}/${params.entityId}/${AD_BUCKET_PATH.testimonial}/${imageFile.name}`,
            image_name: imageFile.name,
          },
        ];

        getPresignedUrlPromise(presignedUrlPayload)
          .then((response) => {
            imageId = response?.res?.data?.data?.[0]?.image_id;
            return uploadImageUsingPresignedUrlPromise(
              response?.res?.data?.data?.[0]?.upload_url,
              imageFile
            );
          })
          .then((response) => {
            const valuesCopy = { ...values };
            valuesCopy.image_id = imageId;
            return updateTestimonialBySequencePromise(
              valuesCopy,
              params.entityId,
              props.kthSection.sequence
            );
          })
          .then((res) => {
            setSubmitting(false);
            dispatch(fetchTestimonial(params.entityId));
            props.onCancelClick();
          })
          .catch((err) => {
            toast.error(
              !isEmpty(err?.data?.error_data)
                ? err?.data?.error_data
                : STD_ERROR_MESSAGE
            );
          });
      } else {
        updateTestimonialBySequencePromise(
          values,
          params.entityId,
          props.kthSection.sequence
        )
          .then((res) => {
            setSubmitting(false);
            dispatch(fetchTestimonial(params.entityId));
            props.onCancelClick();
          })
          .catch((err) => {
            toast.error(
              !isEmpty(err?.data?.error_data)
                ? err?.data?.error_data
                : STD_ERROR_MESSAGE
            );
          });
      }
    } else {
      // already submitting
    }
  }

  function handleSectionImageChange(entity) {
    const file = entity.target.files[0];
    if (file && file.type.substr(0, 5) === "image") {
      setImageFile(file);
    }
  }

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Please enter the title"),
    text: Yup.string().required("Please enter caption/body text"),
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {(formik) => (
        <Form>
          <div
            className="p-3 mb-3"
            style={{
              border: "1px solid rgba(41, 41, 41, 0.38)",
              borderRadius: "8px",
            }}
          >
            <div className="d-flex">
              <div
                className="secondary-color-bg"
                style={{ width: "180px", height: "180px", borderRadius: "50%" }}
              >
                <>
                  <input
                    type="file"
                    style={{ display: "none" }}
                    ref={imageRef}
                    accept="image/png,image/jpg,image/jpeg"
                    name="image"
                    onChange={(entity) => handleSectionImageChange(entity)}
                  />
                  <div className="h-100 position-relative">
                    <img
                      src={
                        previewImg ||
                        getImageUrl(props.kthSection?.image?.bucket_path)
                      }
                      alt=""
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "50%",
                      }}
                    />
                    <div
                      className="d-flex justify-content-center align-items-center pt-cursor"
                      style={{
                        position: "absolute",
                        background: "rgba(0, 0, 0, 0.8)",
                        bottom: "12px",
                        right: "12px",
                        width: "35px",
                        height: "35px",
                        borderRadius: "50%",
                      }}
                    >
                      <img
                        className="edit-collection-thumbnail-icon"
                        style={{
                          width: "20px",
                          height: "20px",
                        }}
                        src={editWhiteIcon}
                        alt=""
                        onClick={(entity) => {
                          entity.preventDefault();
                          imageRef.current.click();
                        }}
                      />
                    </div>
                  </div>
                </>
              </div>
              <div className="flex-1 ml-3">
                <div>
                  <div className="mb-1 d-flex justify-content-between flex-column">
                    <Field name="title">
                      {(props) => {
                        const { field, form, meta } = props;
                        return (
                          <>
                            <input
                              className="pb18-lh32 high-emphasis-color"
                              style={{
                                outline: "none",
                                border: "none",
                              }}
                              type="text"
                              placeholder="Type Header here..."
                              {...field}
                              // errorMsg={meta.touched && meta.error ? meta.error : ""}
                            />
                            {meta.touched && meta.error ? (
                              <div className="d-flex align-items-center justify-content-start mt-2 mb-2">
                                <img src={errorInfoIcon} alt="" />
                                <span className="margin-left-5 pr10-lh15 error-color">
                                  {meta.error}
                                </span>
                              </div>
                            ) : null}
                          </>
                        );
                      }}
                    </Field>
                  </div>
                  <Field name="text">
                    {(props) => {
                      const { field, form, meta } = props;
                      return (
                        <div>
                          <textarea
                            placeholder="Type caption / body text here..."
                            className="img-description-textarea pr16-lh24"
                            style={{
                              border: "none",
                              resize: "none",
                              height: "135px",
                            }}
                            {...field}
                            // errorMsg={meta.touched && meta.error ? meta.error : ""}
                          />
                          {meta.touched && meta.error ? (
                            <div className="d-flex align-items-center justify-content-start mt-2 mb-2">
                              <img src={errorInfoIcon} alt="" />
                              <span className="margin-left-5 pr10-lh15 error-color">
                                {meta.error}
                              </span>
                            </div>
                          ) : null}
                        </div>
                      );
                    }}
                  </Field>
                </div>
              </div>
            </div>
            <div className="mt-1 d-flex justify-content-end">
              <ButtonPrimary label="Cancel" onClick={props.onCancelClick} />
              <LoadingButton
                size="small"
                color="secondary"
                sx={{
                  textTransform: "none",
                  fontFamily: "'Poppins', sans-serif",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: "14px",
                  lineHeight: "21px",
                  padding: "4px 18px",
                  backgroundColor: "var(--accent-color)",
                  color: "var(--primary-color)",
                  "&:hover": {
                    backgroundColor: "var(--accent-color)",
                    color: "var(--primary-color)",
                  },
                  marginLeft: "1rem",
                }}
                variant="contained"
                type="submit"
                loading={submitting}
              >
                Update
              </LoadingButton>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default TestimonialEdit;
