import { 
    REQUEST_MASTER_GUEST_INVITES_FETCH_EM,
    REQUEST_INVITE_GUEST_INVITES_FETCH_EM,
    REQUEST_ITINERARY_GUEST_INVITES_FETCH_EM,
    FETCH_INVITE_GUEST_INVITES_EM_SUCCESS,
    FETCH_MASTER_GUEST_INVITES_EM_SUCCESS,
    FETCH_ITINERARY_GUEST_INVITES_EM_SUCCESS,
    FETCH_GUEST_INVITES_EM_FAILURE,
    CLEAR_FETCH_GUEST_INVITES_EM_STATES
} from './guestInvites.types';

let initialState = {
    reload: false,
    masterGuestListResponse : {},
    inviteGuestListResponse : {},
    itineraryGuestListResponse : {},
    error : null,
    loading : false
}

const fetchGuestInvitesEmReducer = (state = initialState, action) => {

    switch(action.type) {
        case REQUEST_MASTER_GUEST_INVITES_FETCH_EM :
            return {
                ...state,
                loading: true,
                error: null
            }

        case FETCH_MASTER_GUEST_INVITES_EM_SUCCESS:
            return {
                ...state,
                loading : false,
                masterGuestListResponse : action.payload,
                reload: false
            }

        case REQUEST_INVITE_GUEST_INVITES_FETCH_EM :
            return {
                ...state,
                loading: true,
                error: null
            }

        case FETCH_INVITE_GUEST_INVITES_EM_SUCCESS:
            return {
                ...state,
                loading : false,
                inviteGuestListResponse : {
                    ...state.inviteGuestListResponse,
                    [action.payload.id]: action.payload.response
                },
                reload: false
            }

        case REQUEST_ITINERARY_GUEST_INVITES_FETCH_EM :
            return {
                ...state,
                loading: true,
                error: null
            }

        case FETCH_ITINERARY_GUEST_INVITES_EM_SUCCESS:
            return {
                ...state,
                loading : false,
                itineraryGuestListResponse : {
                    ...state.itineraryGuestListResponse,
                    [action.payload.id]: action.payload.response
                },
                reload: false
            }

        case FETCH_GUEST_INVITES_EM_FAILURE:
            return {
                ...state,
                loading : false,
                error : action.payload,
                reload: false
            }
        
        case CLEAR_FETCH_GUEST_INVITES_EM_STATES:
            return {
                ...state,
                loading : false,
                masterGuestListResponse : {},
                inviteGuestListResponse: {},
                itineraryGuestListResponse: {},
                error : null,
                reload: false
            }

        default:
            return state;
    }
}

export default fetchGuestInvitesEmReducer