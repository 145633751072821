import { 
    REQUEST_GALLERY_FOLDERS_FETCH_EM,
    FETCH_GALLERY_FOLDERS_EM_SUCCESS,
    FETCH_GALLERY_FOLDERS_EM_FAILURE,
    CLEAR_FETCH_GALLERY_FOLDERS_EM_STATES
} from './galleryFolders.types';

let initialState = {
    reload: false,
    response : {},
    error : null,
    loading : false
}

const fetchGalleryFoldersEmReducer = (state = initialState, action) => {

    switch(action.type) {
        case REQUEST_GALLERY_FOLDERS_FETCH_EM :
            return {
                ...state,
                loading: true,
                error: null
            }

        case FETCH_GALLERY_FOLDERS_EM_SUCCESS:
            return {
                ...state,
                loading : false,
                response : {
                    ...state.response,
                    [action.payload.id]: action.payload.response
                },
                reload: false
            }

        case FETCH_GALLERY_FOLDERS_EM_FAILURE:
            return {
                ...state,
                loading : false,
                error : action.payload,
                reload: false
            }
        
        case CLEAR_FETCH_GALLERY_FOLDERS_EM_STATES:
            return {
                ...state,
                loading : false,
                response : {},
                error : null,
                reload: false
            }

        default:
            return state;
    }
}

export default fetchGalleryFoldersEmReducer