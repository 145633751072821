import { isEmpty } from "lodash";
import CollectionHeaderPartner from "../../collection-header-partner/CollectionHeaderPartner";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import {
  createPermaLink,
  getPermaLinkById,
  updatePermalinkById,
} from "../../../../services/permalink";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { getPresignedUrlPromise } from "../../../../services/uploadImage.service";
import { uploadImageUsingPresignedUrlPromise } from "../../../../services/uploadImage.service";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { toast } from "react-toastify";
import { AD_BUCKET_PATH, STD_ERROR_MESSAGE } from "../../../../utils/constant";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useRef } from "react";
import * as Yup from "yup";
import ButtonAccent from "../../../common/button-accent/ButtonAccent";
import PublishIcon from "../../../../assets/icon/publish_web.svg";
import UnpublishIcon from "../../../../assets/icon/unpublish.svg";
import errorInfoIcon from "../../../../assets/icon/error_icon_red.svg";
import CustomSpinner from "../../../common/spinner/CustomSpinner";
import { getImageUrl } from "../../../../utils/utils";
import Error from "../../../common/v2/error/Error";
import { updateEntity } from "../../../../services/entities.service";
import { fetchEntityAdSuccess } from "../../../../redux/entity/entity.action";

export const PreviewLogo = (props) => {
  const { className, src, onClick } = props;
  return (
    <div
      style={{
        padding: "12px 24px",
        border: "1px solid var(--high-emphasis)",
        minWidth: "140px",
        borderRadius: "4px",
        marginRight: "1rem",
      }}
      className={`d-flex justify-content-center ${className || ""}`}
      onClick={onClick}
    >
      <LazyLoadComponent>
        <img
          style={{
            maxHeight: "28px",
            height: "auto",
            width: "auto",
          }}
          src={src}
          alt=""
        />
      </LazyLoadComponent>
    </div>
  );
};

const SectionPermalink = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [logoSubmitting, setLogoSubmitting] = useState(false);
  const params = useParams();
  const permalinkState = useSelector((state) => state.permalinkAd);
  const permalinkData = permalinkState.response?.data;
  const [errorMessage, setErrorMessage] = useState({});

  const entityState = useSelector((state) => state.entityAd);
  const entityData = entityState.response?.data;

  const initialValues = {
    permalink: !isEmpty(permalinkData) ? permalinkData.permalink : "",
    publish: false,
  };

  const imagesRef = useRef({
    logo: null,
  });

  const [imageFiles, setImageFiles] = useState({
    logo: null, //{[key = mobile | desktop | tablet | logo]: {file: null, preview: null}}
  });

  useEffect(() => {
    if (imageFiles.current && imageFiles[imageFiles.current]?.file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageFiles((ps) => {
          return {
            ...ps,
            current: null,
            [ps.current]: {
              ...ps[ps.current],
              preview: reader.result,
            },
          };
        });
      };
      reader.readAsDataURL(imageFiles[imageFiles.current].file);
    }
  }, [imageFiles]);

  const validationSchema = Yup.object({
    permalink: Yup.string()
      .required("Permalink cannot be blank")
      .matches(
        /^[a-zA-Z][a-zA-Z0-9-_]*$/,
        "Please start with alphabets and use alphabets, numerics, hyphen and underscores only"
      ),
  });

  function handleImageChange(event, logo) {
    const file = event.target.files[0];
    if (errorMessage?.logoImage) {
      setErrorMessage((ps) => {
        const { logoImage, ...rest } = ps;
        return {
          ...rest,
        };
      });
    }
    setImageFiles((ps) => {
      return {
        ...ps,
        current: logo,
        [logo]: {
          file,
        },
      };
    });
  }

  function uploadImagePromise(imageFile, device) {
    let imageId = "";
    const presignedUrlPayload = [
      {
        bucket_path: `${AD_BUCKET_PATH.entity}/${params.entityId}/${AD_BUCKET_PATH.logo}/${imageFile.name}`,
        image_name: imageFile.name,
      },
    ];
    return getPresignedUrlPromise(presignedUrlPayload, { device })
      .then((response) => {
        imageId = response?.res?.data?.data?.[0]?.image_id;
        return uploadImageUsingPresignedUrlPromise(
          response?.res?.data?.data?.[0]?.upload_url,
          imageFile,
          { device, imageId }
        );
      })
      .then((res) => {
        return updateEntity({ logo_image_id: imageId }, entityData.id);
      })
      .then((res) => {
        dispatch(fetchEntityAdSuccess(res.data));
        setLogoSubmitting(false);
        toast.info("Logo added successfully");
      })
      .catch((err) => {
        setLogoSubmitting(false);
        toast.error(
          !isEmpty(err?.data?.error_data)
            ? err?.data?.error_data
            : STD_ERROR_MESSAGE
        );
      });
  }

  useEffect(() => {
    if (isEmpty(permalinkState.response)) {
      dispatch(getPermaLinkById(params.entityId));
    }
  }, []);

  useEffect(() => {
    if (!permalinkState.loading && !isEmpty(permalinkState.response)) {
      setLoading(false);
    }

    if (!permalinkState.loading && !isEmpty(permalinkState.error)) {
      setLoading(false);
      toast.error(permalinkState.error?.data?.error_data || STD_ERROR_MESSAGE);
    }
  }, [permalinkState]);

  const handleOnPublishChangeEvent = () => {
    const publishStatus = permalinkData?.publish;
    const entityId = permalinkData?.entity_id;
    if (typeof publishStatus === "boolean" && entityId) {
      updatePermalinkById(entityId, {
        publish: !publishStatus,
      })
        .then((res) => {
          dispatch(getPermaLinkById(entityId));
          toast.info(
            "Permalink " +
              `${!publishStatus ? "published" : "unpublished"}` +
              " successfully."
          );
        })
        .catch((error) =>
          toast.error(error?.data?.error_data || STD_ERROR_MESSAGE)
        );
    } else {
      toast.error(STD_ERROR_MESSAGE);
    }
  };

  const onSubmitPermalink = (values) => {
    if (!submitting) {
      setSubmitting(true);
      const valuesCopy = JSON.parse(JSON.stringify(values));

      if (permalinkData) {
        permalinkData.permalink = values.permalink;
        updatePermalinkById(params.entityId, permalinkData)
          .then((response) => {
            setSubmitting(false);
            dispatch(getPermaLinkById(params.entityId));
            toast.info("Permalink updated successfully.");
          })
          .catch((err) => {
            setSubmitting(false);
            toast.error(err?.data?.error_data || STD_ERROR_MESSAGE);
          });
      } else {
        createPermaLink(params.entityId, valuesCopy)
          .then((response) => {
            setSubmitting(false);
            dispatch(getPermaLinkById(params.entityId));
            toast.info("Permalink created successfully.");
          })
          .catch((err) => {
            setSubmitting(false);
            toast.error(err?.data?.error_data || STD_ERROR_MESSAGE);
          });
      }
    }
  };

  const onSubmitLogo = () => {
    if (!imageFiles.logo?.file) {
      setErrorMessage({
        ...(!imageFiles.logo?.file
          ? { logoImage: "Please upload the entity logo" }
          : {}),
      });
    } else {
      if (!logoSubmitting) {
        setLogoSubmitting(true);
        uploadImagePromise(imageFiles.logo?.file, "logo");
      }
    }
  };

  function errorElement(msg) {
    return (
      <div className="d-flex align-items-center mt-3 pr12-lh18 error-color">
        <img
          className="mr-2"
          style={{ width: "16px", height: "16px" }}
          src={errorInfoIcon}
          alt=""
        />
        <span className="mb-0">{msg}</span>
      </div>
    );
  }

  return (
    <main
      style={{ maxWidth: "850px", marginLeft: "auto", marginRight: "auto" }}
    >
      {!loading ? (
        <>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmitPermalink}
          >
            {(formik) => (
              <Form>
                <CollectionHeaderPartner
                  title="Add Permalink  "
                  loadingBtn={true}
                  loadingBtnLabel="Save Permalink"
                  loadingBtnProps={{ type: "submit", loading: submitting }}
                />
                <div className="mt-3">
                  <div
                    style={{
                      border: "2px solid rgba(41, 41, 41, 0.38)",
                      borderRadius: "8px",
                    }}
                  >
                    <Field name="permalink">
                      {(props) => {
                        const { field, meta } = props;
                        return (
                          <div
                            className="mb-2"
                            style={{
                              padding: "2rem 2rem",
                            }}
                          >
                            <textarea
                              placeholder="Enter a permalink..."
                              className="img-description-textarea pr16-lh24"
                              style={{
                                padding: "1rem 2rem",
                                border: "none",
                                resize: "none",
                                height: "100px",
                                width: "100%",
                              }}
                              {...field}
                            />
                            {meta.touched && meta.error
                              ? errorElement(meta.error)
                              : ""}
                          </div>
                        );
                      }}
                    </Field>
                  </div>
                  <ButtonAccent
                    variant="icon-button"
                    className="d-flex align-items-center justify-content-center"
                    startIcon={
                      permalinkData?.publish ? UnpublishIcon : PublishIcon
                    }
                    label={permalinkData?.publish ? "Unpublish" : "Publish"}
                    labelClassName="ml-2"
                    disabled={permalinkData?.permalink == undefined}
                    startIconClassName="btn-icon-small"
                    customStyling={{
                      width: "20%",
                      padding: "6px 16px",
                      marginTop: "20px",
                    }}
                    onClick={handleOnPublishChangeEvent}
                  />
                </div>
              </Form>
            )}
          </Formik>
          <Formik initialValues={{}} onSubmit={onSubmitLogo}>
            {(formik) => (
              <Form>
                <div className="mt-5">
                  <CollectionHeaderPartner
                    title={"Add Logo"}
                    loadingBtn={true}
                    loadingBtnProps={{
                      type: "submit",
                      loading: logoSubmitting,
                    }}
                    loadingBtnLabel={"Save logo"}
                  />
                  <div className="d-flex align-items-end">
                    {!isEmpty(entityData?.logo) || imageFiles.logo?.preview ? (
                      <PreviewLogo
                        src={
                          imageFiles.logo?.preview ||
                          getImageUrl(entityData.logo?.bucket_path)
                        }
                      />
                    ) : null}
                    <Button
                      variant="outlined"
                      startIcon={
                        !isEmpty(entityData?.logo) || imageFiles.logo?.file ? (
                          <EditIcon />
                        ) : (
                          <AddIcon />
                        )
                      }
                      style={{ marginTop: "2rem" }}
                      sx={{
                        textTransform: "none",
                        fontFamily: "'Poppins', sans-serif",
                        fontStyle: "normal",
                        fontWeight: "600",
                        fontSize: "16px",
                        lineHeight: "24px",
                        color: "var(--high-emphasis)",
                        borderColor: "var(--high-emphasis)",
                        "&:hover": {
                          backgroundColor: "transparent",
                          borderColor: "var(--high-emphasis)",
                        },
                      }}
                      onClick={(event) => {
                        event.preventDefault();
                        imagesRef.current["logo"].click();
                      }}
                    >
                      {!isEmpty(entityData?.logo) || imageFiles.logo?.file
                        ? "Change the logo"
                        : "Add a logo"}
                    </Button>
                    <input
                      ref={(element) => (imagesRef.current["logo"] = element)}
                      type="file"
                      style={{ display: "none" }}
                      accept="image/png,image/jpg,image/jpeg,image/svg+xml"
                      name={`logoImage`}
                      onChange={(event) => handleImageChange(event, "logo")}
                    />
                  </div>
                  {Boolean(errorMessage.logoImage) ? (
                    <Error errorMsg={errorMessage.logoImage} className="mt-2" />
                  ) : null}
                </div>
              </Form>
            )}
          </Formik>
        </>
      ) : (
        <CustomSpinner />
      )}
    </main>
  );
};
export default SectionPermalink;
