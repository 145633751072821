import React, { useState, useRef, useEffect, forwardRef } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import { Formik, Form } from "formik";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import TabletMacIcon from "@mui/icons-material/TabletMac";
import LaptopIcon from "@mui/icons-material/Laptop";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { LazyLoadComponent } from "react-lazy-load-image-component";

import CollectionHeaderPartner from "../collection-header-partner/CollectionHeaderPartner";
import Error from "../../common/v2/error/Error";

import { getImageUrl } from "../../../utils/utils";
import {
  BUCKET_PATH,
  STD_ERROR_MESSAGE,
  SECTION_VENUE_COMPONENTS,
} from "../../../utils/constant.js";
import {
  getPresignedUrlPromise,
  uploadImageUsingPresignedUrlPromise,
} from "../../../services/uploadImage.service";
import {
  createVenuePromise,
  getAllVenuesInCollection,
  updateVenueByIdPromise,
} from "../../../services/venue.service";

export const DeviceIdentifyIconButton = (props) => {
  const { device } = props;

  function getDeviceIcon() {
    switch (device) {
      case "mobile":
        return <PhoneIphoneIcon sx={{ cursor: "default" }} />;
      case "tablet":
        return <TabletMacIcon sx={{ cursor: "default" }} />;
      default:
        return <LaptopIcon sx={{ cursor: "default" }} />;
    }
  }

  return (
    <IconButton
      style={{
        position: "absolute",
        bottom: "7px",
        left: "7px",
        zIndex: "1",
        color: "white",
        backgroundColor: "var(--primary-color)",
        "&:hover": {
          backgroundColor: "var(--primary-color)",
        },
      }}
      size="small"
    >
      {getDeviceIcon()}
    </IconButton>
  );
};

// const CloseIconButton = (props) => {
//   const { onCloseButtonClick } = props;
//   return (
//     <IconButton
//       sx={{
//         position: "absolute",
//         top: "-14px",
//         right: "-14px",
//         zIndex: "1",
//         color: "white",
//         backgroundColor: "var(--error-color)",
//         "&:hover": {
//           backgroundColor: "var(--error-color)",
//         },
//       }}
//       size="small"
//       onClick={onCloseButtonClick}
//     >
//       <CloseIcon />
//     </IconButton>
//   );
// };

export const PreviewLogo = (props) => {
  const { className, src, onClick } = props;
  return (
    <div
      style={{
        padding: "12px 24px",
        border: "1px solid var(--high-emphasis)",
        minWidth: "180px",
        borderRadius: "4px",
        marginRight: "1rem",
      }}
      className={`d-flex justify-content-center ${className || ""}`}
      onClick={onClick}
    >
      <LazyLoadComponent>
        <img
          style={{
            maxHeight: "28px",
            height: "auto",
            width: "auto",
          }}
          src={src}
          alt=""
        />
      </LazyLoadComponent>
    </div>
  );
};

export const PreviewImage = (props) => {
  const { src } = props;
  return (
    <img
      style={{
        width: "auto",
        height: "auto",
        maxWidth: "100%",
        maxHeight: "250px",
      }}
      src={src}
      alt=""
    />
  );
};

const ImageInputIconButton = forwardRef((props, ref) => {
  const { device, handleImageChange } = props;

  function getDeviceIcon() {
    switch (device) {
      case "mobile":
        return <PhoneIphoneIcon sx={{ fontSize: "40px" }} />;
      case "tablet":
        return <TabletMacIcon sx={{ fontSize: "40px" }} />;
      default:
        return <LaptopIcon sx={{ fontSize: "40px" }} />;
    }
  }

  return (
    <div>
      <div
        className="p-3 pt-cursor"
        style={{
          border: "1px solid rgba(41, 41, 41, 0.38)",
          borderRadius: "50%",
        }}
        onClick={(event) => {
          event.preventDefault();
          ref.current[device].click();
        }}
      >
        {getDeviceIcon()}
      </div>
      <input
        ref={(element) => (ref.current[device] = element)}
        type="file"
        style={{ display: "none" }}
        accept="image/png,image/jpg,image/jpeg"
        name={`${device}Image`}
        onChange={(event) => handleImageChange(event, device)}
      />
    </div>
  );
});

const AddOrEditVenue = (props) => {
  const { onCancelClick, changeNextComponent, action } = props;
  const venueState = useSelector((state) => state.venueEm);
  const imagesRef = useRef({
    mobile: null,
    tablet: null,
    desktop: null,
    logo: null,
  });
  const [errorMessage, setErrorMessage] = useState({});
  const [imageFiles, setImageFiles] = useState({
    current: null, // mobile | desktop | tablet | logo
    mobile: null,
    tablet: null,
    desktop: null,
    logo: null, //{[key = mobile | desktop | tablet | logo]: {file: null, preview: null}}
  });
  const [submitting, setSubmitting] = useState(false);

  const dispatch = useDispatch();
  const params = useParams();

  const venueData = venueState.response?.data?.[0];

  function uploadImagePromise(imageFile, device) {
    const presignedUrlPayload = [
      {
        bucket_path: `${BUCKET_PATH.collection}/${params.collectionId}/${BUCKET_PATH.venue}/${imageFile.name}`,
        image_name: imageFile.name,
      },
    ];
    return getPresignedUrlPromise(presignedUrlPayload, { device }).then(
      (response) => {
        const imageId = response?.res?.data?.data?.[0]?.image_id;
        return uploadImageUsingPresignedUrlPromise(
          response?.res?.data?.data?.[0]?.upload_url,
          imageFile,
          { device, imageId }
        );
      }
    );
  }

  const onSubmit = () => {
    if (
      action !== "edit" &&
      (!imageFiles.desktop?.file ||
        !imageFiles.logo?.file ||
        !imageFiles.mobile?.file ||
        !imageFiles.tablet?.file)
    ) {
      setErrorMessage({
        ...(!imageFiles.desktop?.file
          ? { desktopImage: "Please upload the venue banner for desktop" }
          : {}),
        ...(!imageFiles.logo?.file
          ? { logoImage: "Please upload the venue logo" }
          : {}),
        ...(!imageFiles.mobile?.file
          ? { mobileImage: "Please upload the venue banner for mobile" }
          : {}),
        ...(!imageFiles.tablet?.file
          ? { tabletImage: "Please upload the venue banner for tablet" }
          : {}),
      });
      return;
    } else if (
      !imageFiles.desktop?.file &&
      !imageFiles.logo?.file &&
      !imageFiles.mobile?.file &&
      !imageFiles.tablet?.file
    ) {
      toast.info("No changes made to update");
      return;
    }

    if (!submitting) {
      setSubmitting(true);
      const uploadImgPromises = [];
      if (imageFiles.logo?.file)
        uploadImgPromises.push(
          uploadImagePromise(imageFiles.logo?.file, "logo")
        );
      if (imageFiles.desktop?.file)
        uploadImgPromises.push(
          uploadImagePromise(imageFiles.desktop?.file, "desktop")
        );
      if (imageFiles.mobile?.file)
        uploadImgPromises.push(
          uploadImagePromise(imageFiles.mobile?.file, "mobile")
        );
      if (imageFiles.tablet?.file)
        uploadImgPromises.push(
          uploadImagePromise(imageFiles.tablet?.file, "tablet")
        );

      Promise.all(uploadImgPromises.map((uploadImgPromise) => uploadImgPromise))
        .then((res) => {
          const payload = res.reduce((accumulator, item) => {
            if (item.id.device === "logo")
              return { ...accumulator, logo_image_id: item.id.imageId };
            else if (item.id.device === "mobile")
              return { ...accumulator, mobile_image_id: item.id.imageId };
            else if (item.id.device === "tablet")
              return { ...accumulator, tablet_image_id: item.id.imageId };
            else return { ...accumulator, image_id: item.id.imageId };
          }, {});
          if (action === "edit")
            return updateVenueByIdPromise(
              params.collectionId,
              venueData?.id,
              payload
            );
          else return createVenuePromise(params.collectionId, payload);
        })
        .then((res) => {
          setSubmitting(false);
          dispatch(getAllVenuesInCollection(params.collectionId));
          changeNextComponent(SECTION_VENUE_COMPONENTS.venue_list);
        })
        .catch((err) => {
          toast.error(STD_ERROR_MESSAGE);
          setSubmitting(false);
        });
    }
  };

  useEffect(() => {
    if (imageFiles.current && imageFiles[imageFiles.current]?.file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageFiles((ps) => {
          return {
            ...ps,
            current: null,
            [ps.current]: {
              ...ps[ps.current],
              preview: reader.result,
            },
          };
        });
      };
      reader.readAsDataURL(imageFiles[imageFiles.current].file);
    }
  }, [imageFiles]);

  function handleImageChange(event, device) {
    const file = event.target.files[0];
    if (file && file.type.substr(0, 5) === "image") {
      if (errorMessage?.desktopImage && device === "desktop") {
        setErrorMessage((ps) => {
          const { desktopImage, ...rest } = ps;
          return {
            ...rest,
          };
        });
      }
      if (errorMessage?.logoImage && device === "logo") {
        setErrorMessage((ps) => {
          const { logoImage, ...rest } = ps;
          return {
            ...rest,
          };
        });
      }
      if (errorMessage?.mobileImage && device === "mobile") {
        setErrorMessage((ps) => {
          const { mobileImage, ...rest } = ps;
          return {
            ...rest,
          };
        });
      }
      if (errorMessage?.tabletImage && device === "tablet") {
        setErrorMessage((ps) => {
          const { tabletImage, ...rest } = ps;
          return {
            ...rest,
          };
        });
      }
      setImageFiles((ps) => {
        return {
          ...ps,
          current: device,
          [device]: {
            file,
          },
        };
      });
    }
  }

  // function handleImageCancelEvent(device) {
  //   setImageFiles((ps) => {
  //     return {
  //       ...ps,
  //       [device]: null,
  //     };
  //   });
  // }

  function displayError() {
    if (errorMessage.desktopImage) {
      return <Error errorMsg={errorMessage.desktopImage} className="mt-2" />;
    } else if (errorMessage.mobileImage) {
      return <Error errorMsg={errorMessage.mobileImage} className="mt-2" />;
    } else if (errorMessage.tabletImage) {
      return <Error errorMsg={errorMessage.tabletImage} className="mt-2" />;
    } else {
      return null;
    }
  }

  return (
    <main
      style={{ maxWidth: "850px", marginLeft: "auto", marginRight: "auto" }}
    >
      <Formik initialValues={{}} onSubmit={onSubmit}>
        {() => (
          <Form>
            <CollectionHeaderPartner
              title={action === "edit" ? "Edit Venue" : "Add Venue"}
              loadingBtn={true}
              loadingBtnProps={{
                type: "submit",
                loading: submitting,
              }}
              loadingBtnLabel={action === "edit" ? "Update" : "Save"}
              cancelBtn={true}
              handleCancelBtnClick={onCancelClick}
            />
            <div className="mt-5">
              <div className="d-flex align-items-end">
                {(action === "edit" && !isEmpty(venueData?.logo)) ||
                imageFiles.logo?.preview ? (
                  <PreviewLogo
                    src={
                      imageFiles.logo?.preview ||
                      getImageUrl(venueData.logo?.bucket_path)
                    }
                  />
                ) : null}
                <Button
                  variant="outlined"
                  startIcon={
                    (action === "edit" && !isEmpty(venueData?.logo)) ||
                    imageFiles.logo?.file ? (
                      <EditIcon />
                    ) : (
                      <AddIcon />
                    )
                  }
                  sx={{
                    textTransform: "none",
                    fontFamily: "'Poppins', sans-serif",
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "var(--high-emphasis)",
                    borderColor: "var(--high-emphasis)",
                    "&:hover": {
                      backgroundColor: "transparent",
                      borderColor: "var(--high-emphasis)",
                    },
                  }}
                  onClick={(event) => {
                    event.preventDefault();
                    imagesRef.current["logo"].click();
                  }}
                >
                  {(action === "edit" && !isEmpty(venueData?.logo)) ||
                  imageFiles.logo?.file
                    ? "Change the logo"
                    : "Add a logo"}
                </Button>
                <input
                  ref={(element) => (imagesRef.current["logo"] = element)}
                  type="file"
                  style={{ display: "none" }}
                  accept="image/png,image/jpg,image/jpeg,image/svg+xml"
                  name={`logoImage`}
                  onChange={(event) => handleImageChange(event, "logo")}
                />
              </div>
              {Boolean(errorMessage.logoImage) ? (
                <Error errorMsg={errorMessage.logoImage} className="mt-2" />
              ) : null}
              <div
                className="mt-3 p-3"
                style={{
                  border: "1px solid rgba(41, 41, 41, 0.38)",
                  borderRadius: "8px",
                }}
              >
                <div className="d-flex justify-content-around">
                  <ImageInputIconButton
                    ref={imagesRef}
                    device="mobile"
                    handleImageChange={handleImageChange}
                  />
                  <ImageInputIconButton
                    ref={imagesRef}
                    device="tablet"
                    handleImageChange={handleImageChange}
                  />
                  <ImageInputIconButton
                    ref={imagesRef}
                    device="desktop"
                    handleImageChange={handleImageChange}
                  />
                </div>
              </div>
              {displayError()}
              {imageFiles.mobile?.preview ||
              (action === "edit" && !isEmpty(venueData?.mobile_image)) ||
              imageFiles.tablet?.preview ||
              (action === "edit" && !isEmpty(venueData?.tablet_image)) ||
              imageFiles.desktop?.preview ||
              (action === "edit" && !isEmpty(venueData?.image)) ? (
                <div className="d-flex flex-wrap justify-content-around align-items-start mt-4">
                  {imageFiles.mobile?.preview || venueData?.mobile_image ? (
                    <div className="mb-3" style={{ position: "relative" }}>
                      <PreviewImage
                        src={
                          imageFiles.mobile?.preview ||
                          getImageUrl(venueData?.mobile_image?.bucket_path)
                        }
                      />
                      <DeviceIdentifyIconButton device="mobile" />
                    </div>
                  ) : null}
                  {imageFiles.tablet?.preview || venueData?.tablet_image ? (
                    <div className="mb-3" style={{ position: "relative" }}>
                      <PreviewImage
                        src={
                          imageFiles.tablet?.preview ||
                          getImageUrl(venueData?.tablet_image?.bucket_path)
                        }
                      />
                      <DeviceIdentifyIconButton device="tablet" />
                    </div>
                  ) : null}
                  {imageFiles.desktop?.preview || venueData?.image ? (
                    <div className="mb-3" style={{ position: "relative" }}>
                      <PreviewImage
                        src={
                          imageFiles.desktop?.preview ||
                          getImageUrl(venueData?.image?.bucket_path)
                        }
                      />
                      <DeviceIdentifyIconButton device="desktop" />
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>
          </Form>
        )}
      </Formik>
    </main>
  );
};

export default AddOrEditVenue;
